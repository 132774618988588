import React from "react";
import { LabelizerLayoutDataProps } from "../LabelizerLayout";
import { Input } from "antd";
import moment from "moment";
import Info from "../../../GenericComponents/info";
import { Helpertexts } from "../../../Constants/HelperTexts";

export const ChooseNameSegment = (props: LabelizerLayoutDataProps) => (
  <div className="choose-name">
    <h2>1. General Information</h2>
    <div className="choose-name-content">
      <div className="input-headline">
        Analysis Name
        <Info infotext={Helpertexts["analysisName"]} />{" "}
      </div>
      <Input
        className="input-text"
        placeholder={`My Analysis ${moment().format("YYYY/MM/DD")}`}
        value={props.data.analysisName}
        onChange={(val: any) =>
          props.changeData({ ...props.data, analysisName: val.target.value })
        }
      />
    </div>
  </div>
);
