import { Button } from "antd";
import React from "react";
import logo from "../Labelizer_Image.png";

type Props = {
  history: any;
};
export const Headline: React.FC<Props> = (props) => (
  <div className="headline-frame">
    <div className="headline-flexrow-logo-text">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="text">
        <div className="headline">Labelizer </div>
        <div className="subtitle">A Protein Label Toolkit</div>
      </div>
    </div>
    <div className="links">
      <Button onClick={() => props.history.push("/analysis")} className="link">
        Analysis
      </Button>
      <Button
        onClick={() => props.history.push("/workingGroup")}
        className="link"
      >
        Cite us / Who we are?
      </Button>
      <Button onClick={() => props.history.push("/faq")} className="link">
        FAQs
      </Button>
      <Button onClick={() => props.history.push("/feedback")} className="link">
        Feedback / Problems
      </Button>
    </div>
  </div>
);
