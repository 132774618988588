import { Button, notification } from "antd";
import React from "react";
import Info from "../../../GenericComponents/info";

type SelectChainRadioProps = {
  chainOptions: any[];
  changeSelectedChain: (chain: string) => void;
  selectedChain: string | undefined;
};
export const SelectChainRadio: React.FC<SelectChainRadioProps> = (props) => {
  const { chainOptions, changeSelectedChain, selectedChain } = props;

  const handleSelectedChainChange = (val: any) => {
    const eventSelectedChain: string = val;

    if (`${selectedChain}`.length === 0) {
      changeSelectedChain(eventSelectedChain);
    } else if (`${selectedChain}`.length === 1) {
      if (selectedChain === eventSelectedChain) {
        changeSelectedChain("");
      } else {
        changeSelectedChain(`${selectedChain},${eventSelectedChain}`);
      }
    } else if (`${selectedChain}`.length === 3) {
      const [chain1, chain2] = `${selectedChain}`.split(",");
      if (chain1 === eventSelectedChain) {
        changeSelectedChain(chain2);
      } else if (chain2 === eventSelectedChain) {
        changeSelectedChain(chain1);
      } else {
        notification.warn({
          message:
            "Only two chains are selectable. Please De-Select one chain before selecting another",
        });
      }
    }
  };

  if (chainOptions.length > 0) {
    return (
      <div className="select-chain">
        <div className="headline">
          Chain
          <Info infotext="You can select one or two chains for your analysis. Whenever there is only one chain available it will be pre selected automatically." />{" "}
        </div>
        <div className="select-buttons">
          {chainOptions.map((option: { label: string; value: string }) => {
            let className = "default";
            if (selectedChain?.includes(option.value)) {
              className = "checked";
            }

            return (
              <Button
                className={className}
                value={option.value}
                onClick={() => handleSelectedChainChange(option.value)}
              >
                {option.label}
              </Button>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};
