import React from "react";
import { getRequest } from "../services/apicalls";
import { addressBuilder } from "../utils/addressbuilder";
import * as Plotly from "plotly.js";
import Plot from "react-plotly.js";
import { Spin } from "antd";

export interface DistanceHeatmapPageContainerProps {
  jobId: string;
}

export interface DistanceHeatmapPageContainerState {
  requestPending: boolean;
  heatmapData: Record<string, any> | undefined;
}

class DistanceHeatmapContainer extends React.Component<
  DistanceHeatmapPageContainerProps,
  DistanceHeatmapPageContainerState
> {
  constructor(props: DistanceHeatmapPageContainerProps) {
    super(props);
    this.state = {
      requestPending: false,
      heatmapData: undefined,
    };
  }

  componentDidMount() {
    this.requestHeatmapData();
    this.setState({ requestPending: true });
  }

  requestHeatmapData = () => {
    const { jobId } = this.props;

    const processResults = (response: any) => {
      let data: Record<string, any> | undefined = undefined;
      if (response.data !== 404) {
        data = response.data;
      }
      this.setState({ requestPending: false, heatmapData: data });
    };

    const errorCallback = () => {
      this.setState({ requestPending: false });
    };

    getRequest(
      addressBuilder(`analysis/${jobId}/heatmap`),
      processResults,
      errorCallback
    );
  };

  render() {
    const layout: Partial<Plotly.Layout> = {
      shapes: [],
      height: 480,
      width: 480,
      title: {
        text: "C-Beta Distance Change [Å]",
        font: {
          //family: 'Courier New, monospace',
          size: 18,
        },
        xref: "paper",
        x: 0.05,
      },
      xaxis: {
        title: {
          text: "Protein 1 - Residue Numbers",
          font: {
            size: 16,
            color: "#7f7f7f",
          },
        },
      },
      yaxis: {
        title: {
          text: "Protein 2 - Residue Numbers",
          font: {
            //family: 'Arial',
            size: 16,
            color: "#7f7f7f",
          },
        },
      },
    };

    let matrix: Plotly.Datum[] = [];

    if (this.state.heatmapData?.matrix !== undefined) {
      matrix = this.state.heatmapData.matrix;
    }
    let x_values = [];
    if (
      this.state.heatmapData?.x0 !== undefined &&
      this.state.heatmapData?.matrix !== undefined
    ) {
      const start = this.state.heatmapData.x0;
      const len = this.state.heatmapData.matrix.length;
      x_values = Array.from(new Array(len), (x, i) => i + start);
    }
    let y_values = [];
    if (
      this.state.heatmapData?.y0 !== undefined &&
      this.state.heatmapData?.matrix !== undefined
    ) {
      const start = this.state.heatmapData.y0;
      const len = this.state.heatmapData.matrix.length;
      y_values = Array.from(new Array(len), (x, i) => i + start);
    }
    const data: Plotly.Data[] = [
      {
        x: x_values,
        y: y_values,
        z: matrix,
        type: "heatmap",
        colorscale: "GnBu",
      },
    ];
    if (this.state.heatmapData === undefined && !this.state.requestPending) {
      return null;
    } else if (this.state.requestPending) {
      return <Spin />;
    }

    return <Plot data={data} layout={layout} />;
  }
}

export default DistanceHeatmapContainer;

/*
const getCircle = (x: number, y: number, radius: number) => {
  return {
    type: 'circle',
    xref: 'x',
    yref: 'y',
    x0: x + radius,
    y0: y + radius,
    x1: x - radius,
    y1: y - radius,
    opacity: 0.8,
    line: {
      color: 'red',
      width: 3
    }
  };
};
*/
