import React from "react";
import { Headline } from "../GenericComponents/Headline";

export interface FaqPageContainerProps {
  history: any;
}

export interface FaqPageContainerState {}

class FaqContainer extends React.Component<
  FaqPageContainerProps,
  FaqPageContainerState
> {
  componentDidMount() {}

  render() {
    return (
      <div className="informational-page">
        <Headline history={this.props.history} />
        <div className="informational-content">
          <h1>Frequently Asked Questions (FAQs)</h1>
          <h2>Can I save my results and reanalyze them at a later stage?</h2>
          The results of your analysis are saved for six months on our
          webserver, where you can access them with the result link and
          reanalyze them. Further, you can download all scores as human readable
          csv-files, which you can analyze, filter, and store as you like.
          <hr></hr>
          <h2>
            Can I analyze a protein with more than two pdb structures (e.g. 3
            protein conformations)?
          </h2>
          The labelizer server only support to compare two conformations of a
          protein structure, currently. If you have 3 conformations (say A, B,
          C), you can run two analysis requests (e.g. A & B and B & C) and
          combine the resulting tables in the csv-files to filter the results
          according to your needs.
          <hr></hr>
          <h2>
            Can I change the analysis to provide optimal FRET pairs that have no
            distance change even though I want to compare two protein
            conformations?
          </h2>
          We do not provide a specific score for this type of request. But the
          resulting tables can easily be filtered for mutants without any (or
          very minor) distance changes.
          <hr></hr>
          <h2>Can I increase the number of refined pairs?</h2>
          We do not support a larger number of refined pairs to share the
          computational load equally among all requests. You can increase the
          refinement and further adapt the analysis by the direct use of the
          labelizer python package. Alternatively, you can get in touch with us
          if you want to discuss specific adaptions of the analysis procedure
          (see <a href="/feedback">feedback</a> section).
          <hr></hr>
          <h2>Is there a planned updated with new features?</h2>
          We plan to improve our prediction and update the available services in
          the future. If you have any specific suggestion, please get in touch
          with us.
          <hr></hr>
          <h2>
            How can I get in touch directly to discuss problems or results?
          </h2>
          We appreciate any feedback or suggestions for improvement regarding
          our webserver. Please, send us a mail to{" "}
          <a href="mailto: labelizer-feedback@bio.lmu.de">labelizer-feedback@bio.lmu.de</a>.
          <hr></hr>
        </div>
      </div>
    );
  }
}

export default FaqContainer;
