import { UploadOutlined } from "@ant-design/icons";
import { Button, Input, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { Helpertexts } from "../../../Constants/HelperTexts";
import {
  ATOM_PATTERN,
  HETATOM_PATTERN,
  PDBID_PATTERN,
} from "../../../Constants/PDBpatterns";
import Info from "../../../GenericComponents/info";

type UploadCustomPdbProps = {
  postProteinDB: (
    payload: { pdbID: string; chains: string; payload: any },
    fieldIndex: number
  ) => void;
  fieldIndex: number;
};

export const UploadCustomPdb: React.FC<UploadCustomPdbProps> = (props) => {
  const { postProteinDB, fieldIndex } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [pdbId, setPdbId] = useState<string>("");
  const [chains, setChains] = useState<string>("");

  const [numberOfAtomMatches, setNumberOfAtomMatches] = useState<number>(0);
  const [numberOfHetAtomMatches, setNumberOfHetAtomMatches] =
    useState<number>(0);

  const onChangePDBId = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.target.value || "";
    setPdbId(value);
  };

  const onChangeChains = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = event.target.value || "";
    setChains(value);
  };

  const onChangePdbContent = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const value: string = event.target.value || "";
    updatePdbContent(value);
  };

  const updatePdbContent = (value: string): void => {
    setTextAreaValue(value);
    const atomMatches: any[] = [...`${value}`.matchAll(ATOM_PATTERN)];
    const hetatmMatches: any[] = [...`${value}`.matchAll(HETATOM_PATTERN)];
    setNumberOfAtomMatches(atomMatches.length);
    setNumberOfHetAtomMatches(hetatmMatches.length);
    const extractedChains: string[] = atomMatches.reduce(
      (accumulator: string[], currVal: any[]) => {
        const chain: string = currVal[5];
        if (!accumulator.includes(chain)) {
          accumulator.push(chain);
        }
        return accumulator;
      },
      []
    );
    if (chains === "") {
      setChains(extractedChains.join(","));
    }
    const pdbIdMatch: any[] = [...value.matchAll(PDBID_PATTERN)];
    if (pdbId === "" && pdbIdMatch.length > 0 && pdbIdMatch[0].length > 1) {
      setPdbId(pdbIdMatch[0][1]);
    }
  };

  const onFire = () => {
    if (chains !== "" && pdbId !== "" && textAreaValue !== "") {
      const atomMatches: any[] = [...`${textAreaValue}`.matchAll(ATOM_PATTERN)];
      const hetatmMatches: any[] = [
        ...`${textAreaValue}`.matchAll(HETATOM_PATTERN),
      ];
      const payload = `${atomMatches.join("\n")}\n${hetatmMatches.join("\n")}`;
      postProteinDB(
        { chains: chains, pdbID: pdbId, payload: payload },
        fieldIndex
      );
      setModalOpen(false);
    } else {
      notification.warn({
        message:
          "Please fill in chains, PDB-ID and the PDB data before submitting",
      });
    }
  };

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let [first] = event.target.files;

    // We have to use this ancient API because `Blob.text()` insists on doing utf-8 only
    // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsText
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      (event) => {
        // We know it's a string because we fed it a text file
        updatePdbContent(reader.result as string);
      },
      false
    );

    if (first) {
      reader.readAsText(first);
    }

    reader.addEventListener("error", (event) => {
      notification.error({
        message: "Failed to upload file",
        description: String(event),
        duration: 5,
      });
    });
  };

  return (
    <div className="upload-custom-pdb">
      <div className="upload-button-frame">
        <Button onClick={() => setModalOpen(true)}>
          <UploadOutlined /> Upload Custom pdb
        </Button>
      </div>
      <Modal
        width={"96ch"}
        onOk={onFire}
        onCancel={() => setModalOpen(false)}
        visible={modalOpen}
      >
        <h2>Enter Custom PDB Data</h2>
        <div>Either Upload or Copy&Paste your PDB Data</div>
        <div>
          <label htmlFor="pdb-upload" className="custom-file-upload">
            <div className="ant-btn ant-btn-primary">
              <i className="fa fa-cloud-upload"></i>{" "}
              <span style={{ fontWeight: "bold" }}>Upload PDB file</span>
            </div>
          </label>
          <input
            id="pdb-upload"
            type="file"
            name="file"
            accept=".pdb,.txt"
            style={{ display: "none" }}
            onChange={uploadFile}
          />
        </div>
        <div className="upload-modal-frame">
          {numberOfAtomMatches > 0 ? (
            <>
              <div className="pdb-id">
                PDB-ID <Info infotext={Helpertexts["uploadModal"]["pdbid"]} />
                <Input
                  value={pdbId}
                  placeholder="1ANF"
                  onChange={onChangePDBId}
                />
              </div>
              <div className="chains">
                Chains <Info infotext={Helpertexts["uploadModal"]["chains"]} />
                <Input
                  value={chains}
                  placeholder="A,B"
                  onChange={onChangeChains}
                />
              </div>{" "}
            </>
          ) : null}
          <div className="pdb-data">
            <span style={{ fontWeight: "bold" }}>or paste PDB text</span>{" "}
            <Info infotext={Helpertexts["uploadModal"]["pdbData"]} />
            <TextArea
              style={{ fontFamily: "monospace" }}
              rows={8}
              placeholder="ATOM    210  CG  LYS E  27      21.649  49.427  71.896  1.00 17.10           C  "
              value={textAreaValue}
              onChange={onChangePdbContent}
            />
          </div>
          <div className="pdb-result-table">
            <table>
              <tr>
                <th>Type</th>
                <th>Amount Entries</th>
              </tr>
              <tr>
                <td>ATOM</td>
                <td style={{ textAlign: "right" }} className="amount">
                  {numberOfAtomMatches}
                </td>
              </tr>
              <tr>
                <td>HETATM</td>
                <td style={{ textAlign: "right" }} className="amount">
                  {numberOfHetAtomMatches}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
