import { ParameterName } from "./Parameters";

export const Helpertexts = {
  analysisName:
    "This is optional. Name your Analysis to remember what you wanted to do.",
  analysisMode: {
    LABELLING_ONE_FLUOROPHORE_ONE_STRUCTURE:
      "Analysis for single-residue labeling in one pdb structure",
    FRET_ONE_STRUCTURE:
      "Analysis of ideal residue-pairs for smFRET experiments in one pdb structure",
    FRET_TWO_STRUCTURES_APO_HOLO:
      "Analysis of ideal residue-pairs for smFRET experiments with two pdb structures of the same protein",
  },
  enterPdbId:
    "Please enter here your alphanumeric pdb-id code. It should look something like 1ANF",
  EnterAlphaFoldDbId:
    "To use a predicted AlphaFold Structure, please enter here your alphanumeric AlphaFold DB ID and press enter. It should look something like A0A239SWU4 or Q8W3K0",
  customPdb:
    "Please enter here your alphanumeric pdb-id code. It should look something like 1ANF",
  fluorophore: "Select the fluorophore from the list",
  foersterRadius:
    "The Förster radius is automatically calculated based on the literature values",
  FluorohporeDonor: "Select the donor fluorphore from the list",
  FluorohporeAcceptor: "Select the acceptor fluorophore from the list",
  advancedParameters: {
    [ParameterName.ConservationScore]: "Conservation score from ConSurf server",
    [ParameterName.SolventExposure]: "Mean surface distance of residue",
    [ParameterName.CysteineResemblance]:
      "Amino acid identity, a parameter derived from the propensities of amino acids replaced with Cystein",
    [ParameterName.SecondaryStructure]: "Secondary structure",
    [ParameterName.AAExclusion]:
      "Exclude residues close to Methionine or another Amino Acid of your choice. This is intended to be used to exclude potential quencher in the proximity of your fluorophore. Set this parameter to zero weight to just include the information about proximal quencher in the result files without actually excluding those residues.",
    [ParameterName.TryptophaneProximity]: "coming soon",
    [ParameterName.ChargeEnvironment]: "coming soon",
  },
  uploadModal: {
    pdbid: "Four letter (custom) pdb identifier",
    chains: "Selected chain letter",
    pdbData:
      "Copy the content of your pdb-file with all ATOM entities into the text box",
  },
};
