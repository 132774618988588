export function generateColorFromScale(fraction: number) {
  if (fraction > 1.5) {
    return colorScore[colorScore.length - 1];
  }
  if (fraction < 0.5) {
    return colorScale[0];
  }
  const scaleLength: number = colorScale.length;
  const scalelInducedFraction: number = 1.0 / scaleLength;
  const bin: number = Math.trunc((fraction - 0.5) / scalelInducedFraction);
  return colorScale[bin];
}

//export const colorScale: string[] = ['0x0000FF', '0x0033FF', '0x00D3FF', '0x00FFEA', '0x00FF6D', '0x00FF50', '0x00FF2A', '0x00FF00'];

export const colorScale: string[] = [
  "0xE0E0E0",
  "0xDDDDDD",
  "0xD0D0D0",
  "0xCCCCCC",
  "0xD3AFAF",
  "0xDB9292",
  "0xE27575",
  "0xE95757",
  "0xF03A3A",
  "0xF81D1D",
  "0xFF0000",
];

//  if (fraction > 0.95) {
//    return colorScale[colorScale.length - 1];
//  }

export function generateColorFromScore(fraction: number) {
  if (fraction > 1.5) {
    return colorScore[colorScore.length - 1];
  }
  if (fraction < 0.5) {
    return colorScore[0];
  }
  const scaleLength: number = colorScore.length;
  const scalelInducedFraction: number = 0.5 / scaleLength;
  const bin: number = Math.trunc((fraction - 1.0) / scalelInducedFraction);
  return colorScore[bin];
}

//export const colorScale: string[] = ['0x0000FF', '0x0033FF', '0x00D3FF', '0x00FFEA', '0x00FF6D', '0x00FF50', '0x00FF2A', '0x00FF00'];

export const colorScore: string[] = [
  "0xCCCCCC",
  "0xD3AFAF",
  "0xDB9292",
  "0xE27575",
  "0xE95757",
  "0xF03A3A",
  "0xF81D1D",
  "0xFF0000",
];
