import React from "react";
import { Headline } from "../GenericComponents/Headline";
import { Button, Input } from "antd";

export interface ResultPendingPageProps {
  jobId: string;
  onClickSubscribeHandler: () => void;
  onChangeMailAddressHandler: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export interface ResultFormPageContainerProps {
  history: any;
}

export interface ResultFormPageContainerState {
  uuid: string | undefined;
}

class ResultFormContainer extends React.Component<
  ResultFormPageContainerProps,
  ResultFormPageContainerState
> {
  componentDidMount() {}

  //onChangeUUIDHandler = () => {
  //const { jobId } = this.props.match.params;
  //if (this.state.mailAddress !== undefined) {
  //  this.subscribeAnalysisToMail(this.state.mailAddress, jobId);
  //}
  //};

  onClickResultHandler = () => {
    //  //const { uuid } = this.props.match.params;
    //alert("SUBMIT "+this.state.uuid);
    if (this.state.uuid !== undefined) {
      this.props.history.push("/results/" + this.state.uuid);
    }
  };

  onChangeUUIDHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    //alert("CHANGE");
    this.setState({ uuid: event.target.value });
  };

  render() {
    return (
      <div className="informational-page">
        <Headline history={this.props.history} />
        <div className="informational-content">
          <h1>Results</h1>
          <p>
            Enter your indiviual "Analysis-ID" to load and analyze your results:
            <div className="result-input-frame">
              <Input
                style={{ width: "50%" }}
                placeholder="e238de39-347d-48f3-9e92-e4665b016b88"
                onChange={this.onChangeUUIDHandler}
                className="result-input"
              />
              <Button onClick={this.onClickResultHandler}>Show result</Button>
            </div>
          </p>
        </div>
      </div>
    );
  }
}

export default ResultFormContainer;
