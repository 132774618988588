import React from "react";

type Props = {
  text?: string;
};
export const LoadingBubbles: React.FC<Props> = (props: Props) => {
  return (
    <div className="loading-bubbles">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
      {props.text ? props.text : "Loading..."}
    </div>
  );
};
