import React from "react";
import Select from "react-select";

export interface CustomSelectProps {
  options: string[];
  changeHandler: (selectedValue: string) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const { options, changeHandler } = props;

  return (
    <Select
      onChange={(val: any) => changeHandler(val.value)}
      options={options.map((fl: string) => {
        return { value: fl, label: fl };
      })}
    />
  );
};

export default CustomSelect;
