import React from "react";
import { ColormakerRegistry, Stage, Shape } from "ngl";
import { getTextFileRequest } from "../../../services/apicalls";
import { addressBuilder } from "../../../utils/addressbuilder";
import { Button } from "antd";
import { gfp } from "../../../Constants/gfp.ts";
//import { colorScale, generateColorFromScale } from '../../../utils/colorscale';
import { colorScale, generateColorFromScale } from "../../../utils/colorscale";

let schemeId = ColormakerRegistry.addScheme(function (params) {
  this.atomColor = function (atom) {
    if (atom.serial < 1000) {
      return 0x0000ff; // blue
    } else if (atom.serial > 2000) {
      return 0xff0000; // red
    } else {
      return 0x00ff00; // green
    }
  };
});

let newSchemeId = undefined;

class PdbViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: null,
      pdb: null,
      StructureComonent: null,
    };
  }

  componentDidMount() {
    const { id, pdbId } = this.props;

    // Post processing of protein data
    const processProteinData = (response) => {
      const text = response.data;
      this.pdb(text);
    };

    // For this special protein, there is data already in the application
    if (pdbId === "1GFP") {
      processProteinData({ data: gfp }); // this is just a mock for a nice loading scren
    } else if (id) {
      // Request the id of the requested protein
      getTextFileRequest(
        addressBuilder(`load_pdb_file/${id}`),
        processProteinData
      );
    }
  }

  componentDidUpdate(prevProps) {
    // Define fucntion for processing backend response including pdb data
    const processProteinData = (response) => {
      const text = response.data;
      this.pdb(text);
    };

    // If user selects other protein, the id changes hence a re-load is needed
    if (prevProps.id !== this.props.id) {
      // If a 3d model has been displayed before, the stage has to be cleaned
      if (this.state.stage !== null && this.state.stage !== undefined) {
        this.state.stage.removeAllComponents();
      }

      // If a 3d model has been displayed before, the structured component has to be cleaned
      if (
        this.state.StructuredComponent !== null &&
        this.state.StructuredComponent !== undefined
      ) {
        this.state.StructuredComponent.removeAllRepresentations();
      }

      // Actually send the request to the backend
      getTextFileRequest(
        addressBuilder(`load_pdb_file/${this.props.id}`),
        processProteinData
      );
    }

    // Color selected residues according to userSelectedAtoms property if given, else default coloring
    if (
      this.props.userSelectedAtoms &&
      this.props.userSelectedAtoms.length > 0
    ) {
      this.colorSelectedAtoms(
        this.state.StructureComonent,
        this.props.userSelectedAtoms
      );
    }
  }

  colorSelectedAtoms = (StructuredComponent, userSelectedAtomList) => {
    // Mapping of what residues shall be colored with what intensity
    const dictionary = {};

    // Fill the list {ResidueIndex : Color}
    userSelectedAtomList.forEach((el) => {
      const key = el.residueIndex.toString().replace(/[^\d.]/g, "");
      dictionary[key] = el.score;
    });

    // Create a `schema`
    let userSchema = ColormakerRegistry.addScheme(function (params) {
      this.atomColor = function (atom) {
        // ResidueIndex starts with 0. Is needed to access residuenumber
        const residueIndex = atom.atomStore.residueIndex[atom.index];

        // Residue Number starts not always with 0. Corresponds to residuNumber by Labelizer
        const residueNumber = atom.residueStore.resno[residueIndex];

        // Get the value from the previously filled dictionary
        const value = dictionary[residueNumber.toString()];
        //const atType = ((atom.atomStore.atomTypeId[atom.index] === 1) ? "CA" : "NOTCA");

        // If there is a score for the residue, color it. Else: white
        if (value) {
          //if (dictionary !== undefined && Object.keys(dictionary).length===3) {
          //	if (atom.atomType.atomname === "CA") {
          //	  alert(atType);
          //    };
          //  };
          return generateColorFromScale(value);
        } else {
          return 0xffffff;
        }
      };
    });
    try {
      // Remove the old colorscheme and add the new one
      ColormakerRegistry.removeScheme(newSchemeId);
      StructuredComponent.removeAllRepresentations();
      StructuredComponent.addRepresentation("cartoon", { color: userSchema });
      newSchemeId = userSchema;
    } catch {}
    if (dictionary !== undefined && Object.keys(dictionary).length === 3) {
      //remove previous spheres and labels
      if (this.state.stage !== undefined && this.state.stage !== null) {
        this.state.stage.removeAllComponents("shape");
      }
      const getCoord = function (res) {
        for (var i = 0; i < StructuredComponent.structure.atomCount; i++) {
          const residueIndex =
            StructuredComponent.structure.atomStore.residueIndex[i];
          if (
            StructuredComponent.structure.residueStore.resno[residueIndex] ===
            res
          ) {
            return {
              x: StructuredComponent.structure.atomStore.x[i],
              y: StructuredComponent.structure.atomStore.y[i],
              z: StructuredComponent.structure.atomStore.z[i],
            };
          }
        }
        return null;
      };
      var shape = new Shape("shape");
      var coords = undefined;
      var coord1 = undefined;
      var key1 = "";
      var coord2 = undefined;
      var key2 = "";
      Object.keys(dictionary).forEach(function (key) {
        if (key > 1) {
          coords = getCoord(parseInt(key));
          if (coords !== undefined && coords !== null) {
            if (coord1 === undefined) {
              key1 = key;
              coord1 = coords;
            } else if (coord2 === undefined) {
              key2 = key;
              coord2 = coords;
            } else {
              console.log("Too many positions to mark!");
            }
          }
        }
      });
      // add spheres, line and labels
      shape.addSphere([coord1.x, coord1.y, coord1.z], [1, 0, 0], 1.5);
      shape.addSphere([coord2.x, coord2.y, coord2.z], [1, 0, 0], 1.5);
      shape.addCylinder(
        [coord1.x, coord1.y, coord1.z],
        [coord2.x, coord2.y, coord2.z],
        [0.4, 0, 0],
        0.3
      );
      shape.addLabel(
        [
          coord1.x - 0.2 * (coord2.x - coord1.x),
          coord1.y - 0.2 * (coord2.y - coord1.y),
          coord1.z - 0.2 * (coord2.z - coord1.z),
        ],
        [0.5, 0.5, 0.5],
        4,
        key1
      );
      shape.addLabel(
        [
          coord2.x - 0.2 * (coord1.x - coord2.x),
          coord2.y - 0.2 * (coord1.y - coord2.y),
          coord2.z - 0.2 * (coord1.z - coord2.z),
        ],
        [0.5, 0.5, 0.5],
        4,
        key2
      );
      var shapeComp = this.state.stage.addComponentFromObject(shape);
      shapeComp.addRepresentation("buffer");
    }
    if (dictionary !== undefined && Object.keys(dictionary).length === 1) {
      //remove previous spheres and labels
      if (this.state.stage !== undefined && this.state.stage !== null) {
        this.state.stage.removeAllComponents("shape");
      }
    }
  };

  createRollingScreensaverScheme = (index, oldscheme, StructureComponent) => {
    newSchemeId = ColormakerRegistry.addScheme(function (params) {
      this.atomColor = function (atom) {
        if (atom.serial < index + 360 && atom.index > index - 360) {
          if (atom.serial > index - 30 && atom.serial < index + 30) {
            return 0x52ffb1;
          }
          if (atom.serial > index - 60 && atom.serial < index + 60) {
            return 0x14ff8b;
          }

          if (atom.serial > index - 140 && atom.serial < index + 140) {
            return 0x3db88b;
          }
          if (atom.serial > index - 190 && atom.serial > index + 190) {
            return 0x62b18a;
          }
          if (atom.serial > index - 360 && atom.serial < index + 360) {
            return 0x42a16a;
          }
        }
        return 0x3a3a3a;
      };
    });
    try {
      ColormakerRegistry.removeScheme(oldscheme);
      StructureComponent.removeAllRepresentations();
      StructureComponent.addRepresentation("cartoon", { color: newSchemeId });
    } catch {}
  };

  componentWillUnmount() {
    // If there was a protein loaded, remove the satge to not leave artifacts behind
    if (this.state.stage !== null) {
      this.state.stage.removeAllComponents();
    }
  }

  /**
   *
   * @param {string} content this is the content of a .pdb file
   * @param {string} viewportId id of the html DOM element
   */
  pdb(content, viewportId = `viewport-${this.props.index}`) {
    // Save the .pdb content in a Blob - required for NGL library
    const pdb = new Blob([content], { type: "text/plain" });

    // If there is no stage yet - create one and attach it to the html DOM element
    if (this.state.stage === null || this.state.stage === undefined) {
      const stage = new Stage(
        viewportId,
        this.props.screenSaverMode ? { backgroundColor: "#f5f5f5" } : {}
      );
      this.setState({ stage: stage });
    } else {
      // If there was a
      this.state.stage.removeAllComponents();
    }

    this.setState({ pdb });
    // http://nglviewer.org/ngl/api/manual/usage/coloring.html#representations

    setTimeout(() => {
      this.state.stage
        .loadFile(pdb, {
          ext: "pdb",
          defaultRepresentation: false,
        })
        .then((StructureComonent) => {
          // File has been loaded, set state to 3d Model
          this.setState({ StructureComonent: StructureComonent });

          // Handle colors
          if (this.props.screenSaverMode) {
            // Start with 160 offset
            this.createRollingScreensaverScheme(
              160,
              schemeId,
              StructureComonent
            );
            StructureComonent.autoView();
            const { atomCount } = StructureComonent.structure;
            let index = 0;
            // Keep rotating the colored parts of the screensaver every 600ms
            setInterval(() => {
              this.createRollingScreensaverScheme(
                index,
                schemeId,
                StructureComonent
              );
              index = (index + 180) % atomCount;
            }, 600);
          } else if (
            this.props.userSelectedAtoms &&
            this.props.userSelectedAtoms.length > 0
          ) {
            // Aplly special coloration if given
            this.colorSelectedAtoms(
              StructureComonent,
              this.props.userSelectedAtoms
            );
            StructureComonent.autoView();
          } else {
            // Default coloration
            StructureComonent.addRepresentation("cartoon", {
              colorScheme: "atomindex",
            });
            StructureComonent.autoView();
          }
        });

      // Center the molecule for presentation
      this.state.stage.setFocus(1);
      this.state.stage.setSpin(true);
    });
  }

  // When a user clicks into the frame, the user might want to inspect somethin specific. For this: Deactivate spinning
  onClickHandler = () => {
    if (this.state.stage !== null) {
      this.state.stage.setSpin(false);
    }
  };

  render() {
    const { userSelectedSchema } = this.props;
    if (userSelectedSchema) {
      this.colorSelectedAtoms(this.state.StructureComonent, userSelectedSchema);
    }

    return (
      <div className="pdb-viewer-frame">
        <div
          id={`viewport-${this.props.index}`}
          className={`viewport`}
          onClick={this.onClickHandler}
        />
        {this.state.stage !== null && !this.props.screenSaverMode ? (
          <div style={{ display: "flex" }}>
            <div>
              <Button
                className="fullscreen-button"
                onClick={() => this.state.stage.toggleFullscreen()}
              >
                Fullscreen
              </Button>
            </div>
            <div style={{ display: "flex" }}>
              {this.props.currentFileType !== undefined &&
              this.props.currentFileType !== "FRET" ? (
                <div
                  style={{ textAlign: "center", width: "24pt", height: "16pt" }}
                >
                  0.5
                </div>
              ) : null}
              {this.props.currentFileType !== undefined &&
              this.props.currentFileType !== "FRET"
                ? colorScale.map((color, idx) => (
                    <div
                      style={{
                        textAlign: "center",
                        width: "16pt",
                        height: "16pt",
                        backgroundColor: color.replace("0x", "#"),
                      }}
                    >
                      {`‎‎‎‏‏‎ ‎`}{" "}
                    </div>
                  ))
                : null}
              {this.props.currentFileType !== undefined &&
              this.props.currentFileType !== "FRET" ? (
                <div
                  style={{ textAlign: "center", width: "24pt", height: "16pt" }}
                >
                  1.5
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default PdbViewer;
