import { Collapse } from "antd";
import React from "react";
import { ParameterName, Parameters } from "../../../Constants/Parameters";
import { LabelizerLayoutDataProps } from "../LabelizerLayout";
import { ParameterSliders } from "./ParameterSliders";

export const AdvancedParametersSegment = (props: LabelizerLayoutDataProps) => {
  return (
    <div className="parameter-segment">
      <div className="selector-frame">
        <Collapse className="collapse" ghost destroyInactivePanel={true}>
          <Collapse.Panel header="Advanced Parameters" key="1">
            <div className="row">
              <p className="parameter">
                You can choose whether a parameter will have no weight (but will
                still be available in result files), default weight or doubled
                weight. Sensitivity is currently unused.
              </p>
            </div>
            {Parameters.map((parameter: ParameterName, _idx: number) => (
              <ParameterSliders parameterName={parameter} {...props} />
            ))}
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};
