export const gfp: string = `HEADER    FLUORESCENT PROTEIN                     23-AUG-96   1GFL               
TITLE     STRUCTURE OF GREEN FLUORESCENT PROTEIN                                
COMPND    MOL_ID: 1;                                                            
COMPND   2 MOLECULE: GREEN FLUORESCENT PROTEIN;                                 
COMPND   3 CHAIN: A, B;                                                         
COMPND   4 ENGINEERED: YES;                                                     
COMPND   5 MUTATION: YES                                                        
SOURCE    MOL_ID: 1;                                                            
SOURCE   2 ORGANISM_SCIENTIFIC: AEQUOREA VICTORIA;                              
SOURCE   3 ORGANISM_TAXID: 6100;                                                
SOURCE   4 EXPRESSION_SYSTEM: ESCHERICHIA COLI;                                 
SOURCE   5 EXPRESSION_SYSTEM_TAXID: 562;                                        
SOURCE   6 EXPRESSION_SYSTEM_PLASMID: PTU58                                     
KEYWDS    FLUOROPHORE GREEN FLUORESCENT PROTEIN, LUMINESCENCE                   
EXPDTA    X-RAY DIFFRACTION                                                     
AUTHOR    F.YANG,L.G.MOSS,G.N.PHILLIPS JR.                                      
REVDAT   3   24-FEB-09 1GFL    1       VERSN                                    
REVDAT   2   17-FEB-04 1GFL    1       AUTHOR JRNL   LINK   CONECT              
REVDAT   1   11-JAN-97 1GFL    0                                                
JRNL        AUTH   F.YANG,L.G.MOSS,G.N.PHILLIPS JR.                             
JRNL        TITL   THE MOLECULAR STRUCTURE OF GREEN FLUORESCENT                 
JRNL        TITL 2 PROTEIN.                                                     
JRNL        REF    NAT.BIOTECHNOL.               V.  14  1246 1996              
JRNL        REFN                   ISSN 1087-0156                               
JRNL        PMID   9631087                                                      
JRNL        DOI    10.1038/NBT1096-1246                                         
REMARK   1                                                                      
REMARK   1 REFERENCE 1                                                          
REMARK   1  AUTH   A.B.CUBITT,R.HEIM,S.R.ADAMS,A.E.BOYD,L.A.GROSS,              
REMARK   1  AUTH 2 R.Y.TSIEN                                                    
REMARK   1  TITL   UNDERSTANDING, IMPROVING AND USING GREEN                     
REMARK   1  TITL 2 FLUORESCENT PROTEINS                                         
REMARK   1  REF    TRENDS BIOCHEM.SCI.           V.  20   448 1995              
REMARK   1  REFN                   ISSN 0968-0004                               
REMARK   2                                                                      
REMARK   2 RESOLUTION.    1.90 ANGSTROMS.                                       
REMARK   3                                                                      
REMARK   3 REFINEMENT.                                                          
REMARK   3   PROGRAM     : X-PLOR 3.1                                           
REMARK   3   AUTHORS     : BRUNGER                                              
REMARK   3                                                                      
REMARK   3  DATA USED IN REFINEMENT.                                            
REMARK   3   RESOLUTION RANGE HIGH (ANGSTROMS) : 1.90                           
REMARK   3   RESOLUTION RANGE LOW  (ANGSTROMS) : 10.00                          
REMARK   3   DATA CUTOFF            (SIGMA(F)) : 0.000                          
REMARK   3   DATA CUTOFF HIGH         (ABS(F)) : NULL                           
REMARK   3   DATA CUTOFF LOW          (ABS(F)) : NULL                           
REMARK   3   COMPLETENESS (WORKING+TEST)   (%) : 96.8                           
REMARK   3   NUMBER OF REFLECTIONS             : 37225                          
REMARK   3                                                                      
REMARK   3  FIT TO DATA USED IN REFINEMENT.                                     
REMARK   3   CROSS-VALIDATION METHOD          : NULL                            
REMARK   3   FREE R VALUE TEST SET SELECTION  : NULL                            
REMARK   3   R VALUE            (WORKING SET) : 0.214                           
REMARK   3   FREE R VALUE                     : 0.262                           
REMARK   3   FREE R VALUE TEST SET SIZE   (%) : NULL                            
REMARK   3   FREE R VALUE TEST SET COUNT      : NULL                            
REMARK   3   ESTIMATED ERROR OF FREE R VALUE  : NULL                            
REMARK   3                                                                      
REMARK   3  FIT IN THE HIGHEST RESOLUTION BIN.                                  
REMARK   3   TOTAL NUMBER OF BINS USED           : NULL                         
REMARK   3   BIN RESOLUTION RANGE HIGH       (A) : NULL                         
REMARK   3   BIN RESOLUTION RANGE LOW        (A) : NULL                         
REMARK   3   BIN COMPLETENESS (WORKING+TEST) (%) : NULL                         
REMARK   3   REFLECTIONS IN BIN    (WORKING SET) : NULL                         
REMARK   3   BIN R VALUE           (WORKING SET) : NULL                         
REMARK   3   BIN FREE R VALUE                    : NULL                         
REMARK   3   BIN FREE R VALUE TEST SET SIZE  (%) : NULL                         
REMARK   3   BIN FREE R VALUE TEST SET COUNT     : NULL                         
REMARK   3   ESTIMATED ERROR OF BIN FREE R VALUE : NULL                         
REMARK   3                                                                      
REMARK   3  NUMBER OF NON-HYDROGEN ATOMS USED IN REFINEMENT.                    
REMARK   3   PROTEIN ATOMS            : 3650                                    
REMARK   3   NUCLEIC ACID ATOMS       : 0                                       
REMARK   3   HETEROGEN ATOMS          : 0                                       
REMARK   3   SOLVENT ATOMS            : 300                                     
REMARK   3                                                                      
REMARK   3  B VALUES.                                                           
REMARK   3   FROM WILSON PLOT           (A**2) : NULL                           
REMARK   3   MEAN B VALUE      (OVERALL, A**2) : NULL                           
REMARK   3   OVERALL ANISOTROPIC B VALUE.                                       
REMARK   3    B11 (A**2) : NULL                                                 
REMARK   3    B22 (A**2) : NULL                                                 
REMARK   3    B33 (A**2) : NULL                                                 
REMARK   3    B12 (A**2) : NULL                                                 
REMARK   3    B13 (A**2) : NULL                                                 
REMARK   3    B23 (A**2) : NULL                                                 
REMARK   3                                                                      
REMARK   3  ESTIMATED COORDINATE ERROR.                                         
REMARK   3   ESD FROM LUZZATI PLOT        (A) : NULL                            
REMARK   3   ESD FROM SIGMAA              (A) : NULL                            
REMARK   3   LOW RESOLUTION CUTOFF        (A) : NULL                            
REMARK   3                                                                      
REMARK   3  CROSS-VALIDATED ESTIMATED COORDINATE ERROR.                         
REMARK   3   ESD FROM C-V LUZZATI PLOT    (A) : NULL                            
REMARK   3   ESD FROM C-V SIGMAA          (A) : NULL                            
REMARK   3                                                                      
REMARK   3  RMS DEVIATIONS FROM IDEAL VALUES.                                   
REMARK   3   BOND LENGTHS                 (A) : 0.013                           
REMARK   3   BOND ANGLES            (DEGREES) : 1.79                            
REMARK   3   DIHEDRAL ANGLES        (DEGREES) : 29.47                           
REMARK   3   IMPROPER ANGLES        (DEGREES) : 2.39                            
REMARK   3                                                                      
REMARK   3  ISOTROPIC THERMAL MODEL : NULL                                      
REMARK   3                                                                      
REMARK   3  ISOTROPIC THERMAL FACTOR RESTRAINTS.    RMS    SIGMA                
REMARK   3   MAIN-CHAIN BOND              (A**2) : NULL  ; NULL                 
REMARK   3   MAIN-CHAIN ANGLE             (A**2) : NULL  ; NULL                 
REMARK   3   SIDE-CHAIN BOND              (A**2) : NULL  ; NULL                 
REMARK   3   SIDE-CHAIN ANGLE             (A**2) : NULL  ; NULL                 
REMARK   3                                                                      
REMARK   3  NCS MODEL : NULL                                                    
REMARK   3                                                                      
REMARK   3  NCS RESTRAINTS.                         RMS   SIGMA/WEIGHT          
REMARK   3   GROUP  1  POSITIONAL            (A) : NULL  ; NULL                 
REMARK   3   GROUP  1  B-FACTOR           (A**2) : NULL  ; NULL                 
REMARK   3                                                                      
REMARK   3  PARAMETER FILE  1  : NULL                                           
REMARK   3  TOPOLOGY FILE  1   : NULL                                           
REMARK   3                                                                      
REMARK   3  OTHER REFINEMENT REMARKS: NULL                                      
REMARK   4                                                                      
REMARK   4 1GFL COMPLIES WITH FORMAT V. 3.15, 01-DEC-08                         
REMARK 100                                                                      
REMARK 100 THIS ENTRY HAS BEEN PROCESSED BY BNL.                                
REMARK 200                                                                      
REMARK 200 EXPERIMENTAL DETAILS                                                 
REMARK 200  EXPERIMENT TYPE                : X-RAY DIFFRACTION                  
REMARK 200  DATE OF DATA COLLECTION        : 25-MAR-95                          
REMARK 200  TEMPERATURE           (KELVIN) : 299                                
REMARK 200  PH                             : 7.0                                
REMARK 200  NUMBER OF CRYSTALS USED        : NULL                               
REMARK 200                                                                      
REMARK 200  SYNCHROTRON              (Y/N) : N                                  
REMARK 200  RADIATION SOURCE               : NULL                               
REMARK 200  BEAMLINE                       : NULL                               
REMARK 200  X-RAY GENERATOR MODEL          : NULL                               
REMARK 200  MONOCHROMATIC OR LAUE    (M/L) : NULL                               
REMARK 200  WAVELENGTH OR RANGE        (A) : NULL                               
REMARK 200  MONOCHROMATOR                  : NULL                               
REMARK 200  OPTICS                         : NULL                               
REMARK 200                                                                      
REMARK 200  DETECTOR TYPE                  : IMAGE PLATE                        
REMARK 200  DETECTOR MANUFACTURER          : RIGAKU                             
REMARK 200  INTENSITY-INTEGRATION SOFTWARE : DENZO                              
REMARK 200  DATA SCALING SOFTWARE          : SCALEPACK                          
REMARK 200                                                                      
REMARK 200  NUMBER OF UNIQUE REFLECTIONS   : 38472                              
REMARK 200  RESOLUTION RANGE HIGH      (A) : 1.900                              
REMARK 200  RESOLUTION RANGE LOW       (A) : 30.000                             
REMARK 200  REJECTION CRITERIA  (SIGMA(I)) : 0.000                              
REMARK 200                                                                      
REMARK 200 OVERALL.                                                             
REMARK 200  COMPLETENESS FOR RANGE     (%) : 99.5                               
REMARK 200  DATA REDUNDANCY                : 5.000                              
REMARK 200  R MERGE                    (I) : 0.07700                            
REMARK 200  R SYM                      (I) : NULL                               
REMARK 200  <I/SIGMA(I)> FOR THE DATA SET  : NULL                               
REMARK 200                                                                      
REMARK 200 IN THE HIGHEST RESOLUTION SHELL.                                     
REMARK 200  HIGHEST RESOLUTION SHELL, RANGE HIGH (A) : NULL                     
REMARK 200  HIGHEST RESOLUTION SHELL, RANGE LOW  (A) : NULL                     
REMARK 200  COMPLETENESS FOR SHELL     (%) : NULL                               
REMARK 200  DATA REDUNDANCY IN SHELL       : NULL                               
REMARK 200  R MERGE FOR SHELL          (I) : NULL                               
REMARK 200  R SYM FOR SHELL            (I) : NULL                               
REMARK 200  <I/SIGMA(I)> FOR SHELL         : NULL                               
REMARK 200                                                                      
REMARK 200 DIFFRACTION PROTOCOL: NULL                                           
REMARK 200 METHOD USED TO DETERMINE THE STRUCTURE: NULL                         
REMARK 200 SOFTWARE USED: X-PLOR 3.1                                            
REMARK 200 STARTING MODEL: NULL                                                 
REMARK 200                                                                      
REMARK 200 REMARK: NULL                                                         
REMARK 280                                                                      
REMARK 280 CRYSTAL                                                              
REMARK 280 SOLVENT CONTENT, VS   (%): 44.48                                     
REMARK 280 MATTHEWS COEFFICIENT, VM (ANGSTROMS**3/DA): 2.22                     
REMARK 280                                                                      
REMARK 280 CRYSTALLIZATION CONDITIONS: FREE TEXT GOES HERE., PH 7.0             
REMARK 290                                                                      
REMARK 290 CRYSTALLOGRAPHIC SYMMETRY                                            
REMARK 290 SYMMETRY OPERATORS FOR SPACE GROUP: P 41 21 2                        
REMARK 290                                                                      
REMARK 290      SYMOP   SYMMETRY                                                
REMARK 290     NNNMMM   OPERATOR                                                
REMARK 290       1555   X,Y,Z                                                   
REMARK 290       2555   -X,-Y,Z+1/2                                             
REMARK 290       3555   -Y+1/2,X+1/2,Z+1/4                                      
REMARK 290       4555   Y+1/2,-X+1/2,Z+3/4                                      
REMARK 290       5555   -X+1/2,Y+1/2,-Z+1/4                                     
REMARK 290       6555   X+1/2,-Y+1/2,-Z+3/4                                     
REMARK 290       7555   Y,X,-Z                                                  
REMARK 290       8555   -Y,-X,-Z+1/2                                            
REMARK 290                                                                      
REMARK 290     WHERE NNN -> OPERATOR NUMBER                                     
REMARK 290           MMM -> TRANSLATION VECTOR                                  
REMARK 290                                                                      
REMARK 290 CRYSTALLOGRAPHIC SYMMETRY TRANSFORMATIONS                            
REMARK 290 THE FOLLOWING TRANSFORMATIONS OPERATE ON THE ATOM/HETATM             
REMARK 290 RECORDS IN THIS ENTRY TO PRODUCE CRYSTALLOGRAPHICALLY                
REMARK 290 RELATED MOLECULES.                                                   
REMARK 290   SMTRY1   1  1.000000  0.000000  0.000000        0.00000            
REMARK 290   SMTRY2   1  0.000000  1.000000  0.000000        0.00000            
REMARK 290   SMTRY3   1  0.000000  0.000000  1.000000        0.00000            
REMARK 290   SMTRY1   2 -1.000000  0.000000  0.000000        0.00000            
REMARK 290   SMTRY2   2  0.000000 -1.000000  0.000000        0.00000            
REMARK 290   SMTRY3   2  0.000000  0.000000  1.000000       59.88550            
REMARK 290   SMTRY1   3  0.000000 -1.000000  0.000000       44.61300            
REMARK 290   SMTRY2   3  1.000000  0.000000  0.000000       44.61300            
REMARK 290   SMTRY3   3  0.000000  0.000000  1.000000       29.94275            
REMARK 290   SMTRY1   4  0.000000  1.000000  0.000000       44.61300            
REMARK 290   SMTRY2   4 -1.000000  0.000000  0.000000       44.61300            
REMARK 290   SMTRY3   4  0.000000  0.000000  1.000000       89.82825            
REMARK 290   SMTRY1   5 -1.000000  0.000000  0.000000       44.61300            
REMARK 290   SMTRY2   5  0.000000  1.000000  0.000000       44.61300            
REMARK 290   SMTRY3   5  0.000000  0.000000 -1.000000       29.94275            
REMARK 290   SMTRY1   6  1.000000  0.000000  0.000000       44.61300            
REMARK 290   SMTRY2   6  0.000000 -1.000000  0.000000       44.61300            
REMARK 290   SMTRY3   6  0.000000  0.000000 -1.000000       89.82825            
REMARK 290   SMTRY1   7  0.000000  1.000000  0.000000        0.00000            
REMARK 290   SMTRY2   7  1.000000  0.000000  0.000000        0.00000            
REMARK 290   SMTRY3   7  0.000000  0.000000 -1.000000        0.00000            
REMARK 290   SMTRY1   8  0.000000 -1.000000  0.000000        0.00000            
REMARK 290   SMTRY2   8 -1.000000  0.000000  0.000000        0.00000            
REMARK 290   SMTRY3   8  0.000000  0.000000 -1.000000       59.88550            
REMARK 290                                                                      
REMARK 290 REMARK: NULL                                                         
REMARK 300                                                                      
REMARK 300 BIOMOLECULE: 1                                                       
REMARK 300 SEE REMARK 350 FOR THE AUTHOR PROVIDED AND/OR PROGRAM                
REMARK 300 GENERATED ASSEMBLY INFORMATION FOR THE STRUCTURE IN                  
REMARK 300 THIS ENTRY. THE REMARK MAY ALSO PROVIDE INFORMATION ON               
REMARK 300 BURIED SURFACE AREA.                                                 
REMARK 350                                                                      
REMARK 350 COORDINATES FOR A COMPLETE MULTIMER REPRESENTING THE KNOWN           
REMARK 350 BIOLOGICALLY SIGNIFICANT OLIGOMERIZATION STATE OF THE                
REMARK 350 MOLECULE CAN BE GENERATED BY APPLYING BIOMT TRANSFORMATIONS          
REMARK 350 GIVEN BELOW.  BOTH NON-CRYSTALLOGRAPHIC AND                          
REMARK 350 CRYSTALLOGRAPHIC OPERATIONS ARE GIVEN.                               
REMARK 350                                                                      
REMARK 350 BIOMOLECULE: 1                                                       
REMARK 350 AUTHOR DETERMINED BIOLOGICAL UNIT: DIMERIC                           
REMARK 350 APPLY THE FOLLOWING TO CHAINS: A, B                                  
REMARK 350   BIOMT1   1  1.000000  0.000000  0.000000        0.00000            
REMARK 350   BIOMT2   1  0.000000  1.000000  0.000000        0.00000            
REMARK 350   BIOMT3   1  0.000000  0.000000  1.000000        0.00000            
REMARK 465                                                                      
REMARK 465 MISSING RESIDUES                                                     
REMARK 465 THE FOLLOWING RESIDUES WERE NOT LOCATED IN THE                       
REMARK 465 EXPERIMENT. (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN               
REMARK 465 IDENTIFIER; SSSEQ=SEQUENCE NUMBER; I=INSERTION CODE.)                
REMARK 465                                                                      
REMARK 465   M RES C SSSEQI                                                     
REMARK 465     HIS A   231                                                      
REMARK 465     GLY A   232                                                      
REMARK 465     MET A   233                                                      
REMARK 465     ASP A   234                                                      
REMARK 465     GLU A   235                                                      
REMARK 465     LEU A   236                                                      
REMARK 465     TYR A   237                                                      
REMARK 465     LYS A   238                                                      
REMARK 465     HIS B   231                                                      
REMARK 465     GLY B   232                                                      
REMARK 465     MET B   233                                                      
REMARK 465     ASP B   234                                                      
REMARK 465     GLU B   235                                                      
REMARK 465     LEU B   236                                                      
REMARK 465     TYR B   237                                                      
REMARK 465     LYS B   238                                                      
REMARK 470                                                                      
REMARK 470 MISSING ATOM                                                         
REMARK 470 THE FOLLOWING RESIDUES HAVE MISSING ATOMS(M=MODEL NUMBER;            
REMARK 470 RES=RESIDUE NAME; C=CHAIN IDENTIFIER; SSEQ=SEQUENCE NUMBER;          
REMARK 470 I=INSERTION CODE):                                                   
REMARK 470   M RES CSSEQI  ATOMS                                                
REMARK 470     SER A  65    O                                                   
REMARK 470     SER B  65    O                                                   
REMARK 500                                                                      
REMARK 500 GEOMETRY AND STEREOCHEMISTRY                                         
REMARK 500 SUBTOPIC: COVALENT BOND LENGTHS                                      
REMARK 500                                                                      
REMARK 500 THE STEREOCHEMICAL PARAMETERS OF THE FOLLOWING RESIDUES              
REMARK 500 HAVE VALUES WHICH DEVIATE FROM EXPECTED VALUES BY MORE               
REMARK 500 THAN 6*RMSD (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN               
REMARK 500 IDENTIFIER; SSEQ=SEQUENCE NUMBER; I=INSERTION CODE).                 
REMARK 500                                                                      
REMARK 500 STANDARD TABLE:                                                      
REMARK 500 FORMAT: (10X,I3,1X,2(A3,1X,A1,I4,A1,1X,A4,3X),1X,F6.3)               
REMARK 500                                                                      
REMARK 500 EXPECTED VALUES PROTEIN: ENGH AND HUBER, 1999                        
REMARK 500 EXPECTED VALUES NUCLEIC ACID: CLOWNEY ET AL 1996                     
REMARK 500                                                                      
REMARK 500  M RES CSSEQI ATM1   RES CSSEQI ATM2   DEVIATION                     
REMARK 500    HIS A  25   CG    HIS A  25   CD2     0.081                       
REMARK 500    TYR A  66   CA    TYR A  66   CB     -0.155                       
REMARK 500    TYR A  66   CB    TYR A  66   CG     -0.099                       
REMARK 500    TYR B  66   CB    TYR B  66   CG     -0.114                       
REMARK 500    HIS B  77   CG    HIS B  77   CD2     0.079                       
REMARK 500    HIS B 217   CG    HIS B 217   CD2     0.058                       
REMARK 500                                                                      
REMARK 500 REMARK: NULL                                                         
REMARK 500                                                                      
REMARK 500 GEOMETRY AND STEREOCHEMISTRY                                         
REMARK 500 SUBTOPIC: COVALENT BOND ANGLES                                       
REMARK 500                                                                      
REMARK 500 THE STEREOCHEMICAL PARAMETERS OF THE FOLLOWING RESIDUES              
REMARK 500 HAVE VALUES WHICH DEVIATE FROM EXPECTED VALUES BY MORE               
REMARK 500 THAN 6*RMSD (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN               
REMARK 500 IDENTIFIER; SSEQ=SEQUENCE NUMBER; I=INSERTION CODE).                 
REMARK 500                                                                      
REMARK 500 STANDARD TABLE:                                                      
REMARK 500 FORMAT: (10X,I3,1X,A3,1X,A1,I4,A1,3(1X,A4,2X),12X,F5.1)              
REMARK 500                                                                      
REMARK 500 EXPECTED VALUES PROTEIN: ENGH AND HUBER, 1999                        
REMARK 500 EXPECTED VALUES NUCLEIC ACID: CLOWNEY ET AL 1996                     
REMARK 500                                                                      
REMARK 500  M RES CSSEQI ATM1   ATM2   ATM3                                     
REMARK 500    LEU A  18   CA  -  CB  -  CG  ANGL. DEV. =  14.5 DEGREES          
REMARK 500    TYR A  66   N   -  CA  -  CB  ANGL. DEV. =  20.0 DEGREES          
REMARK 500    TYR A  66   CA  -  CB  -  CG  ANGL. DEV. =  15.9 DEGREES          
REMARK 500    TYR A  66   C   -  N   -  CA  ANGL. DEV. = -20.9 DEGREES          
REMARK 500    HIS A  77   CE1 -  NE2 -  CD2 ANGL. DEV. =   4.5 DEGREES          
REMARK 500    HIS A  81   CE1 -  NE2 -  CD2 ANGL. DEV. =   5.7 DEGREES          
REMARK 500    HIS A 139   CE1 -  NE2 -  CD2 ANGL. DEV. =   6.5 DEGREES          
REMARK 500    HIS B  25   CE1 -  NE2 -  CD2 ANGL. DEV. =   5.2 DEGREES          
REMARK 500    TYR B  66   N   -  CA  -  CB  ANGL. DEV. =  22.5 DEGREES          
REMARK 500    TYR B  66   CA  -  CB  -  CG  ANGL. DEV. =  13.7 DEGREES          
REMARK 500    TYR B  66   C   -  N   -  CA  ANGL. DEV. = -19.9 DEGREES          
REMARK 500    HIS B  81   CE1 -  NE2 -  CD2 ANGL. DEV. =   5.8 DEGREES          
REMARK 500    HIS B 139   CE1 -  NE2 -  CD2 ANGL. DEV. =   5.3 DEGREES          
REMARK 500    HIS B 217   CE1 -  NE2 -  CD2 ANGL. DEV. =   7.2 DEGREES          
REMARK 500                                                                      
REMARK 500 REMARK: NULL                                                         
REMARK 500                                                                      
REMARK 500 GEOMETRY AND STEREOCHEMISTRY                                         
REMARK 500 SUBTOPIC: TORSION ANGLES                                             
REMARK 500                                                                      
REMARK 500 TORSION ANGLES OUTSIDE THE EXPECTED RAMACHANDRAN REGIONS:            
REMARK 500 (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN IDENTIFIER;               
REMARK 500 SSEQ=SEQUENCE NUMBER; I=INSERTION CODE).                             
REMARK 500                                                                      
REMARK 500 STANDARD TABLE:                                                      
REMARK 500 FORMAT:(10X,I3,1X,A3,1X,A1,I4,A1,4X,F7.2,3X,F7.2)                    
REMARK 500                                                                      
REMARK 500 EXPECTED VALUES: GJ KLEYWEGT AND TA JONES (1996). PHI/PSI-           
REMARK 500 CHOLOGY: RAMACHANDRAN REVISITED. STRUCTURE 4, 1395 - 1400            
REMARK 500                                                                      
REMARK 500  M RES CSSEQI        PSI       PHI                                   
REMARK 500    SER A  65     -101.94   -123.73                                   
REMARK 500    TYR A  66       -4.29      3.23                                   
REMARK 500    SER B  65      -98.81   -125.34                                   
REMARK 500    TYR B  66       -0.48     -1.08                                   
REMARK 500    ASP B 103     -158.36   -156.31                                   
REMARK 500                                                                      
REMARK 500 REMARK: NULL                                                         
REMARK 500                                                                      
REMARK 500 GEOMETRY AND STEREOCHEMISTRY                                         
REMARK 500 SUBTOPIC: NON-CIS, NON-TRANS                                         
REMARK 500                                                                      
REMARK 500 THE FOLLOWING PEPTIDE BONDS DEVIATE SIGNIFICANTLY FROM BOTH          
REMARK 500 CIS AND TRANS CONFORMATION.  CIS BONDS, IF ANY, ARE LISTED           
REMARK 500 ON CISPEP RECORDS.  TRANS IS DEFINED AS 180 +/- 30 AND               
REMARK 500 CIS IS DEFINED AS 0 +/- 30 DEGREES.                                  
REMARK 500                                 MODEL     OMEGA                      
REMARK 500 ARG A   96     THR A   97                  149.39                    
REMARK 500 ARG B   96     THR B   97                  148.75                    
REMARK 500                                                                      
REMARK 500 REMARK: NULL                                                         
REMARK 500                                                                      
REMARK 500 GEOMETRY AND STEREOCHEMISTRY                                         
REMARK 500 SUBTOPIC: PLANAR GROUPS                                              
REMARK 500                                                                      
REMARK 500 PLANAR GROUPS IN THE FOLLOWING RESIDUES HAVE A TOTAL                 
REMARK 500 RMS DISTANCE OF ALL ATOMS FROM THE BEST-FIT PLANE                    
REMARK 500 BY MORE THAN AN EXPECTED VALUE OF 6*RMSD, WITH AN                    
REMARK 500 RMSD 0.02 ANGSTROMS, OR AT LEAST ONE ATOM HAS                        
REMARK 500 AN RMSD GREATER THAN THIS VALUE                                      
REMARK 500 (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN IDENTIFIER;               
REMARK 500 SSEQ=SEQUENCE NUMBER; I=INSERTION CODE).                             
REMARK 500                                                                      
REMARK 500  M RES CSSEQI        RMS     TYPE                                    
REMARK 500    TYR A  39         0.07    SIDE_CHAIN                              
REMARK 500    ARG A  73         0.07    SIDE_CHAIN                              
REMARK 500                                                                      
REMARK 500 REMARK: NULL                                                         
REMARK 500                                                                      
REMARK 500 GEOMETRY AND STEREOCHEMISTRY                                         
REMARK 500 SUBTOPIC: CHIRAL CENTERS                                             
REMARK 500                                                                      
REMARK 500 UNEXPECTED CONFIGURATION OF THE FOLLOWING CHIRAL                     
REMARK 500 CENTER(S) USING IMPROPER CA--C--CB--N CHIRALITY                      
REMARK 500 M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN                            
REMARK 500 IDENTIFIER; SSEQ=SEQUENCE NUMBER; I=INSERTION CODE                   
REMARK 500                                                                      
REMARK 500 STANDARD TABLE:                                                      
REMARK 500 FORMAT: (11X,I3,1X,A3,1X,A1,I4,A1,6X,F5.1,6X,A1,10X,A1,3X,A16)       
REMARK 500                                                                      
REMARK 500   M RES CSSEQI    IMPROPER   EXPECTED   FOUND DETAILS                
REMARK 500     TYR B  66       180.0                     ALPHA-CARBON           
REMARK 500                                                                      
REMARK 500 REMARK: NULL                                                         
REMARK 525                                                                      
REMARK 525 SOLVENT                                                              
REMARK 525                                                                      
REMARK 525 THE SOLVENT MOLECULES HAVE CHAIN IDENTIFIERS THAT                    
REMARK 525 INDICATE THE POLYMER CHAIN WITH WHICH THEY ARE MOST                  
REMARK 525 CLOSELY ASSOCIATED. THE REMARK LISTS ALL THE SOLVENT                 
REMARK 525 MOLECULES WHICH ARE MORE THAN 5A AWAY FROM THE                       
REMARK 525 NEAREST POLYMER CHAIN (M = MODEL NUMBER;                             
REMARK 525 RES=RESIDUE NAME; C=CHAIN IDENTIFIER; SSEQ=SEQUENCE                  
REMARK 525 NUMBER; I=INSERTION CODE):                                           
REMARK 525                                                                      
REMARK 525  M RES CSSEQI                                                        
REMARK 525    HOH A 244        DISTANCE =  5.05 ANGSTROMS                       
REMARK 525    HOH B 366        DISTANCE =  5.63 ANGSTROMS                       
REMARK 999                                                                      
REMARK 999 SEQUENCE                                                             
REMARK 999                                                                      
REMARK 999 THE FLUOROPHORE IS FORMED BY SER 65, TYR 66 AND GLY 67.              
REMARK 999 THE CARBONYL CARBON OF TYR 66 IS BONDED TO THE NITROGEN              
REMARK 999 OF GLY 67.  THE CARBONYL OXYGEN IS DELETED.                          
REMARK 999 THE SIDE CHAIN OF TYR 66 IS DEHYDROGENATED.                          
DBREF  1GFL A    1   238  UNP    P42212   GFP_AEQVI        2    239             
DBREF  1GFL B    1   238  UNP    P42212   GFP_AEQVI        2    239             
SEQRES   1 A  238  ALA SER LYS GLY GLU GLU LEU PHE THR GLY VAL VAL PRO          
SEQRES   2 A  238  ILE LEU VAL GLU LEU ASP GLY ASP VAL ASN GLY HIS LYS          
SEQRES   3 A  238  PHE SER VAL SER GLY GLU GLY GLU GLY ASP ALA THR TYR          
SEQRES   4 A  238  GLY LYS LEU THR LEU LYS PHE ILE CYS THR THR GLY LYS          
SEQRES   5 A  238  LEU PRO VAL PRO TRP PRO THR LEU VAL THR THR PHE SER          
SEQRES   6 A  238  TYR GLY VAL GLN CYS PHE SER ARG TYR PRO ASP HIS MET          
SEQRES   7 A  238  LYS ARG HIS ASP PHE PHE LYS SER ALA MET PRO GLU GLY          
SEQRES   8 A  238  TYR VAL GLN GLU ARG THR ILE PHE PHE LYS ASP ASP GLY          
SEQRES   9 A  238  ASN TYR LYS THR ARG ALA GLU VAL LYS PHE GLU GLY ASP          
SEQRES  10 A  238  THR LEU VAL ASN ARG ILE GLU LEU LYS GLY ILE ASP PHE          
SEQRES  11 A  238  LYS GLU ASP GLY ASN ILE LEU GLY HIS LYS LEU GLU TYR          
SEQRES  12 A  238  ASN TYR ASN SER HIS ASN VAL TYR ILE MET ALA ASP LYS          
SEQRES  13 A  238  GLN LYS ASN GLY ILE LYS VAL ASN PHE LYS ILE ARG HIS          
SEQRES  14 A  238  ASN ILE GLU ASP GLY SER VAL GLN LEU ALA ASP HIS TYR          
SEQRES  15 A  238  GLN GLN ASN THR PRO ILE GLY ASP GLY PRO VAL LEU LEU          
SEQRES  16 A  238  PRO ASP ASN HIS TYR LEU SER THR GLN SER ALA LEU SER          
SEQRES  17 A  238  LYS ASP PRO ASN GLU LYS ARG ASP HIS MET VAL LEU LEU          
SEQRES  18 A  238  GLU PHE VAL THR ALA ALA GLY ILE THR HIS GLY MET ASP          
SEQRES  19 A  238  GLU LEU TYR LYS                                              
SEQRES   1 B  238  ALA SER LYS GLY GLU GLU LEU PHE THR GLY VAL VAL PRO          
SEQRES   2 B  238  ILE LEU VAL GLU LEU ASP GLY ASP VAL ASN GLY HIS LYS          
SEQRES   3 B  238  PHE SER VAL SER GLY GLU GLY GLU GLY ASP ALA THR TYR          
SEQRES   4 B  238  GLY LYS LEU THR LEU LYS PHE ILE CYS THR THR GLY LYS          
SEQRES   5 B  238  LEU PRO VAL PRO TRP PRO THR LEU VAL THR THR PHE SER          
SEQRES   6 B  238  TYR GLY VAL GLN CYS PHE SER ARG TYR PRO ASP HIS MET          
SEQRES   7 B  238  LYS ARG HIS ASP PHE PHE LYS SER ALA MET PRO GLU GLY          
SEQRES   8 B  238  TYR VAL GLN GLU ARG THR ILE PHE PHE LYS ASP ASP GLY          
SEQRES   9 B  238  ASN TYR LYS THR ARG ALA GLU VAL LYS PHE GLU GLY ASP          
SEQRES  10 B  238  THR LEU VAL ASN ARG ILE GLU LEU LYS GLY ILE ASP PHE          
SEQRES  11 B  238  LYS GLU ASP GLY ASN ILE LEU GLY HIS LYS LEU GLU TYR          
SEQRES  12 B  238  ASN TYR ASN SER HIS ASN VAL TYR ILE MET ALA ASP LYS          
SEQRES  13 B  238  GLN LYS ASN GLY ILE LYS VAL ASN PHE LYS ILE ARG HIS          
SEQRES  14 B  238  ASN ILE GLU ASP GLY SER VAL GLN LEU ALA ASP HIS TYR          
SEQRES  15 B  238  GLN GLN ASN THR PRO ILE GLY ASP GLY PRO VAL LEU LEU          
SEQRES  16 B  238  PRO ASP ASN HIS TYR LEU SER THR GLN SER ALA LEU SER          
SEQRES  17 B  238  LYS ASP PRO ASN GLU LYS ARG ASP HIS MET VAL LEU LEU          
SEQRES  18 B  238  GLU PHE VAL THR ALA ALA GLY ILE THR HIS GLY MET ASP          
SEQRES  19 B  238  GLU LEU TYR LYS                                              
FORMUL   3  HOH   *300(H2 O)                                                    
HELIX    1   1 GLU A    5  PHE A    8  5                                   4    
HELIX    2   2 TRP A   57  PHE A   64  5                                   8    
HELIX    3   3 GLN A   69  PHE A   71  5                                   3    
HELIX    4   4 ASP A   76  HIS A   81  5                                   6    
HELIX    5   5 PHE A   83  SER A   86  1                                   4    
HELIX    6   6 LYS A  156  LYS A  158  5                                   3    
HELIX    7   7 GLU B    5  PHE B    8  5                                   4    
HELIX    8   8 TRP B   57  PHE B   64  5                                   8    
HELIX    9   9 GLN B   69  PHE B   71  5                                   3    
HELIX   10  10 ASP B   76  HIS B   81  5                                   6    
HELIX   11  11 PHE B   83  SER B   86  1                                   4    
HELIX   12  12 LYS B  156  LYS B  158  5                                   3    
SHEET    1   A12 HIS A  25  ASP A  36  0                                        
SHEET    2   A12 VAL A  11  VAL A  22 -1  N  VAL A  22   O  HIS A  25           
SHEET    3   A12 THR A 118  ILE A 128  1  N  LEU A 119   O  LEU A  15           
SHEET    4   A12 ASN A 105  GLU A 115 -1  N  GLU A 115   O  THR A 118           
SHEET    5   A12 TYR A  92  PHE A 100 -1  N  ILE A  98   O  TYR A 106           
SHEET    6   A12 VAL A 176  PRO A 187 -1  N  THR A 186   O  VAL A  93           
SHEET    7   A12 GLY A 160  ASN A 170 -1  N  HIS A 169   O  GLN A 177           
SHEET    8   A12 HIS A 148  ASP A 155 -1  N  ASP A 155   O  GLY A 160           
SHEET    9   A12 HIS A 199  SER A 208 -1  N  THR A 203   O  HIS A 148           
SHEET   10   A12 HIS A 217  ALA A 227 -1  N  ALA A 227   O  TYR A 200           
SHEET   11   A12 LYS A  41  CYS A  48 -1  N  PHE A  46   O  MET A 218           
SHEET   12   A12 VAL A  29  ASP A  36 -1  N  ASP A  36   O  LYS A  41           
SHEET    1   B12 HIS B  25  ASP B  36  0                                        
SHEET    2   B12 VAL B  11  VAL B  22 -1  N  VAL B  22   O  HIS B  25           
SHEET    3   B12 THR B 118  ILE B 128  1  N  LEU B 119   O  LEU B  15           
SHEET    4   B12 ASN B 105  GLU B 115 -1  N  GLU B 115   O  THR B 118           
SHEET    5   B12 TYR B  92  PHE B 100 -1  N  ILE B  98   O  TYR B 106           
SHEET    6   B12 VAL B 176  PRO B 187 -1  N  THR B 186   O  VAL B  93           
SHEET    7   B12 GLY B 160  ASN B 170 -1  N  HIS B 169   O  GLN B 177           
SHEET    8   B12 HIS B 148  ASP B 155 -1  N  ASP B 155   O  GLY B 160           
SHEET    9   B12 HIS B 199  SER B 208 -1  N  THR B 203   O  HIS B 148           
SHEET   10   B12 HIS B 217  ALA B 227 -1  N  ALA B 227   O  TYR B 200           
SHEET   11   B12 LYS B  41  CYS B  48 -1  N  PHE B  46   O  MET B 218           
SHEET   12   B12 VAL B  29  ASP B  36 -1  N  ASP B  36   O  LYS B  41           
LINK         C   SER A  65                 N   GLY A  67     1555   1555  1.35  
LINK         C   SER B  65                 N   GLY B  67     1555   1555  1.35  
CISPEP   1 MET A   88    PRO A   89          0         4.37                     
CISPEP   2 MET B   88    PRO B   89          0         2.32                     
CRYST1   89.226   89.226  119.771  90.00  90.00  90.00 P 41 21 2    16          
ORIGX1      1.000000  0.000000  0.000000        0.00000                         
ORIGX2      0.000000  1.000000  0.000000        0.00000                         
ORIGX3      0.000000  0.000000  1.000000        0.00000                         
SCALE1      0.011207  0.000000  0.000000        0.00000                         
SCALE2      0.000000  0.011207  0.000000        0.00000                         
SCALE3      0.000000  0.000000  0.008349        0.00000                         
MTRIX1   1 -0.950278  0.287772  0.118992        7.36697    1                    
MTRIX2   1  0.294841  0.708504  0.641164       13.04218    1                    
MTRIX3   1  0.100202  0.644367 -0.758123      -38.10565    1                     
ATOM      1  N   ALA A   1     -14.093  60.494  -9.249  1.00 42.10           N  
ATOM      2  CA  ALA A   1     -14.989  61.651  -8.981  1.00 41.80           C  
ATOM      3  C   ALA A   1     -14.809  62.769 -10.006  1.00 41.60           C  
ATOM      4  O   ALA A   1     -15.790  63.397 -10.384  1.00 41.20           O  
ATOM      5  CB  ALA A   1     -14.760  62.190  -7.570  1.00 42.30           C  
ATOM      6  N   SER A   2     -13.573  62.992 -10.472  1.00 41.10           N  
ATOM      7  CA  SER A   2     -13.364  63.821 -11.651  1.00 40.30           C  
ATOM      8  C   SER A   2     -12.245  63.347 -12.591  1.00 39.80           C  
ATOM      9  O   SER A   2     -11.264  62.734 -12.155  1.00 39.50           O  
ATOM     10  CB  SER A   2     -13.236  65.292 -11.216  1.00 39.90           C  
ATOM     11  OG  SER A   2     -12.004  65.880 -11.497  1.00 39.90           O  
ATOM     12  N   LYS A   3     -12.516  63.462 -13.894  1.00 38.90           N  
ATOM     13  CA  LYS A   3     -11.712  62.828 -14.936  1.00 38.10           C  
ATOM     14  C   LYS A   3     -10.271  63.331 -14.956  1.00 37.00           C  
ATOM     15  O   LYS A   3     -10.026  64.469 -14.615  1.00 37.20           O  
ATOM     16  CB  LYS A   3     -12.368  63.066 -16.294  1.00 38.50           C  
ATOM     17  CG  LYS A   3     -12.827  61.783 -16.993  1.00 58.60           C  
ATOM     18  CD  LYS A   3     -12.215  61.615 -18.373  1.00 61.90           C  
ATOM     19  CE  LYS A   3     -11.968  62.958 -19.039  1.00 78.10           C  
ATOM     20  NZ  LYS A   3     -11.573  62.819 -20.468  1.00 94.10           N  
ATOM     21  N   GLY A   4      -9.322  62.461 -15.299  1.00 35.70           N  
ATOM     22  CA  GLY A   4      -7.907  62.824 -15.260  1.00 34.40           C  
ATOM     23  C   GLY A   4      -7.220  62.167 -14.077  1.00 33.10           C  
ATOM     24  O   GLY A   4      -6.180  61.527 -14.209  1.00 32.20           O  
ATOM     25  N   GLU A   5      -7.899  62.221 -12.937  1.00 31.90           N  
ATOM     26  CA  GLU A   5      -7.546  61.431 -11.779  1.00 31.30           C  
ATOM     27  C   GLU A   5      -7.239  59.985 -12.099  1.00 31.20           C  
ATOM     28  O   GLU A   5      -6.322  59.392 -11.549  1.00 31.40           O  
ATOM     29  CB  GLU A   5      -8.684  61.443 -10.788  1.00 31.90           C  
ATOM     30  CG  GLU A   5      -8.691  62.611  -9.834  1.00 40.30           C  
ATOM     31  CD  GLU A   5      -9.995  62.701  -9.094  1.00 44.30           C  
ATOM     32  OE1 GLU A   5     -10.615  61.645  -8.884  1.00 40.00           O  
ATOM     33  OE2 GLU A   5     -10.450  63.817  -8.801  1.00 52.00           O  
ATOM     34  N   GLU A   6      -8.095  59.372 -12.897  1.00 31.10           N  
ATOM     35  CA  GLU A   6      -7.990  57.947 -13.219  1.00 30.40           C  
ATOM     36  C   GLU A   6      -6.630  57.546 -13.818  1.00 29.80           C  
ATOM     37  O   GLU A   6      -6.218  56.391 -13.702  1.00 29.20           O  
ATOM     38  CB  GLU A   6      -9.131  57.512 -14.176  1.00 31.50           C  
ATOM     39  CG  GLU A   6      -9.973  58.665 -14.793  1.00 48.50           C  
ATOM     40  CD  GLU A   6      -9.685  58.899 -16.270  1.00 74.30           C  
ATOM     41  OE1 GLU A   6      -9.786  57.947 -17.077  1.00101.70           O  
ATOM     42  OE2 GLU A   6      -9.378  60.046 -16.635  1.00 75.10           O  
ATOM     43  N   LEU A   7      -5.933  58.496 -14.436  1.00 29.00           N  
ATOM     44  CA  LEU A   7      -4.656  58.206 -15.052  1.00 29.00           C  
ATOM     45  C   LEU A   7      -3.602  57.960 -13.978  1.00 29.70           C  
ATOM     46  O   LEU A   7      -2.540  57.389 -14.260  1.00 30.50           O  
ATOM     47  CB  LEU A   7      -4.239  59.375 -15.931  1.00 29.00           C  
ATOM     48  CG  LEU A   7      -5.135  59.571 -17.150  1.00 29.30           C  
ATOM     49  CD1 LEU A   7      -4.978  60.974 -17.691  1.00 29.00           C  
ATOM     50  CD2 LEU A   7      -4.792  58.516 -18.178  1.00 28.90           C  
ATOM     51  N   PHE A   8      -3.943  58.292 -12.730  1.00 29.10           N  
ATOM     52  CA  PHE A   8      -2.954  58.395 -11.672  1.00 28.80           C  
ATOM     53  C   PHE A   8      -3.219  57.450 -10.538  1.00 29.90           C  
ATOM     54  O   PHE A   8      -2.635  57.603  -9.467  1.00 30.60           O  
ATOM     55  CB  PHE A   8      -2.902  59.814 -11.115  1.00 26.50           C  
ATOM     56  CG  PHE A   8      -2.245  60.802 -12.027  1.00 25.50           C  
ATOM     57  CD1 PHE A   8      -0.850  60.940 -12.042  1.00 24.50           C  
ATOM     58  CD2 PHE A   8      -3.013  61.599 -12.877  1.00 24.50           C  
ATOM     59  CE1 PHE A   8      -0.232  61.861 -12.897  1.00 23.70           C  
ATOM     60  CE2 PHE A   8      -2.401  62.519 -13.736  1.00 25.00           C  
ATOM     61  CZ  PHE A   8      -1.003  62.648 -13.746  1.00 24.00           C  
ATOM     62  N   THR A   9      -4.094  56.478 -10.748  1.00 31.00           N  
ATOM     63  CA  THR A   9      -4.428  55.542  -9.683  1.00 32.10           C  
ATOM     64  C   THR A   9      -3.297  54.554  -9.354  1.00 32.50           C  
ATOM     65  O   THR A   9      -3.129  54.189  -8.176  1.00 33.80           O  
ATOM     66  CB  THR A   9      -5.707  54.748 -10.003  1.00 32.70           C  
ATOM     67  OG1 THR A   9      -5.522  54.001 -11.218  1.00 33.50           O  
ATOM     68  CG2 THR A   9      -6.895  55.710 -10.136  1.00 33.50           C  
ATOM     69  N   GLY A  10      -2.490  54.172 -10.350  1.00 31.00           N  
ATOM     70  CA  GLY A  10      -1.363  53.289 -10.077  1.00 29.40           C  
ATOM     71  C   GLY A  10      -0.012  53.987  -9.991  1.00 28.50           C  
ATOM     72  O   GLY A  10       0.071  55.184  -9.720  1.00 29.40           O  
ATOM     73  N   VAL A  11       1.063  53.235 -10.161  1.00 26.70           N  
ATOM     74  CA  VAL A  11       2.383  53.838 -10.258  1.00 25.60           C  
ATOM     75  C   VAL A  11       2.679  54.148 -11.728  1.00 25.40           C  
ATOM     76  O   VAL A  11       2.418  53.310 -12.606  1.00 26.00           O  
ATOM     77  CB  VAL A  11       3.456  52.897  -9.701  1.00 24.80           C  
ATOM     78  CG1 VAL A  11       4.827  53.511  -9.880  1.00 25.10           C  
ATOM     79  CG2 VAL A  11       3.188  52.609  -8.233  1.00 24.00           C  
ATOM     80  N   VAL A  12       3.048  55.401 -11.999  1.00 24.40           N  
ATOM     81  CA  VAL A  12       3.190  55.922 -13.361  1.00 23.20           C  
ATOM     82  C   VAL A  12       4.686  56.167 -13.642  1.00 23.30           C  
ATOM     83  O   VAL A  12       5.383  56.805 -12.825  1.00 22.00           O  
ATOM     84  CB  VAL A  12       2.368  57.245 -13.546  1.00 22.10           C  
ATOM     85  CG1 VAL A  12       2.571  57.855 -14.957  1.00 22.10           C  
ATOM     86  CG2 VAL A  12       0.901  56.950 -13.330  1.00 22.40           C  
ATOM     87  N   PRO A  13       5.213  55.613 -14.776  1.00 23.20           N  
ATOM     88  CA  PRO A  13       6.574  55.912 -15.265  1.00 22.60           C  
ATOM     89  C   PRO A  13       6.754  57.375 -15.658  1.00 22.30           C  
ATOM     90  O   PRO A  13       5.813  58.019 -16.139  1.00 23.10           O  
ATOM     91  CB  PRO A  13       6.760  54.978 -16.470  1.00 22.70           C  
ATOM     92  CG  PRO A  13       5.559  54.065 -16.449  1.00 23.50           C  
ATOM     93  CD  PRO A  13       4.465  54.775 -15.730  1.00 22.80           C  
ATOM     94  N   ILE A  14       7.929  57.925 -15.340  1.00 21.40           N  
ATOM     95  CA  ILE A  14       8.240  59.331 -15.585  1.00 20.60           C  
ATOM     96  C   ILE A  14       9.483  59.454 -16.468  1.00 21.00           C  
ATOM     97  O   ILE A  14      10.473  58.744 -16.286  1.00 21.70           O  
ATOM     98  CB  ILE A  14       8.496  60.111 -14.259  1.00 19.00           C  
ATOM     99  CG1 ILE A  14       7.270  59.996 -13.341  1.00 19.00           C  
ATOM    100  CG2 ILE A  14       8.817  61.572 -14.562  1.00 18.10           C  
ATOM    101  CD1 ILE A  14       7.493  60.586 -11.928  1.00 18.90           C  
ATOM    102  N   LEU A  15       9.440  60.371 -17.414  1.00 21.20           N  
ATOM    103  CA  LEU A  15      10.600  60.647 -18.221  1.00 21.90           C  
ATOM    104  C   LEU A  15      10.861  62.113 -18.061  1.00 20.30           C  
ATOM    105  O   LEU A  15       9.937  62.907 -18.169  1.00 21.10           O  
ATOM    106  CB  LEU A  15      10.276  60.352 -19.686  1.00 25.20           C  
ATOM    107  CG  LEU A  15      11.242  59.473 -20.463  1.00 28.00           C  
ATOM    108  CD1 LEU A  15      12.693  59.920 -20.198  1.00 29.10           C  
ATOM    109  CD2 LEU A  15      10.999  58.002 -20.051  1.00 29.90           C  
ATOM    110  N   VAL A  16      12.109  62.484 -17.817  1.00 19.00           N  
ATOM    111  CA  VAL A  16      12.458  63.889 -17.680  1.00 18.60           C  
ATOM    112  C   VAL A  16      13.609  64.188 -18.634  1.00 18.50           C  
ATOM    113  O   VAL A  16      14.568  63.414 -18.710  1.00 18.70           O  
ATOM    114  CB  VAL A  16      12.907  64.232 -16.216  1.00 18.30           C  
ATOM    115  CG1 VAL A  16      13.209  65.708 -16.089  1.00 18.00           C  
ATOM    116  CG2 VAL A  16      11.818  63.875 -15.238  1.00 17.40           C  
ATOM    117  N   GLU A  17      13.498  65.289 -19.380  1.00 19.00           N  
ATOM    118  CA  GLU A  17      14.566  65.713 -20.284  1.00 19.10           C  
ATOM    119  C   GLU A  17      14.769  67.191 -20.259  1.00 18.90           C  
ATOM    120  O   GLU A  17      13.810  67.955 -20.255  1.00 19.80           O  
ATOM    121  CB  GLU A  17      14.278  65.240 -21.714  1.00 20.10           C  
ATOM    122  CG  GLU A  17      14.929  63.916 -21.969  1.00 34.00           C  
ATOM    123  CD  GLU A  17      14.409  63.218 -23.179  1.00 56.00           C  
ATOM    124  OE1 GLU A  17      13.819  63.897 -24.057  1.00 77.30           O  
ATOM    125  OE2 GLU A  17      14.641  61.987 -23.263  1.00 83.00           O  
ATOM    126  N   LEU A  18      16.012  67.597 -20.073  1.00 18.90           N  
ATOM    127  CA  LEU A  18      16.312  68.993 -19.861  1.00 20.00           C  
ATOM    128  C   LEU A  18      17.446  69.379 -20.772  1.00 20.40           C  
ATOM    129  O   LEU A  18      18.468  68.691 -20.829  1.00 20.70           O  
ATOM    130  CB  LEU A  18      16.720  69.227 -18.396  1.00 20.90           C  
ATOM    131  CG  LEU A  18      17.333  70.473 -17.729  1.00 21.90           C  
ATOM    132  CD1 LEU A  18      18.660  70.797 -18.354  1.00 22.80           C  
ATOM    133  CD2 LEU A  18      16.416  71.643 -17.717  1.00 22.00           C  
ATOM    134  N   ASP A  19      17.306  70.538 -21.401  1.00 21.00           N  
ATOM    135  CA  ASP A  19      18.412  71.124 -22.149  1.00 21.40           C  
ATOM    136  C   ASP A  19      18.579  72.494 -21.598  1.00 21.20           C  
ATOM    137  O   ASP A  19      17.605  73.167 -21.338  1.00 21.90           O  
ATOM    138  CB  ASP A  19      18.119  71.184 -23.659  1.00 21.70           C  
ATOM    139  CG  ASP A  19      18.237  69.815 -24.324  1.00 34.10           C  
ATOM    140  OD1 ASP A  19      19.371  69.285 -24.410  1.00 38.70           O  
ATOM    141  OD2 ASP A  19      17.191  69.232 -24.698  1.00 58.80           O  
ATOM    142  N   GLY A  20      19.797  72.800 -21.188  1.00 22.00           N  
ATOM    143  CA  GLY A  20      20.022  74.039 -20.500  1.00 21.60           C  
ATOM    144  C   GLY A  20      21.252  74.743 -20.989  1.00 22.00           C  
ATOM    145  O   GLY A  20      22.142  74.153 -21.616  1.00 22.60           O  
ATOM    146  N   ASP A  21      21.338  75.991 -20.575  1.00 22.00           N  
ATOM    147  CA  ASP A  21      22.424  76.860 -20.930  1.00 22.50           C  
ATOM    148  C   ASP A  21      22.467  77.859 -19.793  1.00 22.40           C  
ATOM    149  O   ASP A  21      21.555  78.665 -19.637  1.00 23.30           O  
ATOM    150  CB  ASP A  21      22.069  77.547 -22.263  1.00 23.80           C  
ATOM    151  CG  ASP A  21      22.985  78.736 -22.626  1.00 31.90           C  
ATOM    152  OD1 ASP A  21      23.675  79.332 -21.756  1.00 30.90           O  
ATOM    153  OD2 ASP A  21      22.914  79.152 -23.806  1.00 54.10           O  
ATOM    154  N   VAL A  22      23.576  77.891 -19.066  1.00 22.50           N  
ATOM    155  CA  VAL A  22      23.717  78.814 -17.953  1.00 22.60           C  
ATOM    156  C   VAL A  22      24.989  79.616 -18.166  1.00 22.90           C  
ATOM    157  O   VAL A  22      26.082  79.062 -18.159  1.00 23.90           O  
ATOM    158  CB  VAL A  22      23.775  78.049 -16.566  1.00 22.10           C  
ATOM    159  CG1 VAL A  22      24.133  79.016 -15.440  1.00 21.80           C  
ATOM    160  CG2 VAL A  22      22.425  77.356 -16.278  1.00 21.50           C  
ATOM    161  N   ASN A  23      24.836  80.909 -18.424  1.00 23.70           N  
ATOM    162  CA  ASN A  23      25.982  81.777 -18.723  1.00 25.40           C  
ATOM    163  C   ASN A  23      26.765  81.276 -19.963  1.00 26.40           C  
ATOM    164  O   ASN A  23      28.004  81.352 -19.996  1.00 28.10           O  
ATOM    165  CB  ASN A  23      26.929  81.841 -17.504  1.00 24.40           C  
ATOM    166  CG  ASN A  23      26.506  82.885 -16.467  1.00 24.90           C  
ATOM    167  OD1 ASN A  23      27.345  83.464 -15.788  1.00 35.80           O  
ATOM    168  ND2 ASN A  23      25.213  83.111 -16.325  1.00 24.50           N  
ATOM    169  N   GLY A  24      26.072  80.637 -20.907  1.00 26.90           N  
ATOM    170  CA  GLY A  24      26.765  80.029 -22.038  1.00 26.80           C  
ATOM    171  C   GLY A  24      27.172  78.573 -21.893  1.00 26.90           C  
ATOM    172  O   GLY A  24      27.385  77.909 -22.906  1.00 28.30           O  
ATOM    173  N   HIS A  25      27.300  78.064 -20.668  1.00 26.50           N  
ATOM    174  CA  HIS A  25      27.607  76.658 -20.450  1.00 25.70           C  
ATOM    175  C   HIS A  25      26.385  75.838 -20.790  1.00 25.70           C  
ATOM    176  O   HIS A  25      25.313  76.043 -20.234  1.00 25.50           O  
ATOM    177  CB  HIS A  25      27.998  76.409 -18.986  1.00 27.60           C  
ATOM    178  CG  HIS A  25      29.177  77.235 -18.444  1.00 29.40           C  
ATOM    179  ND1 HIS A  25      29.084  78.500 -17.845  1.00 29.80           N  
ATOM    180  CD2 HIS A  25      30.556  76.848 -18.530  1.00 30.80           C  
ATOM    181  CE1 HIS A  25      30.374  78.912 -17.570  1.00 31.10           C  
ATOM    182  NE2 HIS A  25      31.249  77.986 -17.975  1.00 31.70           N  
ATOM    183  N   LYS A  26      26.527  74.941 -21.748  1.00 25.30           N  
ATOM    184  CA  LYS A  26      25.426  74.094 -22.141  1.00 25.00           C  
ATOM    185  C   LYS A  26      25.553  72.692 -21.569  1.00 24.60           C  
ATOM    186  O   LYS A  26      26.657  72.164 -21.432  1.00 25.30           O  
ATOM    187  CB  LYS A  26      25.357  74.040 -23.660  1.00 25.70           C  
ATOM    188  CG  LYS A  26      25.176  75.408 -24.260  1.00 34.10           C  
ATOM    189  CD  LYS A  26      24.093  75.375 -25.312  1.00 62.70           C  
ATOM    190  CE  LYS A  26      24.300  76.464 -26.344  1.00 74.00           C  
ATOM    191  NZ  LYS A  26      24.223  77.827 -25.743  1.00 89.90           N  
ATOM    192  N   PHE A  27      24.416  72.080 -21.258  1.00 23.20           N  
ATOM    193  CA  PHE A  27      24.405  70.732 -20.713  1.00 21.80           C  
ATOM    194  C   PHE A  27      23.019  70.146 -20.902  1.00 20.70           C  
ATOM    195  O   PHE A  27      22.058  70.870 -21.154  1.00 20.10           O  
ATOM    196  CB  PHE A  27      24.775  70.772 -19.213  1.00 21.90           C  
ATOM    197  CG  PHE A  27      23.867  71.650 -18.386  1.00 20.80           C  
ATOM    198  CD1 PHE A  27      24.073  73.017 -18.329  1.00 20.60           C  
ATOM    199  CD2 PHE A  27      22.738  71.107 -17.756  1.00 20.40           C  
ATOM    200  CE1 PHE A  27      23.164  73.834 -17.673  1.00 21.30           C  
ATOM    201  CE2 PHE A  27      21.833  71.910 -17.094  1.00 19.50           C  
ATOM    202  CZ  PHE A  27      22.034  73.272 -17.051  1.00 20.00           C  
ATOM    203  N   SER A  28      22.943  68.834 -20.862  1.00 20.60           N  
ATOM    204  CA  SER A  28      21.680  68.141 -20.900  1.00 21.50           C  
ATOM    205  C   SER A  28      21.631  67.159 -19.756  1.00 21.30           C  
ATOM    206  O   SER A  28      22.660  66.616 -19.347  1.00 21.00           O  
ATOM    207  CB  SER A  28      21.545  67.374 -22.201  1.00 21.20           C  
ATOM    208  OG  SER A  28      21.591  68.307 -23.244  1.00 24.50           O  
ATOM    209  N   VAL A  29      20.429  66.909 -19.253  1.00 21.20           N  
ATOM    210  CA  VAL A  29      20.241  65.807 -18.338  1.00 20.90           C  
ATOM    211  C   VAL A  29      18.953  65.063 -18.662  1.00 20.50           C  
ATOM    212  O   VAL A  29      17.994  65.649 -19.157  1.00 21.30           O  
ATOM    213  CB  VAL A  29      20.333  66.274 -16.798  1.00 20.50           C  
ATOM    214  CG1 VAL A  29      20.576  67.759 -16.697  1.00 19.80           C  
ATOM    215  CG2 VAL A  29      19.131  65.827 -16.015  1.00 19.80           C  
ATOM    216  N   SER A  30      19.022  63.749 -18.555  1.00 20.80           N  
ATOM    217  CA  SER A  30      17.870  62.880 -18.678  1.00 22.30           C  
ATOM    218  C   SER A  30      17.591  62.257 -17.311  1.00 21.90           C  
ATOM    219  O   SER A  30      18.535  61.942 -16.579  1.00 22.30           O  
ATOM    220  CB  SER A  30      18.167  61.765 -19.691  1.00 23.60           C  
ATOM    221  OG  SER A  30      18.188  62.295 -21.018  1.00 28.70           O  
ATOM    222  N   GLY A  31      16.319  61.992 -17.021  1.00 21.10           N  
ATOM    223  CA  GLY A  31      15.958  61.347 -15.782  1.00 20.30           C  
ATOM    224  C   GLY A  31      14.802  60.417 -16.015  1.00 20.30           C  
ATOM    225  O   GLY A  31      14.128  60.506 -17.037  1.00 21.10           O  
ATOM    226  N   GLU A  32      14.710  59.396 -15.178  1.00 20.10           N  
ATOM    227  CA  GLU A  32      13.583  58.464 -15.195  1.00 19.70           C  
ATOM    228  C   GLU A  32      13.203  58.114 -13.748  1.00 19.30           C  
ATOM    229  O   GLU A  32      14.048  58.115 -12.851  1.00 19.40           O  
ATOM    230  CB  GLU A  32      13.967  57.170 -15.924  1.00 19.50           C  
ATOM    231  CG  GLU A  32      15.246  56.564 -15.394  1.00 34.40           C  
ATOM    232  CD  GLU A  32      15.472  55.126 -15.836  1.00 58.10           C  
ATOM    233  OE1 GLU A  32      14.694  54.633 -16.688  1.00 79.30           O  
ATOM    234  OE2 GLU A  32      16.449  54.500 -15.341  1.00 66.90           O  
ATOM    235  N   GLY A  33      11.970  57.685 -13.562  1.00 19.10           N  
ATOM    236  CA  GLY A  33      11.553  57.198 -12.260  1.00 19.90           C  
ATOM    237  C   GLY A  33      10.079  56.859 -12.280  1.00 20.00           C  
ATOM    238  O   GLY A  33       9.534  56.538 -13.360  1.00 19.20           O  
ATOM    239  N   GLU A  34       9.426  56.973 -11.120  1.00 18.70           N  
ATOM    240  CA  GLU A  34       8.018  56.672 -11.033  1.00 18.40           C  
ATOM    241  C   GLU A  34       7.334  57.475  -9.987  1.00 18.10           C  
ATOM    242  O   GLU A  34       7.975  58.032  -9.099  1.00 18.40           O  
ATOM    243  CB  GLU A  34       7.788  55.204 -10.772  1.00 18.50           C  
ATOM    244  CG  GLU A  34       8.671  54.621  -9.737  1.00 28.40           C  
ATOM    245  CD  GLU A  34       8.470  53.120  -9.591  1.00 40.90           C  
ATOM    246  OE1 GLU A  34       8.605  52.382 -10.599  1.00 43.10           O  
ATOM    247  OE2 GLU A  34       8.144  52.683  -8.460  1.00 45.40           O  
ATOM    248  N   GLY A  35       6.055  57.698 -10.223  1.00 17.90           N  
ATOM    249  CA  GLY A  35       5.289  58.578  -9.380  1.00 18.60           C  
ATOM    250  C   GLY A  35       4.069  57.830  -8.907  1.00 19.10           C  
ATOM    251  O   GLY A  35       3.469  57.049  -9.669  1.00 18.80           O  
ATOM    252  N   ASP A  36       3.736  58.046  -7.641  1.00 18.80           N  
ATOM    253  CA  ASP A  36       2.600  57.422  -7.007  1.00 18.60           C  
ATOM    254  C   ASP A  36       1.760  58.484  -6.317  1.00 19.50           C  
ATOM    255  O   ASP A  36       2.039  58.872  -5.175  1.00 19.00           O  
ATOM    256  CB  ASP A  36       3.108  56.432  -5.982  1.00 20.10           C  
ATOM    257  CG  ASP A  36       2.001  55.583  -5.392  1.00 29.50           C  
ATOM    258  OD1 ASP A  36       0.812  55.948  -5.532  1.00 30.50           O  
ATOM    259  OD2 ASP A  36       2.335  54.556  -4.766  1.00 33.60           O  
ATOM    260  N   ALA A  37       0.729  58.953  -6.997  1.00 19.40           N  
ATOM    261  CA  ALA A  37      -0.021  60.085  -6.508  1.00 20.90           C  
ATOM    262  C   ALA A  37      -0.910  59.705  -5.315  1.00 22.40           C  
ATOM    263  O   ALA A  37      -1.357  60.558  -4.541  1.00 23.70           O  
ATOM    264  CB  ALA A  37      -0.840  60.646  -7.628  1.00 19.80           C  
ATOM    265  N   THR A  38      -1.188  58.419  -5.182  1.00 23.90           N  
ATOM    266  CA  THR A  38      -2.006  57.916  -4.092  1.00 25.20           C  
ATOM    267  C   THR A  38      -1.272  58.136  -2.774  1.00 25.00           C  
ATOM    268  O   THR A  38      -1.853  58.558  -1.770  1.00 24.30           O  
ATOM    269  CB  THR A  38      -2.298  56.417  -4.313  1.00 26.50           C  
ATOM    270  OG1 THR A  38      -3.132  56.261  -5.484  1.00 28.80           O  
ATOM    271  CG2 THR A  38      -3.015  55.840  -3.132  1.00 28.40           C  
ATOM    272  N   TYR A  39       0.041  57.972  -2.829  1.00 25.20           N  
ATOM    273  CA  TYR A  39       0.868  58.212  -1.667  1.00 24.90           C  
ATOM    274  C   TYR A  39       1.442  59.612  -1.685  1.00 23.20           C  
ATOM    275  O   TYR A  39       1.765  60.169  -0.661  1.00 23.70           O  
ATOM    276  CB  TYR A  39       1.965  57.183  -1.632  1.00 27.10           C  
ATOM    277  CG  TYR A  39       2.602  57.041  -0.277  1.00 31.40           C  
ATOM    278  CD1 TYR A  39       3.569  57.969   0.175  1.00 32.70           C  
ATOM    279  CD2 TYR A  39       2.344  55.914   0.522  1.00 33.20           C  
ATOM    280  CE1 TYR A  39       4.280  57.757   1.378  1.00 33.70           C  
ATOM    281  CE2 TYR A  39       3.067  55.688   1.735  1.00 34.60           C  
ATOM    282  CZ  TYR A  39       4.037  56.607   2.141  1.00 34.50           C  
ATOM    283  OH  TYR A  39       4.804  56.305   3.251  1.00 35.50           O  
ATOM    284  N   GLY A  40       1.463  60.228  -2.849  1.00 22.00           N  
ATOM    285  CA  GLY A  40       2.044  61.547  -2.960  1.00 20.60           C  
ATOM    286  C   GLY A  40       3.548  61.460  -3.102  1.00 20.60           C  
ATOM    287  O   GLY A  40       4.274  62.336  -2.650  1.00 22.20           O  
ATOM    288  N   LYS A  41       4.040  60.447  -3.792  1.00 20.10           N  
ATOM    289  CA  LYS A  41       5.458  60.208  -3.770  1.00 19.20           C  
ATOM    290  C   LYS A  41       6.109  59.942  -5.123  1.00 18.90           C  
ATOM    291  O   LYS A  41       5.478  59.395  -6.020  1.00 18.90           O  
ATOM    292  CB  LYS A  41       5.744  59.123  -2.740  1.00 19.40           C  
ATOM    293  CG  LYS A  41       6.036  57.763  -3.197  1.00 21.00           C  
ATOM    294  CD  LYS A  41       7.311  57.323  -2.503  1.00 29.90           C  
ATOM    295  CE  LYS A  41       7.611  55.867  -2.711  1.00 32.80           C  
ATOM    296  NZ  LYS A  41       6.419  55.183  -3.304  1.00 54.60           N  
ATOM    297  N   LEU A  42       7.328  60.456  -5.269  1.00 18.50           N  
ATOM    298  CA  LEU A  42       8.126  60.413  -6.489  1.00 19.00           C  
ATOM    299  C   LEU A  42       9.480  59.827  -6.162  1.00 19.00           C  
ATOM    300  O   LEU A  42      10.137  60.310  -5.240  1.00 18.50           O  
ATOM    301  CB  LEU A  42       8.378  61.832  -6.973  1.00 20.00           C  
ATOM    302  CG  LEU A  42       7.175  62.616  -7.457  1.00 21.70           C  
ATOM    303  CD1 LEU A  42       7.334  64.109  -7.265  1.00 21.30           C  
ATOM    304  CD2 LEU A  42       7.043  62.303  -8.926  1.00 23.30           C  
ATOM    305  N   THR A  43       9.996  58.985  -7.051  1.00 18.30           N  
ATOM    306  CA  THR A  43      11.401  58.581  -7.031  1.00 18.60           C  
ATOM    307  C   THR A  43      12.005  58.799  -8.416  1.00 18.80           C  
ATOM    308  O   THR A  43      11.483  58.250  -9.383  1.00 18.90           O  
ATOM    309  CB  THR A  43      11.524  57.118  -6.743  1.00 19.50           C  
ATOM    310  OG1 THR A  43      10.767  56.815  -5.573  1.00 22.80           O  
ATOM    311  CG2 THR A  43      12.990  56.727  -6.531  1.00 21.30           C  
ATOM    312  N   LEU A  44      13.087  59.572  -8.511  1.00 18.00           N  
ATOM    313  CA  LEU A  44      13.677  59.931  -9.793  1.00 17.80           C  
ATOM    314  C   LEU A  44      15.182  59.844  -9.742  1.00 17.30           C  
ATOM    315  O   LEU A  44      15.781  60.090  -8.705  1.00 17.10           O  
ATOM    316  CB  LEU A  44      13.262  61.334 -10.203  1.00 18.20           C  
ATOM    317  CG  LEU A  44      11.787  61.470 -10.575  1.00 19.20           C  
ATOM    318  CD1 LEU A  44      11.327  62.888 -10.369  1.00 20.00           C  
ATOM    319  CD2 LEU A  44      11.610  61.089 -12.015  1.00 19.60           C  
ATOM    320  N   LYS A  45      15.784  59.391 -10.836  1.00 16.20           N  
ATOM    321  CA  LYS A  45      17.229  59.435 -10.989  1.00 16.00           C  
ATOM    322  C   LYS A  45      17.591  60.250 -12.247  1.00 17.50           C  
ATOM    323  O   LYS A  45      17.019  60.010 -13.330  1.00 17.60           O  
ATOM    324  CB  LYS A  45      17.780  58.020 -11.102  1.00 13.70           C  
ATOM    325  CG  LYS A  45      19.275  58.008 -11.186  1.00 13.50           C  
ATOM    326  CD  LYS A  45      19.765  56.637 -11.396  1.00 15.90           C  
ATOM    327  CE  LYS A  45      21.263  56.647 -11.452  1.00 23.20           C  
ATOM    328  NZ  LYS A  45      21.706  55.245 -11.630  1.00 27.30           N  
ATOM    329  N   PHE A  46      18.512  61.206 -12.105  1.00 17.00           N  
ATOM    330  CA  PHE A  46      18.935  62.079 -13.193  1.00 18.00           C  
ATOM    331  C   PHE A  46      20.410  61.876 -13.506  1.00 19.20           C  
ATOM    332  O   PHE A  46      21.220  61.726 -12.601  1.00 19.60           O  
ATOM    333  CB  PHE A  46      18.712  63.536 -12.844  1.00 17.00           C  
ATOM    334  CG  PHE A  46      17.304  63.846 -12.434  1.00 18.90           C  
ATOM    335  CD1 PHE A  46      16.318  64.078 -13.405  1.00 18.70           C  
ATOM    336  CD2 PHE A  46      16.923  63.847 -11.066  1.00 18.40           C  
ATOM    337  CE1 PHE A  46      14.977  64.286 -13.022  1.00 18.90           C  
ATOM    338  CE2 PHE A  46      15.583  64.056 -10.678  1.00 17.60           C  
ATOM    339  CZ  PHE A  46      14.613  64.269 -11.640  1.00 18.50           C  
ATOM    340  N   ILE A  47      20.733  61.830 -14.801  1.00 20.60           N  
ATOM    341  CA  ILE A  47      22.102  61.660 -15.310  1.00 20.50           C  
ATOM    342  C   ILE A  47      22.430  62.877 -16.205  1.00 20.60           C  
ATOM    343  O   ILE A  47      21.577  63.313 -16.978  1.00 20.40           O  
ATOM    344  CB  ILE A  47      22.207  60.385 -16.161  1.00 21.10           C  
ATOM    345  CG1 ILE A  47      21.737  59.172 -15.374  1.00 20.90           C  
ATOM    346  CG2 ILE A  47      23.632  60.183 -16.615  1.00 22.20           C  
ATOM    347  CD1 ILE A  47      22.732  58.706 -14.364  1.00 23.00           C  
ATOM    348  N   CYS A  48      23.587  63.506 -15.998  1.00 20.00           N  
ATOM    349  CA  CYS A  48      24.084  64.503 -16.937  1.00 20.80           C  
ATOM    350  C   CYS A  48      24.703  63.792 -18.143  1.00 21.50           C  
ATOM    351  O   CYS A  48      25.664  63.028 -18.019  1.00 21.50           O  
ATOM    352  CB  CYS A  48      25.135  65.389 -16.297  1.00 20.30           C  
ATOM    353  SG  CYS A  48      25.668  66.747 -17.330  1.00 20.00           S  
ATOM    354  N   THR A  49      24.055  63.937 -19.287  1.00 22.60           N  
ATOM    355  CA  THR A  49      24.473  63.203 -20.472  1.00 24.00           C  
ATOM    356  C   THR A  49      25.645  63.898 -21.183  1.00 24.80           C  
ATOM    357  O   THR A  49      26.306  63.285 -22.017  1.00 26.70           O  
ATOM    358  CB  THR A  49      23.298  63.040 -21.453  1.00 23.60           C  
ATOM    359  OG1 THR A  49      22.712  64.324 -21.709  1.00 24.60           O  
ATOM    360  CG2 THR A  49      22.243  62.112 -20.875  1.00 23.40           C  
ATOM    361  N   THR A  50      25.963  65.125 -20.791  1.00 24.80           N  
ATOM    362  CA  THR A  50      27.003  65.883 -21.454  1.00 25.50           C  
ATOM    363  C   THR A  50      28.296  66.025 -20.667  1.00 25.90           C  
ATOM    364  O   THR A  50      29.127  66.871 -20.970  1.00 26.50           O  
ATOM    365  CB  THR A  50      26.519  67.274 -21.828  1.00 25.10           C  
ATOM    366  OG1 THR A  50      26.036  67.943 -20.665  1.00 25.30           O  
ATOM    367  CG2 THR A  50      25.407  67.186 -22.870  1.00 25.80           C  
ATOM    368  N   GLY A  51      28.476  65.206 -19.647  1.00 26.50           N  
ATOM    369  CA  GLY A  51      29.676  65.353 -18.848  1.00 26.50           C  
ATOM    370  C   GLY A  51      29.352  65.585 -17.397  1.00 25.90           C  
ATOM    371  O   GLY A  51      28.604  64.808 -16.815  1.00 26.20           O  
ATOM    372  N   LYS A  52      30.016  66.566 -16.795  1.00 25.50           N  
ATOM    373  CA  LYS A  52      29.720  66.987 -15.424  1.00 25.10           C  
ATOM    374  C   LYS A  52      28.887  68.228 -15.398  1.00 24.50           C  
ATOM    375  O   LYS A  52      29.171  69.167 -16.133  1.00 24.70           O  
ATOM    376  CB  LYS A  52      31.007  67.234 -14.656  1.00 25.40           C  
ATOM    377  CG  LYS A  52      31.382  66.028 -13.847  1.00 45.50           C  
ATOM    378  CD  LYS A  52      32.840  65.752 -13.888  1.00 51.70           C  
ATOM    379  CE  LYS A  52      33.586  66.734 -13.025  1.00 89.50           C  
ATOM    380  NZ  LYS A  52      33.722  68.020 -13.715  1.00 99.80           N  
ATOM    381  N   LEU A  53      27.856  68.219 -14.554  1.00 23.50           N  
ATOM    382  CA  LEU A  53      26.854  69.274 -14.515  1.00 23.10           C  
ATOM    383  C   LEU A  53      27.602  70.526 -14.131  1.00 22.70           C  
ATOM    384  O   LEU A  53      28.405  70.503 -13.216  1.00 23.30           O  
ATOM    385  CB  LEU A  53      25.788  68.946 -13.464  1.00 22.20           C  
ATOM    386  CG  LEU A  53      24.280  69.113 -13.629  1.00 23.00           C  
ATOM    387  CD1 LEU A  53      23.761  70.016 -12.527  1.00 21.90           C  
ATOM    388  CD2 LEU A  53      23.925  69.634 -14.967  1.00 23.00           C  
ATOM    389  N   PRO A  54      27.411  71.610 -14.883  1.00 22.90           N  
ATOM    390  CA  PRO A  54      28.166  72.829 -14.590  1.00 22.40           C  
ATOM    391  C   PRO A  54      27.598  73.675 -13.477  1.00 21.60           C  
ATOM    392  O   PRO A  54      28.245  74.641 -13.096  1.00 22.40           O  
ATOM    393  CB  PRO A  54      28.173  73.585 -15.929  1.00 22.50           C  
ATOM    394  CG  PRO A  54      26.956  73.093 -16.650  1.00 23.00           C  
ATOM    395  CD  PRO A  54      26.818  71.639 -16.241  1.00 22.80           C  
ATOM    396  N   VAL A  55      26.377  73.367 -13.016  1.00 20.80           N  
ATOM    397  CA  VAL A  55      25.782  74.010 -11.817  1.00 19.60           C  
ATOM    398  C   VAL A  55      25.488  72.960 -10.729  1.00 18.10           C  
ATOM    399  O   VAL A  55      25.501  71.753 -11.015  1.00 17.40           O  
ATOM    400  CB  VAL A  55      24.463  74.785 -12.172  1.00 20.70           C  
ATOM    401  CG1 VAL A  55      24.766  75.995 -13.049  1.00 21.50           C  
ATOM    402  CG2 VAL A  55      23.487  73.876 -12.890  1.00 21.20           C  
ATOM    403  N   PRO A  56      25.249  73.393  -9.467  1.00 17.60           N  
ATOM    404  CA  PRO A  56      24.985  72.411  -8.397  1.00 17.00           C  
ATOM    405  C   PRO A  56      23.668  71.668  -8.653  1.00 16.70           C  
ATOM    406  O   PRO A  56      22.657  72.299  -9.001  1.00 17.80           O  
ATOM    407  CB  PRO A  56      24.897  73.276  -7.151  1.00 17.00           C  
ATOM    408  CG  PRO A  56      25.724  74.482  -7.470  1.00 17.40           C  
ATOM    409  CD  PRO A  56      25.398  74.751  -8.913  1.00 17.80           C  
ATOM    410  N   TRP A  57      23.640  70.363  -8.408  1.00 15.40           N  
ATOM    411  CA  TRP A  57      22.405  69.600  -8.568  1.00 15.00           C  
ATOM    412  C   TRP A  57      21.193  70.134  -7.839  1.00 15.60           C  
ATOM    413  O   TRP A  57      20.098  70.150  -8.395  1.00 15.40           O  
ATOM    414  CB  TRP A  57      22.615  68.160  -8.184  1.00 14.20           C  
ATOM    415  CG  TRP A  57      23.332  67.400  -9.245  1.00 15.60           C  
ATOM    416  CD1 TRP A  57      24.646  67.012  -9.235  1.00 15.60           C  
ATOM    417  CD2 TRP A  57      22.750  66.861 -10.447  1.00 15.00           C  
ATOM    418  NE1 TRP A  57      24.909  66.230 -10.340  1.00 16.00           N  
ATOM    419  CE2 TRP A  57      23.782  66.119 -11.102  1.00 15.50           C  
ATOM    420  CE3 TRP A  57      21.480  66.926 -11.045  1.00 14.30           C  
ATOM    421  CZ2 TRP A  57      23.563  65.446 -12.335  1.00 16.20           C  
ATOM    422  CZ3 TRP A  57      21.253  66.255 -12.291  1.00 16.00           C  
ATOM    423  CH2 TRP A  57      22.301  65.526 -12.913  1.00 16.40           C  
ATOM    424  N   PRO A  58      21.367  70.624  -6.594  1.00 15.80           N  
ATOM    425  CA  PRO A  58      20.197  71.142  -5.873  1.00 15.60           C  
ATOM    426  C   PRO A  58      19.454  72.318  -6.556  1.00 15.80           C  
ATOM    427  O   PRO A  58      18.220  72.446  -6.397  1.00 16.70           O  
ATOM    428  CB  PRO A  58      20.774  71.524  -4.495  1.00 14.30           C  
ATOM    429  CG  PRO A  58      21.980  70.655  -4.348  1.00 14.50           C  
ATOM    430  CD  PRO A  58      22.554  70.539  -5.711  1.00 15.40           C  
ATOM    431  N   THR A  59      20.147  73.113  -7.389  1.00 15.70           N  
ATOM    432  CA  THR A  59      19.491  74.253  -8.057  1.00 15.70           C  
ATOM    433  C   THR A  59      18.466  73.863  -9.150  1.00 14.70           C  
ATOM    434  O   THR A  59      17.665  74.690  -9.596  1.00 15.10           O  
ATOM    435  CB  THR A  59      20.509  75.252  -8.682  1.00 16.50           C  
ATOM    436  OG1 THR A  59      21.136  74.664  -9.844  1.00 18.90           O  
ATOM    437  CG2 THR A  59      21.573  75.662  -7.662  1.00 16.30           C  
ATOM    438  N   LEU A  60      18.442  72.587  -9.506  1.00 14.60           N  
ATOM    439  CA  LEU A  60      17.582  72.096 -10.581  1.00 14.10           C  
ATOM    440  C   LEU A  60      16.425  71.262 -10.079  1.00 13.70           C  
ATOM    441  O   LEU A  60      15.602  70.824 -10.885  1.00 14.10           O  
ATOM    442  CB  LEU A  60      18.404  71.274 -11.586  1.00 14.40           C  
ATOM    443  CG  LEU A  60      19.464  72.076 -12.369  1.00 15.60           C  
ATOM    444  CD1 LEU A  60      20.304  71.150 -13.265  1.00 15.60           C  
ATOM    445  CD2 LEU A  60      18.776  73.144 -13.184  1.00 16.30           C  
ATOM    446  N   VAL A  61      16.354  71.000  -8.762  1.00 13.00           N  
ATOM    447  CA  VAL A  61      15.343  70.085  -8.219  1.00 12.60           C  
ATOM    448  C   VAL A  61      13.911  70.521  -8.546  1.00 11.30           C  
ATOM    449  O   VAL A  61      13.084  69.681  -8.894  1.00 12.70           O  
ATOM    450  CB  VAL A  61      15.490  69.894  -6.631  1.00 12.70           C  
ATOM    451  CG1 VAL A  61      14.171  69.336  -6.026  1.00 12.20           C  
ATOM    452  CG2 VAL A  61      16.667  68.940  -6.323  1.00 12.80           C  
ATOM    453  N   THR A  62      13.603  71.805  -8.429  1.00 11.70           N  
ATOM    454  CA  THR A  62      12.225  72.264  -8.643  1.00 13.10           C  
ATOM    455  C   THR A  62      11.887  72.235 -10.144  1.00 14.00           C  
ATOM    456  O   THR A  62      10.735  72.025 -10.527  1.00 13.70           O  
ATOM    457  CB  THR A  62      12.000  73.695  -8.131  1.00 13.40           C  
ATOM    458  OG1 THR A  62      13.048  74.528  -8.626  1.00 15.50           O  
ATOM    459  CG2 THR A  62      12.003  73.750  -6.589  1.00 11.90           C  
ATOM    460  N   THR A  63      12.924  72.332 -10.966  1.00 14.50           N  
ATOM    461  CA  THR A  63      12.771  72.299 -12.423  1.00 15.40           C  
ATOM    462  C   THR A  63      12.473  70.892 -12.925  1.00 15.30           C  
ATOM    463  O   THR A  63      11.510  70.690 -13.645  1.00 16.90           O  
ATOM    464  CB  THR A  63      14.027  72.896 -13.123  1.00 14.60           C  
ATOM    465  OG1 THR A  63      14.085  74.297 -12.827  1.00 14.20           O  
ATOM    466  CG2 THR A  63      13.960  72.702 -14.650  1.00 14.80           C  
ATOM    467  N   PHE A  64      13.226  69.916 -12.452  1.00 15.00           N  
ATOM    468  CA  PHE A  64      12.963  68.520 -12.736  1.00 15.10           C  
ATOM    469  C   PHE A  64      11.642  68.057 -12.147  1.00 15.20           C  
ATOM    470  O   PHE A  64      10.933  67.241 -12.738  1.00 14.70           O  
ATOM    471  CB  PHE A  64      14.063  67.643 -12.145  1.00 15.80           C  
ATOM    472  CG  PHE A  64      15.432  67.883 -12.723  1.00 17.30           C  
ATOM    473  CD1 PHE A  64      15.613  68.099 -14.095  1.00 17.80           C  
ATOM    474  CD2 PHE A  64      16.556  67.742 -11.913  1.00 17.60           C  
ATOM    475  CE1 PHE A  64      16.879  68.162 -14.646  1.00 18.40           C  
ATOM    476  CE2 PHE A  64      17.854  67.800 -12.465  1.00 19.20           C  
ATOM    477  CZ  PHE A  64      18.011  68.011 -13.840  1.00 19.00           C  
ATOM    478  N   SER A  65      11.385  68.446 -10.896  1.00 15.50           N  
ATOM    479  CA  SER A  65      10.237  67.920 -10.172  1.00 14.40           C  
ATOM    480  C   SER A  65       9.431  69.147  -9.749  1.00 15.10           C  
ATOM    481  CB  SER A  65      10.723  67.085  -8.984  1.00 15.00           C  
ATOM    482  OG  SER A  65       9.652  66.344  -8.424  1.00 15.30           O  
ATOM    483  N   TYR A  66       9.543  69.559  -8.452  1.00 14.90           N  
ATOM    484  CA  TYR A  66       8.729  70.767  -8.479  1.00 14.30           C  
ATOM    485  C   TYR A  66       8.131  70.900  -9.852  1.00 14.90           C  
ATOM    486  O   TYR A  66       7.326  71.733 -10.289  1.00 14.80           O  
ATOM    487  CB  TYR A  66       8.491  71.711  -7.501  1.00 14.00           C  
ATOM    488  CG  TYR A  66       8.987  71.776  -6.180  1.00 13.70           C  
ATOM    489  CD1 TYR A  66       9.853  70.805  -5.669  1.00 13.80           C  
ATOM    490  CD2 TYR A  66       8.622  72.829  -5.348  1.00 14.00           C  
ATOM    491  CE1 TYR A  66      10.348  70.900  -4.352  1.00 14.00           C  
ATOM    492  CE2 TYR A  66       9.098  72.935  -4.033  1.00 14.70           C  
ATOM    493  CZ  TYR A  66       9.954  71.973  -3.543  1.00 14.50           C  
ATOM    494  OH  TYR A  66      10.379  72.087  -2.245  1.00 14.50           O  
ATOM    495  N   GLY A  67       8.653  69.907 -10.550  1.00 15.30           N  
ATOM    496  CA  GLY A  67       8.418  69.770 -11.975  1.00 15.70           C  
ATOM    497  C   GLY A  67       7.239  68.914 -12.412  1.00 15.80           C  
ATOM    498  O   GLY A  67       6.736  69.089 -13.541  1.00 16.20           O  
ATOM    499  N   VAL A  68       6.850  67.949 -11.587  1.00 14.70           N  
ATOM    500  CA  VAL A  68       5.756  67.061 -11.927  1.00 14.50           C  
ATOM    501  C   VAL A  68       4.702  66.988 -10.795  1.00 13.60           C  
ATOM    502  O   VAL A  68       4.378  65.918 -10.270  1.00 13.20           O  
ATOM    503  CB  VAL A  68       6.282  65.641 -12.355  1.00 14.70           C  
ATOM    504  CG1 VAL A  68       6.949  65.725 -13.750  1.00 15.30           C  
ATOM    505  CG2 VAL A  68       7.309  65.079 -11.354  1.00 15.10           C  
ATOM    506  N   GLN A  69       4.085  68.127 -10.518  1.00 13.80           N  
ATOM    507  CA  GLN A  69       3.291  68.265  -9.299  1.00 14.80           C  
ATOM    508  C   GLN A  69       1.940  67.597  -9.457  1.00 16.30           C  
ATOM    509  O   GLN A  69       1.123  67.601  -8.546  1.00 16.50           O  
ATOM    510  CB  GLN A  69       3.138  69.733  -8.957  1.00 14.10           C  
ATOM    511  CG  GLN A  69       4.448  70.375  -8.613  1.00 12.00           C  
ATOM    512  CD  GLN A  69       4.377  71.891  -8.563  1.00 17.20           C  
ATOM    513  OE1 GLN A  69       3.895  72.539  -9.500  1.00 19.00           O  
ATOM    514  NE2 GLN A  69       4.904  72.476  -7.473  1.00 16.80           N  
ATOM    515  N   CYS A  70       1.723  66.971 -10.609  1.00 16.30           N  
ATOM    516  CA  CYS A  70       0.540  66.152 -10.828  1.00 17.40           C  
ATOM    517  C   CYS A  70       0.617  64.893  -9.995  1.00 17.80           C  
ATOM    518  O   CYS A  70      -0.396  64.232  -9.797  1.00 17.40           O  
ATOM    519  CB  CYS A  70       0.366  65.787 -12.335  1.00 18.40           C  
ATOM    520  SG  CYS A  70       1.873  65.113 -13.156  1.00 19.70           S  
ATOM    521  N   PHE A  71       1.791  64.590  -9.425  1.00 18.30           N  
ATOM    522  CA  PHE A  71       1.884  63.445  -8.508  1.00 18.50           C  
ATOM    523  C   PHE A  71       1.617  63.763  -7.027  1.00 19.10           C  
ATOM    524  O   PHE A  71       1.816  62.891  -6.169  1.00 19.40           O  
ATOM    525  CB  PHE A  71       3.225  62.742  -8.650  1.00 17.00           C  
ATOM    526  CG  PHE A  71       3.369  62.008  -9.933  1.00 17.20           C  
ATOM    527  CD1 PHE A  71       2.697  60.813 -10.134  1.00 17.20           C  
ATOM    528  CD2 PHE A  71       4.091  62.560 -10.978  1.00 16.70           C  
ATOM    529  CE1 PHE A  71       2.735  60.172 -11.368  1.00 17.70           C  
ATOM    530  CE2 PHE A  71       4.137  61.918 -12.216  1.00 17.70           C  
ATOM    531  CZ  PHE A  71       3.457  60.729 -12.409  1.00 17.20           C  
ATOM    532  N   SER A  72       1.226  65.013  -6.750  1.00 20.10           N  
ATOM    533  CA  SER A  72       0.756  65.440  -5.427  1.00 20.50           C  
ATOM    534  C   SER A  72      -0.420  64.587  -4.972  1.00 21.20           C  
ATOM    535  O   SER A  72      -1.308  64.242  -5.764  1.00 20.90           O  
ATOM    536  CB  SER A  72       0.322  66.902  -5.447  1.00 19.60           C  
ATOM    537  OG  SER A  72       1.398  67.741  -5.831  1.00 22.60           O  
ATOM    538  N   ARG A  73      -0.416  64.241  -3.690  1.00 21.60           N  
ATOM    539  CA  ARG A  73      -1.572  63.626  -3.086  1.00 22.70           C  
ATOM    540  C   ARG A  73      -2.542  64.732  -2.697  1.00 22.50           C  
ATOM    541  O   ARG A  73      -2.170  65.658  -1.970  1.00 22.20           O  
ATOM    542  CB  ARG A  73      -1.159  62.841  -1.856  1.00 25.20           C  
ATOM    543  CG  ARG A  73      -2.337  62.398  -1.055  1.00 29.10           C  
ATOM    544  CD  ARG A  73      -1.968  61.279  -0.157  1.00 33.90           C  
ATOM    545  NE  ARG A  73      -3.144  60.465   0.118  1.00 38.80           N  
ATOM    546  CZ  ARG A  73      -4.028  60.751   1.066  1.00 41.10           C  
ATOM    547  NH1 ARG A  73      -3.703  61.611   2.041  1.00 42.70           N  
ATOM    548  NH2 ARG A  73      -5.204  60.120   1.078  1.00 42.70           N  
ATOM    549  N   TYR A  74      -3.751  64.710  -3.260  1.00 22.40           N  
ATOM    550  CA  TYR A  74      -4.812  65.638  -2.824  1.00 21.70           C  
ATOM    551  C   TYR A  74      -5.766  64.843  -1.936  1.00 22.80           C  
ATOM    552  O   TYR A  74      -6.252  63.788  -2.335  1.00 22.40           O  
ATOM    553  CB  TYR A  74      -5.574  66.240  -4.027  1.00 19.70           C  
ATOM    554  CG  TYR A  74      -4.962  67.504  -4.600  1.00 17.80           C  
ATOM    555  CD1 TYR A  74      -3.704  67.489  -5.210  1.00 17.20           C  
ATOM    556  CD2 TYR A  74      -5.589  68.727  -4.450  1.00 16.80           C  
ATOM    557  CE1 TYR A  74      -3.080  68.666  -5.620  1.00 15.80           C  
ATOM    558  CE2 TYR A  74      -4.986  69.886  -4.861  1.00 16.10           C  
ATOM    559  CZ  TYR A  74      -3.731  69.855  -5.446  1.00 15.10           C  
ATOM    560  OH  TYR A  74      -3.157  71.044  -5.843  1.00 15.10           O  
ATOM    561  N   PRO A  75      -5.939  65.270  -0.673  1.00 24.10           N  
ATOM    562  CA  PRO A  75      -6.965  64.648   0.177  1.00 25.50           C  
ATOM    563  C   PRO A  75      -8.368  64.742  -0.457  1.00 26.20           C  
ATOM    564  O   PRO A  75      -8.705  65.736  -1.118  1.00 25.80           O  
ATOM    565  CB  PRO A  75      -6.886  65.437   1.492  1.00 25.50           C  
ATOM    566  CG  PRO A  75      -5.658  66.276   1.383  1.00 25.90           C  
ATOM    567  CD  PRO A  75      -5.404  66.499  -0.070  1.00 23.80           C  
ATOM    568  N   ASP A  76      -9.202  63.752  -0.172  1.00 27.50           N  
ATOM    569  CA  ASP A  76     -10.489  63.603  -0.855  1.00 28.90           C  
ATOM    570  C   ASP A  76     -11.318  64.861  -0.880  1.00 29.30           C  
ATOM    571  O   ASP A  76     -12.083  65.097  -1.834  1.00 30.10           O  
ATOM    572  CB  ASP A  76     -11.311  62.493  -0.209  1.00 29.90           C  
ATOM    573  CG  ASP A  76     -10.820  61.109  -0.592  1.00 45.70           C  
ATOM    574  OD1 ASP A  76      -9.582  60.892  -0.607  1.00 61.60           O  
ATOM    575  OD2 ASP A  76     -11.671  60.243  -0.899  1.00 68.80           O  
ATOM    576  N   HIS A  77     -11.177  65.672   0.165  1.00 29.20           N  
ATOM    577  CA  HIS A  77     -12.027  66.840   0.313  1.00 29.40           C  
ATOM    578  C   HIS A  77     -11.537  67.998  -0.538  1.00 29.60           C  
ATOM    579  O   HIS A  77     -12.304  68.920  -0.827  1.00 30.20           O  
ATOM    580  CB  HIS A  77     -12.124  67.270   1.777  1.00 29.20           C  
ATOM    581  CG  HIS A  77     -10.911  67.931   2.405  1.00 29.10           C  
ATOM    582  ND1 HIS A  77      -9.781  67.206   2.844  1.00 29.70           N  
ATOM    583  CD2 HIS A  77     -10.781  69.296   2.627  1.00 28.80           C  
ATOM    584  CE1 HIS A  77      -8.954  68.151   3.344  1.00 29.50           C  
ATOM    585  NE2 HIS A  77      -9.520  69.334   3.236  1.00 29.50           N  
ATOM    586  N   MET A  78     -10.281  67.946  -0.977  1.00 29.30           N  
ATOM    587  CA  MET A  78      -9.823  68.949  -1.931  1.00 29.10           C  
ATOM    588  C   MET A  78      -9.388  68.425  -3.300  1.00 28.10           C  
ATOM    589  O   MET A  78      -8.728  69.137  -4.047  1.00 27.50           O  
ATOM    590  CB  MET A  78      -8.756  69.870  -1.309  1.00 28.60           C  
ATOM    591  CG  MET A  78      -7.721  69.230  -0.448  1.00 29.20           C  
ATOM    592  SD  MET A  78      -6.813  70.538   0.434  1.00 27.50           S  
ATOM    593  CE  MET A  78      -6.071  71.413  -0.820  1.00 27.70           C  
ATOM    594  N   LYS A  79     -10.022  67.345  -3.734  1.00 27.70           N  
ATOM    595  CA  LYS A  79      -9.790  66.826  -5.079  1.00 28.20           C  
ATOM    596  C   LYS A  79     -10.429  67.676  -6.178  1.00 28.30           C  
ATOM    597  O   LYS A  79      -9.997  67.651  -7.332  1.00 29.00           O  
ATOM    598  CB  LYS A  79     -10.285  65.393  -5.167  1.00 28.20           C  
ATOM    599  CG  LYS A  79      -9.372  64.397  -4.433  1.00 28.60           C  
ATOM    600  CD  LYS A  79      -9.636  62.961  -4.897  1.00 35.90           C  
ATOM    601  CE  LYS A  79      -8.759  61.919  -4.199  1.00 27.80           C  
ATOM    602  NZ  LYS A  79      -7.317  62.252  -4.281  1.00 56.40           N  
ATOM    603  N   ARG A  80     -11.357  68.537  -5.788  1.00 27.80           N  
ATOM    604  CA  ARG A  80     -11.966  69.496  -6.709  1.00 28.20           C  
ATOM    605  C   ARG A  80     -10.974  70.578  -7.133  1.00 27.50           C  
ATOM    606  O   ARG A  80     -11.289  71.428  -7.966  1.00 27.90           O  
ATOM    607  CB  ARG A  80     -13.136  70.177  -6.016  1.00 29.50           C  
ATOM    608  CG  ARG A  80     -14.378  70.343  -6.816  1.00 40.90           C  
ATOM    609  CD  ARG A  80     -15.553  70.296  -5.850  1.00 69.50           C  
ATOM    610  NE  ARG A  80     -15.559  69.044  -5.091  1.00104.20           N  
ATOM    611  CZ  ARG A  80     -16.401  68.765  -4.101  1.00108.20           C  
ATOM    612  NH1 ARG A  80     -17.258  69.686  -3.679  1.00110.90           N  
ATOM    613  NH2 ARG A  80     -16.368  67.570  -3.514  1.00109.90           N  
ATOM    614  N   HIS A  81      -9.854  70.651  -6.427  1.00 26.80           N  
ATOM    615  CA  HIS A  81      -8.886  71.743  -6.596  1.00 25.70           C  
ATOM    616  C   HIS A  81      -7.604  71.277  -7.320  1.00 24.00           C  
ATOM    617  O   HIS A  81      -6.607  71.999  -7.353  1.00 22.70           O  
ATOM    618  CB  HIS A  81      -8.530  72.348  -5.210  1.00 26.90           C  
ATOM    619  CG  HIS A  81      -9.740  72.930  -4.421  1.00 27.60           C  
ATOM    620  ND1 HIS A  81     -10.553  72.307  -3.435  1.00 28.50           N  
ATOM    621  CD2 HIS A  81     -10.240  74.165  -4.734  1.00 27.10           C  
ATOM    622  CE1 HIS A  81     -11.532  73.182  -3.193  1.00 28.10           C  
ATOM    623  NE2 HIS A  81     -11.332  74.234  -3.912  1.00 28.00           N  
ATOM    624  N   ASP A  82      -7.620  70.056  -7.846  1.00 22.60           N  
ATOM    625  CA  ASP A  82      -6.419  69.504  -8.438  1.00 22.30           C  
ATOM    626  C   ASP A  82      -6.355  69.888  -9.929  1.00 22.10           C  
ATOM    627  O   ASP A  82      -6.694  69.091 -10.797  1.00 21.60           O  
ATOM    628  CB  ASP A  82      -6.385  67.994  -8.259  1.00 21.30           C  
ATOM    629  CG  ASP A  82      -5.022  67.398  -8.573  1.00 18.50           C  
ATOM    630  OD1 ASP A  82      -4.144  68.087  -9.120  1.00 19.00           O  
ATOM    631  OD2 ASP A  82      -4.825  66.217  -8.288  1.00 23.50           O  
ATOM    632  N   PHE A  83      -5.859  71.094 -10.195  1.00 22.00           N  
ATOM    633  CA  PHE A  83      -5.583  71.548 -11.559  1.00 22.60           C  
ATOM    634  C   PHE A  83      -4.690  70.563 -12.338  1.00 22.40           C  
ATOM    635  O   PHE A  83      -4.995  70.180 -13.479  1.00 22.50           O  
ATOM    636  CB  PHE A  83      -4.925  72.933 -11.544  1.00 23.00           C  
ATOM    637  CG  PHE A  83      -4.437  73.374 -12.898  1.00 24.60           C  
ATOM    638  CD1 PHE A  83      -5.312  73.978 -13.806  1.00 24.50           C  
ATOM    639  CD2 PHE A  83      -3.121  73.114 -13.299  1.00 24.10           C  
ATOM    640  CE1 PHE A  83      -4.884  74.328 -15.110  1.00 25.00           C  
ATOM    641  CE2 PHE A  83      -2.678  73.450 -14.599  1.00 25.20           C  
ATOM    642  CZ  PHE A  83      -3.566  74.061 -15.504  1.00 24.90           C  
ATOM    643  N   PHE A  84      -3.632  70.106 -11.683  1.00 21.40           N  
ATOM    644  CA  PHE A  84      -2.587  69.365 -12.348  1.00 20.80           C  
ATOM    645  C   PHE A  84      -3.090  68.123 -13.034  1.00 21.70           C  
ATOM    646  O   PHE A  84      -2.735  67.900 -14.200  1.00 22.50           O  
ATOM    647  CB  PHE A  84      -1.450  69.018 -11.382  1.00 19.10           C  
ATOM    648  CG  PHE A  84      -0.829  70.219 -10.728  1.00 19.20           C  
ATOM    649  CD1 PHE A  84       0.116  70.987 -11.396  1.00 18.40           C  
ATOM    650  CD2 PHE A  84      -1.218  70.607  -9.424  1.00 19.50           C  
ATOM    651  CE1 PHE A  84       0.675  72.131 -10.806  1.00 19.00           C  
ATOM    652  CE2 PHE A  84      -0.671  71.751  -8.821  1.00 18.50           C  
ATOM    653  CZ  PHE A  84       0.279  72.518  -9.517  1.00 19.40           C  
ATOM    654  N   LYS A  85      -3.898  67.312 -12.340  1.00 21.70           N  
ATOM    655  CA  LYS A  85      -4.405  66.057 -12.918  1.00 21.20           C  
ATOM    656  C   LYS A  85      -5.539  66.303 -13.923  1.00 21.20           C  
ATOM    657  O   LYS A  85      -5.759  65.511 -14.829  1.00 20.60           O  
ATOM    658  CB  LYS A  85      -4.888  65.109 -11.823  1.00 20.50           C  
ATOM    659  CG  LYS A  85      -3.771  64.549 -10.970  1.00 16.90           C  
ATOM    660  CD  LYS A  85      -4.317  63.509 -10.003  1.00 16.60           C  
ATOM    661  CE  LYS A  85      -3.304  63.113  -8.924  1.00 14.80           C  
ATOM    662  NZ  LYS A  85      -2.743  64.303  -8.218  1.00 15.20           N  
ATOM    663  N   SER A  86      -6.230  67.423 -13.753  1.00 21.90           N  
ATOM    664  CA  SER A  86      -7.371  67.780 -14.594  1.00 21.90           C  
ATOM    665  C   SER A  86      -6.945  68.169 -16.006  1.00 22.70           C  
ATOM    666  O   SER A  86      -7.733  68.040 -16.954  1.00 22.30           O  
ATOM    667  CB  SER A  86      -8.186  68.913 -13.947  1.00 20.30           C  
ATOM    668  OG  SER A  86      -7.629  70.187 -14.149  1.00 19.50           O  
ATOM    669  N   ALA A  87      -5.670  68.534 -16.157  1.00 22.40           N  
ATOM    670  CA  ALA A  87      -5.137  68.934 -17.452  1.00 23.00           C  
ATOM    671  C   ALA A  87      -4.670  67.748 -18.287  1.00 23.50           C  
ATOM    672  O   ALA A  87      -4.449  67.869 -19.496  1.00 25.40           O  
ATOM    673  CB  ALA A  87      -4.006  69.933 -17.261  1.00 22.70           C  
ATOM    674  N   MET A  88      -4.685  66.568 -17.692  1.00 23.40           N  
ATOM    675  CA  MET A  88      -4.156  65.365 -18.321  1.00 23.60           C  
ATOM    676  C   MET A  88      -5.259  64.631 -19.105  1.00 23.80           C  
ATOM    677  O   MET A  88      -6.431  64.747 -18.773  1.00 24.60           O  
ATOM    678  CB  MET A  88      -3.571  64.439 -17.239  1.00 23.60           C  
ATOM    679  CG  MET A  88      -2.404  65.046 -16.465  1.00 22.40           C  
ATOM    680  SD  MET A  88      -1.014  65.296 -17.544  1.00 24.00           S  
ATOM    681  CE  MET A  88      -0.066  66.465 -16.690  1.00 22.40           C  
ATOM    682  N   PRO A  89      -4.901  63.880 -20.169  1.00 23.70           N  
ATOM    683  CA  PRO A  89      -3.572  63.548 -20.703  1.00 23.60           C  
ATOM    684  C   PRO A  89      -2.854  64.592 -21.558  1.00 23.40           C  
ATOM    685  O   PRO A  89      -1.642  64.505 -21.760  1.00 23.30           O  
ATOM    686  CB  PRO A  89      -3.822  62.264 -21.475  1.00 22.80           C  
ATOM    687  CG  PRO A  89      -5.184  62.390 -21.899  1.00 22.70           C  
ATOM    688  CD  PRO A  89      -5.933  63.064 -20.818  1.00 22.80           C  
ATOM    689  N   GLU A  90      -3.553  65.651 -21.925  1.00 23.80           N  
ATOM    690  CA  GLU A  90      -2.989  66.616 -22.868  1.00 24.50           C  
ATOM    691  C   GLU A  90      -1.837  67.404 -22.242  1.00 23.90           C  
ATOM    692  O   GLU A  90      -0.873  67.787 -22.918  1.00 23.70           O  
ATOM    693  CB  GLU A  90      -4.093  67.570 -23.388  1.00 25.80           C  
ATOM    694  CG  GLU A  90      -5.231  66.860 -24.147  1.00 34.40           C  
ATOM    695  CD  GLU A  90      -6.515  66.828 -23.357  1.00 49.40           C  
ATOM    696  OE1 GLU A  90      -6.549  66.162 -22.300  1.00 47.60           O  
ATOM    697  OE2 GLU A  90      -7.496  67.449 -23.813  1.00 52.90           O  
ATOM    698  N   GLY A  91      -1.982  67.695 -20.958  1.00 23.00           N  
ATOM    699  CA  GLY A  91      -0.871  68.217 -20.203  1.00 21.30           C  
ATOM    700  C   GLY A  91      -0.976  69.699 -20.018  1.00 20.90           C  
ATOM    701  O   GLY A  91      -2.015  70.306 -20.297  1.00 21.40           O  
ATOM    702  N   TYR A  92       0.102  70.278 -19.510  1.00 19.90           N  
ATOM    703  CA  TYR A  92       0.158  71.697 -19.235  1.00 19.30           C  
ATOM    704  C   TYR A  92       1.562  72.181 -19.516  1.00 18.00           C  
ATOM    705  O   TYR A  92       2.492  71.384 -19.622  1.00 18.10           O  
ATOM    706  CB  TYR A  92      -0.256  72.002 -17.760  1.00 20.00           C  
ATOM    707  CG  TYR A  92       0.467  71.204 -16.673  1.00 19.60           C  
ATOM    708  CD1 TYR A  92       1.714  71.615 -16.183  1.00 19.70           C  
ATOM    709  CD2 TYR A  92      -0.146  70.109 -16.058  1.00 19.70           C  
ATOM    710  CE1 TYR A  92       2.330  70.953 -15.092  1.00 19.20           C  
ATOM    711  CE2 TYR A  92       0.476  69.430 -14.989  1.00 19.40           C  
ATOM    712  CZ  TYR A  92       1.712  69.861 -14.520  1.00 18.90           C  
ATOM    713  OH  TYR A  92       2.358  69.136 -13.547  1.00 19.20           O  
ATOM    714  N   VAL A  93       1.671  73.471 -19.768  1.00 17.80           N  
ATOM    715  CA  VAL A  93       2.937  74.153 -19.895  1.00 18.10           C  
ATOM    716  C   VAL A  93       3.226  74.765 -18.556  1.00 18.90           C  
ATOM    717  O   VAL A  93       2.368  75.417 -17.955  1.00 19.50           O  
ATOM    718  CB  VAL A  93       2.863  75.282 -20.948  1.00 18.00           C  
ATOM    719  CG1 VAL A  93       4.110  76.117 -20.925  1.00 18.10           C  
ATOM    720  CG2 VAL A  93       2.674  74.683 -22.319  1.00 18.20           C  
ATOM    721  N   GLN A  94       4.439  74.544 -18.078  1.00 18.60           N  
ATOM    722  CA  GLN A  94       4.889  75.127 -16.828  1.00 18.60           C  
ATOM    723  C   GLN A  94       6.056  76.058 -17.125  1.00 18.00           C  
ATOM    724  O   GLN A  94       7.099  75.628 -17.616  1.00 18.60           O  
ATOM    725  CB  GLN A  94       5.328  74.012 -15.845  1.00 18.20           C  
ATOM    726  CG  GLN A  94       5.876  74.538 -14.504  1.00 16.10           C  
ATOM    727  CD  GLN A  94       5.896  73.482 -13.395  1.00 23.00           C  
ATOM    728  OE1 GLN A  94       4.885  72.854 -13.102  1.00 19.10           O  
ATOM    729  NE2 GLN A  94       7.072  73.235 -12.827  1.00 16.50           N  
ATOM    730  N   GLU A  95       5.907  77.313 -16.741  1.00 18.50           N  
ATOM    731  CA  GLU A  95       6.945  78.316 -16.944  1.00 19.90           C  
ATOM    732  C   GLU A  95       7.340  78.972 -15.614  1.00 20.70           C  
ATOM    733  O   GLU A  95       6.483  79.234 -14.749  1.00 21.30           O  
ATOM    734  CB  GLU A  95       6.454  79.400 -17.910  1.00 19.80           C  
ATOM    735  CG  GLU A  95       5.965  78.838 -19.249  1.00 26.40           C  
ATOM    736  CD  GLU A  95       5.331  79.898 -20.135  1.00 38.70           C  
ATOM    737  OE1 GLU A  95       4.134  80.237 -19.936  1.00 41.00           O  
ATOM    738  OE2 GLU A  95       6.036  80.388 -21.035  1.00 43.20           O  
ATOM    739  N   ARG A  96       8.635  79.257 -15.468  1.00 20.10           N  
ATOM    740  CA  ARG A  96       9.138  79.884 -14.271  1.00 20.30           C  
ATOM    741  C   ARG A  96      10.206  80.900 -14.623  1.00 21.00           C  
ATOM    742  O   ARG A  96      11.016  80.690 -15.549  1.00 21.60           O  
ATOM    743  CB  ARG A  96       9.755  78.839 -13.305  1.00 19.30           C  
ATOM    744  CG  ARG A  96       8.788  77.887 -12.624  1.00 18.40           C  
ATOM    745  CD  ARG A  96       9.237  77.572 -11.157  1.00 17.50           C  
ATOM    746  NE  ARG A  96       8.460  76.478 -10.547  1.00 16.10           N  
ATOM    747  CZ  ARG A  96       8.815  75.192 -10.544  1.00 15.50           C  
ATOM    748  NH1 ARG A  96       9.907  74.792 -11.149  1.00 15.30           N  
ATOM    749  NH2 ARG A  96       8.055  74.280  -9.955  1.00 16.20           N  
ATOM    750  N   THR A  97      10.302  81.906 -13.758  1.00 21.20           N  
ATOM    751  CA  THR A  97      11.547  82.605 -13.478  1.00 21.60           C  
ATOM    752  C   THR A  97      11.941  82.364 -12.022  1.00 21.40           C  
ATOM    753  O   THR A  97      11.139  82.529 -11.098  1.00 21.70           O  
ATOM    754  CB  THR A  97      11.394  84.099 -13.676  1.00 22.00           C  
ATOM    755  OG1 THR A  97      10.935  84.331 -15.013  1.00 23.80           O  
ATOM    756  CG2 THR A  97      12.733  84.805 -13.471  1.00 23.20           C  
ATOM    757  N   ILE A  98      13.187  81.974 -11.842  1.00 21.60           N  
ATOM    758  CA  ILE A  98      13.728  81.648 -10.536  1.00 21.80           C  
ATOM    759  C   ILE A  98      14.836  82.628 -10.249  1.00 22.50           C  
ATOM    760  O   ILE A  98      15.839  82.655 -10.960  1.00 23.10           O  
ATOM    761  CB  ILE A  98      14.320  80.224 -10.527  1.00 20.50           C  
ATOM    762  CG1 ILE A  98      13.244  79.219 -10.934  1.00 19.40           C  
ATOM    763  CG2 ILE A  98      14.883  79.914  -9.137  1.00 21.40           C  
ATOM    764  CD1 ILE A  98      13.739  77.832 -11.055  1.00 19.70           C  
ATOM    765  N   PHE A  99      14.643  83.460  -9.241  1.00 23.10           N  
ATOM    766  CA  PHE A  99      15.638  84.463  -8.932  1.00 24.00           C  
ATOM    767  C   PHE A  99      16.499  84.015  -7.758  1.00 24.20           C  
ATOM    768  O   PHE A  99      16.014  83.938  -6.638  1.00 24.70           O  
ATOM    769  CB  PHE A  99      14.956  85.788  -8.582  1.00 24.60           C  
ATOM    770  CG  PHE A  99      14.042  86.304  -9.651  1.00 25.80           C  
ATOM    771  CD1 PHE A  99      14.524  87.157 -10.641  1.00 26.00           C  
ATOM    772  CD2 PHE A  99      12.676  86.055  -9.580  1.00 26.20           C  
ATOM    773  CE1 PHE A  99      13.649  87.767 -11.531  1.00 27.00           C  
ATOM    774  CE2 PHE A  99      11.792  86.660 -10.456  1.00 26.80           C  
ATOM    775  CZ  PHE A  99      12.271  87.519 -11.433  1.00 27.40           C  
ATOM    776  N   PHE A 100      17.780  83.754  -7.989  1.00 24.60           N  
ATOM    777  CA  PHE A 100      18.707  83.537  -6.879  1.00 24.80           C  
ATOM    778  C   PHE A 100      19.173  84.859  -6.293  1.00 25.80           C  
ATOM    779  O   PHE A 100      19.756  85.701  -6.989  1.00 26.50           O  
ATOM    780  CB  PHE A 100      19.908  82.710  -7.331  1.00 24.20           C  
ATOM    781  CG  PHE A 100      19.545  81.316  -7.767  1.00 24.50           C  
ATOM    782  CD1 PHE A 100      19.079  81.074  -9.044  1.00 23.40           C  
ATOM    783  CD2 PHE A 100      19.586  80.254  -6.870  1.00 23.90           C  
ATOM    784  CE1 PHE A 100      18.652  79.787  -9.414  1.00 24.10           C  
ATOM    785  CE2 PHE A 100      19.166  78.970  -7.246  1.00 23.70           C  
ATOM    786  CZ  PHE A 100      18.702  78.740  -8.506  1.00 23.30           C  
ATOM    787  N   LYS A 101      18.939  85.036  -4.996  1.00 26.80           N  
ATOM    788  CA  LYS A 101      19.199  86.314  -4.338  1.00 27.50           C  
ATOM    789  C   LYS A 101      20.678  86.677  -4.349  1.00 27.60           C  
ATOM    790  O   LYS A 101      21.545  85.878  -3.984  1.00 28.20           O  
ATOM    791  CB  LYS A 101      18.675  86.283  -2.911  1.00 27.40           C  
ATOM    792  CG  LYS A 101      18.430  87.639  -2.315  1.00 33.80           C  
ATOM    793  CD  LYS A 101      18.585  87.575  -0.788  1.00 51.70           C  
ATOM    794  CE  LYS A 101      18.791  88.963  -0.142  1.00 71.70           C  
ATOM    795  NZ  LYS A 101      20.203  89.510  -0.180  1.00 72.00           N  
ATOM    796  N   ASP A 102      20.958  87.873  -4.847  1.00 28.30           N  
ATOM    797  CA  ASP A 102      22.325  88.350  -5.042  1.00 28.20           C  
ATOM    798  C   ASP A 102      23.045  87.556  -6.133  1.00 27.90           C  
ATOM    799  O   ASP A 102      24.272  87.353  -6.062  1.00 28.60           O  
ATOM    800  CB  ASP A 102      23.123  88.287  -3.722  1.00 28.20           C  
ATOM    801  CG  ASP A 102      22.575  89.223  -2.667  1.00 47.70           C  
ATOM    802  OD1 ASP A 102      21.989  90.274  -3.021  1.00 47.30           O  
ATOM    803  OD2 ASP A 102      22.729  88.902  -1.469  1.00 56.20           O  
ATOM    804  N   ASP A 103      22.291  87.036  -7.097  1.00 26.40           N  
ATOM    805  CA  ASP A 103      22.911  86.248  -8.154  1.00 25.60           C  
ATOM    806  C   ASP A 103      22.008  86.214  -9.407  1.00 24.90           C  
ATOM    807  O   ASP A 103      21.140  87.065  -9.571  1.00 25.50           O  
ATOM    808  CB  ASP A 103      23.239  84.838  -7.625  1.00 25.10           C  
ATOM    809  CG  ASP A 103      24.573  84.304  -8.145  1.00 26.40           C  
ATOM    810  OD1 ASP A 103      24.933  84.566  -9.298  1.00 30.40           O  
ATOM    811  OD2 ASP A 103      25.236  83.538  -7.441  1.00 32.60           O  
ATOM    812  N   GLY A 104      22.247  85.275 -10.310  1.00 24.50           N  
ATOM    813  CA  GLY A 104      21.502  85.244 -11.554  1.00 24.20           C  
ATOM    814  C   GLY A 104      20.102  84.653 -11.464  1.00 23.60           C  
ATOM    815  O   GLY A 104      19.534  84.496 -10.377  1.00 23.80           O  
ATOM    816  N   ASN A 105      19.557  84.278 -12.611  1.00 22.50           N  
ATOM    817  CA  ASN A 105      18.206  83.761 -12.646  1.00 21.60           C  
ATOM    818  C   ASN A 105      18.075  82.712 -13.724  1.00 21.50           C  
ATOM    819  O   ASN A 105      18.930  82.607 -14.602  1.00 22.30           O  
ATOM    820  CB  ASN A 105      17.220  84.902 -12.880  1.00 22.60           C  
ATOM    821  CG  ASN A 105      17.467  85.616 -14.172  1.00 29.60           C  
ATOM    822  OD1 ASN A 105      16.905  85.244 -15.197  1.00 36.00           O  
ATOM    823  ND2 ASN A 105      18.378  86.596 -14.156  1.00 28.50           N  
ATOM    824  N   TYR A 106      17.181  81.761 -13.474  1.00 21.20           N  
ATOM    825  CA  TYR A 106      16.836  80.733 -14.443  1.00 20.70           C  
ATOM    826  C   TYR A 106      15.471  81.135 -15.006  1.00 20.40           C  
ATOM    827  O   TYR A 106      14.593  81.635 -14.279  1.00 19.70           O  
ATOM    828  CB  TYR A 106      16.691  79.337 -13.784  1.00 20.30           C  
ATOM    829  CG  TYR A 106      17.942  78.672 -13.215  1.00 19.70           C  
ATOM    830  CD1 TYR A 106      19.230  79.046 -13.622  1.00 19.60           C  
ATOM    831  CD2 TYR A 106      17.812  77.566 -12.357  1.00 18.90           C  
ATOM    832  CE1 TYR A 106      20.366  78.315 -13.198  1.00 19.10           C  
ATOM    833  CE2 TYR A 106      18.912  76.827 -11.939  1.00 18.40           C  
ATOM    834  CZ  TYR A 106      20.190  77.193 -12.360  1.00 18.90           C  
ATOM    835  OH  TYR A 106      21.258  76.400 -11.978  1.00 18.20           O  
ATOM    836  N   LYS A 107      15.283  80.899 -16.301  1.00 20.40           N  
ATOM    837  CA  LYS A 107      13.970  81.012 -16.898  1.00 19.60           C  
ATOM    838  C   LYS A 107      13.719  79.681 -17.562  1.00 19.20           C  
ATOM    839  O   LYS A 107      14.609  79.131 -18.210  1.00 19.60           O  
ATOM    840  CB  LYS A 107      13.961  82.152 -17.914  1.00 20.20           C  
ATOM    841  CG  LYS A 107      14.367  83.512 -17.342  1.00 33.50           C  
ATOM    842  CD  LYS A 107      14.837  84.498 -18.447  1.00 50.30           C  
ATOM    843  CE  LYS A 107      16.327  84.889 -18.310  1.00 57.90           C  
ATOM    844  NZ  LYS A 107      17.216  83.758 -17.837  1.00 45.70           N  
ATOM    845  N   THR A 108      12.604  79.046 -17.242  1.00 18.80           N  
ATOM    846  CA  THR A 108      12.380  77.707 -17.760  1.00 18.80           C  
ATOM    847  C   THR A 108      11.019  77.614 -18.402  1.00 19.30           C  
ATOM    848  O   THR A 108      10.116  78.401 -18.090  1.00 19.60           O  
ATOM    849  CB  THR A 108      12.485  76.617 -16.660  1.00 18.40           C  
ATOM    850  OG1 THR A 108      11.440  76.798 -15.706  1.00 18.80           O  
ATOM    851  CG2 THR A 108      13.792  76.718 -15.934  1.00 17.30           C  
ATOM    852  N   ARG A 109      10.900  76.699 -19.357  1.00 19.80           N  
ATOM    853  CA  ARG A 109       9.622  76.389 -19.932  1.00 20.00           C  
ATOM    854  C   ARG A 109       9.609  74.909 -20.092  1.00 19.20           C  
ATOM    855  O   ARG A 109      10.576  74.312 -20.549  1.00 19.90           O  
ATOM    856  CB  ARG A 109       9.454  77.059 -21.298  1.00 23.30           C  
ATOM    857  CG  ARG A 109       7.981  77.234 -21.685  1.00 26.80           C  
ATOM    858  CD  ARG A 109       7.774  77.573 -23.170  1.00 30.10           C  
ATOM    859  NE  ARG A 109       7.800  76.353 -23.973  1.00 33.00           N  
ATOM    860  CZ  ARG A 109       6.721  75.764 -24.488  1.00 34.90           C  
ATOM    861  NH1 ARG A 109       5.534  76.373 -24.452  1.00 36.10           N  
ATOM    862  NH2 ARG A 109       6.849  74.597 -25.129  1.00 36.10           N  
ATOM    863  N   ALA A 110       8.515  74.305 -19.683  1.00 18.70           N  
ATOM    864  CA  ALA A 110       8.410  72.881 -19.711  1.00 18.90           C  
ATOM    865  C   ALA A 110       7.030  72.460 -20.215  1.00 18.50           C  
ATOM    866  O   ALA A 110       6.029  73.168 -20.052  1.00 19.00           O  
ATOM    867  CB  ALA A 110       8.657  72.333 -18.306  1.00 19.50           C  
ATOM    868  N   GLU A 111       6.972  71.277 -20.791  1.00 18.50           N  
ATOM    869  CA  GLU A 111       5.705  70.670 -21.115  1.00 19.00           C  
ATOM    870  C   GLU A 111       5.593  69.382 -20.334  1.00 19.00           C  
ATOM    871  O   GLU A 111       6.507  68.529 -20.362  1.00 19.40           O  
ATOM    872  CB  GLU A 111       5.627  70.370 -22.616  1.00 19.80           C  
ATOM    873  CG  GLU A 111       5.002  71.482 -23.424  1.00 35.50           C  
ATOM    874  CD  GLU A 111       5.127  71.256 -24.933  1.00 44.70           C  
ATOM    875  OE1 GLU A 111       5.116  70.075 -25.373  1.00 46.60           O  
ATOM    876  OE2 GLU A 111       5.259  72.268 -25.665  1.00 54.60           O  
ATOM    877  N   VAL A 112       4.457  69.207 -19.668  1.00 18.30           N  
ATOM    878  CA  VAL A 112       4.255  68.006 -18.881  1.00 18.80           C  
ATOM    879  C   VAL A 112       3.010  67.361 -19.441  1.00 18.70           C  
ATOM    880  O   VAL A 112       1.949  67.962 -19.404  1.00 19.40           O  
ATOM    881  CB  VAL A 112       4.055  68.356 -17.343  1.00 17.90           C  
ATOM    882  CG1 VAL A 112       3.787  67.103 -16.528  1.00 18.10           C  
ATOM    883  CG2 VAL A 112       5.265  69.097 -16.827  1.00 17.90           C  
ATOM    884  N   LYS A 113       3.148  66.162 -19.983  1.00 19.80           N  
ATOM    885  CA  LYS A 113       2.022  65.504 -20.643  1.00 21.10           C  
ATOM    886  C   LYS A 113       2.240  63.991 -20.749  1.00 21.80           C  
ATOM    887  O   LYS A 113       3.360  63.502 -20.543  1.00 21.70           O  
ATOM    888  CB  LYS A 113       1.816  66.120 -22.037  1.00 21.30           C  
ATOM    889  CG  LYS A 113       2.949  65.827 -23.010  1.00 26.60           C  
ATOM    890  CD  LYS A 113       2.560  66.137 -24.443  1.00 42.00           C  
ATOM    891  CE  LYS A 113       3.481  67.186 -25.015  1.00 44.30           C  
ATOM    892  NZ  LYS A 113       4.883  66.682 -25.126  1.00 52.10           N  
ATOM    893  N   PHE A 114       1.171  63.242 -21.018  1.00 22.30           N  
ATOM    894  CA  PHE A 114       1.325  61.823 -21.267  1.00 24.00           C  
ATOM    895  C   PHE A 114       1.749  61.596 -22.721  1.00 25.90           C  
ATOM    896  O   PHE A 114       1.292  62.287 -23.645  1.00 25.80           O  
ATOM    897  CB  PHE A 114       0.029  61.060 -20.977  1.00 23.80           C  
ATOM    898  CG  PHE A 114      -0.130  60.662 -19.538  1.00 23.20           C  
ATOM    899  CD1 PHE A 114       0.388  59.469 -19.077  1.00 23.30           C  
ATOM    900  CD2 PHE A 114      -0.762  61.504 -18.639  1.00 23.60           C  
ATOM    901  CE1 PHE A 114       0.277  59.122 -17.743  1.00 23.70           C  
ATOM    902  CE2 PHE A 114      -0.880  61.152 -17.284  1.00 23.10           C  
ATOM    903  CZ  PHE A 114      -0.359  59.963 -16.844  1.00 22.70           C  
ATOM    904  N   GLU A 115       2.787  60.789 -22.869  1.00 27.00           N  
ATOM    905  CA  GLU A 115       3.191  60.302 -24.163  1.00 28.80           C  
ATOM    906  C   GLU A 115       3.080  58.794 -24.042  1.00 29.60           C  
ATOM    907  O   GLU A 115       3.942  58.157 -23.450  1.00 30.50           O  
ATOM    908  CB  GLU A 115       4.625  60.735 -24.481  1.00 28.00           C  
ATOM    909  CG  GLU A 115       4.755  62.230 -24.802  1.00 36.20           C  
ATOM    910  CD  GLU A 115       6.189  62.669 -25.103  1.00 44.90           C  
ATOM    911  OE1 GLU A 115       7.064  61.779 -25.258  1.00 43.90           O  
ATOM    912  OE2 GLU A 115       6.439  63.904 -25.154  1.00 43.20           O  
ATOM    913  N   GLY A 116       1.928  58.253 -24.431  1.00 30.00           N  
ATOM    914  CA  GLY A 116       1.722  56.827 -24.305  1.00 30.40           C  
ATOM    915  C   GLY A 116       1.350  56.529 -22.881  1.00 31.00           C  
ATOM    916  O   GLY A 116       0.464  57.189 -22.338  1.00 31.30           O  
ATOM    917  N   ASP A 117       2.082  55.616 -22.247  1.00 31.10           N  
ATOM    918  CA  ASP A 117       1.854  55.310 -20.831  1.00 30.60           C  
ATOM    919  C   ASP A 117       2.764  56.019 -19.811  1.00 29.20           C  
ATOM    920  O   ASP A 117       2.599  55.813 -18.620  1.00 29.50           O  
ATOM    921  CB  ASP A 117       1.882  53.783 -20.580  1.00 31.90           C  
ATOM    922  CG  ASP A 117       3.081  53.080 -21.220  1.00 55.10           C  
ATOM    923  OD1 ASP A 117       4.198  53.227 -20.697  1.00 53.90           O  
ATOM    924  OD2 ASP A 117       2.885  52.311 -22.192  1.00 74.80           O  
ATOM    925  N   THR A 118       3.656  56.897 -20.248  1.00 27.50           N  
ATOM    926  CA  THR A 118       4.539  57.530 -19.307  1.00 25.90           C  
ATOM    927  C   THR A 118       4.358  59.018 -19.308  1.00 25.00           C  
ATOM    928  O   THR A 118       3.878  59.595 -20.289  1.00 24.70           O  
ATOM    929  CB  THR A 118       6.039  57.116 -19.520  1.00 26.70           C  
ATOM    930  OG1 THR A 118       6.840  58.240 -19.907  1.00 27.60           O  
ATOM    931  CG2 THR A 118       6.160  56.049 -20.515  1.00 25.00           C  
ATOM    932  N   LEU A 119       4.459  59.581 -18.110  1.00 23.10           N  
ATOM    933  CA  LEU A 119       4.378  61.017 -17.938  1.00 22.00           C  
ATOM    934  C   LEU A 119       5.743  61.627 -18.199  1.00 21.40           C  
ATOM    935  O   LEU A 119       6.749  61.130 -17.691  1.00 20.20           O  
ATOM    936  CB  LEU A 119       3.897  61.375 -16.526  1.00 22.10           C  
ATOM    937  CG  LEU A 119       3.702  62.887 -16.507  1.00 23.10           C  
ATOM    938  CD1 LEU A 119       2.294  63.233 -16.159  1.00 23.80           C  
ATOM    939  CD2 LEU A 119       4.697  63.529 -15.593  1.00 22.50           C  
ATOM    940  N   VAL A 120       5.805  62.573 -19.135  1.00 21.00           N  
ATOM    941  CA  VAL A 120       7.099  63.118 -19.514  1.00 20.60           C  
ATOM    942  C   VAL A 120       7.164  64.601 -19.290  1.00 19.90           C  
ATOM    943  O   VAL A 120       6.185  65.311 -19.435  1.00 20.90           O  
ATOM    944  CB  VAL A 120       7.511  62.774 -20.997  1.00 20.50           C  
ATOM    945  CG1 VAL A 120       6.913  61.452 -21.430  1.00 19.90           C  
ATOM    946  CG2 VAL A 120       7.139  63.875 -21.906  1.00 21.20           C  
ATOM    947  N   ASN A 121       8.321  65.054 -18.843  1.00 19.80           N  
ATOM    948  CA  ASN A 121       8.533  66.445 -18.494  1.00 19.90           C  
ATOM    949  C   ASN A 121       9.719  66.867 -19.349  1.00 19.80           C  
ATOM    950  O   ASN A 121      10.819  66.323 -19.179  1.00 19.40           O  
ATOM    951  CB  ASN A 121       8.856  66.539 -16.972  1.00 20.20           C  
ATOM    952  CG  ASN A 121       9.199  67.953 -16.486  1.00 17.70           C  
ATOM    953  OD1 ASN A 121       9.779  68.109 -15.395  1.00 20.60           O  
ATOM    954  ND2 ASN A 121       8.707  68.975 -17.176  1.00 14.70           N  
ATOM    955  N   ARG A 122       9.454  67.691 -20.372  1.00 20.10           N  
ATOM    956  CA  ARG A 122      10.513  68.194 -21.250  1.00 19.90           C  
ATOM    957  C   ARG A 122      10.729  69.668 -21.000  1.00 18.70           C  
ATOM    958  O   ARG A 122       9.771  70.451 -21.008  1.00 18.10           O  
ATOM    959  CB  ARG A 122      10.162  67.988 -22.719  1.00 21.80           C  
ATOM    960  CG  ARG A 122      10.097  66.548 -23.179  1.00 23.20           C  
ATOM    961  CD  ARG A 122       9.440  66.537 -24.583  1.00 26.00           C  
ATOM    962  NE  ARG A 122       9.050  65.205 -25.007  1.00 27.20           N  
ATOM    963  CZ  ARG A 122       9.910  64.198 -25.090  1.00 28.10           C  
ATOM    964  NH1 ARG A 122      11.201  64.418 -24.862  1.00 29.30           N  
ATOM    965  NH2 ARG A 122       9.505  62.993 -25.471  1.00 29.50           N  
ATOM    966  N   ILE A 123      11.988  70.039 -20.739  1.00 18.00           N  
ATOM    967  CA  ILE A 123      12.305  71.352 -20.183  1.00 17.80           C  
ATOM    968  C   ILE A 123      13.367  72.027 -20.979  1.00 17.20           C  
ATOM    969  O   ILE A 123      14.347  71.402 -21.340  1.00 17.80           O  
ATOM    970  CB  ILE A 123      12.866  71.290 -18.708  1.00 18.10           C  
ATOM    971  CG1 ILE A 123      11.957  70.470 -17.797  1.00 17.60           C  
ATOM    972  CG2 ILE A 123      12.997  72.685 -18.143  1.00 17.50           C  
ATOM    973  CD1 ILE A 123      12.710  69.477 -16.996  1.00 17.70           C  
ATOM    974  N   GLU A 124      13.208  73.330 -21.113  1.00 17.70           N  
ATOM    975  CA  GLU A 124      14.247  74.230 -21.565  1.00 19.30           C  
ATOM    976  C   GLU A 124      14.581  75.214 -20.474  1.00 19.50           C  
ATOM    977  O   GLU A 124      13.692  75.885 -19.933  1.00 20.20           O  
ATOM    978  CB  GLU A 124      13.795  75.023 -22.816  1.00 21.40           C  
ATOM    979  CG  GLU A 124      13.731  74.173 -24.086  1.00 48.70           C  
ATOM    980  CD  GLU A 124      13.433  74.990 -25.340  1.00 69.20           C  
ATOM    981  OE1 GLU A 124      13.680  76.225 -25.335  1.00 92.90           O  
ATOM    982  OE2 GLU A 124      12.964  74.376 -26.332  1.00 94.70           O  
ATOM    983  N   LEU A 125      15.876  75.409 -20.250  1.00 19.80           N  
ATOM    984  CA  LEU A 125      16.320  76.314 -19.217  1.00 19.60           C  
ATOM    985  C   LEU A 125      17.382  77.235 -19.762  1.00 19.90           C  
ATOM    986  O   LEU A 125      18.373  76.765 -20.321  1.00 20.70           O  
ATOM    987  CB  LEU A 125      16.889  75.510 -18.030  1.00 19.40           C  
ATOM    988  CG  LEU A 125      17.520  76.242 -16.849  1.00 19.30           C  
ATOM    989  CD1 LEU A 125      17.298  75.384 -15.642  1.00 20.60           C  
ATOM    990  CD2 LEU A 125      19.009  76.469 -17.025  1.00 19.00           C  
ATOM    991  N   LYS A 126      17.269  78.513 -19.423  1.00 20.00           N  
ATOM    992  CA  LYS A 126      18.324  79.485 -19.681  1.00 21.30           C  
ATOM    993  C   LYS A 126      18.653  80.184 -18.373  1.00 21.90           C  
ATOM    994  O   LYS A 126      17.756  80.633 -17.661  1.00 24.10           O  
ATOM    995  CB  LYS A 126      17.858  80.532 -20.707  1.00 21.70           C  
ATOM    996  CG  LYS A 126      18.923  80.957 -21.752  1.00 45.00           C  
ATOM    997  CD  LYS A 126      19.890  82.031 -21.235  1.00 47.40           C  
ATOM    998  CE  LYS A 126      21.278  81.913 -21.898  1.00 70.00           C  
ATOM    999  NZ  LYS A 126      22.445  82.131 -20.958  1.00 43.60           N  
ATOM   1000  N   GLY A 127      19.927  80.281 -18.044  1.00 22.10           N  
ATOM   1001  CA  GLY A 127      20.300  81.011 -16.858  1.00 22.70           C  
ATOM   1002  C   GLY A 127      21.320  82.072 -17.215  1.00 23.70           C  
ATOM   1003  O   GLY A 127      22.227  81.872 -18.016  1.00 24.50           O  
ATOM   1004  N   ILE A 128      21.160  83.231 -16.636  1.00 25.00           N  
ATOM   1005  CA  ILE A 128      22.098  84.289 -16.889  1.00 27.50           C  
ATOM   1006  C   ILE A 128      22.334  85.126 -15.650  1.00 27.30           C  
ATOM   1007  O   ILE A 128      21.476  85.180 -14.778  1.00 26.70           O  
ATOM   1008  CB  ILE A 128      21.586  85.227 -17.978  1.00 28.60           C  
ATOM   1009  CG1 ILE A 128      20.082  85.390 -17.847  1.00 29.00           C  
ATOM   1010  CG2 ILE A 128      21.982  84.702 -19.355  1.00 30.60           C  
ATOM   1011  CD1 ILE A 128      19.681  86.696 -17.217  1.00 30.40           C  
ATOM   1012  N   ASP A 129      23.360  85.967 -15.741  1.00 28.00           N  
ATOM   1013  CA  ASP A 129      23.719  86.905 -14.690  1.00 29.20           C  
ATOM   1014  C   ASP A 129      24.308  86.184 -13.481  1.00 29.30           C  
ATOM   1015  O   ASP A 129      24.238  86.691 -12.372  1.00 30.50           O  
ATOM   1016  CB  ASP A 129      22.497  87.740 -14.242  1.00 29.90           C  
ATOM   1017  CG  ASP A 129      22.222  88.945 -15.143  1.00 41.90           C  
ATOM   1018  OD1 ASP A 129      23.174  89.470 -15.762  1.00 52.00           O  
ATOM   1019  OD2 ASP A 129      21.055  89.405 -15.182  1.00 49.10           O  
ATOM   1020  N   PHE A 130      24.915  85.028 -13.686  1.00 29.40           N  
ATOM   1021  CA  PHE A 130      25.495  84.336 -12.563  1.00 29.80           C  
ATOM   1022  C   PHE A 130      26.934  84.797 -12.390  1.00 31.10           C  
ATOM   1023  O   PHE A 130      27.587  85.160 -13.361  1.00 30.70           O  
ATOM   1024  CB  PHE A 130      25.406  82.828 -12.767  1.00 28.70           C  
ATOM   1025  CG  PHE A 130      24.024  82.259 -12.506  1.00 27.80           C  
ATOM   1026  CD1 PHE A 130      23.576  82.046 -11.196  1.00 26.80           C  
ATOM   1027  CD2 PHE A 130      23.142  82.032 -13.558  1.00 25.90           C  
ATOM   1028  CE1 PHE A 130      22.257  81.638 -10.942  1.00 26.10           C  
ATOM   1029  CE2 PHE A 130      21.834  81.628 -13.318  1.00 25.90           C  
ATOM   1030  CZ  PHE A 130      21.387  81.438 -12.010  1.00 26.30           C  
ATOM   1031  N   LYS A 131      27.279  85.091 -11.140  1.00 32.70           N  
ATOM   1032  CA  LYS A 131      28.659  85.351 -10.736  1.00 34.20           C  
ATOM   1033  C   LYS A 131      29.515  84.080 -10.766  1.00 35.40           C  
ATOM   1034  O   LYS A 131      29.132  83.057 -10.184  1.00 35.70           O  
ATOM   1035  CB  LYS A 131      28.691  85.900  -9.318  1.00 33.80           C  
ATOM   1036  CG  LYS A 131      27.829  87.091  -9.043  1.00 37.70           C  
ATOM   1037  CD  LYS A 131      28.095  87.521  -7.597  1.00 55.60           C  
ATOM   1038  CE  LYS A 131      26.995  88.390  -7.006  1.00 69.20           C  
ATOM   1039  NZ  LYS A 131      26.945  88.242  -5.516  1.00 66.60           N  
ATOM   1040  N   GLU A 132      30.754  84.207 -11.239  1.00 37.00           N  
ATOM   1041  CA  GLU A 132      31.657  83.046 -11.338  1.00 38.60           C  
ATOM   1042  C   GLU A 132      32.237  82.694  -9.956  1.00 39.10           C  
ATOM   1043  O   GLU A 132      32.772  81.602  -9.730  1.00 39.40           O  
ATOM   1044  CB  GLU A 132      32.779  83.326 -12.366  1.00 39.20           C  
ATOM   1045  CG  GLU A 132      32.301  83.514 -13.875  1.00 63.70           C  
ATOM   1046  CD  GLU A 132      31.963  82.193 -14.651  1.00 78.30           C  
ATOM   1047  OE1 GLU A 132      32.728  81.198 -14.550  1.00 96.80           O  
ATOM   1048  OE2 GLU A 132      30.980  82.186 -15.450  1.00 71.40           O  
ATOM   1049  N   ASP A 133      31.955  83.574  -9.005  1.00 39.30           N  
ATOM   1050  CA  ASP A 133      32.417  83.453  -7.634  1.00 39.70           C  
ATOM   1051  C   ASP A 133      31.222  83.235  -6.706  1.00 39.50           C  
ATOM   1052  O   ASP A 133      31.374  83.235  -5.474  1.00 40.50           O  
ATOM   1053  CB  ASP A 133      33.178  84.742  -7.216  1.00 41.00           C  
ATOM   1054  CG  ASP A 133      32.330  86.037  -7.370  1.00 65.90           C  
ATOM   1055  OD1 ASP A 133      31.972  86.388  -8.522  1.00 80.40           O  
ATOM   1056  OD2 ASP A 133      32.112  86.759  -6.358  1.00 67.40           O  
ATOM   1057  N   GLY A 134      30.019  83.199  -7.282  1.00 37.80           N  
ATOM   1058  CA  GLY A 134      28.821  83.192  -6.459  1.00 35.30           C  
ATOM   1059  C   GLY A 134      28.623  81.820  -5.856  1.00 33.70           C  
ATOM   1060  O   GLY A 134      29.364  80.876  -6.183  1.00 33.00           O  
ATOM   1061  N   ASN A 135      27.539  81.672  -5.098  1.00 32.40           N  
ATOM   1062  CA  ASN A 135      27.204  80.384  -4.496  1.00 30.70           C  
ATOM   1063  C   ASN A 135      26.904  79.323  -5.544  1.00 29.70           C  
ATOM   1064  O   ASN A 135      27.232  78.147  -5.357  1.00 29.10           O  
ATOM   1065  CB  ASN A 135      25.997  80.527  -3.581  1.00 31.00           C  
ATOM   1066  CG  ASN A 135      26.321  81.245  -2.293  1.00 28.00           C  
ATOM   1067  OD1 ASN A 135      25.473  81.952  -1.739  1.00 33.40           O  
ATOM   1068  ND2 ASN A 135      27.542  81.065  -1.797  1.00 35.40           N  
ATOM   1069  N   ILE A 136      26.337  79.744  -6.672  1.00 28.80           N  
ATOM   1070  CA  ILE A 136      25.938  78.790  -7.705  1.00 28.10           C  
ATOM   1071  C   ILE A 136      27.098  78.347  -8.615  1.00 28.20           C  
ATOM   1072  O   ILE A 136      27.449  77.163  -8.645  1.00 28.00           O  
ATOM   1073  CB  ILE A 136      24.736  79.325  -8.546  1.00 28.10           C  
ATOM   1074  CG1 ILE A 136      23.438  79.277  -7.710  1.00 27.90           C  
ATOM   1075  CG2 ILE A 136      24.538  78.465  -9.791  1.00 27.30           C  
ATOM   1076  CD1 ILE A 136      23.237  80.452  -6.852  1.00 28.30           C  
ATOM   1077  N   LEU A 137      27.751  79.291  -9.290  1.00 28.10           N  
ATOM   1078  CA  LEU A 137      28.840  78.937 -10.190  1.00 27.40           C  
ATOM   1079  C   LEU A 137      30.070  78.560  -9.393  1.00 27.00           C  
ATOM   1080  O   LEU A 137      30.870  77.747  -9.846  1.00 26.20           O  
ATOM   1081  CB  LEU A 137      29.155  80.087 -11.145  1.00 28.50           C  
ATOM   1082  CG  LEU A 137      28.345  80.098 -12.456  1.00 29.50           C  
ATOM   1083  CD1 LEU A 137      28.580  78.829 -13.281  1.00 30.00           C  
ATOM   1084  CD2 LEU A 137      26.893  80.147 -12.108  1.00 30.70           C  
ATOM   1085  N   GLY A 138      30.110  79.025  -8.141  1.00 27.00           N  
ATOM   1086  CA  GLY A 138      31.122  78.576  -7.210  1.00 27.20           C  
ATOM   1087  C   GLY A 138      30.892  77.203  -6.581  1.00 27.80           C  
ATOM   1088  O   GLY A 138      31.808  76.659  -5.940  1.00 28.20           O  
ATOM   1089  N   HIS A 139      29.689  76.638  -6.720  1.00 27.40           N  
ATOM   1090  CA  HIS A 139      29.402  75.300  -6.175  1.00 26.90           C  
ATOM   1091  C   HIS A 139      29.609  75.240  -4.653  1.00 25.70           C  
ATOM   1092  O   HIS A 139      30.355  74.393  -4.152  1.00 25.60           O  
ATOM   1093  CB  HIS A 139      30.288  74.240  -6.844  1.00 27.70           C  
ATOM   1094  CG  HIS A 139      29.953  73.838  -8.272  1.00 28.80           C  
ATOM   1095  ND1 HIS A 139      30.157  74.566  -9.497  1.00 30.10           N  
ATOM   1096  CD2 HIS A 139      29.236  72.694  -8.572  1.00 28.50           C  
ATOM   1097  CE1 HIS A 139      29.541  73.900 -10.487  1.00 27.90           C  
ATOM   1098  NE2 HIS A 139      29.020  72.853  -9.965  1.00 28.20           N  
ATOM   1099  N   LYS A 140      28.962  76.159  -3.942  1.00 24.50           N  
ATOM   1100  CA  LYS A 140      29.075  76.236  -2.493  1.00 23.40           C  
ATOM   1101  C   LYS A 140      27.857  75.678  -1.787  1.00 23.00           C  
ATOM   1102  O   LYS A 140      27.724  75.854  -0.569  1.00 24.00           O  
ATOM   1103  CB  LYS A 140      29.285  77.686  -2.054  1.00 24.30           C  
ATOM   1104  CG  LYS A 140      30.673  78.261  -2.326  1.00 26.80           C  
ATOM   1105  CD  LYS A 140      30.583  79.779  -2.385  1.00 35.80           C  
ATOM   1106  CE  LYS A 140      31.941  80.439  -2.601  1.00 55.20           C  
ATOM   1107  NZ  LYS A 140      32.613  79.966  -3.846  1.00 61.10           N  
ATOM   1108  N   LEU A 141      26.909  75.147  -2.557  1.00 21.80           N  
ATOM   1109  CA  LEU A 141      25.670  74.594  -1.999  1.00 21.00           C  
ATOM   1110  C   LEU A 141      25.927  73.209  -1.390  1.00 20.50           C  
ATOM   1111  O   LEU A 141      26.613  72.375  -1.995  1.00 21.10           O  
ATOM   1112  CB  LEU A 141      24.599  74.447  -3.085  1.00 21.10           C  
ATOM   1113  CG  LEU A 141      23.959  75.610  -3.824  1.00 21.20           C  
ATOM   1114  CD1 LEU A 141      22.575  75.196  -4.301  1.00 20.80           C  
ATOM   1115  CD2 LEU A 141      23.879  76.792  -2.937  1.00 22.20           C  
ATOM   1116  N   GLU A 142      25.307  72.916  -0.255  1.00 18.80           N  
ATOM   1117  CA  GLU A 142      25.352  71.566   0.257  1.00 17.50           C  
ATOM   1118  C   GLU A 142      24.456  70.694  -0.597  1.00 16.20           C  
ATOM   1119  O   GLU A 142      23.475  71.164  -1.144  1.00 16.80           O  
ATOM   1120  CB  GLU A 142      24.874  71.552   1.702  1.00 17.80           C  
ATOM   1121  CG  GLU A 142      25.700  72.434   2.609  1.00 22.10           C  
ATOM   1122  CD  GLU A 142      25.319  72.255   4.061  1.00 27.00           C  
ATOM   1123  OE1 GLU A 142      24.109  72.168   4.360  1.00 25.00           O  
ATOM   1124  OE2 GLU A 142      26.243  72.172   4.887  1.00 33.50           O  
ATOM   1125  N   TYR A 143      24.822  69.438  -0.741  1.00 15.60           N  
ATOM   1126  CA  TYR A 143      24.004  68.468  -1.438  1.00 15.70           C  
ATOM   1127  C   TYR A 143      22.896  67.895  -0.545  1.00 15.50           C  
ATOM   1128  O   TYR A 143      22.957  66.742  -0.112  1.00 16.00           O  
ATOM   1129  CB  TYR A 143      24.889  67.362  -1.965  1.00 14.80           C  
ATOM   1130  CG  TYR A 143      24.183  66.415  -2.875  1.00 15.70           C  
ATOM   1131  CD1 TYR A 143      23.883  66.783  -4.205  1.00 16.10           C  
ATOM   1132  CD2 TYR A 143      23.845  65.134  -2.450  1.00 15.10           C  
ATOM   1133  CE1 TYR A 143      23.270  65.889  -5.074  1.00 14.50           C  
ATOM   1134  CE2 TYR A 143      23.260  64.233  -3.318  1.00 15.50           C  
ATOM   1135  CZ  TYR A 143      22.973  64.620  -4.635  1.00 15.00           C  
ATOM   1136  OH  TYR A 143      22.369  63.709  -5.472  1.00 15.70           O  
ATOM   1137  N   ASN A 144      21.831  68.668  -0.386  1.00 15.80           N  
ATOM   1138  CA  ASN A 144      20.706  68.301   0.477  1.00 16.40           C  
ATOM   1139  C   ASN A 144      19.532  69.214   0.131  1.00 16.50           C  
ATOM   1140  O   ASN A 144      19.641  70.053  -0.791  1.00 17.00           O  
ATOM   1141  CB  ASN A 144      21.096  68.396   1.994  1.00 14.90           C  
ATOM   1142  CG  ASN A 144      21.708  69.751   2.396  1.00 17.80           C  
ATOM   1143  OD1 ASN A 144      21.288  70.817   1.933  1.00 18.40           O  
ATOM   1144  ND2 ASN A 144      22.569  69.714   3.407  1.00 20.20           N  
ATOM   1145  N   TYR A 145      18.400  69.013   0.803  1.00 16.00           N  
ATOM   1146  CA  TYR A 145      17.204  69.798   0.515  1.00 16.80           C  
ATOM   1147  C   TYR A 145      16.320  69.939   1.787  1.00 16.70           C  
ATOM   1148  O   TYR A 145      16.359  69.051   2.657  1.00 17.70           O  
ATOM   1149  CB  TYR A 145      16.402  69.139  -0.653  1.00 15.70           C  
ATOM   1150  CG  TYR A 145      15.841  70.146  -1.654  1.00 15.50           C  
ATOM   1151  CD1 TYR A 145      16.637  70.625  -2.695  1.00 15.10           C  
ATOM   1152  CD2 TYR A 145      14.551  70.657  -1.522  1.00 15.00           C  
ATOM   1153  CE1 TYR A 145      16.168  71.574  -3.577  1.00 15.70           C  
ATOM   1154  CE2 TYR A 145      14.063  71.606  -2.409  1.00 16.20           C  
ATOM   1155  CZ  TYR A 145      14.877  72.060  -3.446  1.00 16.80           C  
ATOM   1156  OH  TYR A 145      14.398  72.960  -4.375  1.00 17.20           O  
ATOM   1157  N   ASN A 146      15.530  71.020   1.852  1.00 15.90           N  
ATOM   1158  CA  ASN A 146      14.626  71.329   2.965  1.00 15.80           C  
ATOM   1159  C   ASN A 146      13.186  71.212   2.536  1.00 16.60           C  
ATOM   1160  O   ASN A 146      12.891  70.977   1.358  1.00 16.90           O  
ATOM   1161  CB  ASN A 146      14.873  72.743   3.445  1.00 13.30           C  
ATOM   1162  CG  ASN A 146      16.298  72.941   3.908  1.00 18.10           C  
ATOM   1163  OD1 ASN A 146      16.843  72.100   4.627  1.00 18.20           O  
ATOM   1164  ND2 ASN A 146      16.936  74.011   3.448  1.00 19.70           N  
ATOM   1165  N   SER A 147      12.283  71.360   3.498  1.00 16.60           N  
ATOM   1166  CA  SER A 147      10.839  71.326   3.256  1.00 16.10           C  
ATOM   1167  C   SER A 147      10.344  72.694   2.867  1.00 15.10           C  
ATOM   1168  O   SER A 147      10.750  73.677   3.473  1.00 15.30           O  
ATOM   1169  CB  SER A 147      10.093  70.909   4.527  1.00 16.20           C  
ATOM   1170  OG  SER A 147      10.202  69.521   4.721  1.00 19.10           O  
ATOM   1171  N   HIS A 148       9.403  72.755   1.930  1.00 14.20           N  
ATOM   1172  CA  HIS A 148       8.821  74.025   1.534  1.00 13.60           C  
ATOM   1173  C   HIS A 148       7.341  73.884   1.238  1.00 14.00           C  
ATOM   1174  O   HIS A 148       6.830  72.782   1.061  1.00 15.10           O  
ATOM   1175  CB  HIS A 148       9.544  74.617   0.320  1.00 12.70           C  
ATOM   1176  CG  HIS A 148      11.028  74.752   0.425  1.00 11.50           C  
ATOM   1177  ND1 HIS A 148      11.955  73.770   0.118  1.00 11.10           N  
ATOM   1178  CD2 HIS A 148      11.674  75.852   0.912  1.00 12.10           C  
ATOM   1179  CE1 HIS A 148      13.153  74.269   0.422  1.00 11.40           C  
ATOM   1180  NE2 HIS A 148      13.032  75.447   0.923  1.00 12.20           N  
ATOM   1181  N   ASN A 149       6.622  74.979   1.417  1.00 15.30           N  
ATOM   1182  CA  ASN A 149       5.247  75.054   1.019  1.00 16.60           C  
ATOM   1183  C   ASN A 149       5.202  75.821  -0.286  1.00 17.10           C  
ATOM   1184  O   ASN A 149       5.879  76.844  -0.444  1.00 17.90           O  
ATOM   1185  CB  ASN A 149       4.408  75.805   2.057  1.00 17.00           C  
ATOM   1186  CG  ASN A 149       4.319  75.075   3.378  1.00 18.80           C  
ATOM   1187  OD1 ASN A 149       4.328  75.715   4.420  1.00 28.80           O  
ATOM   1188  ND2 ASN A 149       4.122  73.761   3.344  1.00 14.90           N  
ATOM   1189  N   VAL A 150       4.301  75.384  -1.159  1.00 17.50           N  
ATOM   1190  CA  VAL A 150       4.182  75.904  -2.518  1.00 17.00           C  
ATOM   1191  C   VAL A 150       2.770  76.442  -2.640  1.00 17.80           C  
ATOM   1192  O   VAL A 150       1.817  75.700  -2.472  1.00 17.60           O  
ATOM   1193  CB  VAL A 150       4.389  74.757  -3.528  1.00 17.00           C  
ATOM   1194  CG1 VAL A 150       3.931  75.166  -4.902  1.00 15.80           C  
ATOM   1195  CG2 VAL A 150       5.850  74.351  -3.545  1.00 16.20           C  
ATOM   1196  N   TYR A 151       2.636  77.748  -2.793  1.00 19.20           N  
ATOM   1197  CA  TYR A 151       1.320  78.393  -2.780  1.00 21.20           C  
ATOM   1198  C   TYR A 151       0.703  78.554  -4.152  1.00 21.70           C  
ATOM   1199  O   TYR A 151       1.311  79.142  -5.044  1.00 21.20           O  
ATOM   1200  CB  TYR A 151       1.429  79.753  -2.136  1.00 22.90           C  
ATOM   1201  CG  TYR A 151       1.863  79.621  -0.713  1.00 26.10           C  
ATOM   1202  CD1 TYR A 151       0.957  79.202   0.264  1.00 27.00           C  
ATOM   1203  CD2 TYR A 151       3.204  79.779  -0.357  1.00 26.80           C  
ATOM   1204  CE1 TYR A 151       1.368  78.928   1.576  1.00 28.70           C  
ATOM   1205  CE2 TYR A 151       3.638  79.517   0.955  1.00 28.70           C  
ATOM   1206  CZ  TYR A 151       2.710  79.086   1.927  1.00 29.30           C  
ATOM   1207  OH  TYR A 151       3.105  78.811   3.236  1.00 29.70           O  
ATOM   1208  N   ILE A 152      -0.557  78.148  -4.257  1.00 22.40           N  
ATOM   1209  CA  ILE A 152      -1.273  78.033  -5.533  1.00 22.90           C  
ATOM   1210  C   ILE A 152      -2.528  78.888  -5.539  1.00 23.80           C  
ATOM   1211  O   ILE A 152      -3.310  78.867  -4.602  1.00 23.70           O  
ATOM   1212  CB  ILE A 152      -1.700  76.582  -5.780  1.00 22.60           C  
ATOM   1213  CG1 ILE A 152      -0.462  75.683  -5.841  1.00 22.10           C  
ATOM   1214  CG2 ILE A 152      -2.530  76.488  -7.039  1.00 22.70           C  
ATOM   1215  CD1 ILE A 152      -0.792  74.244  -5.838  1.00 22.00           C  
ATOM   1216  N   MET A 153      -2.694  79.679  -6.578  1.00 25.40           N  
ATOM   1217  CA  MET A 153      -3.952  80.373  -6.789  1.00 27.10           C  
ATOM   1218  C   MET A 153      -4.353  80.262  -8.243  1.00 28.30           C  
ATOM   1219  O   MET A 153      -3.502  80.063  -9.114  1.00 28.60           O  
ATOM   1220  CB  MET A 153      -3.822  81.834  -6.415  1.00 27.60           C  
ATOM   1221  CG  MET A 153      -2.500  82.443  -6.732  1.00 48.40           C  
ATOM   1222  SD  MET A 153      -2.092  83.577  -5.408  1.00 64.40           S  
ATOM   1223  CE  MET A 153      -3.421  84.787  -5.667  1.00 58.60           C  
ATOM   1224  N   ALA A 154      -5.651  80.307  -8.497  1.00 29.10           N  
ATOM   1225  CA  ALA A 154      -6.148  80.242  -9.851  1.00 30.30           C  
ATOM   1226  C   ALA A 154      -5.841  81.552 -10.569  1.00 31.60           C  
ATOM   1227  O   ALA A 154      -5.608  82.586  -9.943  1.00 31.40           O  
ATOM   1228  CB  ALA A 154      -7.638  79.961  -9.850  1.00 30.10           C  
ATOM   1229  N   ASP A 155      -5.648  81.454 -11.877  1.00 33.40           N  
ATOM   1230  CA  ASP A 155      -5.538  82.624 -12.743  1.00 35.10           C  
ATOM   1231  C   ASP A 155      -6.650  82.452 -13.761  1.00 36.00           C  
ATOM   1232  O   ASP A 155      -6.471  81.733 -14.745  1.00 35.80           O  
ATOM   1233  CB  ASP A 155      -4.192  82.620 -13.463  1.00 35.70           C  
ATOM   1234  CG  ASP A 155      -3.940  83.877 -14.280  1.00 43.10           C  
ATOM   1235  OD1 ASP A 155      -4.715  84.862 -14.208  1.00 42.30           O  
ATOM   1236  OD2 ASP A 155      -2.885  83.891 -14.938  1.00 42.70           O  
ATOM   1237  N   LYS A 156      -7.829  82.973 -13.415  1.00 36.90           N  
ATOM   1238  CA  LYS A 156      -9.069  82.807 -14.193  1.00 37.80           C  
ATOM   1239  C   LYS A 156      -8.877  83.256 -15.638  1.00 38.00           C  
ATOM   1240  O   LYS A 156      -9.205  82.520 -16.596  1.00 37.90           O  
ATOM   1241  CB  LYS A 156     -10.189  83.649 -13.562  1.00 39.10           C  
ATOM   1242  CG  LYS A 156     -11.549  82.956 -13.478  1.00 66.20           C  
ATOM   1243  CD  LYS A 156     -11.645  82.026 -12.249  1.00 83.50           C  
ATOM   1244  CE  LYS A 156     -11.607  82.794 -10.916  1.00 89.40           C  
ATOM   1245  NZ  LYS A 156     -12.024  81.943  -9.748  1.00 95.00           N  
ATOM   1246  N   GLN A 157      -8.180  84.386 -15.762  1.00 37.80           N  
ATOM   1247  CA  GLN A 157      -7.862  84.998 -17.053  1.00 38.00           C  
ATOM   1248  C   GLN A 157      -7.014  84.155 -18.021  1.00 37.50           C  
ATOM   1249  O   GLN A 157      -7.096  84.348 -19.237  1.00 38.30           O  
ATOM   1250  CB  GLN A 157      -7.167  86.342 -16.841  1.00 38.50           C  
ATOM   1251  CG  GLN A 157      -8.089  87.450 -16.376  1.00 55.20           C  
ATOM   1252  CD  GLN A 157      -7.312  88.707 -15.992  1.00 74.20           C  
ATOM   1253  OE1 GLN A 157      -7.863  89.817 -15.996  1.00 86.20           O  
ATOM   1254  NE2 GLN A 157      -6.031  88.537 -15.633  1.00 75.20           N  
ATOM   1255  N   LYS A 158      -6.134  83.305 -17.494  1.00 35.70           N  
ATOM   1256  CA  LYS A 158      -5.338  82.435 -18.346  1.00 33.70           C  
ATOM   1257  C   LYS A 158      -5.843  81.000 -18.370  1.00 33.00           C  
ATOM   1258  O   LYS A 158      -5.191  80.128 -18.962  1.00 32.90           O  
ATOM   1259  CB  LYS A 158      -3.890  82.435 -17.894  1.00 33.50           C  
ATOM   1260  CG  LYS A 158      -3.027  83.458 -18.575  1.00 41.50           C  
ATOM   1261  CD  LYS A 158      -3.244  84.845 -17.984  1.00 47.50           C  
ATOM   1262  CE  LYS A 158      -1.940  85.460 -17.476  1.00 39.00           C  
ATOM   1263  NZ  LYS A 158      -2.204  86.431 -16.351  1.00 51.40           N  
ATOM   1264  N   ASN A 159      -6.973  80.739 -17.708  1.00 31.70           N  
ATOM   1265  CA  ASN A 159      -7.450  79.367 -17.478  1.00 31.10           C  
ATOM   1266  C   ASN A 159      -6.348  78.447 -16.869  1.00 30.40           C  
ATOM   1267  O   ASN A 159      -6.218  77.254 -17.208  1.00 30.00           O  
ATOM   1268  CB  ASN A 159      -8.000  78.766 -18.784  1.00 32.20           C  
ATOM   1269  CG  ASN A 159      -8.806  77.475 -18.561  1.00 44.70           C  
ATOM   1270  OD1 ASN A 159      -9.821  77.476 -17.872  1.00 45.80           O  
ATOM   1271  ND2 ASN A 159      -8.406  76.403 -19.233  1.00 52.20           N  
ATOM   1272  N   GLY A 160      -5.603  78.997 -15.916  1.00 29.10           N  
ATOM   1273  CA  GLY A 160      -4.503  78.254 -15.336  1.00 27.90           C  
ATOM   1274  C   GLY A 160      -4.326  78.602 -13.873  1.00 26.80           C  
ATOM   1275  O   GLY A 160      -5.289  78.991 -13.200  1.00 27.30           O  
ATOM   1276  N   ILE A 161      -3.097  78.474 -13.386  1.00 24.80           N  
ATOM   1277  CA  ILE A 161      -2.777  78.786 -12.000  1.00 23.50           C  
ATOM   1278  C   ILE A 161      -1.457  79.552 -11.964  1.00 23.80           C  
ATOM   1279  O   ILE A 161      -0.660  79.482 -12.909  1.00 23.70           O  
ATOM   1280  CB  ILE A 161      -2.623  77.506 -11.143  1.00 22.40           C  
ATOM   1281  CG1 ILE A 161      -1.491  76.644 -11.739  1.00 20.80           C  
ATOM   1282  CG2 ILE A 161      -3.982  76.800 -10.996  1.00 21.60           C  
ATOM   1283  CD1 ILE A 161      -1.068  75.433 -10.972  1.00 19.80           C  
ATOM   1284  N   LYS A 162      -1.288  80.359 -10.920  1.00 23.80           N  
ATOM   1285  CA  LYS A 162      -0.025  81.009 -10.589  1.00 23.60           C  
ATOM   1286  C   LYS A 162       0.459  80.428  -9.271  1.00 23.50           C  
ATOM   1287  O   LYS A 162      -0.329  80.289  -8.311  1.00 23.10           O  
ATOM   1288  CB  LYS A 162      -0.222  82.501 -10.380  1.00 24.50           C  
ATOM   1289  CG  LYS A 162      -0.223  83.319 -11.646  1.00 38.00           C  
ATOM   1290  CD  LYS A 162      -1.325  84.388 -11.579  1.00 71.20           C  
ATOM   1291  CE  LYS A 162      -1.278  85.180 -10.273  1.00 91.80           C  
ATOM   1292  NZ  LYS A 162      -0.010  85.970 -10.150  1.00113.40           N  
ATOM   1293  N   VAL A 163       1.769  80.239  -9.187  1.00 22.80           N  
ATOM   1294  CA  VAL A 163       2.387  79.502  -8.093  1.00 21.70           C  
ATOM   1295  C   VAL A 163       3.595  80.318  -7.638  1.00 22.00           C  
ATOM   1296  O   VAL A 163       4.319  80.868  -8.474  1.00 21.90           O  
ATOM   1297  CB  VAL A 163       2.818  78.080  -8.564  1.00 21.20           C  
ATOM   1298  CG1 VAL A 163       3.477  77.314  -7.433  1.00 20.30           C  
ATOM   1299  CG2 VAL A 163       1.608  77.289  -9.071  1.00 20.20           C  
ATOM   1300  N   ASN A 164       3.709  80.546  -6.322  1.00 21.80           N  
ATOM   1301  CA  ASN A 164       4.875  81.236  -5.759  1.00 21.70           C  
ATOM   1302  C   ASN A 164       5.413  80.492  -4.556  1.00 20.70           C  
ATOM   1303  O   ASN A 164       4.647  79.955  -3.772  1.00 20.70           O  
ATOM   1304  CB  ASN A 164       4.550  82.671  -5.306  1.00 23.20           C  
ATOM   1305  CG  ASN A 164       3.419  83.298  -6.092  1.00 48.80           C  
ATOM   1306  OD1 ASN A 164       3.521  83.520  -7.308  1.00 59.50           O  
ATOM   1307  ND2 ASN A 164       2.311  83.560  -5.404  1.00 69.70           N  
ATOM   1308  N   PHE A 165       6.728  80.538  -4.386  1.00 20.30           N  
ATOM   1309  CA  PHE A 165       7.399  79.985  -3.217  1.00 19.90           C  
ATOM   1310  C   PHE A 165       8.875  80.353  -3.231  1.00 20.20           C  
ATOM   1311  O   PHE A 165       9.391  80.792  -4.259  1.00 21.10           O  
ATOM   1312  CB  PHE A 165       7.227  78.465  -3.161  1.00 18.60           C  
ATOM   1313  CG  PHE A 165       7.685  77.746  -4.407  1.00 18.60           C  
ATOM   1314  CD1 PHE A 165       6.843  77.623  -5.508  1.00 18.00           C  
ATOM   1315  CD2 PHE A 165       8.916  77.099  -4.427  1.00 17.90           C  
ATOM   1316  CE1 PHE A 165       7.216  76.872  -6.586  1.00 18.60           C  
ATOM   1317  CE2 PHE A 165       9.293  76.348  -5.489  1.00 17.90           C  
ATOM   1318  CZ  PHE A 165       8.436  76.223  -6.584  1.00 18.70           C  
ATOM   1319  N   LYS A 166       9.513  80.318  -2.065  1.00 20.60           N  
ATOM   1320  CA  LYS A 166      10.951  80.581  -1.951  1.00 21.50           C  
ATOM   1321  C   LYS A 166      11.635  79.311  -1.434  1.00 21.40           C  
ATOM   1322  O   LYS A 166      11.151  78.670  -0.499  1.00 22.60           O  
ATOM   1323  CB  LYS A 166      11.233  81.743  -0.986  1.00 21.80           C  
ATOM   1324  CG  LYS A 166      10.398  82.981  -1.249  1.00 39.30           C  
ATOM   1325  CD  LYS A 166      10.880  84.177  -0.415  1.00 45.30           C  
ATOM   1326  CE  LYS A 166      12.203  84.737  -0.943  1.00 68.70           C  
ATOM   1327  NZ  LYS A 166      12.423  86.168  -0.537  1.00 73.40           N  
ATOM   1328  N   ILE A 167      12.675  78.883  -2.134  1.00 21.00           N  
ATOM   1329  CA  ILE A 167      13.466  77.738  -1.743  1.00 19.60           C  
ATOM   1330  C   ILE A 167      14.633  78.248  -0.897  1.00 20.90           C  
ATOM   1331  O   ILE A 167      15.181  79.310  -1.168  1.00 21.00           O  
ATOM   1332  CB  ILE A 167      14.019  77.010  -2.986  1.00 18.40           C  
ATOM   1333  CG1 ILE A 167      12.870  76.331  -3.756  1.00 17.30           C  
ATOM   1334  CG2 ILE A 167      15.091  76.010  -2.574  1.00 17.30           C  
ATOM   1335  CD1 ILE A 167      12.019  75.304  -2.936  1.00 15.40           C  
ATOM   1336  N   ARG A 168      14.962  77.525   0.166  1.00 22.00           N  
ATOM   1337  CA  ARG A 168      16.113  77.855   0.993  1.00 22.90           C  
ATOM   1338  C   ARG A 168      17.140  76.758   0.797  1.00 21.80           C  
ATOM   1339  O   ARG A 168      16.880  75.595   1.136  1.00 21.60           O  
ATOM   1340  CB  ARG A 168      15.712  77.916   2.472  1.00 25.50           C  
ATOM   1341  CG  ARG A 168      14.612  78.911   2.772  1.00 29.50           C  
ATOM   1342  CD  ARG A 168      14.096  78.727   4.198  1.00 34.40           C  
ATOM   1343  NE  ARG A 168      14.060  77.313   4.619  1.00 37.70           N  
ATOM   1344  CZ  ARG A 168      12.960  76.553   4.655  1.00 38.70           C  
ATOM   1345  NH1 ARG A 168      11.766  77.069   4.342  1.00 39.00           N  
ATOM   1346  NH2 ARG A 168      13.047  75.278   5.047  1.00 39.10           N  
ATOM   1347  N   HIS A 169      18.242  77.111   0.141  1.00 20.80           N  
ATOM   1348  CA  HIS A 169      19.329  76.182  -0.135  1.00 20.60           C  
ATOM   1349  C   HIS A 169      20.434  76.397   0.878  1.00 21.30           C  
ATOM   1350  O   HIS A 169      20.936  77.503   1.015  1.00 21.80           O  
ATOM   1351  CB  HIS A 169      19.903  76.439  -1.534  1.00 20.10           C  
ATOM   1352  CG  HIS A 169      19.036  76.140  -2.804  1.00 18.30           C  
ATOM   1353  ND1 HIS A 169      18.556  77.083  -3.716  1.00 18.30           N  
ATOM   1354  CD2 HIS A 169      18.693  74.889  -3.271  1.00 16.60           C  
ATOM   1355  CE1 HIS A 169      17.939  76.420  -4.689  1.00 16.20           C  
ATOM   1356  NE2 HIS A 169      17.993  75.161  -4.475  1.00 16.60           N  
ATOM   1357  N   ASN A 170      20.895  75.339   1.519  1.00 22.30           N  
ATOM   1358  CA  ASN A 170      21.909  75.519   2.558  1.00 23.20           C  
ATOM   1359  C   ASN A 170      23.246  75.682   1.884  1.00 24.40           C  
ATOM   1360  O   ASN A 170      23.595  74.915   0.985  1.00 24.40           O  
ATOM   1361  CB  ASN A 170      21.966  74.322   3.513  1.00 22.00           C  
ATOM   1362  CG  ASN A 170      20.685  74.141   4.310  1.00 19.90           C  
ATOM   1363  OD1 ASN A 170      19.874  75.058   4.463  1.00 22.90           O  
ATOM   1364  ND2 ASN A 170      20.458  72.924   4.749  1.00 30.60           N  
ATOM   1365  N   ILE A 171      24.006  76.670   2.331  1.00 25.80           N  
ATOM   1366  CA  ILE A 171      25.343  76.873   1.829  1.00 26.80           C  
ATOM   1367  C   ILE A 171      26.296  76.205   2.785  1.00 28.40           C  
ATOM   1368  O   ILE A 171      26.090  76.199   3.996  1.00 28.60           O  
ATOM   1369  CB  ILE A 171      25.711  78.367   1.732  1.00 26.90           C  
ATOM   1370  CG1 ILE A 171      24.610  79.123   1.006  1.00 27.00           C  
ATOM   1371  CG2 ILE A 171      27.014  78.534   0.943  1.00 26.70           C  
ATOM   1372  CD1 ILE A 171      24.251  78.488  -0.317  1.00 27.80           C  
ATOM   1373  N   GLU A 172      27.425  75.784   2.253  1.00 29.90           N  
ATOM   1374  CA  GLU A 172      28.315  74.950   3.002  1.00 31.80           C  
ATOM   1375  C   GLU A 172      28.998  75.715   4.152  1.00 33.10           C  
ATOM   1376  O   GLU A 172      29.461  75.095   5.109  1.00 34.40           O  
ATOM   1377  CB  GLU A 172      29.308  74.302   2.039  1.00 31.70           C  
ATOM   1378  CG  GLU A 172      30.750  74.662   2.276  1.00 54.90           C  
ATOM   1379  CD  GLU A 172      31.449  75.179   1.032  1.00 63.40           C  
ATOM   1380  OE1 GLU A 172      31.400  76.414   0.795  1.00 86.40           O  
ATOM   1381  OE2 GLU A 172      32.075  74.355   0.322  1.00 58.30           O  
ATOM   1382  N   ASP A 173      28.826  77.030   4.201  1.00 33.60           N  
ATOM   1383  CA  ASP A 173      29.370  77.788   5.317  1.00 34.20           C  
ATOM   1384  C   ASP A 173      28.347  78.092   6.427  1.00 34.80           C  
ATOM   1385  O   ASP A 173      28.637  78.825   7.375  1.00 36.00           O  
ATOM   1386  CB  ASP A 173      30.008  79.079   4.811  1.00 34.40           C  
ATOM   1387  CG  ASP A 173      28.987  80.090   4.313  1.00 40.20           C  
ATOM   1388  OD1 ASP A 173      27.779  79.765   4.225  1.00 45.40           O  
ATOM   1389  OD2 ASP A 173      29.413  81.223   3.993  1.00 52.20           O  
ATOM   1390  N   GLY A 174      27.119  77.615   6.265  1.00 34.80           N  
ATOM   1391  CA  GLY A 174      26.116  77.826   7.290  1.00 33.40           C  
ATOM   1392  C   GLY A 174      25.061  78.860   6.949  1.00 33.00           C  
ATOM   1393  O   GLY A 174      24.029  78.933   7.612  1.00 33.70           O  
ATOM   1394  N   SER A 175      25.279  79.648   5.902  1.00 32.80           N  
ATOM   1395  CA  SER A 175      24.242  80.575   5.432  1.00 31.80           C  
ATOM   1396  C   SER A 175      23.225  79.899   4.520  1.00 31.00           C  
ATOM   1397  O   SER A 175      23.280  78.682   4.282  1.00 31.10           O  
ATOM   1398  CB  SER A 175      24.874  81.748   4.707  1.00 31.60           C  
ATOM   1399  OG  SER A 175      25.705  81.278   3.672  1.00 32.40           O  
ATOM   1400  N   VAL A 176      22.268  80.686   4.058  1.00 30.40           N  
ATOM   1401  CA  VAL A 176      21.244  80.187   3.156  1.00 30.40           C  
ATOM   1402  C   VAL A 176      21.193  81.053   1.891  1.00 29.90           C  
ATOM   1403  O   VAL A 176      21.386  82.269   1.968  1.00 30.70           O  
ATOM   1404  CB  VAL A 176      19.862  80.164   3.864  1.00 30.50           C  
ATOM   1405  CG1 VAL A 176      18.743  79.986   2.863  1.00 31.00           C  
ATOM   1406  CG2 VAL A 176      19.818  79.008   4.846  1.00 31.00           C  
ATOM   1407  N   GLN A 177      21.146  80.397   0.726  1.00 29.30           N  
ATOM   1408  CA  GLN A 177      20.895  81.053  -0.571  1.00 27.30           C  
ATOM   1409  C   GLN A 177      19.399  80.945  -0.918  1.00 26.10           C  
ATOM   1410  O   GLN A 177      18.835  79.849  -0.953  1.00 25.90           O  
ATOM   1411  CB  GLN A 177      21.767  80.394  -1.653  1.00 26.60           C  
ATOM   1412  CG  GLN A 177      21.400  80.764  -3.085  1.00 28.00           C  
ATOM   1413  CD  GLN A 177      21.763  82.189  -3.434  1.00 24.40           C  
ATOM   1414  OE1 GLN A 177      20.893  83.028  -3.672  1.00 28.90           O  
ATOM   1415  NE2 GLN A 177      23.054  82.465  -3.488  1.00 25.00           N  
ATOM   1416  N   LEU A 178      18.727  82.082  -1.003  1.00 25.40           N  
ATOM   1417  CA  LEU A 178      17.307  82.101  -1.341  1.00 24.60           C  
ATOM   1418  C   LEU A 178      17.086  81.997  -2.846  1.00 23.20           C  
ATOM   1419  O   LEU A 178      17.789  82.615  -3.627  1.00 23.10           O  
ATOM   1420  CB  LEU A 178      16.673  83.391  -0.839  1.00 25.70           C  
ATOM   1421  CG  LEU A 178      15.653  83.272   0.277  1.00 27.20           C  
ATOM   1422  CD1 LEU A 178      16.089  82.211   1.280  1.00 27.00           C  
ATOM   1423  CD2 LEU A 178      15.527  84.651   0.922  1.00 27.10           C  
ATOM   1424  N   ALA A 179      16.073  81.238  -3.234  1.00 22.30           N  
ATOM   1425  CA  ALA A 179      15.694  81.106  -4.624  1.00 21.10           C  
ATOM   1426  C   ALA A 179      14.196  81.348  -4.715  1.00 20.90           C  
ATOM   1427  O   ALA A 179      13.382  80.512  -4.317  1.00 20.20           O  
ATOM   1428  CB  ALA A 179      16.061  79.720  -5.140  1.00 21.10           C  
ATOM   1429  N   ASP A 180      13.855  82.544  -5.189  1.00 20.70           N  
ATOM   1430  CA  ASP A 180      12.491  83.016  -5.339  1.00 19.90           C  
ATOM   1431  C   ASP A 180      11.882  82.508  -6.663  1.00 19.10           C  
ATOM   1432  O   ASP A 180      12.359  82.845  -7.744  1.00 19.10           O  
ATOM   1433  CB  ASP A 180      12.544  84.548  -5.314  1.00 21.00           C  
ATOM   1434  CG  ASP A 180      11.290  85.185  -4.745  1.00 40.40           C  
ATOM   1435  OD1 ASP A 180      10.259  84.476  -4.557  1.00 42.10           O  
ATOM   1436  OD2 ASP A 180      11.315  86.433  -4.568  1.00 48.70           O  
ATOM   1437  N   HIS A 181      10.799  81.751  -6.560  1.00 17.90           N  
ATOM   1438  CA  HIS A 181      10.199  81.086  -7.712  1.00 17.90           C  
ATOM   1439  C   HIS A 181       8.891  81.719  -8.080  1.00 18.90           C  
ATOM   1440  O   HIS A 181       7.991  81.835  -7.246  1.00 19.60           O  
ATOM   1441  CB  HIS A 181       9.944  79.596  -7.430  1.00 16.20           C  
ATOM   1442  CG  HIS A 181      11.144  78.656  -7.343  1.00 14.80           C  
ATOM   1443  ND1 HIS A 181      12.225  78.848  -6.508  1.00 14.20           N  
ATOM   1444  CD2 HIS A 181      11.292  77.495  -8.121  1.00 14.70           C  
ATOM   1445  CE1 HIS A 181      13.069  77.857  -6.802  1.00 13.60           C  
ATOM   1446  NE2 HIS A 181      12.533  77.058  -7.687  1.00 14.90           N  
ATOM   1447  N   TYR A 182       8.763  82.083  -9.351  1.00 19.40           N  
ATOM   1448  CA  TYR A 182       7.502  82.557  -9.882  1.00 19.60           C  
ATOM   1449  C   TYR A 182       7.111  81.638 -10.992  1.00 19.80           C  
ATOM   1450  O   TYR A 182       7.936  81.309 -11.851  1.00 19.90           O  
ATOM   1451  CB  TYR A 182       7.662  83.953 -10.423  1.00 21.30           C  
ATOM   1452  CG  TYR A 182       7.966  84.933  -9.345  1.00 23.00           C  
ATOM   1453  CD1 TYR A 182       9.272  85.141  -8.923  1.00 24.10           C  
ATOM   1454  CD2 TYR A 182       6.936  85.610  -8.689  1.00 24.60           C  
ATOM   1455  CE1 TYR A 182       9.550  86.005  -7.880  1.00 24.60           C  
ATOM   1456  CE2 TYR A 182       7.198  86.474  -7.644  1.00 25.40           C  
ATOM   1457  CZ  TYR A 182       8.500  86.657  -7.246  1.00 25.90           C  
ATOM   1458  OH  TYR A 182       8.730  87.442  -6.142  1.00 29.50           O  
ATOM   1459  N   GLN A 183       5.881  81.157 -10.931  1.00 19.20           N  
ATOM   1460  CA  GLN A 183       5.483  80.059 -11.774  1.00 19.40           C  
ATOM   1461  C   GLN A 183       4.077  80.260 -12.348  1.00 20.10           C  
ATOM   1462  O   GLN A 183       3.191  80.871 -11.713  1.00 20.60           O  
ATOM   1463  CB  GLN A 183       5.592  78.744 -10.994  1.00 18.20           C  
ATOM   1464  CG  GLN A 183       4.817  77.552 -11.563  1.00 16.80           C  
ATOM   1465  CD  GLN A 183       4.969  76.332 -10.693  1.00 15.50           C  
ATOM   1466  OE1 GLN A 183       5.843  76.305  -9.840  1.00 25.50           O  
ATOM   1467  NE2 GLN A 183       4.177  75.296 -10.939  1.00 16.10           N  
ATOM   1468  N   GLN A 184       3.868  79.672 -13.529  1.00 20.20           N  
ATOM   1469  CA  GLN A 184       2.646  79.821 -14.296  1.00 19.80           C  
ATOM   1470  C   GLN A 184       2.420  78.493 -14.998  1.00 19.70           C  
ATOM   1471  O   GLN A 184       3.358  77.880 -15.494  1.00 19.00           O  
ATOM   1472  CB  GLN A 184       2.869  80.926 -15.318  1.00 21.20           C  
ATOM   1473  CG  GLN A 184       1.660  81.451 -16.034  1.00 37.50           C  
ATOM   1474  CD  GLN A 184       2.016  82.703 -16.828  1.00 45.20           C  
ATOM   1475  OE1 GLN A 184       2.885  82.680 -17.714  1.00 50.50           O  
ATOM   1476  NE2 GLN A 184       1.466  83.825 -16.411  1.00 50.70           N  
ATOM   1477  N   ASN A 185       1.199  77.989 -14.912  1.00 19.70           N  
ATOM   1478  CA  ASN A 185       0.840  76.732 -15.526  1.00 20.20           C  
ATOM   1479  C   ASN A 185      -0.421  77.001 -16.334  1.00 21.40           C  
ATOM   1480  O   ASN A 185      -1.340  77.648 -15.838  1.00 21.90           O  
ATOM   1481  CB  ASN A 185       0.523  75.681 -14.465  1.00 20.20           C  
ATOM   1482  CG  ASN A 185       1.756  75.209 -13.720  1.00 12.70           C  
ATOM   1483  OD1 ASN A 185       2.249  74.116 -13.962  1.00 20.10           O  
ATOM   1484  ND2 ASN A 185       2.232  76.023 -12.783  1.00 17.40           N  
ATOM   1485  N   THR A 186      -0.438  76.554 -17.587  1.00 22.30           N  
ATOM   1486  CA  THR A 186      -1.606  76.717 -18.471  1.00 23.30           C  
ATOM   1487  C   THR A 186      -1.846  75.424 -19.291  1.00 23.50           C  
ATOM   1488  O   THR A 186      -0.899  74.690 -19.583  1.00 23.00           O  
ATOM   1489  CB  THR A 186      -1.403  77.926 -19.422  1.00 22.90           C  
ATOM   1490  OG1 THR A 186      -0.141  77.794 -20.061  1.00 24.10           O  
ATOM   1491  CG2 THR A 186      -1.409  79.259 -18.658  1.00 23.50           C  
ATOM   1492  N   PRO A 187      -3.120  75.013 -19.466  1.00 24.80           N  
ATOM   1493  CA  PRO A 187      -3.337  73.690 -20.076  1.00 25.70           C  
ATOM   1494  C   PRO A 187      -2.929  73.701 -21.549  1.00 26.70           C  
ATOM   1495  O   PRO A 187      -2.926  74.753 -22.187  1.00 26.70           O  
ATOM   1496  CB  PRO A 187      -4.841  73.437 -19.907  1.00 25.20           C  
ATOM   1497  CG  PRO A 187      -5.449  74.791 -19.721  1.00 24.50           C  
ATOM   1498  CD  PRO A 187      -4.389  75.720 -19.177  1.00 25.00           C  
ATOM   1499  N   ILE A 188      -2.436  72.567 -22.022  1.00 28.30           N  
ATOM   1500  CA  ILE A 188      -2.081  72.408 -23.423  1.00 30.50           C  
ATOM   1501  C   ILE A 188      -3.374  72.105 -24.214  1.00 32.80           C  
ATOM   1502  O   ILE A 188      -3.556  72.551 -25.350  1.00 33.50           O  
ATOM   1503  CB  ILE A 188      -1.056  71.256 -23.604  1.00 29.70           C  
ATOM   1504  CG1 ILE A 188       0.325  71.668 -23.068  1.00 29.00           C  
ATOM   1505  CG2 ILE A 188      -0.941  70.887 -25.072  1.00 30.30           C  
ATOM   1506  CD1 ILE A 188       1.323  70.527 -23.054  1.00 28.60           C  
ATOM   1507  N   GLY A 189      -4.310  71.449 -23.549  1.00 34.40           N  
ATOM   1508  CA  GLY A 189      -5.496  70.995 -24.223  1.00 36.60           C  
ATOM   1509  C   GLY A 189      -6.627  72.004 -24.312  1.00 37.80           C  
ATOM   1510  O   GLY A 189      -6.562  73.129 -23.795  1.00 37.90           O  
ATOM   1511  N   ASP A 190      -7.730  71.494 -24.857  1.00 39.40           N  
ATOM   1512  CA  ASP A 190      -8.893  72.274 -25.265  1.00 40.50           C  
ATOM   1513  C   ASP A 190      -9.933  72.208 -24.141  1.00 40.40           C  
ATOM   1514  O   ASP A 190     -10.711  73.153 -23.953  1.00 41.20           O  
ATOM   1515  CB  ASP A 190      -9.508  71.695 -26.568  1.00 42.00           C  
ATOM   1516  CG  ASP A 190      -8.543  70.761 -27.343  1.00 75.60           C  
ATOM   1517  OD1 ASP A 190      -8.130  69.694 -26.804  1.00 72.60           O  
ATOM   1518  OD2 ASP A 190      -8.241  71.074 -28.523  1.00 87.40           O  
ATOM   1519  N   GLY A 191      -9.942  71.075 -23.429  1.00 39.50           N  
ATOM   1520  CA  GLY A 191     -10.962  70.776 -22.434  1.00 38.10           C  
ATOM   1521  C   GLY A 191     -10.981  71.666 -21.198  1.00 37.10           C  
ATOM   1522  O   GLY A 191     -10.047  72.460 -20.957  1.00 37.10           O  
ATOM   1523  N   PRO A 192     -12.036  71.532 -20.375  1.00 35.80           N  
ATOM   1524  CA  PRO A 192     -12.075  72.283 -19.127  1.00 34.70           C  
ATOM   1525  C   PRO A 192     -10.999  71.761 -18.181  1.00 33.70           C  
ATOM   1526  O   PRO A 192     -10.556  70.607 -18.278  1.00 33.00           O  
ATOM   1527  CB  PRO A 192     -13.482  72.012 -18.609  1.00 35.10           C  
ATOM   1528  CG  PRO A 192     -13.753  70.608 -19.072  1.00 35.20           C  
ATOM   1529  CD  PRO A 192     -13.080  70.483 -20.414  1.00 35.70           C  
ATOM   1530  N   VAL A 193     -10.473  72.669 -17.376  1.00 32.80           N  
ATOM   1531  CA  VAL A 193      -9.570  72.313 -16.290  1.00 31.80           C  
ATOM   1532  C   VAL A 193     -10.156  72.804 -14.951  1.00 30.80           C  
ATOM   1533  O   VAL A 193     -11.111  73.581 -14.941  1.00 30.10           O  
ATOM   1534  CB  VAL A 193      -8.166  72.930 -16.534  1.00 31.70           C  
ATOM   1535  CG1 VAL A 193      -7.494  72.256 -17.725  1.00 30.30           C  
ATOM   1536  CG2 VAL A 193      -8.284  74.419 -16.763  1.00 31.40           C  
ATOM   1537  N   LEU A 194      -9.689  72.228 -13.843  1.00 30.20           N  
ATOM   1538  CA  LEU A 194     -10.142  72.641 -12.501  1.00 29.10           C  
ATOM   1539  C   LEU A 194      -9.324  73.841 -12.035  1.00 28.40           C  
ATOM   1540  O   LEU A 194      -8.111  73.763 -11.932  1.00 28.60           O  
ATOM   1541  CB  LEU A 194      -9.979  71.492 -11.504  1.00 28.20           C  
ATOM   1542  CG  LEU A 194     -10.831  70.242 -11.742  1.00 28.40           C  
ATOM   1543  CD1 LEU A 194     -10.511  69.149 -10.732  1.00 28.30           C  
ATOM   1544  CD2 LEU A 194     -12.288  70.627 -11.668  1.00 28.10           C  
ATOM   1545  N   LEU A 195      -9.973  74.976 -11.844  1.00 28.00           N  
ATOM   1546  CA  LEU A 195      -9.279  76.162 -11.368  1.00 28.60           C  
ATOM   1547  C   LEU A 195      -9.496  76.257  -9.862  1.00 28.30           C  
ATOM   1548  O   LEU A 195     -10.635  76.195  -9.418  1.00 29.40           O  
ATOM   1549  CB  LEU A 195      -9.848  77.409 -12.056  1.00 29.70           C  
ATOM   1550  CG  LEU A 195      -9.429  77.735 -13.498  1.00 30.20           C  
ATOM   1551  CD1 LEU A 195      -8.814  79.115 -13.527  1.00 31.70           C  
ATOM   1552  CD2 LEU A 195      -8.394  76.766 -13.983  1.00 30.50           C  
ATOM   1553  N   PRO A 196      -8.417  76.239  -9.060  1.00 27.30           N  
ATOM   1554  CA  PRO A 196      -8.544  75.952  -7.622  1.00 26.70           C  
ATOM   1555  C   PRO A 196      -8.886  77.187  -6.787  1.00 25.90           C  
ATOM   1556  O   PRO A 196      -8.537  78.314  -7.170  1.00 25.90           O  
ATOM   1557  CB  PRO A 196      -7.158  75.419  -7.255  1.00 26.20           C  
ATOM   1558  CG  PRO A 196      -6.260  76.180  -8.134  1.00 26.80           C  
ATOM   1559  CD  PRO A 196      -7.002  76.227  -9.471  1.00 27.20           C  
ATOM   1560  N   ASP A 197      -9.456  76.975  -5.598  1.00 25.30           N  
ATOM   1561  CA  ASP A 197      -9.288  77.955  -4.507  1.00 25.30           C  
ATOM   1562  C   ASP A 197      -7.857  77.910  -3.974  1.00 25.00           C  
ATOM   1563  O   ASP A 197      -7.152  76.903  -4.141  1.00 24.90           O  
ATOM   1564  CB  ASP A 197     -10.255  77.695  -3.341  1.00 25.70           C  
ATOM   1565  CG  ASP A 197     -11.705  77.952  -3.711  1.00 32.50           C  
ATOM   1566  OD1 ASP A 197     -11.985  78.944  -4.400  1.00 39.70           O  
ATOM   1567  OD2 ASP A 197     -12.564  77.134  -3.337  1.00 35.20           O  
ATOM   1568  N   ASN A 198      -7.416  79.006  -3.362  1.00 25.10           N  
ATOM   1569  CA  ASN A 198      -6.057  79.092  -2.810  1.00 25.20           C  
ATOM   1570  C   ASN A 198      -5.778  77.934  -1.837  1.00 24.70           C  
ATOM   1571  O   ASN A 198      -6.611  77.585  -0.976  1.00 24.50           O  
ATOM   1572  CB  ASN A 198      -5.843  80.425  -2.065  1.00 26.00           C  
ATOM   1573  CG  ASN A 198      -6.128  81.672  -2.932  1.00 39.80           C  
ATOM   1574  OD1 ASN A 198      -6.210  81.617  -4.156  1.00 36.20           O  
ATOM   1575  ND2 ASN A 198      -6.289  82.805  -2.266  1.00 51.00           N  
ATOM   1576  N   HIS A 199      -4.621  77.314  -2.013  1.00 23.70           N  
ATOM   1577  CA  HIS A 199      -4.190  76.226  -1.153  1.00 22.10           C  
ATOM   1578  C   HIS A 199      -2.679  76.135  -1.298  1.00 21.50           C  
ATOM   1579  O   HIS A 199      -2.065  77.067  -1.809  1.00 21.50           O  
ATOM   1580  CB  HIS A 199      -4.876  74.915  -1.554  1.00 21.80           C  
ATOM   1581  CG  HIS A 199      -4.535  74.324  -2.901  1.00 21.60           C  
ATOM   1582  ND1 HIS A 199      -5.169  74.784  -4.005  1.00 21.50           N  
ATOM   1583  CD2 HIS A 199      -3.747  73.228  -3.164  1.00 21.30           C  
ATOM   1584  CE1 HIS A 199      -4.798  73.886  -4.930  1.00 21.50           C  
ATOM   1585  NE2 HIS A 199      -4.025  72.982  -4.488  1.00 21.50           N  
ATOM   1586  N   TYR A 200      -2.057  75.094  -0.761  1.00 20.50           N  
ATOM   1587  CA  TYR A 200      -0.630  74.973  -0.953  1.00 20.20           C  
ATOM   1588  C   TYR A 200      -0.263  73.537  -0.913  1.00 18.90           C  
ATOM   1589  O   TYR A 200      -1.125  72.703  -0.637  1.00 19.00           O  
ATOM   1590  CB  TYR A 200       0.154  75.772   0.089  1.00 21.90           C  
ATOM   1591  CG  TYR A 200       0.018  75.254   1.494  1.00 24.30           C  
ATOM   1592  CD1 TYR A 200       0.842  74.225   1.957  1.00 25.60           C  
ATOM   1593  CD2 TYR A 200      -0.957  75.763   2.347  1.00 25.70           C  
ATOM   1594  CE1 TYR A 200       0.700  73.712   3.218  1.00 27.20           C  
ATOM   1595  CE2 TYR A 200      -1.120  75.261   3.621  1.00 27.00           C  
ATOM   1596  CZ  TYR A 200      -0.287  74.228   4.054  1.00 28.40           C  
ATOM   1597  OH  TYR A 200      -0.459  73.674   5.312  1.00 29.60           O  
ATOM   1598  N   LEU A 201       0.942  73.240  -1.397  1.00 17.90           N  
ATOM   1599  CA  LEU A 201       1.445  71.870  -1.443  1.00 17.10           C  
ATOM   1600  C   LEU A 201       2.589  71.827  -0.481  1.00 16.40           C  
ATOM   1601  O   LEU A 201       3.446  72.695  -0.517  1.00 16.70           O  
ATOM   1602  CB  LEU A 201       1.970  71.513  -2.836  1.00 17.50           C  
ATOM   1603  CG  LEU A 201       1.033  71.580  -4.041  1.00 17.20           C  
ATOM   1604  CD1 LEU A 201       1.833  71.152  -5.276  1.00 16.70           C  
ATOM   1605  CD2 LEU A 201      -0.185  70.646  -3.837  1.00 16.20           C  
ATOM   1606  N   SER A 202       2.483  70.945   0.499  1.00 16.20           N  
ATOM   1607  CA  SER A 202       3.573  70.692   1.425  1.00 16.00           C  
ATOM   1608  C   SER A 202       4.577  69.719   0.781  1.00 14.70           C  
ATOM   1609  O   SER A 202       4.252  68.551   0.527  1.00 14.70           O  
ATOM   1610  CB  SER A 202       3.002  70.099   2.733  1.00 16.80           C  
ATOM   1611  OG  SER A 202       4.029  69.677   3.611  1.00 18.60           O  
ATOM   1612  N   THR A 203       5.805  70.175   0.558  1.00 14.40           N  
ATOM   1613  CA  THR A 203       6.762  69.364  -0.191  1.00 14.70           C  
ATOM   1614  C   THR A 203       8.003  69.057   0.628  1.00 14.00           C  
ATOM   1615  O   THR A 203       8.537  69.935   1.316  1.00 14.50           O  
ATOM   1616  CB  THR A 203       7.155  70.071  -1.532  1.00 14.60           C  
ATOM   1617  OG1 THR A 203       5.962  70.448  -2.222  1.00 17.30           O  
ATOM   1618  CG2 THR A 203       7.937  69.138  -2.442  1.00 15.50           C  
ATOM   1619  N   GLN A 204       8.416  67.791   0.596  1.00 13.90           N  
ATOM   1620  CA  GLN A 204       9.681  67.367   1.198  1.00 14.20           C  
ATOM   1621  C   GLN A 204      10.508  66.594   0.183  1.00 14.00           C  
ATOM   1622  O   GLN A 204       9.990  65.650  -0.427  1.00 13.60           O  
ATOM   1623  CB  GLN A 204       9.413  66.465   2.408  1.00 13.50           C  
ATOM   1624  CG  GLN A 204       8.443  67.094   3.418  1.00 16.60           C  
ATOM   1625  CD  GLN A 204       8.009  66.110   4.463  1.00 19.30           C  
ATOM   1626  OE1 GLN A 204       6.859  65.665   4.491  1.00 17.90           O  
ATOM   1627  NE2 GLN A 204       8.931  65.739   5.312  1.00 10.60           N  
ATOM   1628  N   SER A 205      11.824  66.834   0.202  1.00 14.20           N  
ATOM   1629  CA  SER A 205      12.751  66.189  -0.735  1.00 15.40           C  
ATOM   1630  C   SER A 205      14.016  65.722  -0.042  1.00 15.60           C  
ATOM   1631  O   SER A 205      14.534  66.404   0.832  1.00 17.40           O  
ATOM   1632  CB  SER A 205      13.149  67.143  -1.868  1.00 14.60           C  
ATOM   1633  OG  SER A 205      12.075  67.957  -2.274  1.00 15.00           O  
ATOM   1634  N   ALA A 206      14.573  64.617  -0.513  1.00 15.00           N  
ATOM   1635  CA  ALA A 206      15.844  64.107  -0.019  1.00 14.90           C  
ATOM   1636  C   ALA A 206      16.703  63.720  -1.229  1.00 15.40           C  
ATOM   1637  O   ALA A 206      16.206  63.062  -2.134  1.00 14.60           O  
ATOM   1638  CB  ALA A 206      15.600  62.911   0.811  1.00 14.60           C  
ATOM   1639  N   LEU A 207      17.963  64.162  -1.259  1.00 15.70           N  
ATOM   1640  CA  LEU A 207      18.888  63.863  -2.378  1.00 15.70           C  
ATOM   1641  C   LEU A 207      19.944  62.842  -1.963  1.00 16.10           C  
ATOM   1642  O   LEU A 207      20.489  62.906  -0.863  1.00 15.40           O  
ATOM   1643  CB  LEU A 207      19.605  65.129  -2.862  1.00 14.60           C  
ATOM   1644  CG  LEU A 207      18.907  66.482  -2.949  1.00 14.90           C  
ATOM   1645  CD1 LEU A 207      19.841  67.517  -3.569  1.00 14.30           C  
ATOM   1646  CD2 LEU A 207      17.638  66.364  -3.702  1.00 14.40           C  
ATOM   1647  N   SER A 208      20.204  61.864  -2.816  1.00 16.50           N  
ATOM   1648  CA  SER A 208      21.282  60.937  -2.550  1.00 17.60           C  
ATOM   1649  C   SER A 208      22.029  60.558  -3.841  1.00 18.50           C  
ATOM   1650  O   SER A 208      21.750  61.099  -4.911  1.00 17.50           O  
ATOM   1651  CB  SER A 208      20.735  59.705  -1.849  1.00 16.60           C  
ATOM   1652  OG  SER A 208      19.729  59.130  -2.643  1.00 18.80           O  
ATOM   1653  N   LYS A 209      23.059  59.738  -3.678  1.00 20.20           N  
ATOM   1654  CA  LYS A 209      23.857  59.203  -4.757  1.00 21.90           C  
ATOM   1655  C   LYS A 209      23.736  57.698  -4.727  1.00 23.50           C  
ATOM   1656  O   LYS A 209      23.552  57.104  -3.667  1.00 23.10           O  
ATOM   1657  CB  LYS A 209      25.328  59.549  -4.581  1.00 21.80           C  
ATOM   1658  CG  LYS A 209      25.676  61.017  -4.573  1.00 21.90           C  
ATOM   1659  CD  LYS A 209      25.413  61.702  -5.891  1.00 25.10           C  
ATOM   1660  CE  LYS A 209      26.041  63.084  -5.877  1.00 18.10           C  
ATOM   1661  NZ  LYS A 209      25.745  63.848  -7.107  1.00 23.60           N  
ATOM   1662  N   ASP A 210      23.572  57.136  -5.916  1.00 25.90           N  
ATOM   1663  CA  ASP A 210      23.656  55.709  -6.145  1.00 28.50           C  
ATOM   1664  C   ASP A 210      25.151  55.352  -6.137  1.00 31.00           C  
ATOM   1665  O   ASP A 210      25.933  55.868  -6.955  1.00 31.50           O  
ATOM   1666  CB  ASP A 210      23.022  55.406  -7.504  1.00 28.50           C  
ATOM   1667  CG  ASP A 210      23.090  53.924  -7.892  1.00 39.30           C  
ATOM   1668  OD1 ASP A 210      23.843  53.131  -7.269  1.00 33.40           O  
ATOM   1669  OD2 ASP A 210      22.365  53.549  -8.842  1.00 37.10           O  
ATOM   1670  N   PRO A 211      25.588  54.532  -5.162  1.00 33.00           N  
ATOM   1671  CA  PRO A 211      27.049  54.388  -5.042  1.00 34.40           C  
ATOM   1672  C   PRO A 211      27.677  53.427  -6.092  1.00 35.30           C  
ATOM   1673  O   PRO A 211      28.902  53.299  -6.186  1.00 35.90           O  
ATOM   1674  CB  PRO A 211      27.238  53.929  -3.587  1.00 34.30           C  
ATOM   1675  CG  PRO A 211      25.924  53.309  -3.203  1.00 33.10           C  
ATOM   1676  CD  PRO A 211      24.877  54.055  -3.957  1.00 33.00           C  
ATOM   1677  N   ASN A 212      26.822  52.845  -6.939  1.00 36.20           N  
ATOM   1678  CA  ASN A 212      27.235  52.101  -8.143  1.00 36.60           C  
ATOM   1679  C   ASN A 212      27.409  52.940  -9.435  1.00 36.40           C  
ATOM   1680  O   ASN A 212      27.744  52.398 -10.492  1.00 37.20           O  
ATOM   1681  CB  ASN A 212      26.217  50.999  -8.463  1.00 37.30           C  
ATOM   1682  CG  ASN A 212      26.228  49.855  -7.451  1.00 57.80           C  
ATOM   1683  OD1 ASN A 212      25.186  49.245  -7.189  1.00 63.20           O  
ATOM   1684  ND2 ASN A 212      27.403  49.532  -6.909  1.00 60.90           N  
ATOM   1685  N   GLU A 213      27.072  54.222  -9.380  1.00 35.10           N  
ATOM   1686  CA  GLU A 213      26.913  55.024 -10.577  1.00 33.90           C  
ATOM   1687  C   GLU A 213      28.124  55.922 -10.769  1.00 33.30           C  
ATOM   1688  O   GLU A 213      28.407  56.792  -9.952  1.00 33.40           O  
ATOM   1689  CB  GLU A 213      25.614  55.849 -10.461  1.00 33.70           C  
ATOM   1690  CG  GLU A 213      25.329  56.815 -11.585  1.00 25.70           C  
ATOM   1691  CD  GLU A 213      25.245  56.122 -12.927  1.00 33.00           C  
ATOM   1692  OE1 GLU A 213      24.190  55.534 -13.212  1.00 34.30           O  
ATOM   1693  OE2 GLU A 213      26.266  56.064 -13.644  1.00 38.30           O  
ATOM   1694  N   LYS A 214      28.769  55.780 -11.915  1.00 33.00           N  
ATOM   1695  CA  LYS A 214      29.961  56.558 -12.197  1.00 32.70           C  
ATOM   1696  C   LYS A 214      29.685  57.880 -12.881  1.00 31.90           C  
ATOM   1697  O   LYS A 214      30.539  58.758 -12.883  1.00 32.60           O  
ATOM   1698  CB  LYS A 214      30.943  55.724 -13.028  1.00 33.80           C  
ATOM   1699  CG  LYS A 214      32.029  55.076 -12.177  1.00 45.30           C  
ATOM   1700  CD  LYS A 214      32.151  53.597 -12.451  1.00 61.90           C  
ATOM   1701  CE  LYS A 214      30.943  52.844 -11.932  1.00 84.30           C  
ATOM   1702  NZ  LYS A 214      31.260  51.399 -11.736  1.00 84.30           N  
ATOM   1703  N   ARG A 215      28.517  58.006 -13.503  1.00 31.00           N  
ATOM   1704  CA  ARG A 215      28.109  59.276 -14.110  1.00 30.00           C  
ATOM   1705  C   ARG A 215      27.754  60.307 -13.060  1.00 28.00           C  
ATOM   1706  O   ARG A 215      27.340  59.970 -11.950  1.00 27.80           O  
ATOM   1707  CB  ARG A 215      26.898  59.085 -15.010  1.00 32.00           C  
ATOM   1708  CG  ARG A 215      27.200  58.431 -16.342  1.00 35.80           C  
ATOM   1709  CD  ARG A 215      26.575  57.056 -16.414  1.00 38.50           C  
ATOM   1710  NE  ARG A 215      25.491  56.980 -17.380  1.00 41.20           N  
ATOM   1711  CZ  ARG A 215      24.352  56.322 -17.187  1.00 42.80           C  
ATOM   1712  NH1 ARG A 215      24.020  55.860 -15.989  1.00 42.70           N  
ATOM   1713  NH2 ARG A 215      23.496  56.202 -18.196  1.00 44.60           N  
ATOM   1714  N   ASP A 216      27.848  61.564 -13.439  1.00 25.20           N  
ATOM   1715  CA  ASP A 216      27.369  62.604 -12.591  1.00 23.30           C  
ATOM   1716  C   ASP A 216      25.855  62.470 -12.575  1.00 22.30           C  
ATOM   1717  O   ASP A 216      25.216  62.325 -13.628  1.00 20.90           O  
ATOM   1718  CB  ASP A 216      27.787  63.956 -13.139  1.00 23.60           C  
ATOM   1719  CG  ASP A 216      27.747  65.027 -12.094  1.00 19.90           C  
ATOM   1720  OD1 ASP A 216      27.529  64.690 -10.915  1.00 23.10           O  
ATOM   1721  OD2 ASP A 216      27.949  66.196 -12.439  1.00 27.10           O  
ATOM   1722  N   HIS A 217      25.305  62.371 -11.372  1.00 20.60           N  
ATOM   1723  CA  HIS A 217      23.899  62.036 -11.204  1.00 19.20           C  
ATOM   1724  C   HIS A 217      23.329  62.524  -9.847  1.00 18.60           C  
ATOM   1725  O   HIS A 217      24.090  62.909  -8.938  1.00 16.60           O  
ATOM   1726  CB  HIS A 217      23.713  60.525 -11.321  1.00 19.30           C  
ATOM   1727  CG  HIS A 217      24.189  59.744 -10.117  1.00 19.90           C  
ATOM   1728  ND1 HIS A 217      25.503  59.631  -9.728  1.00 20.40           N  
ATOM   1729  CD2 HIS A 217      23.374  59.154  -9.162  1.00 20.00           C  
ATOM   1730  CE1 HIS A 217      25.483  58.952  -8.561  1.00 19.80           C  
ATOM   1731  NE2 HIS A 217      24.278  58.659  -8.241  1.00 19.20           N  
ATOM   1732  N   MET A 218      21.992  62.502  -9.765  1.00 17.50           N  
ATOM   1733  CA  MET A 218      21.225  62.795  -8.568  1.00 15.50           C  
ATOM   1734  C   MET A 218      20.083  61.802  -8.460  1.00 15.90           C  
ATOM   1735  O   MET A 218      19.424  61.496  -9.446  1.00 15.90           O  
ATOM   1736  CB  MET A 218      20.671  64.213  -8.629  1.00 15.70           C  
ATOM   1737  CG  MET A 218      19.680  64.576  -7.523  1.00 15.30           C  
ATOM   1738  SD  MET A 218      19.325  66.302  -7.387  1.00 16.70           S  
ATOM   1739  CE  MET A 218      18.211  66.445  -8.610  1.00 17.50           C  
ATOM   1740  N   VAL A 219      19.929  61.185  -7.292  1.00 15.00           N  
ATOM   1741  CA  VAL A 219      18.703  60.474  -6.950  1.00 14.50           C  
ATOM   1742  C   VAL A 219      17.840  61.355  -6.013  1.00 15.30           C  
ATOM   1743  O   VAL A 219      18.375  61.984  -5.074  1.00 15.20           O  
ATOM   1744  CB  VAL A 219      19.029  59.130  -6.262  1.00 14.80           C  
ATOM   1745  CG1 VAL A 219      17.732  58.401  -5.849  1.00 14.70           C  
ATOM   1746  CG2 VAL A 219      19.913  58.261  -7.178  1.00 13.50           C  
ATOM   1747  N   LEU A 220      16.568  61.551  -6.394  1.00 15.20           N  
ATOM   1748  CA  LEU A 220      15.621  62.444  -5.716  1.00 15.00           C  
ATOM   1749  C   LEU A 220      14.417  61.634  -5.206  1.00 16.00           C  
ATOM   1750  O   LEU A 220      13.803  60.860  -5.933  1.00 16.50           O  
ATOM   1751  CB  LEU A 220      15.144  63.558  -6.668  1.00 14.70           C  
ATOM   1752  CG  LEU A 220      13.964  64.444  -6.268  1.00 14.10           C  
ATOM   1753  CD1 LEU A 220      14.343  65.320  -5.098  1.00 14.90           C  
ATOM   1754  CD2 LEU A 220      13.575  65.312  -7.420  1.00 14.80           C  
ATOM   1755  N   LEU A 221      14.124  61.757  -3.924  1.00 16.00           N  
ATOM   1756  CA  LEU A 221      12.905  61.202  -3.335  1.00 16.60           C  
ATOM   1757  C   LEU A 221      12.118  62.404  -2.864  1.00 15.80           C  
ATOM   1758  O   LEU A 221      12.682  63.308  -2.259  1.00 16.10           O  
ATOM   1759  CB  LEU A 221      13.231  60.351  -2.110  1.00 19.00           C  
ATOM   1760  CG  LEU A 221      13.060  58.848  -2.163  1.00 22.20           C  
ATOM   1761  CD1 LEU A 221      11.590  58.494  -2.443  1.00 23.50           C  
ATOM   1762  CD2 LEU A 221      14.029  58.292  -3.239  1.00 24.60           C  
ATOM   1763  N   GLU A 222      10.813  62.395  -3.087  1.00 15.10           N  
ATOM   1764  CA  GLU A 222      10.005  63.557  -2.781  1.00 15.60           C  
ATOM   1765  C   GLU A 222       8.610  63.110  -2.370  1.00 16.10           C  
ATOM   1766  O   GLU A 222       8.125  62.084  -2.858  1.00 16.00           O  
ATOM   1767  CB  GLU A 222       9.938  64.472  -4.002  1.00 16.60           C  
ATOM   1768  CG  GLU A 222       9.665  65.916  -3.712  1.00 18.90           C  
ATOM   1769  CD  GLU A 222      10.080  66.823  -4.857  1.00 15.70           C  
ATOM   1770  OE1 GLU A 222       9.273  66.930  -5.780  1.00 19.60           O  
ATOM   1771  OE2 GLU A 222      11.138  67.498  -4.803  1.00 15.00           O  
ATOM   1772  N   PHE A 223       8.049  63.785  -1.359  1.00 16.00           N  
ATOM   1773  CA  PHE A 223       6.702  63.511  -0.870  1.00 16.10           C  
ATOM   1774  C   PHE A 223       5.960  64.811  -0.964  1.00 15.40           C  
ATOM   1775  O   PHE A 223       6.519  65.844  -0.596  1.00 16.50           O  
ATOM   1776  CB  PHE A 223       6.747  63.068   0.600  1.00 16.70           C  
ATOM   1777  CG  PHE A 223       7.393  61.738   0.809  1.00 17.70           C  
ATOM   1778  CD1 PHE A 223       6.655  60.566   0.692  1.00 18.50           C  
ATOM   1779  CD2 PHE A 223       8.756  61.650   1.037  1.00 19.70           C  
ATOM   1780  CE1 PHE A 223       7.266  59.318   0.791  1.00 18.30           C  
ATOM   1781  CE2 PHE A 223       9.379  60.410   1.143  1.00 20.10           C  
ATOM   1782  CZ  PHE A 223       8.627  59.243   1.016  1.00 19.60           C  
ATOM   1783  N   VAL A 224       4.784  64.813  -1.588  1.00 15.40           N  
ATOM   1784  CA  VAL A 224       4.030  66.065  -1.785  1.00 16.00           C  
ATOM   1785  C   VAL A 224       2.573  65.857  -1.471  1.00 16.30           C  
ATOM   1786  O   VAL A 224       1.941  64.933  -1.978  1.00 16.30           O  
ATOM   1787  CB  VAL A 224       4.081  66.623  -3.232  1.00 16.20           C  
ATOM   1788  CG1 VAL A 224       3.654  68.067  -3.229  1.00 16.00           C  
ATOM   1789  CG2 VAL A 224       5.469  66.503  -3.827  1.00 17.10           C  
ATOM   1790  N   THR A 225       2.034  66.738  -0.646  1.00 17.30           N  
ATOM   1791  CA  THR A 225       0.651  66.633  -0.183  1.00 19.90           C  
ATOM   1792  C   THR A 225      -0.003  68.001  -0.173  1.00 19.90           C  
ATOM   1793  O   THR A 225       0.582  68.968   0.303  1.00 20.10           O  
ATOM   1794  CB  THR A 225       0.540  66.041   1.270  1.00 20.80           C  
ATOM   1795  OG1 THR A 225       1.210  64.777   1.332  1.00 23.40           O  
ATOM   1796  CG2 THR A 225      -0.942  65.806   1.643  1.00 22.30           C  
ATOM   1797  N   ALA A 226      -1.217  68.077  -0.709  1.00 20.90           N  
ATOM   1798  CA  ALA A 226      -1.960  69.332  -0.762  1.00 22.40           C  
ATOM   1799  C   ALA A 226      -2.615  69.624   0.585  1.00 24.00           C  
ATOM   1800  O   ALA A 226      -3.078  68.695   1.272  1.00 24.00           O  
ATOM   1801  CB  ALA A 226      -3.021  69.266  -1.846  1.00 22.00           C  
ATOM   1802  N   ALA A 227      -2.741  70.912   0.895  1.00 25.10           N  
ATOM   1803  CA  ALA A 227      -3.283  71.356   2.169  1.00 27.40           C  
ATOM   1804  C   ALA A 227      -3.842  72.772   2.043  1.00 28.90           C  
ATOM   1805  O   ALA A 227      -3.731  73.393   0.993  1.00 28.50           O  
ATOM   1806  CB  ALA A 227      -2.184  71.314   3.242  1.00 26.90           C  
ATOM   1807  N   GLY A 228      -4.491  73.252   3.100  1.00 31.50           N  
ATOM   1808  CA  GLY A 228      -4.817  74.668   3.205  1.00 34.50           C  
ATOM   1809  C   GLY A 228      -6.283  75.040   3.026  1.00 36.60           C  
ATOM   1810  O   GLY A 228      -6.638  76.216   3.151  1.00 36.80           O  
ATOM   1811  N   ILE A 229      -7.109  74.064   2.647  1.00 38.60           N  
ATOM   1812  CA  ILE A 229      -8.556  74.256   2.452  1.00 41.10           C  
ATOM   1813  C   ILE A 229      -9.275  73.368   3.447  1.00 43.10           C  
ATOM   1814  O   ILE A 229      -8.856  72.229   3.655  1.00 43.60           O  
ATOM   1815  CB  ILE A 229      -9.003  73.826   1.031  1.00 40.40           C  
ATOM   1816  CG1 ILE A 229      -8.528  74.844   0.013  1.00 40.50           C  
ATOM   1817  CG2 ILE A 229     -10.512  73.689   0.942  1.00 39.90           C  
ATOM   1818  CD1 ILE A 229      -8.610  74.327  -1.389  1.00 41.40           C  
ATOM   1819  N   THR A 230     -10.312  73.894   4.098  1.00 45.60           N  
ATOM   1820  CA  THR A 230     -11.129  73.085   5.017  1.00 47.90           C  
ATOM   1821  C   THR A 230     -12.555  72.789   4.490  1.00 49.10           C  
ATOM   1822  O   THR A 230     -12.945  71.602   4.523  1.00 50.90           O  
ATOM   1823  CB  THR A 230     -11.179  73.714   6.460  1.00 48.20           C  
ATOM   1824  OG1 THR A 230     -11.348  75.136   6.384  1.00 48.60           O  
ATOM   1825  CG2 THR A 230      -9.880  73.414   7.220  1.00 49.10           C  
TER    1826      THR A 230                                                      
ATOM   1827  N   ALA B   1      37.642  45.936   6.027  0.44 43.40           N  
ATOM   1828  CA  ALA B   1      38.888  46.634   6.471  0.44 43.30           C  
ATOM   1829  C   ALA B   1      38.955  46.782   7.996  0.44 43.10           C  
ATOM   1830  O   ALA B   1      40.033  46.670   8.583  0.44 43.00           O  
ATOM   1831  CB  ALA B   1      38.997  48.009   5.802  0.44 43.60           C  
ATOM   1832  N   SER B   2      37.813  47.023   8.639  0.44 42.70           N  
ATOM   1833  CA  SER B   2      37.749  46.855  10.086  0.44 42.30           C  
ATOM   1834  C   SER B   2      36.417  46.280  10.563  0.44 42.00           C  
ATOM   1835  O   SER B   2      35.400  46.426   9.904  0.44 42.00           O  
ATOM   1836  CB  SER B   2      38.089  48.165  10.811  0.44 42.00           C  
ATOM   1837  OG  SER B   2      36.936  48.862  11.225  0.44 42.10           O  
ATOM   1838  N   LYS B   3      36.493  45.470  11.610  1.00 42.20           N  
ATOM   1839  CA  LYS B   3      35.427  44.547  12.027  1.00 41.70           C  
ATOM   1840  C   LYS B   3      34.229  45.312  12.556  1.00 41.40           C  
ATOM   1841  O   LYS B   3      34.394  46.444  13.034  1.00 43.10           O  
ATOM   1842  CB  LYS B   3      35.951  43.639  13.139  1.00 42.70           C  
ATOM   1843  CG  LYS B   3      36.002  42.151  12.781  1.00 60.40           C  
ATOM   1844  CD  LYS B   3      35.289  41.299  13.839  1.00 64.50           C  
ATOM   1845  CE  LYS B   3      35.335  41.946  15.251  1.00 81.60           C  
ATOM   1846  NZ  LYS B   3      34.828  41.044  16.348  1.00 96.80           N  
ATOM   1847  N   GLY B   4      33.061  44.668  12.605  1.00 39.50           N  
ATOM   1848  CA  GLY B   4      31.846  45.404  12.928  1.00 36.70           C  
ATOM   1849  C   GLY B   4      31.144  45.953  11.697  1.00 34.80           C  
ATOM   1850  O   GLY B   4      29.955  45.710  11.518  1.00 34.50           O  
ATOM   1851  N   GLU B   5      31.893  46.620  10.825  1.00 33.70           N  
ATOM   1852  CA  GLU B   5      31.470  46.854   9.449  1.00 33.20           C  
ATOM   1853  C   GLU B   5      30.743  45.673   8.810  1.00 32.90           C  
ATOM   1854  O   GLU B   5      29.766  45.886   8.096  1.00 33.50           O  
ATOM   1855  CB  GLU B   5      32.662  47.168   8.570  1.00 33.80           C  
ATOM   1856  CG  GLU B   5      33.066  48.612   8.477  1.00 40.80           C  
ATOM   1857  CD  GLU B   5      34.461  48.751   7.872  1.00 49.40           C  
ATOM   1858  OE1 GLU B   5      34.797  47.973   6.939  1.00 49.50           O  
ATOM   1859  OE2 GLU B   5      35.256  49.578   8.379  1.00 56.70           O  
ATOM   1860  N   GLU B   6      31.277  44.456   8.965  1.00 32.20           N  
ATOM   1861  CA  GLU B   6      30.765  43.287   8.238  1.00 31.60           C  
ATOM   1862  C   GLU B   6      29.325  42.959   8.563  1.00 31.30           C  
ATOM   1863  O   GLU B   6      28.618  42.373   7.740  1.00 32.10           O  
ATOM   1864  CB  GLU B   6      31.614  42.039   8.484  1.00 32.20           C  
ATOM   1865  CG  GLU B   6      32.671  42.184   9.530  1.00 51.50           C  
ATOM   1866  CD  GLU B   6      32.275  41.527  10.849  1.00 77.40           C  
ATOM   1867  OE1 GLU B   6      31.997  40.303  10.831  1.00101.70           O  
ATOM   1868  OE2 GLU B   6      32.242  42.231  11.893  1.00 75.00           O  
ATOM   1869  N   LEU B   7      28.863  43.400   9.731  1.00 30.40           N  
ATOM   1870  CA  LEU B   7      27.462  43.228  10.115  1.00 29.30           C  
ATOM   1871  C   LEU B   7      26.494  44.068   9.277  1.00 29.10           C  
ATOM   1872  O   LEU B   7      25.292  43.806   9.277  1.00 29.40           O  
ATOM   1873  CB  LEU B   7      27.292  43.578  11.583  1.00 29.00           C  
ATOM   1874  CG  LEU B   7      28.117  42.675  12.480  1.00 28.70           C  
ATOM   1875  CD1 LEU B   7      28.331  43.316  13.819  1.00 28.80           C  
ATOM   1876  CD2 LEU B   7      27.393  41.360  12.598  1.00 28.70           C  
ATOM   1877  N   PHE B   8      27.033  45.020   8.515  1.00 28.50           N  
ATOM   1878  CA  PHE B   8      26.235  46.037   7.840  1.00 29.10           C  
ATOM   1879  C   PHE B   8      26.322  46.012   6.311  1.00 29.90           C  
ATOM   1880  O   PHE B   8      25.949  46.984   5.655  1.00 29.80           O  
ATOM   1881  CB  PHE B   8      26.648  47.425   8.329  1.00 27.40           C  
ATOM   1882  CG  PHE B   8      26.225  47.710   9.732  1.00 26.90           C  
ATOM   1883  CD1 PHE B   8      24.944  48.206   9.990  1.00 25.60           C  
ATOM   1884  CD2 PHE B   8      27.110  47.480  10.798  1.00 25.80           C  
ATOM   1885  CE1 PHE B   8      24.545  48.477  11.291  1.00 25.90           C  
ATOM   1886  CE2 PHE B   8      26.727  47.749  12.098  1.00 25.90           C  
ATOM   1887  CZ  PHE B   8      25.434  48.253  12.354  1.00 25.80           C  
ATOM   1888  N   THR B   9      26.842  44.928   5.752  1.00 30.90           N  
ATOM   1889  CA  THR B   9      26.995  44.850   4.307  1.00 32.40           C  
ATOM   1890  C   THR B   9      25.659  44.699   3.574  1.00 31.90           C  
ATOM   1891  O   THR B   9      25.459  45.328   2.544  1.00 34.00           O  
ATOM   1892  CB  THR B   9      27.937  43.702   3.894  1.00 33.20           C  
ATOM   1893  OG1 THR B   9      27.413  42.462   4.378  1.00 34.70           O  
ATOM   1894  CG2 THR B   9      29.346  43.919   4.478  1.00 34.20           C  
ATOM   1895  N   GLY B  10      24.695  44.012   4.165  1.00 30.60           N  
ATOM   1896  CA  GLY B  10      23.393  43.927   3.520  1.00 29.20           C  
ATOM   1897  C   GLY B  10      22.362  44.911   4.046  1.00 27.60           C  
ATOM   1898  O   GLY B  10      22.706  45.988   4.526  1.00 28.20           O  
ATOM   1899  N   VAL B  11      21.093  44.582   3.866  1.00 25.90           N  
ATOM   1900  CA  VAL B  11      20.014  45.313   4.508  1.00 24.40           C  
ATOM   1901  C   VAL B  11      19.707  44.627   5.849  1.00 23.90           C  
ATOM   1902  O   VAL B  11      19.655  43.397   5.929  1.00 24.00           O  
ATOM   1903  CB  VAL B  11      18.762  45.335   3.621  1.00 23.60           C  
ATOM   1904  CG1 VAL B  11      17.623  46.050   4.327  1.00 23.00           C  
ATOM   1905  CG2 VAL B  11      19.081  46.010   2.298  1.00 23.70           C  
ATOM   1906  N   VAL B  12      19.643  45.426   6.913  1.00 23.10           N  
ATOM   1907  CA  VAL B  12      19.516  44.930   8.297  1.00 21.60           C  
ATOM   1908  C   VAL B  12      18.174  45.376   8.866  1.00 21.00           C  
ATOM   1909  O   VAL B  12      17.790  46.539   8.731  1.00 21.00           O  
ATOM   1910  CB  VAL B  12      20.664  45.492   9.190  1.00 21.60           C  
ATOM   1911  CG1 VAL B  12      20.487  45.071  10.687  1.00 20.30           C  
ATOM   1912  CG2 VAL B  12      22.013  45.028   8.655  1.00 20.90           C  
ATOM   1913  N   PRO B  13      17.390  44.428   9.393  1.00 21.10           N  
ATOM   1914  CA  PRO B  13      16.120  44.710  10.081  1.00 21.50           C  
ATOM   1915  C   PRO B  13      16.301  45.567  11.357  1.00 20.60           C  
ATOM   1916  O   PRO B  13      17.338  45.492  11.999  1.00 20.80           O  
ATOM   1917  CB  PRO B  13      15.554  43.319  10.403  1.00 21.80           C  
ATOM   1918  CG  PRO B  13      16.479  42.330   9.737  1.00 22.00           C  
ATOM   1919  CD  PRO B  13      17.767  43.004   9.489  1.00 21.90           C  
ATOM   1920  N   ILE B  14      15.367  46.488  11.592  1.00 20.60           N  
ATOM   1921  CA  ILE B  14      15.460  47.418  12.692  1.00 20.00           C  
ATOM   1922  C   ILE B  14      14.220  47.283  13.547  1.00 20.70           C  
ATOM   1923  O   ILE B  14      13.111  47.169  13.024  1.00 20.30           O  
ATOM   1924  CB  ILE B  14      15.589  48.897  12.218  1.00 19.00           C  
ATOM   1925  CG1 ILE B  14      16.841  49.072  11.363  1.00 16.90           C  
ATOM   1926  CG2 ILE B  14      15.740  49.833  13.432  1.00 18.30           C  
ATOM   1927  CD1 ILE B  14      16.907  50.410  10.715  1.00 16.80           C  
ATOM   1928  N   LEU B  15      14.436  47.222  14.862  1.00 21.20           N  
ATOM   1929  CA  LEU B  15      13.357  47.274  15.841  1.00 21.50           C  
ATOM   1930  C   LEU B  15      13.562  48.501  16.729  1.00 20.30           C  
ATOM   1931  O   LEU B  15      14.672  48.741  17.184  1.00 19.90           O  
ATOM   1932  CB  LEU B  15      13.441  46.036  16.691  1.00 24.40           C  
ATOM   1933  CG  LEU B  15      12.175  45.219  16.854  1.00 27.90           C  
ATOM   1934  CD1 LEU B  15      10.954  46.150  17.056  1.00 28.70           C  
ATOM   1935  CD2 LEU B  15      12.029  44.318  15.627  1.00 29.20           C  
ATOM   1936  N   VAL B  16      12.525  49.317  16.896  1.00 19.70           N  
ATOM   1937  CA  VAL B  16      12.600  50.508  17.737  1.00 19.50           C  
ATOM   1938  C   VAL B  16      11.489  50.439  18.783  1.00 20.10           C  
ATOM   1939  O   VAL B  16      10.324  50.147  18.459  1.00 20.30           O  
ATOM   1940  CB  VAL B  16      12.437  51.808  16.920  1.00 19.20           C  
ATOM   1941  CG1 VAL B  16      12.554  53.034  17.828  1.00 18.10           C  
ATOM   1942  CG2 VAL B  16      13.503  51.874  15.838  1.00 17.80           C  
ATOM   1943  N   GLU B  17      11.868  50.651  20.048  1.00 20.80           N  
ATOM   1944  CA  GLU B  17      10.889  50.703  21.136  1.00 20.70           C  
ATOM   1945  C   GLU B  17      11.156  51.843  22.075  1.00 19.70           C  
ATOM   1946  O   GLU B  17      12.299  52.099  22.402  1.00 19.50           O  
ATOM   1947  CB  GLU B  17      10.863  49.374  21.890  1.00 21.80           C  
ATOM   1948  CG  GLU B  17       9.777  48.454  21.350  1.00 35.00           C  
ATOM   1949  CD  GLU B  17       9.967  47.012  21.737  1.00 55.50           C  
ATOM   1950  OE1 GLU B  17      10.657  46.737  22.746  1.00 77.60           O  
ATOM   1951  OE2 GLU B  17       9.380  46.152  21.050  1.00 82.50           O  
ATOM   1952  N   LEU B  18      10.121  52.633  22.327  1.00 19.70           N  
ATOM   1953  CA  LEU B  18      10.241  53.855  23.113  1.00 20.10           C  
ATOM   1954  C   LEU B  18       9.164  53.854  24.195  1.00 20.00           C  
ATOM   1955  O   LEU B  18       7.982  53.653  23.911  1.00 19.40           O  
ATOM   1956  CB  LEU B  18      10.069  55.081  22.214  1.00 20.20           C  
ATOM   1957  CG  LEU B  18       9.876  56.546  22.647  1.00 21.20           C  
ATOM   1958  CD1 LEU B  18       8.722  56.743  23.553  1.00 20.80           C  
ATOM   1959  CD2 LEU B  18      11.108  57.079  23.249  1.00 22.10           C  
ATOM   1960  N   ASP B  19       9.583  54.127  25.434  1.00 20.80           N  
ATOM   1961  CA  ASP B  19       8.651  54.437  26.520  1.00 19.70           C  
ATOM   1962  C   ASP B  19       8.932  55.821  27.011  1.00 18.80           C  
ATOM   1963  O   ASP B  19      10.077  56.200  27.210  1.00 18.30           O  
ATOM   1964  CB  ASP B  19       8.769  53.437  27.657  1.00 21.00           C  
ATOM   1965  CG  ASP B  19       8.172  52.085  27.294  1.00 33.00           C  
ATOM   1966  OD1 ASP B  19       6.938  52.005  27.099  1.00 37.80           O  
ATOM   1967  OD2 ASP B  19       8.950  51.127  27.096  1.00 57.90           O  
ATOM   1968  N   GLY B  20       7.895  56.629  26.982  1.00 19.10           N  
ATOM   1969  CA  GLY B  20       8.085  58.029  27.240  1.00 20.20           C  
ATOM   1970  C   GLY B  20       7.093  58.524  28.256  1.00 19.90           C  
ATOM   1971  O   GLY B  20       6.045  57.898  28.496  1.00 20.30           O  
ATOM   1972  N   ASP B  21       7.412  59.699  28.769  1.00 20.20           N  
ATOM   1973  CA  ASP B  21       6.585  60.423  29.695  1.00 19.60           C  
ATOM   1974  C   ASP B  21       6.942  61.887  29.436  1.00 18.50           C  
ATOM   1975  O   ASP B  21       8.081  62.298  29.641  1.00 18.30           O  
ATOM   1976  CB  ASP B  21       7.007  59.965  31.097  1.00 21.20           C  
ATOM   1977  CG  ASP B  21       6.460  60.825  32.216  1.00 27.20           C  
ATOM   1978  OD1 ASP B  21       6.030  61.976  32.013  1.00 25.50           O  
ATOM   1979  OD2 ASP B  21       6.574  60.356  33.357  1.00 53.50           O  
ATOM   1980  N   VAL B  22       5.964  62.672  29.013  1.00 17.90           N  
ATOM   1981  CA  VAL B  22       6.173  64.095  28.798  1.00 17.90           C  
ATOM   1982  C   VAL B  22       5.140  64.889  29.603  1.00 17.80           C  
ATOM   1983  O   VAL B  22       3.937  64.844  29.334  1.00 16.90           O  
ATOM   1984  CB  VAL B  22       6.081  64.484  27.264  1.00 18.30           C  
ATOM   1985  CG1 VAL B  22       6.156  66.009  27.084  1.00 18.10           C  
ATOM   1986  CG2 VAL B  22       7.238  63.839  26.469  1.00 18.50           C  
ATOM   1987  N   ASN B  23       5.629  65.608  30.610  1.00 19.20           N  
ATOM   1988  CA  ASN B  23       4.776  66.364  31.534  1.00 19.90           C  
ATOM   1989  C   ASN B  23       3.766  65.434  32.212  1.00 19.60           C  
ATOM   1990  O   ASN B  23       2.631  65.815  32.429  1.00 20.70           O  
ATOM   1991  CB  ASN B  23       4.038  67.488  30.784  1.00 18.40           C  
ATOM   1992  CG  ASN B  23       4.874  68.744  30.634  1.00 16.40           C  
ATOM   1993  OD1 ASN B  23       4.322  69.825  30.549  1.00 28.30           O  
ATOM   1994  ND2 ASN B  23       6.196  68.606  30.563  1.00 15.80           N  
ATOM   1995  N   GLY B  24       4.162  64.193  32.457  1.00 20.00           N  
ATOM   1996  CA  GLY B  24       3.259  63.225  33.053  1.00 20.60           C  
ATOM   1997  C   GLY B  24       2.514  62.349  32.068  1.00 21.10           C  
ATOM   1998  O   GLY B  24       2.077  61.247  32.410  1.00 19.80           O  
ATOM   1999  N   HIS B  25       2.359  62.838  30.840  1.00 21.50           N  
ATOM   2000  CA  HIS B  25       1.682  62.079  29.799  1.00 22.70           C  
ATOM   2001  C   HIS B  25       2.529  60.920  29.294  1.00 23.00           C  
ATOM   2002  O   HIS B  25       3.638  61.094  28.787  1.00 22.70           O  
ATOM   2003  CB  HIS B  25       1.313  62.972  28.634  1.00 24.10           C  
ATOM   2004  CG  HIS B  25       0.499  64.229  28.894  1.00 25.90           C  
ATOM   2005  ND1 HIS B  25       1.049  65.528  29.184  1.00 27.40           N  
ATOM   2006  CD2 HIS B  25      -0.894  64.266  28.820  1.00 26.10           C  
ATOM   2007  CE1 HIS B  25      -0.022  66.371  29.308  1.00 27.60           C  
ATOM   2008  NE2 HIS B  25      -1.129  65.659  29.117  1.00 27.40           N  
ATOM   2009  N   LYS B  26       2.044  59.716  29.531  1.00 22.80           N  
ATOM   2010  CA  LYS B  26       2.821  58.555  29.177  1.00 23.20           C  
ATOM   2011  C   LYS B  26       2.317  57.956  27.877  1.00 23.00           C  
ATOM   2012  O   LYS B  26       1.126  58.044  27.544  1.00 23.00           O  
ATOM   2013  CB  LYS B  26       2.779  57.518  30.287  1.00 23.20           C  
ATOM   2014  CG  LYS B  26       3.260  58.063  31.607  1.00 33.70           C  
ATOM   2015  CD  LYS B  26       4.212  57.082  32.255  1.00 63.00           C  
ATOM   2016  CE  LYS B  26       4.182  57.226  33.772  1.00 75.10           C  
ATOM   2017  NZ  LYS B  26       4.676  58.566  34.199  1.00 91.70           N  
ATOM   2018  N   PHE B  27       3.247  57.383  27.126  1.00 22.30           N  
ATOM   2019  CA  PHE B  27       2.933  56.822  25.823  1.00 21.10           C  
ATOM   2020  C   PHE B  27       4.071  55.874  25.415  1.00 20.50           C  
ATOM   2021  O   PHE B  27       5.159  55.920  25.981  1.00 19.40           O  
ATOM   2022  CB  PHE B  27       2.749  57.946  24.794  1.00 20.30           C  
ATOM   2023  CG  PHE B  27       3.960  58.791  24.624  1.00 21.40           C  
ATOM   2024  CD1 PHE B  27       4.193  59.865  25.482  1.00 21.30           C  
ATOM   2025  CD2 PHE B  27       4.919  58.479  23.645  1.00 21.40           C  
ATOM   2026  CE1 PHE B  27       5.359  60.631  25.383  1.00 22.00           C  
ATOM   2027  CE2 PHE B  27       6.091  59.230  23.531  1.00 21.50           C  
ATOM   2028  CZ  PHE B  27       6.312  60.318  24.407  1.00 21.90           C  
ATOM   2029  N   SER B  28       3.741  54.897  24.580  1.00 19.80           N  
ATOM   2030  CA  SER B  28       4.733  54.013  24.000  1.00 20.30           C  
ATOM   2031  C   SER B  28       4.612  54.038  22.478  1.00 19.70           C  
ATOM   2032  O   SER B  28       3.530  54.260  21.926  1.00 18.60           O  
ATOM   2033  CB  SER B  28       4.527  52.582  24.480  1.00 20.90           C  
ATOM   2034  OG  SER B  28       4.588  52.516  25.890  1.00 25.20           O  
ATOM   2035  N   VAL B  29       5.750  53.877  21.815  1.00 19.90           N  
ATOM   2036  CA  VAL B  29       5.735  53.652  20.384  1.00 20.30           C  
ATOM   2037  C   VAL B  29       6.687  52.537  20.006  1.00 20.60           C  
ATOM   2038  O   VAL B  29       7.672  52.291  20.699  1.00 21.00           O  
ATOM   2039  CB  VAL B  29       5.971  54.990  19.533  1.00 20.10           C  
ATOM   2040  CG1 VAL B  29       6.144  56.208  20.411  1.00 19.40           C  
ATOM   2041  CG2 VAL B  29       7.081  54.827  18.544  1.00 19.70           C  
ATOM   2042  N   SER B  30       6.222  51.705  19.078  1.00 20.80           N  
ATOM   2043  CA  SER B  30       7.046  50.679  18.470  1.00 21.40           C  
ATOM   2044  C   SER B  30       7.277  51.022  16.990  1.00 21.10           C  
ATOM   2045  O   SER B  30       6.399  51.576  16.307  1.00 21.10           O  
ATOM   2046  CB  SER B  30       6.350  49.312  18.570  1.00 23.50           C  
ATOM   2047  OG  SER B  30       6.296  48.830  19.908  1.00 26.80           O  
ATOM   2048  N   GLY B  31       8.463  50.701  16.494  1.00 20.80           N  
ATOM   2049  CA  GLY B  31       8.735  50.908  15.089  1.00 19.50           C  
ATOM   2050  C   GLY B  31       9.489  49.738  14.512  1.00 19.00           C  
ATOM   2051  O   GLY B  31      10.142  48.975  15.219  1.00 19.00           O  
ATOM   2052  N   GLU B  32       9.369  49.567  13.205  1.00 19.20           N  
ATOM   2053  CA  GLU B  32      10.155  48.566  12.485  1.00 19.00           C  
ATOM   2054  C   GLU B  32      10.561  49.113  11.121  1.00 17.90           C  
ATOM   2055  O   GLU B  32       9.879  49.970  10.563  1.00 18.10           O  
ATOM   2056  CB  GLU B  32       9.331  47.296  12.293  1.00 19.60           C  
ATOM   2057  CG  GLU B  32       8.000  47.584  11.631  1.00 36.20           C  
ATOM   2058  CD  GLU B  32       7.323  46.350  11.102  1.00 56.30           C  
ATOM   2059  OE1 GLU B  32       7.832  45.230  11.347  1.00 77.10           O  
ATOM   2060  OE2 GLU B  32       6.267  46.512  10.453  1.00 66.90           O  
ATOM   2061  N   GLY B  33      11.673  48.633  10.601  1.00 17.60           N  
ATOM   2062  CA  GLY B  33      12.063  49.058   9.278  1.00 18.50           C  
ATOM   2063  C   GLY B  33      13.310  48.340   8.871  1.00 17.60           C  
ATOM   2064  O   GLY B  33      13.630  47.299   9.451  1.00 18.40           O  
ATOM   2065  N   GLU B  34      14.085  48.970   8.006  1.00 16.70           N  
ATOM   2066  CA  GLU B  34      15.338  48.392   7.590  1.00 16.80           C  
ATOM   2067  C   GLU B  34      16.351  49.431   7.227  1.00 16.30           C  
ATOM   2068  O   GLU B  34      16.015  50.569   6.928  1.00 15.80           O  
ATOM   2069  CB  GLU B  34      15.145  47.438   6.414  1.00 17.40           C  
ATOM   2070  CG  GLU B  34      14.314  47.968   5.318  1.00 25.60           C  
ATOM   2071  CD  GLU B  34      14.105  46.935   4.216  1.00 39.00           C  
ATOM   2072  OE1 GLU B  34      13.686  45.785   4.500  1.00 41.20           O  
ATOM   2073  OE2 GLU B  34      14.381  47.280   3.062  1.00 39.40           O  
ATOM   2074  N   GLY B  35      17.606  49.066   7.408  1.00 16.40           N  
ATOM   2075  CA  GLY B  35      18.666  50.021   7.201  1.00 18.10           C  
ATOM   2076  C   GLY B  35      19.667  49.469   6.212  1.00 18.40           C  
ATOM   2077  O   GLY B  35      19.996  48.283   6.239  1.00 18.60           O  
ATOM   2078  N   ASP B  36      20.167  50.353   5.363  1.00 18.70           N  
ATOM   2079  CA  ASP B  36      21.142  49.993   4.360  1.00 18.20           C  
ATOM   2080  C   ASP B  36      22.307  50.958   4.479  1.00 18.30           C  
ATOM   2081  O   ASP B  36      22.264  52.077   3.969  1.00 18.10           O  
ATOM   2082  CB  ASP B  36      20.487  50.103   2.986  1.00 18.70           C  
ATOM   2083  CG  ASP B  36      21.367  49.585   1.861  1.00 27.70           C  
ATOM   2084  OD1 ASP B  36      22.596  49.449   2.032  1.00 26.00           O  
ATOM   2085  OD2 ASP B  36      20.818  49.370   0.769  1.00 33.20           O  
ATOM   2086  N   ALA B  37      23.375  50.515   5.107  1.00 18.40           N  
ATOM   2087  CA  ALA B  37      24.482  51.412   5.361  1.00 20.40           C  
ATOM   2088  C   ALA B  37      25.355  51.631   4.127  1.00 21.60           C  
ATOM   2089  O   ALA B  37      26.116  52.584   4.058  1.00 21.00           O  
ATOM   2090  CB  ALA B  37      25.317  50.874   6.477  1.00 20.60           C  
ATOM   2091  N   THR B  38      25.269  50.712   3.171  1.00 23.30           N  
ATOM   2092  CA  THR B  38      25.976  50.861   1.898  1.00 24.60           C  
ATOM   2093  C   THR B  38      25.487  52.077   1.116  1.00 23.70           C  
ATOM   2094  O   THR B  38      26.277  52.928   0.689  1.00 22.90           O  
ATOM   2095  CB  THR B  38      25.812  49.589   1.062  1.00 26.30           C  
ATOM   2096  OG1 THR B  38      26.435  48.495   1.756  1.00 28.60           O  
ATOM   2097  CG2 THR B  38      26.474  49.756  -0.295  1.00 28.20           C  
ATOM   2098  N   TYR B  39      24.174  52.246   1.119  1.00 23.60           N  
ATOM   2099  CA  TYR B  39      23.562  53.427   0.545  1.00 24.00           C  
ATOM   2100  C   TYR B  39      23.479  54.598   1.561  1.00 23.50           C  
ATOM   2101  O   TYR B  39      23.536  55.770   1.163  1.00 25.00           O  
ATOM   2102  CB  TYR B  39      22.185  53.049   0.010  1.00 25.30           C  
ATOM   2103  CG  TYR B  39      21.656  53.989  -1.024  1.00 28.20           C  
ATOM   2104  CD1 TYR B  39      21.101  55.216  -0.652  1.00 29.80           C  
ATOM   2105  CD2 TYR B  39      21.603  53.619  -2.375  1.00 29.70           C  
ATOM   2106  CE1 TYR B  39      20.482  56.056  -1.595  1.00 31.30           C  
ATOM   2107  CE2 TYR B  39      20.980  54.445  -3.332  1.00 30.50           C  
ATOM   2108  CZ  TYR B  39      20.412  55.657  -2.927  1.00 31.10           C  
ATOM   2109  OH  TYR B  39      19.690  56.448  -3.798  1.00 31.60           O  
ATOM   2110  N   GLY B  40      23.408  54.288   2.860  1.00 21.70           N  
ATOM   2111  CA  GLY B  40      23.266  55.320   3.869  1.00 19.80           C  
ATOM   2112  C   GLY B  40      21.810  55.622   4.055  1.00 18.70           C  
ATOM   2113  O   GLY B  40      21.407  56.770   4.155  1.00 19.40           O  
ATOM   2114  N   LYS B  41      20.988  54.592   4.010  1.00 18.10           N  
ATOM   2115  CA  LYS B  41      19.569  54.855   4.043  1.00 17.80           C  
ATOM   2116  C   LYS B  41      18.711  53.967   4.946  1.00 16.70           C  
ATOM   2117  O   LYS B  41      19.008  52.802   5.201  1.00 16.40           O  
ATOM   2118  CB  LYS B  41      19.027  54.923   2.606  1.00 18.40           C  
ATOM   2119  CG  LYS B  41      18.332  53.714   2.103  1.00 19.30           C  
ATOM   2120  CD  LYS B  41      17.002  54.134   1.526  1.00 31.90           C  
ATOM   2121  CE  LYS B  41      16.359  53.032   0.723  1.00 33.20           C  
ATOM   2122  NZ  LYS B  41      17.284  51.874   0.545  1.00 51.00           N  
ATOM   2123  N   LEU B  42      17.667  54.588   5.473  1.00 16.80           N  
ATOM   2124  CA  LEU B  42      16.795  54.010   6.497  1.00 18.10           C  
ATOM   2125  C   LEU B  42      15.364  54.181   6.045  1.00 17.20           C  
ATOM   2126  O   LEU B  42      14.944  55.299   5.746  1.00 17.80           O  
ATOM   2127  CB  LEU B  42      16.927  54.795   7.833  1.00 19.00           C  
ATOM   2128  CG  LEU B  42      18.242  54.687   8.550  1.00 20.50           C  
ATOM   2129  CD1 LEU B  42      18.563  55.954   9.256  1.00 21.80           C  
ATOM   2130  CD2 LEU B  42      18.143  53.524   9.493  1.00 22.20           C  
ATOM   2131  N   THR B  43      14.563  53.162   6.286  1.00 17.70           N  
ATOM   2132  CA  THR B  43      13.113  53.285   6.183  1.00 18.50           C  
ATOM   2133  C   THR B  43      12.464  52.730   7.456  1.00 17.80           C  
ATOM   2134  O   THR B  43      12.726  51.574   7.828  1.00 17.80           O  
ATOM   2135  CB  THR B  43      12.596  52.486   4.981  1.00 18.90           C  
ATOM   2136  OG1 THR B  43      13.340  52.885   3.832  1.00 21.20           O  
ATOM   2137  CG2 THR B  43      11.132  52.763   4.751  1.00 19.30           C  
ATOM   2138  N   LEU B  44      11.640  53.548   8.124  1.00 17.70           N  
ATOM   2139  CA  LEU B  44      11.053  53.149   9.402  1.00 17.40           C  
ATOM   2140  C   LEU B  44       9.611  53.541   9.462  1.00 16.30           C  
ATOM   2141  O   LEU B  44       9.228  54.566   8.910  1.00 16.70           O  
ATOM   2142  CB  LEU B  44      11.816  53.791  10.565  1.00 17.80           C  
ATOM   2143  CG  LEU B  44      13.217  53.218  10.723  1.00 18.20           C  
ATOM   2144  CD1 LEU B  44      14.130  54.253  11.347  1.00 19.40           C  
ATOM   2145  CD2 LEU B  44      13.174  51.927  11.542  1.00 19.20           C  
ATOM   2146  N   LYS B  45       8.799  52.677  10.060  1.00 15.40           N  
ATOM   2147  CA  LYS B  45       7.415  53.021  10.366  1.00 15.60           C  
ATOM   2148  C   LYS B  45       7.185  52.887  11.879  1.00 15.90           C  
ATOM   2149  O   LYS B  45       7.533  51.863  12.459  1.00 16.20           O  
ATOM   2150  CB  LYS B  45       6.449  52.096   9.637  1.00 13.70           C  
ATOM   2151  CG  LYS B  45       5.043  52.526   9.791  1.00 12.10           C  
ATOM   2152  CD  LYS B  45       4.121  51.509   9.225  1.00 17.80           C  
ATOM   2153  CE  LYS B  45       2.699  51.916   9.459  1.00 25.20           C  
ATOM   2154  NZ  LYS B  45       1.794  50.899   8.850  1.00 30.90           N  
ATOM   2155  N   PHE B  46       6.559  53.899  12.474  1.00 16.70           N  
ATOM   2156  CA  PHE B  46       6.328  53.975  13.926  1.00 17.80           C  
ATOM   2157  C   PHE B  46       4.836  54.057  14.232  1.00 18.80           C  
ATOM   2158  O   PHE B  46       4.108  54.809  13.591  1.00 20.20           O  
ATOM   2159  CB  PHE B  46       7.023  55.220  14.516  1.00 16.80           C  
ATOM   2160  CG  PHE B  46       8.516  55.263  14.257  1.00 16.40           C  
ATOM   2161  CD1 PHE B  46       9.405  54.538  15.049  1.00 14.50           C  
ATOM   2162  CD2 PHE B  46       9.021  55.994  13.181  1.00 16.00           C  
ATOM   2163  CE1 PHE B  46      10.760  54.548  14.773  1.00 14.70           C  
ATOM   2164  CE2 PHE B  46      10.384  56.006  12.897  1.00 15.20           C  
ATOM   2165  CZ  PHE B  46      11.252  55.293  13.680  1.00 15.20           C  
ATOM   2166  N   ILE B  47       4.398  53.290  15.225  1.00 19.80           N  
ATOM   2167  CA  ILE B  47       3.005  53.263  15.678  1.00 20.50           C  
ATOM   2168  C   ILE B  47       2.991  53.614  17.186  1.00 20.60           C  
ATOM   2169  O   ILE B  47       3.790  53.077  17.959  1.00 19.80           O  
ATOM   2170  CB  ILE B  47       2.410  51.844  15.500  1.00 20.50           C  
ATOM   2171  CG1 ILE B  47       2.582  51.371  14.044  1.00 21.80           C  
ATOM   2172  CG2 ILE B  47       0.961  51.839  15.905  1.00 20.00           C  
ATOM   2173  CD1 ILE B  47       1.684  52.069  13.065  1.00 22.30           C  
ATOM   2174  N   CYS B  48       2.142  54.559  17.578  1.00 21.20           N  
ATOM   2175  CA  CYS B  48       1.873  54.792  19.002  1.00 22.00           C  
ATOM   2176  C   CYS B  48       0.930  53.684  19.514  1.00 22.70           C  
ATOM   2177  O   CYS B  48      -0.204  53.502  19.006  1.00 22.30           O  
ATOM   2178  CB  CYS B  48       1.243  56.173  19.216  1.00 21.90           C  
ATOM   2179  SG  CYS B  48       1.010  56.583  20.972  1.00 21.40           S  
ATOM   2180  N   THR B  49       1.464  52.853  20.403  1.00 23.20           N  
ATOM   2181  CA  THR B  49       0.721  51.694  20.874  1.00 24.40           C  
ATOM   2182  C   THR B  49      -0.273  52.042  21.969  1.00 25.30           C  
ATOM   2183  O   THR B  49      -1.137  51.233  22.310  1.00 26.40           O  
ATOM   2184  CB  THR B  49       1.651  50.573  21.376  1.00 23.70           C  
ATOM   2185  OG1 THR B  49       2.550  51.097  22.354  1.00 24.70           O  
ATOM   2186  CG2 THR B  49       2.462  49.965  20.199  1.00 24.20           C  
ATOM   2187  N   THR B  50      -0.262  53.288  22.415  1.00 25.50           N  
ATOM   2188  CA  THR B  50      -1.051  53.641  23.585  1.00 25.80           C  
ATOM   2189  C   THR B  50      -2.138  54.628  23.244  1.00 26.10           C  
ATOM   2190  O   THR B  50      -2.666  55.302  24.121  1.00 27.50           O  
ATOM   2191  CB  THR B  50      -0.166  54.243  24.691  1.00 24.90           C  
ATOM   2192  OG1 THR B  50       0.605  55.329  24.149  1.00 24.60           O  
ATOM   2193  CG2 THR B  50       0.756  53.189  25.255  1.00 24.80           C  
ATOM   2194  N   GLY B  51      -2.434  54.782  21.965  1.00 26.20           N  
ATOM   2195  CA  GLY B  51      -3.455  55.741  21.592  1.00 25.70           C  
ATOM   2196  C   GLY B  51      -2.889  56.790  20.668  1.00 26.30           C  
ATOM   2197  O   GLY B  51      -2.251  56.461  19.657  1.00 26.80           O  
ATOM   2198  N   LYS B  52      -3.230  58.044  20.922  1.00 25.90           N  
ATOM   2199  CA  LYS B  52      -2.658  59.120  20.146  1.00 26.00           C  
ATOM   2200  C   LYS B  52      -1.418  59.702  20.815  1.00 26.10           C  
ATOM   2201  O   LYS B  52      -1.376  59.852  22.042  1.00 26.00           O  
ATOM   2202  CB  LYS B  52      -3.695  60.198  19.885  1.00 26.20           C  
ATOM   2203  CG  LYS B  52      -4.284  60.058  18.485  1.00 50.20           C  
ATOM   2204  CD  LYS B  52      -5.799  60.208  18.455  1.00 55.90           C  
ATOM   2205  CE  LYS B  52      -6.209  61.677  18.594  1.00 91.10           C  
ATOM   2206  NZ  LYS B  52      -5.982  62.187  19.982  1.00100.00           N  
ATOM   2207  N   LEU B  53      -0.372  59.915  20.019  1.00 24.90           N  
ATOM   2208  CA  LEU B  53       0.881  60.475  20.512  1.00 24.30           C  
ATOM   2209  C   LEU B  53       0.586  61.865  21.091  1.00 23.90           C  
ATOM   2210  O   LEU B  53      -0.093  62.689  20.449  1.00 22.70           O  
ATOM   2211  CB  LEU B  53       1.877  60.614  19.361  1.00 24.00           C  
ATOM   2212  CG  LEU B  53       3.350  60.213  19.430  1.00 24.00           C  
ATOM   2213  CD1 LEU B  53       4.181  61.416  19.116  1.00 23.60           C  
ATOM   2214  CD2 LEU B  53       3.738  59.590  20.713  1.00 22.90           C  
ATOM   2215  N   PRO B  54       1.048  62.119  22.341  1.00 23.40           N  
ATOM   2216  CA  PRO B  54       0.715  63.397  23.004  1.00 22.80           C  
ATOM   2217  C   PRO B  54       1.606  64.578  22.586  1.00 22.30           C  
ATOM   2218  O   PRO B  54       1.344  65.731  22.978  1.00 23.20           O  
ATOM   2219  CB  PRO B  54       0.855  63.078  24.500  1.00 22.70           C  
ATOM   2220  CG  PRO B  54       1.779  61.904  24.554  1.00 22.80           C  
ATOM   2221  CD  PRO B  54       1.524  61.102  23.304  1.00 22.90           C  
ATOM   2222  N   VAL B  55       2.722  64.282  21.911  1.00 20.30           N  
ATOM   2223  CA  VAL B  55       3.577  65.317  21.347  1.00 19.00           C  
ATOM   2224  C   VAL B  55       3.624  65.183  19.799  1.00 18.20           C  
ATOM   2225  O   VAL B  55       3.272  64.134  19.244  1.00 17.50           O  
ATOM   2226  CB  VAL B  55       5.009  65.250  21.939  1.00 18.60           C  
ATOM   2227  CG1 VAL B  55       4.980  65.668  23.396  1.00 19.40           C  
ATOM   2228  CG2 VAL B  55       5.589  63.871  21.802  1.00 19.10           C  
ATOM   2229  N   PRO B  56       4.024  66.246  19.087  1.00 17.30           N  
ATOM   2230  CA  PRO B  56       4.126  66.146  17.617  1.00 16.20           C  
ATOM   2231  C   PRO B  56       5.133  65.077  17.204  1.00 15.60           C  
ATOM   2232  O   PRO B  56       6.239  65.008  17.743  1.00 15.90           O  
ATOM   2233  CB  PRO B  56       4.594  67.530  17.198  1.00 16.40           C  
ATOM   2234  CG  PRO B  56       4.200  68.421  18.358  1.00 17.90           C  
ATOM   2235  CD  PRO B  56       4.413  67.581  19.572  1.00 17.40           C  
ATOM   2236  N   TRP B  57       4.802  64.300  16.181  1.00 15.00           N  
ATOM   2237  CA  TRP B  57       5.738  63.306  15.664  1.00 13.20           C  
ATOM   2238  C   TRP B  57       7.140  63.815  15.306  1.00 12.80           C  
ATOM   2239  O   TRP B  57       8.133  63.143  15.589  1.00 12.40           O  
ATOM   2240  CB  TRP B  57       5.119  62.583  14.476  1.00 12.10           C  
ATOM   2241  CG  TRP B  57       4.116  61.579  14.875  1.00 11.60           C  
ATOM   2242  CD1 TRP B  57       2.764  61.706  14.801  1.00 11.40           C  
ATOM   2243  CD2 TRP B  57       4.386  60.250  15.348  1.00 12.10           C  
ATOM   2244  NE1 TRP B  57       2.170  60.528  15.178  1.00 12.60           N  
ATOM   2245  CE2 TRP B  57       3.139  59.622  15.537  1.00 12.20           C  
ATOM   2246  CE3 TRP B  57       5.559  59.528  15.638  1.00 11.90           C  
ATOM   2247  CZ2 TRP B  57       3.025  58.306  16.011  1.00 13.20           C  
ATOM   2248  CZ3 TRP B  57       5.445  58.220  16.109  1.00 12.90           C  
ATOM   2249  CH2 TRP B  57       4.185  57.623  16.292  1.00 13.50           C  
ATOM   2250  N   PRO B  58       7.252  65.008  14.703  1.00 13.30           N  
ATOM   2251  CA  PRO B  58       8.589  65.480  14.322  1.00 13.90           C  
ATOM   2252  C   PRO B  58       9.587  65.672  15.471  1.00 14.40           C  
ATOM   2253  O   PRO B  58      10.807  65.526  15.246  1.00 15.00           O  
ATOM   2254  CB  PRO B  58       8.316  66.794  13.595  1.00 14.00           C  
ATOM   2255  CG  PRO B  58       6.910  66.651  13.088  1.00 14.70           C  
ATOM   2256  CD  PRO B  58       6.200  65.894  14.179  1.00 13.90           C  
ATOM   2257  N   THR B  59       9.086  65.902  16.701  1.00 13.70           N  
ATOM   2258  CA  THR B  59       9.955  66.080  17.884  1.00 12.80           C  
ATOM   2259  C   THR B  59      10.719  64.820  18.304  1.00 12.50           C  
ATOM   2260  O   THR B  59      11.755  64.910  18.977  1.00 13.30           O  
ATOM   2261  CB  THR B  59       9.156  66.636  19.128  1.00 12.10           C  
ATOM   2262  OG1 THR B  59       8.237  65.650  19.639  1.00 12.30           O  
ATOM   2263  CG2 THR B  59       8.403  67.902  18.750  1.00 11.60           C  
ATOM   2264  N   LEU B  60      10.247  63.659  17.858  1.00 11.70           N  
ATOM   2265  CA  LEU B  60      10.829  62.372  18.210  1.00 12.10           C  
ATOM   2266  C   LEU B  60      11.763  61.737  17.160  1.00 11.50           C  
ATOM   2267  O   LEU B  60      12.326  60.675  17.411  1.00 10.70           O  
ATOM   2268  CB  LEU B  60       9.711  61.378  18.534  1.00 13.40           C  
ATOM   2269  CG  LEU B  60       8.891  61.777  19.755  1.00 14.10           C  
ATOM   2270  CD1 LEU B  60       7.741  60.788  19.945  1.00 14.40           C  
ATOM   2271  CD2 LEU B  60       9.835  61.786  20.951  1.00 14.90           C  
ATOM   2272  N   VAL B  61      11.854  62.336  15.961  1.00 12.10           N  
ATOM   2273  CA  VAL B  61      12.642  61.768  14.841  1.00 11.50           C  
ATOM   2274  C   VAL B  61      14.103  61.459  15.223  1.00 10.90           C  
ATOM   2275  O   VAL B  61      14.573  60.344  15.000  1.00 10.20           O  
ATOM   2276  CB  VAL B  61      12.608  62.723  13.550  1.00 11.50           C  
ATOM   2277  CG1 VAL B  61      13.751  62.382  12.566  1.00 10.70           C  
ATOM   2278  CG2 VAL B  61      11.271  62.586  12.841  1.00 12.10           C  
ATOM   2279  N   THR B  62      14.796  62.418  15.852  1.00 11.20           N  
ATOM   2280  CA  THR B  62      16.196  62.212  16.253  1.00 12.30           C  
ATOM   2281  C   THR B  62      16.353  61.123  17.320  1.00 12.00           C  
ATOM   2282  O   THR B  62      17.355  60.432  17.350  1.00 11.10           O  
ATOM   2283  CB  THR B  62      16.869  63.515  16.806  1.00 13.10           C  
ATOM   2284  OG1 THR B  62      16.039  64.080  17.838  1.00 13.40           O  
ATOM   2285  CG2 THR B  62      17.064  64.556  15.667  1.00 12.90           C  
ATOM   2286  N   THR B  63      15.340  60.964  18.161  1.00 12.20           N  
ATOM   2287  CA  THR B  63      15.343  59.940  19.199  1.00 13.30           C  
ATOM   2288  C   THR B  63      15.141  58.542  18.629  1.00 13.80           C  
ATOM   2289  O   THR B  63      15.905  57.633  18.947  1.00 13.70           O  
ATOM   2290  CB  THR B  63      14.251  60.247  20.251  1.00 12.80           C  
ATOM   2291  OG1 THR B  63      14.566  61.488  20.891  1.00 13.10           O  
ATOM   2292  CG2 THR B  63      14.153  59.128  21.267  1.00 13.50           C  
ATOM   2293  N   PHE B  64      14.137  58.378  17.766  1.00 14.10           N  
ATOM   2294  CA  PHE B  64      13.948  57.113  17.051  1.00 14.20           C  
ATOM   2295  C   PHE B  64      15.130  56.780  16.134  1.00 13.70           C  
ATOM   2296  O   PHE B  64      15.508  55.612  15.986  1.00 14.50           O  
ATOM   2297  CB  PHE B  64      12.708  57.194  16.177  1.00 13.90           C  
ATOM   2298  CG  PHE B  64      11.449  57.403  16.929  1.00 15.60           C  
ATOM   2299  CD1 PHE B  64      11.201  56.711  18.107  1.00 16.50           C  
ATOM   2300  CD2 PHE B  64      10.440  58.163  16.379  1.00 15.20           C  
ATOM   2301  CE1 PHE B  64       9.963  56.768  18.710  1.00 17.00           C  
ATOM   2302  CE2 PHE B  64       9.199  58.226  16.977  1.00 17.10           C  
ATOM   2303  CZ  PHE B  64       8.961  57.526  18.150  1.00 16.50           C  
ATOM   2304  N   SER B  65      15.629  57.791  15.431  1.00 13.70           N  
ATOM   2305  CA  SER B  65      16.633  57.579  14.395  1.00 13.60           C  
ATOM   2306  C   SER B  65      17.796  58.481  14.749  1.00 12.50           C  
ATOM   2307  CB  SER B  65      16.007  57.862  13.008  1.00 14.50           C  
ATOM   2308  OG  SER B  65      16.888  57.489  11.942  1.00 14.30           O  
ATOM   2309  N   TYR B  66      17.888  59.656  14.110  1.00 12.80           N  
ATOM   2310  CA  TYR B  66      19.079  60.232  14.715  1.00 13.00           C  
ATOM   2311  C   TYR B  66      19.565  59.260  15.732  1.00 13.20           C  
ATOM   2312  O   TYR B  66      20.514  59.369  16.489  1.00 13.60           O  
ATOM   2313  CB  TYR B  66      19.773  61.438  14.512  1.00 12.80           C  
ATOM   2314  CG  TYR B  66      19.407  62.490  13.667  1.00 13.10           C  
ATOM   2315  CD1 TYR B  66      18.318  62.383  12.784  1.00 13.20           C  
ATOM   2316  CD2 TYR B  66      20.134  63.667  13.674  1.00 12.60           C  
ATOM   2317  CE1 TYR B  66      17.982  63.429  11.927  1.00 13.60           C  
ATOM   2318  CE2 TYR B  66      19.821  64.711  12.837  1.00 14.50           C  
ATOM   2319  CZ  TYR B  66      18.755  64.602  11.949  1.00 14.90           C  
ATOM   2320  OH  TYR B  66      18.524  65.634  11.039  1.00 14.40           O  
ATOM   2321  N   GLY B  67      18.723  58.250  15.704  1.00 13.30           N  
ATOM   2322  CA  GLY B  67      18.775  57.188  16.694  1.00 13.40           C  
ATOM   2323  C   GLY B  67      19.580  55.971  16.349  1.00 14.70           C  
ATOM   2324  O   GLY B  67      19.975  55.227  17.243  1.00 14.70           O  
ATOM   2325  N   VAL B  68      19.783  55.712  15.057  1.00 14.60           N  
ATOM   2326  CA  VAL B  68      20.514  54.516  14.665  1.00 14.20           C  
ATOM   2327  C   VAL B  68      21.576  54.893  13.654  1.00 14.70           C  
ATOM   2328  O   VAL B  68      21.596  54.391  12.534  1.00 14.20           O  
ATOM   2329  CB  VAL B  68      19.547  53.401  14.126  1.00 15.10           C  
ATOM   2330  CG1 VAL B  68      18.794  52.774  15.302  1.00 15.50           C  
ATOM   2331  CG2 VAL B  68      18.518  53.959  13.121  1.00 14.90           C  
ATOM   2332  N   GLN B  69      22.482  55.767  14.073  1.00 14.60           N  
ATOM   2333  CA  GLN B  69      23.418  56.377  13.160  1.00 15.10           C  
ATOM   2334  C   GLN B  69      24.508  55.407  12.711  1.00 15.70           C  
ATOM   2335  O   GLN B  69      25.396  55.771  11.926  1.00 16.70           O  
ATOM   2336  CB  GLN B  69      24.028  57.608  13.801  1.00 14.50           C  
ATOM   2337  CG  GLN B  69      23.008  58.677  14.067  1.00 11.40           C  
ATOM   2338  CD  GLN B  69      23.520  59.753  15.016  1.00 14.50           C  
ATOM   2339  OE1 GLN B  69      24.056  59.440  16.079  1.00 18.60           O  
ATOM   2340  NE2 GLN B  69      23.338  61.020  14.651  1.00 13.50           N  
ATOM   2341  N   CYS B  70      24.408  54.155  13.138  1.00 16.20           N  
ATOM   2342  CA  CYS B  70      25.283  53.117  12.621  1.00 16.90           C  
ATOM   2343  C   CYS B  70      24.936  52.762  11.166  1.00 17.60           C  
ATOM   2344  O   CYS B  70      25.704  52.055  10.525  1.00 18.60           O  
ATOM   2345  CB  CYS B  70      25.218  51.859  13.479  1.00 15.10           C  
ATOM   2346  SG  CYS B  70      23.592  51.345  13.824  1.00 18.80           S  
ATOM   2347  N   PHE B  71      23.816  53.274  10.649  1.00 17.90           N  
ATOM   2348  CA  PHE B  71      23.446  53.071   9.232  1.00 18.80           C  
ATOM   2349  C   PHE B  71      23.893  54.182   8.302  1.00 19.60           C  
ATOM   2350  O   PHE B  71      23.465  54.221   7.151  1.00 20.80           O  
ATOM   2351  CB  PHE B  71      21.941  52.870   9.070  1.00 17.20           C  
ATOM   2352  CG  PHE B  71      21.462  51.554   9.579  1.00 17.00           C  
ATOM   2353  CD1 PHE B  71      21.762  50.380   8.891  1.00 16.30           C  
ATOM   2354  CD2 PHE B  71      20.772  51.473  10.788  1.00 15.60           C  
ATOM   2355  CE1 PHE B  71      21.387  49.141   9.407  1.00 16.90           C  
ATOM   2356  CE2 PHE B  71      20.389  50.246  11.319  1.00 16.00           C  
ATOM   2357  CZ  PHE B  71      20.691  49.072  10.641  1.00 17.60           C  
ATOM   2358  N   SER B  72      24.682  55.124   8.821  1.00 20.20           N  
ATOM   2359  CA  SER B  72      25.331  56.123   7.996  1.00 20.40           C  
ATOM   2360  C   SER B  72      26.243  55.440   6.995  1.00 20.90           C  
ATOM   2361  O   SER B  72      26.912  54.440   7.306  1.00 20.80           O  
ATOM   2362  CB  SER B  72      26.173  57.065   8.851  1.00 20.90           C  
ATOM   2363  OG  SER B  72      25.375  57.591   9.897  1.00 23.90           O  
ATOM   2364  N   ARG B  73      26.309  56.024   5.809  1.00 21.40           N  
ATOM   2365  CA  ARG B  73      27.312  55.662   4.849  1.00 22.20           C  
ATOM   2366  C   ARG B  73      28.581  56.426   5.156  1.00 21.10           C  
ATOM   2367  O   ARG B  73      28.564  57.648   5.241  1.00 20.10           O  
ATOM   2368  CB  ARG B  73      26.826  56.012   3.452  1.00 25.20           C  
ATOM   2369  CG  ARG B  73      27.901  55.870   2.429  1.00 29.70           C  
ATOM   2370  CD  ARG B  73      27.293  55.760   1.052  1.00 35.20           C  
ATOM   2371  NE  ARG B  73      28.213  55.047   0.171  1.00 40.30           N  
ATOM   2372  CZ  ARG B  73      29.219  55.635  -0.477  1.00 42.40           C  
ATOM   2373  NH1 ARG B  73      29.245  56.966  -0.615  1.00 43.90           N  
ATOM   2374  NH2 ARG B  73      30.149  54.892  -1.070  1.00 43.90           N  
ATOM   2375  N   TYR B  74      29.670  55.697   5.390  1.00 21.50           N  
ATOM   2376  CA  TYR B  74      30.992  56.322   5.521  1.00 22.10           C  
ATOM   2377  C   TYR B  74      31.775  56.051   4.235  1.00 23.00           C  
ATOM   2378  O   TYR B  74      31.917  54.897   3.831  1.00 22.70           O  
ATOM   2379  CB  TYR B  74      31.754  55.743   6.733  1.00 21.80           C  
ATOM   2380  CG  TYR B  74      31.480  56.471   8.047  1.00 20.50           C  
ATOM   2381  CD1 TYR B  74      30.231  56.387   8.673  1.00 19.80           C  
ATOM   2382  CD2 TYR B  74      32.447  57.284   8.624  1.00 20.30           C  
ATOM   2383  CE1 TYR B  74      29.953  57.103   9.846  1.00 19.50           C  
ATOM   2384  CE2 TYR B  74      32.174  57.985   9.813  1.00 20.50           C  
ATOM   2385  CZ  TYR B  74      30.931  57.892  10.410  1.00 19.10           C  
ATOM   2386  OH  TYR B  74      30.660  58.655  11.532  1.00 18.80           O  
ATOM   2387  N   PRO B  75      32.194  57.116   3.531  1.00 24.00           N  
ATOM   2388  CA  PRO B  75      33.060  56.933   2.362  1.00 25.70           C  
ATOM   2389  C   PRO B  75      34.335  56.174   2.755  1.00 27.30           C  
ATOM   2390  O   PRO B  75      34.860  56.337   3.865  1.00 27.80           O  
ATOM   2391  CB  PRO B  75      33.368  58.363   1.908  1.00 25.40           C  
ATOM   2392  CG  PRO B  75      32.414  59.243   2.627  1.00 25.30           C  
ATOM   2393  CD  PRO B  75      32.085  58.532   3.910  1.00 24.50           C  
ATOM   2394  N   ASP B  76      34.872  55.406   1.817  1.00 28.50           N  
ATOM   2395  CA  ASP B  76      35.972  54.492   2.097  1.00 29.60           C  
ATOM   2396  C   ASP B  76      37.122  55.160   2.836  1.00 29.70           C  
ATOM   2397  O   ASP B  76      37.798  54.530   3.642  1.00 30.60           O  
ATOM   2398  CB  ASP B  76      36.495  53.885   0.789  1.00 30.90           C  
ATOM   2399  CG  ASP B  76      35.593  52.818   0.246  1.00 42.70           C  
ATOM   2400  OD1 ASP B  76      34.358  53.034   0.217  1.00 57.70           O  
ATOM   2401  OD2 ASP B  76      36.129  51.764  -0.161  1.00 64.60           O  
ATOM   2402  N   HIS B  77      37.354  56.432   2.549  1.00 29.70           N  
ATOM   2403  CA  HIS B  77      38.532  57.091   3.079  1.00 30.50           C  
ATOM   2404  C   HIS B  77      38.325  57.466   4.554  1.00 30.80           C  
ATOM   2405  O   HIS B  77      39.289  57.736   5.272  1.00 31.20           O  
ATOM   2406  CB  HIS B  77      38.877  58.334   2.241  1.00 30.20           C  
ATOM   2407  CG  HIS B  77      38.005  59.571   2.294  1.00 30.90           C  
ATOM   2408  ND1 HIS B  77      36.774  59.675   1.636  1.00 31.10           N  
ATOM   2409  CD2 HIS B  77      38.299  60.779   3.006  1.00 31.30           C  
ATOM   2410  CE1 HIS B  77      36.328  60.948   1.943  1.00 31.50           C  
ATOM   2411  NE2 HIS B  77      37.168  61.610   2.709  1.00 31.40           N  
ATOM   2412  N   MET B  78      37.072  57.513   5.004  1.00 30.80           N  
ATOM   2413  CA  MET B  78      36.833  57.794   6.413  1.00 30.50           C  
ATOM   2414  C   MET B  78      36.125  56.691   7.171  1.00 29.90           C  
ATOM   2415  O   MET B  78      35.656  56.921   8.271  1.00 30.10           O  
ATOM   2416  CB  MET B  78      36.141  59.154   6.626  1.00 30.80           C  
ATOM   2417  CG  MET B  78      35.056  59.505   5.670  1.00 30.40           C  
ATOM   2418  SD  MET B  78      34.581  61.221   5.816  1.00 32.90           S  
ATOM   2419  CE  MET B  78      34.046  61.307   7.602  1.00 30.20           C  
ATOM   2420  N   LYS B  79      36.343  55.456   6.740  1.00 29.20           N  
ATOM   2421  CA  LYS B  79      35.826  54.295   7.460  1.00 29.70           C  
ATOM   2422  C   LYS B  79      36.568  54.051   8.804  1.00 30.10           C  
ATOM   2423  O   LYS B  79      36.065  53.368   9.717  1.00 29.50           O  
ATOM   2424  CB  LYS B  79      35.892  53.051   6.560  1.00 29.60           C  
ATOM   2425  CG  LYS B  79      34.800  53.030   5.479  1.00 31.60           C  
ATOM   2426  CD  LYS B  79      34.603  51.632   4.909  1.00 34.30           C  
ATOM   2427  CE  LYS B  79      33.535  51.624   3.811  1.00 31.90           C  
ATOM   2428  NZ  LYS B  79      32.279  52.297   4.230  1.00 57.40           N  
ATOM   2429  N   ARG B  80      37.752  54.654   8.921  1.00 30.10           N  
ATOM   2430  CA  ARG B  80      38.555  54.584  10.136  1.00 29.80           C  
ATOM   2431  C   ARG B  80      37.857  55.362  11.241  1.00 28.90           C  
ATOM   2432  O   ARG B  80      38.296  55.331  12.379  1.00 29.80           O  
ATOM   2433  CB  ARG B  80      39.929  55.213   9.893  1.00 30.00           C  
ATOM   2434  CG  ARG B  80      41.076  54.410  10.432  1.00 43.00           C  
ATOM   2435  CD  ARG B  80      42.276  54.600   9.534  1.00 71.60           C  
ATOM   2436  NE  ARG B  80      41.986  54.196   8.151  1.00106.00           N  
ATOM   2437  CZ  ARG B  80      42.798  54.397   7.112  1.00109.50           C  
ATOM   2438  NH1 ARG B  80      43.935  55.072   7.268  1.00111.80           N  
ATOM   2439  NH2 ARG B  80      42.462  53.942   5.905  1.00110.30           N  
ATOM   2440  N   HIS B  81      36.902  56.196  10.862  1.00 27.20           N  
ATOM   2441  CA  HIS B  81      36.277  57.107  11.788  1.00 26.20           C  
ATOM   2442  C   HIS B  81      34.879  56.660  12.176  1.00 25.00           C  
ATOM   2443  O   HIS B  81      34.147  57.411  12.808  1.00 25.10           O  
ATOM   2444  CB  HIS B  81      36.236  58.510  11.191  1.00 27.00           C  
ATOM   2445  CG  HIS B  81      37.647  58.999  10.838  1.00 29.10           C  
ATOM   2446  ND1 HIS B  81      38.370  58.949   9.597  1.00 30.10           N  
ATOM   2447  CD2 HIS B  81      38.454  59.606  11.792  1.00 28.80           C  
ATOM   2448  CE1 HIS B  81      39.594  59.503   9.819  1.00 29.30           C  
ATOM   2449  NE2 HIS B  81      39.622  59.884  11.066  1.00 29.60           N  
ATOM   2450  N   ASP B  82      34.516  55.427  11.848  1.00 24.10           N  
ATOM   2451  CA  ASP B  82      33.151  54.979  12.093  1.00 23.30           C  
ATOM   2452  C   ASP B  82      33.038  54.344  13.490  1.00 22.70           C  
ATOM   2453  O   ASP B  82      33.035  53.121  13.628  1.00 22.10           O  
ATOM   2454  CB  ASP B  82      32.692  53.995  10.992  1.00 22.70           C  
ATOM   2455  CG  ASP B  82      31.180  53.722  11.021  1.00 25.20           C  
ATOM   2456  OD1 ASP B  82      30.477  54.107  11.981  1.00 20.50           O  
ATOM   2457  OD2 ASP B  82      30.684  53.084  10.080  1.00 25.00           O  
ATOM   2458  N   PHE B  83      32.856  55.181  14.511  1.00 22.40           N  
ATOM   2459  CA  PHE B  83      32.619  54.681  15.875  1.00 21.90           C  
ATOM   2460  C   PHE B  83      31.394  53.764  15.930  1.00 20.80           C  
ATOM   2461  O   PHE B  83      31.437  52.691  16.537  1.00 20.80           O  
ATOM   2462  CB  PHE B  83      32.445  55.868  16.845  1.00 22.90           C  
ATOM   2463  CG  PHE B  83      31.974  55.470  18.220  1.00 25.00           C  
ATOM   2464  CD1 PHE B  83      32.889  55.058  19.190  1.00 26.00           C  
ATOM   2465  CD2 PHE B  83      30.609  55.386  18.510  1.00 25.30           C  
ATOM   2466  CE1 PHE B  83      32.458  54.537  20.435  1.00 26.40           C  
ATOM   2467  CE2 PHE B  83      30.164  54.878  19.751  1.00 26.40           C  
ATOM   2468  CZ  PHE B  83      31.093  54.450  20.709  1.00 26.50           C  
ATOM   2469  N   PHE B  84      30.317  54.152  15.250  1.00 20.00           N  
ATOM   2470  CA  PHE B  84      29.031  53.487  15.426  1.00 19.40           C  
ATOM   2471  C   PHE B  84      29.043  52.010  15.080  1.00 20.10           C  
ATOM   2472  O   PHE B  84      28.482  51.190  15.811  1.00 19.90           O  
ATOM   2473  CB  PHE B  84      27.963  54.206  14.617  1.00 18.40           C  
ATOM   2474  CG  PHE B  84      27.819  55.664  14.960  1.00 17.20           C  
ATOM   2475  CD1 PHE B  84      27.045  56.061  16.060  1.00 17.80           C  
ATOM   2476  CD2 PHE B  84      28.433  56.635  14.187  1.00 16.40           C  
ATOM   2477  CE1 PHE B  84      26.878  57.389  16.372  1.00 16.80           C  
ATOM   2478  CE2 PHE B  84      28.278  57.981  14.491  1.00 17.20           C  
ATOM   2479  CZ  PHE B  84      27.495  58.365  15.588  1.00 16.90           C  
ATOM   2480  N   LYS B  85      29.666  51.653  13.957  1.00 20.40           N  
ATOM   2481  CA  LYS B  85      29.694  50.251  13.547  1.00 20.60           C  
ATOM   2482  C   LYS B  85      30.736  49.475  14.341  1.00 20.40           C  
ATOM   2483  O   LYS B  85      30.585  48.280  14.566  1.00 19.80           O  
ATOM   2484  CB  LYS B  85      29.988  50.135  12.037  1.00 20.70           C  
ATOM   2485  CG  LYS B  85      28.842  50.592  11.157  1.00 19.60           C  
ATOM   2486  CD  LYS B  85      29.204  50.429   9.694  1.00 17.50           C  
ATOM   2487  CE  LYS B  85      28.132  51.047   8.820  1.00 11.70           C  
ATOM   2488  NZ  LYS B  85      28.070  52.508   8.999  1.00 15.40           N  
ATOM   2489  N   SER B  86      31.796  50.170  14.745  1.00 21.20           N  
ATOM   2490  CA  SER B  86      32.877  49.555  15.519  1.00 22.70           C  
ATOM   2491  C   SER B  86      32.469  49.057  16.921  1.00 22.70           C  
ATOM   2492  O   SER B  86      33.039  48.074  17.409  1.00 23.30           O  
ATOM   2493  CB  SER B  86      34.073  50.513  15.630  1.00 22.80           C  
ATOM   2494  OG  SER B  86      33.874  51.488  16.643  1.00 23.90           O  
ATOM   2495  N   ALA B  87      31.377  49.603  17.460  1.00 22.30           N  
ATOM   2496  CA  ALA B  87      30.848  49.190  18.746  1.00 21.80           C  
ATOM   2497  C   ALA B  87      29.994  47.938  18.649  1.00 22.10           C  
ATOM   2498  O   ALA B  87      29.705  47.317  19.650  1.00 22.60           O  
ATOM   2499  CB  ALA B  87      30.059  50.308  19.331  1.00 21.50           C  
ATOM   2500  N   MET B  88      29.687  47.493  17.437  1.00 22.20           N  
ATOM   2501  CA  MET B  88      28.771  46.376  17.235  1.00 21.70           C  
ATOM   2502  C   MET B  88      29.542  45.044  17.220  1.00 21.90           C  
ATOM   2503  O   MET B  88      30.748  45.023  16.998  1.00 22.80           O  
ATOM   2504  CB  MET B  88      28.016  46.580  15.917  1.00 22.20           C  
ATOM   2505  CG  MET B  88      27.215  47.845  15.894  1.00 21.20           C  
ATOM   2506  SD  MET B  88      25.827  47.650  16.961  1.00 23.90           S  
ATOM   2507  CE  MET B  88      25.226  49.310  17.100  1.00 21.30           C  
ATOM   2508  N   PRO B  89      28.872  43.925  17.532  1.00 21.70           N  
ATOM   2509  CA  PRO B  89      27.452  43.731  17.860  1.00 22.60           C  
ATOM   2510  C   PRO B  89      26.950  44.156  19.267  1.00 23.40           C  
ATOM   2511  O   PRO B  89      25.726  44.200  19.489  1.00 23.80           O  
ATOM   2512  CB  PRO B  89      27.244  42.231  17.636  1.00 21.80           C  
ATOM   2513  CG  PRO B  89      28.543  41.651  17.953  1.00 20.90           C  
ATOM   2514  CD  PRO B  89      29.578  42.636  17.474  1.00 21.60           C  
ATOM   2515  N   GLU B  90      27.879  44.454  20.191  1.00 24.20           N  
ATOM   2516  CA  GLU B  90      27.557  44.626  21.623  1.00 24.00           C  
ATOM   2517  C   GLU B  90      26.800  45.926  21.804  1.00 23.50           C  
ATOM   2518  O   GLU B  90      25.870  45.995  22.607  1.00 24.20           O  
ATOM   2519  CB  GLU B  90      28.831  44.652  22.487  1.00 25.30           C  
ATOM   2520  CG  GLU B  90      29.666  43.369  22.465  1.00 36.10           C  
ATOM   2521  CD  GLU B  90      30.992  43.537  21.705  1.00 51.70           C  
ATOM   2522  OE1 GLU B  90      30.958  43.729  20.457  1.00 51.30           O  
ATOM   2523  OE2 GLU B  90      32.069  43.483  22.355  1.00 54.60           O  
ATOM   2524  N   GLY B  91      27.180  46.935  21.025  1.00 22.30           N  
ATOM   2525  CA  GLY B  91      26.351  48.104  20.901  1.00 21.20           C  
ATOM   2526  C   GLY B  91      26.902  49.258  21.712  1.00 20.60           C  
ATOM   2527  O   GLY B  91      28.050  49.251  22.157  1.00 20.20           O  
ATOM   2528  N   TYR B  92      26.099  50.306  21.798  1.00 19.70           N  
ATOM   2529  CA  TYR B  92      26.486  51.518  22.472  1.00 19.40           C  
ATOM   2530  C   TYR B  92      25.270  52.082  23.149  1.00 19.00           C  
ATOM   2531  O   TYR B  92      24.123  51.730  22.842  1.00 19.00           O  
ATOM   2532  CB  TYR B  92      27.090  52.562  21.502  1.00 19.90           C  
ATOM   2533  CG  TYR B  92      26.271  52.921  20.255  1.00 20.30           C  
ATOM   2534  CD1 TYR B  92      25.257  53.882  20.306  1.00 19.80           C  
ATOM   2535  CD2 TYR B  92      26.587  52.362  18.991  1.00 20.30           C  
ATOM   2536  CE1 TYR B  92      24.571  54.283  19.116  1.00 20.70           C  
ATOM   2537  CE2 TYR B  92      25.919  52.773  17.815  1.00 19.90           C  
ATOM   2538  CZ  TYR B  92      24.916  53.717  17.881  1.00 20.00           C  
ATOM   2539  OH  TYR B  92      24.199  54.050  16.749  1.00 20.10           O  
ATOM   2540  N   VAL B  93      25.535  52.866  24.176  1.00 18.90           N  
ATOM   2541  CA  VAL B  93      24.491  53.596  24.851  1.00 17.50           C  
ATOM   2542  C   VAL B  93      24.471  54.981  24.255  1.00 16.30           C  
ATOM   2543  O   VAL B  93      25.507  55.605  24.105  1.00 16.80           O  
ATOM   2544  CB  VAL B  93      24.777  53.681  26.385  1.00 16.90           C  
ATOM   2545  CG1 VAL B  93      23.821  54.646  27.032  1.00 15.80           C  
ATOM   2546  CG2 VAL B  93      24.600  52.324  26.970  1.00 16.70           C  
ATOM   2547  N   GLN B  94      23.287  55.491  23.971  1.00 16.60           N  
ATOM   2548  CA  GLN B  94      23.190  56.817  23.434  1.00 16.50           C  
ATOM   2549  C   GLN B  94      22.387  57.613  24.421  1.00 15.80           C  
ATOM   2550  O   GLN B  94      21.255  57.279  24.702  1.00 16.90           O  
ATOM   2551  CB  GLN B  94      22.494  56.791  22.055  1.00 15.50           C  
ATOM   2552  CG  GLN B  94      22.306  58.162  21.444  1.00 13.30           C  
ATOM   2553  CD  GLN B  94      22.121  58.120  19.921  1.00 23.10           C  
ATOM   2554  OE1 GLN B  94      22.955  57.577  19.206  1.00 14.00           O  
ATOM   2555  NE2 GLN B  94      21.017  58.666  19.442  1.00 13.80           N  
ATOM   2556  N   GLU B  95      22.921  58.740  24.844  1.00 16.40           N  
ATOM   2557  CA  GLU B  95      22.174  59.597  25.744  1.00 17.30           C  
ATOM   2558  C   GLU B  95      22.121  61.007  25.206  1.00 16.30           C  
ATOM   2559  O   GLU B  95      23.125  61.521  24.733  1.00 17.00           O  
ATOM   2560  CB  GLU B  95      22.834  59.570  27.149  1.00 17.50           C  
ATOM   2561  CG  GLU B  95      23.077  58.145  27.697  1.00 24.40           C  
ATOM   2562  CD  GLU B  95      23.899  58.125  28.991  1.00 36.70           C  
ATOM   2563  OE1 GLU B  95      25.139  58.232  28.925  1.00 37.40           O  
ATOM   2564  OE2 GLU B  95      23.292  58.018  30.073  1.00 41.50           O  
ATOM   2565  N   ARG B  96      20.998  61.672  25.433  1.00 16.20           N  
ATOM   2566  CA  ARG B  96      20.829  63.058  25.044  1.00 16.30           C  
ATOM   2567  C   ARG B  96      20.105  63.867  26.100  1.00 16.40           C  
ATOM   2568  O   ARG B  96      19.178  63.382  26.740  1.00 16.30           O  
ATOM   2569  CB  ARG B  96      20.008  63.186  23.740  1.00 16.80           C  
ATOM   2570  CG  ARG B  96      20.673  62.616  22.474  1.00 15.90           C  
ATOM   2571  CD  ARG B  96      20.262  63.424  21.230  1.00 16.30           C  
ATOM   2572  NE  ARG B  96      20.790  62.831  19.995  1.00 15.90           N  
ATOM   2573  CZ  ARG B  96      20.125  61.983  19.209  1.00 15.60           C  
ATOM   2574  NH1 ARG B  96      18.901  61.582  19.526  1.00 13.60           N  
ATOM   2575  NH2 ARG B  96      20.692  61.555  18.082  1.00 16.30           N  
ATOM   2576  N   THR B  97      20.377  65.163  26.067  1.00 16.90           N  
ATOM   2577  CA  THR B  97      19.419  66.182  26.454  1.00 17.80           C  
ATOM   2578  C   THR B  97      19.104  67.032  25.225  1.00 17.80           C  
ATOM   2579  O   THR B  97      20.007  67.504  24.536  1.00 17.60           O  
ATOM   2580  CB  THR B  97      19.997  67.102  27.557  1.00 18.90           C  
ATOM   2581  OG1 THR B  97      20.387  66.290  28.672  1.00 19.50           O  
ATOM   2582  CG2 THR B  97      18.954  68.084  28.022  1.00 20.40           C  
ATOM   2583  N   ILE B  98      17.820  67.265  25.013  1.00 17.10           N  
ATOM   2584  CA  ILE B  98      17.342  68.032  23.898  1.00 18.00           C  
ATOM   2585  C   ILE B  98      16.607  69.218  24.487  1.00 18.60           C  
ATOM   2586  O   ILE B  98      15.634  69.055  25.223  1.00 19.20           O  
ATOM   2587  CB  ILE B  98      16.370  67.198  23.040  1.00 16.80           C  
ATOM   2588  CG1 ILE B  98      17.068  65.928  22.545  1.00 16.80           C  
ATOM   2589  CG2 ILE B  98      15.899  68.006  21.865  1.00 18.60           C  
ATOM   2590  CD1 ILE B  98      16.134  64.921  21.894  1.00 17.70           C  
ATOM   2591  N   PHE B  99      17.105  70.414  24.213  1.00 20.10           N  
ATOM   2592  CA  PHE B  99      16.496  71.636  24.731  1.00 21.40           C  
ATOM   2593  C   PHE B  99      15.689  72.333  23.654  1.00 21.20           C  
ATOM   2594  O   PHE B  99      16.276  72.909  22.738  1.00 22.00           O  
ATOM   2595  CB  PHE B  99      17.584  72.600  25.228  1.00 22.40           C  
ATOM   2596  CG  PHE B  99      18.525  72.004  26.254  1.00 24.10           C  
ATOM   2597  CD1 PHE B  99      18.249  72.111  27.599  1.00 25.30           C  
ATOM   2598  CD2 PHE B  99      19.742  71.451  25.879  1.00 24.70           C  
ATOM   2599  CE1 PHE B  99      19.185  71.679  28.558  1.00 25.40           C  
ATOM   2600  CE2 PHE B  99      20.665  71.026  26.823  1.00 25.50           C  
ATOM   2601  CZ  PHE B  99      20.387  71.142  28.164  1.00 25.20           C  
ATOM   2602  N   PHE B 100      14.368  72.345  23.790  1.00 21.40           N  
ATOM   2603  CA  PHE B 100      13.515  73.167  22.924  1.00 22.60           C  
ATOM   2604  C   PHE B 100      13.502  74.632  23.361  1.00 24.20           C  
ATOM   2605  O   PHE B 100      13.141  74.950  24.505  1.00 24.30           O  
ATOM   2606  CB  PHE B 100      12.095  72.615  22.896  1.00 22.20           C  
ATOM   2607  CG  PHE B 100      12.000  71.242  22.292  1.00 22.00           C  
ATOM   2608  CD1 PHE B 100      12.236  70.121  23.052  1.00 21.20           C  
ATOM   2609  CD2 PHE B 100      11.736  71.086  20.945  1.00 21.80           C  
ATOM   2610  CE1 PHE B 100      12.219  68.856  22.480  1.00 21.80           C  
ATOM   2611  CE2 PHE B 100      11.713  69.850  20.372  1.00 21.50           C  
ATOM   2612  CZ  PHE B 100      11.959  68.727  21.129  1.00 21.50           C  
ATOM   2613  N   LYS B 101      13.951  75.520  22.477  1.00 24.60           N  
ATOM   2614  CA  LYS B 101      14.182  76.903  22.858  1.00 25.30           C  
ATOM   2615  C   LYS B 101      12.863  77.564  23.291  1.00 25.80           C  
ATOM   2616  O   LYS B 101      11.820  77.420  22.631  1.00 25.70           O  
ATOM   2617  CB  LYS B 101      14.824  77.664  21.701  1.00 25.30           C  
ATOM   2618  CG  LYS B 101      15.458  78.977  22.093  1.00 31.60           C  
ATOM   2619  CD  LYS B 101      15.452  79.925  20.911  1.00 51.70           C  
ATOM   2620  CE  LYS B 101      15.757  81.365  21.319  1.00 71.90           C  
ATOM   2621  NZ  LYS B 101      14.567  82.129  21.823  1.00 74.10           N  
ATOM   2622  N   ASP B 102      12.896  78.177  24.481  1.00 26.60           N  
ATOM   2623  CA  ASP B 102      11.717  78.803  25.106  1.00 26.00           C  
ATOM   2624  C   ASP B 102      10.678  77.778  25.502  1.00 25.30           C  
ATOM   2625  O   ASP B 102       9.491  78.093  25.553  1.00 26.00           O  
ATOM   2626  CB  ASP B 102      11.053  79.846  24.174  1.00 27.90           C  
ATOM   2627  CG  ASP B 102      11.959  81.057  23.890  1.00 50.80           C  
ATOM   2628  OD1 ASP B 102      12.779  81.418  24.783  1.00 49.60           O  
ATOM   2629  OD2 ASP B 102      11.844  81.638  22.773  1.00 57.40           O  
ATOM   2630  N   ASP B 103      11.113  76.549  25.766  1.00 23.70           N  
ATOM   2631  CA  ASP B 103      10.178  75.504  26.125  1.00 21.90           C  
ATOM   2632  C   ASP B 103      10.889  74.404  26.928  1.00 20.70           C  
ATOM   2633  O   ASP B 103      11.963  74.617  27.484  1.00 19.90           O  
ATOM   2634  CB  ASP B 103       9.523  74.963  24.846  1.00 22.00           C  
ATOM   2635  CG  ASP B 103       8.049  74.611  25.035  1.00 25.90           C  
ATOM   2636  OD1 ASP B 103       7.669  74.153  26.128  1.00 29.00           O  
ATOM   2637  OD2 ASP B 103       7.263  74.749  24.084  1.00 27.90           O  
ATOM   2638  N   GLY B 104      10.311  73.216  26.945  1.00 19.40           N  
ATOM   2639  CA  GLY B 104      10.860  72.151  27.752  1.00 19.40           C  
ATOM   2640  C   GLY B 104      12.035  71.414  27.184  1.00 18.80           C  
ATOM   2641  O   GLY B 104      12.682  71.882  26.266  1.00 20.00           O  
ATOM   2642  N   ASN B 105      12.315  70.249  27.735  1.00 17.50           N  
ATOM   2643  CA  ASN B 105      13.471  69.509  27.312  1.00 18.00           C  
ATOM   2644  C   ASN B 105      13.148  68.036  27.421  1.00 18.10           C  
ATOM   2645  O   ASN B 105      12.199  67.667  28.107  1.00 18.20           O  
ATOM   2646  CB  ASN B 105      14.683  69.846  28.179  1.00 18.60           C  
ATOM   2647  CG  ASN B 105      14.447  69.557  29.658  1.00 25.80           C  
ATOM   2648  OD1 ASN B 105      14.690  68.458  30.136  1.00 31.80           O  
ATOM   2649  ND2 ASN B 105      13.929  70.526  30.362  1.00 19.60           N  
ATOM   2650  N   TYR B 106      13.805  67.238  26.581  1.00 17.80           N  
ATOM   2651  CA  TYR B 106      13.708  65.780  26.621  1.00 17.30           C  
ATOM   2652  C   TYR B 106      15.036  65.291  27.146  1.00 16.90           C  
ATOM   2653  O   TYR B 106      16.080  65.815  26.767  1.00 16.90           O  
ATOM   2654  CB  TYR B 106      13.511  65.151  25.213  1.00 17.20           C  
ATOM   2655  CG  TYR B 106      12.187  65.397  24.497  1.00 17.00           C  
ATOM   2656  CD1 TYR B 106      11.029  65.824  25.178  1.00 16.70           C  
ATOM   2657  CD2 TYR B 106      12.068  65.109  23.125  1.00 16.60           C  
ATOM   2658  CE1 TYR B 106       9.787  65.940  24.521  1.00 15.60           C  
ATOM   2659  CE2 TYR B 106      10.841  65.214  22.461  1.00 15.50           C  
ATOM   2660  CZ  TYR B 106       9.696  65.614  23.143  1.00 16.40           C  
ATOM   2661  OH  TYR B 106       8.477  65.635  22.475  1.00 15.10           O  
ATOM   2662  N   LYS B 107      14.998  64.262  27.983  1.00 16.60           N  
ATOM   2663  CA  LYS B 107      16.191  63.530  28.349  1.00 16.80           C  
ATOM   2664  C   LYS B 107      15.990  62.090  27.915  1.00 16.20           C  
ATOM   2665  O   LYS B 107      14.926  61.505  28.167  1.00 16.90           O  
ATOM   2666  CB  LYS B 107      16.403  63.632  29.872  1.00 18.10           C  
ATOM   2667  CG  LYS B 107      16.490  65.099  30.374  1.00 34.90           C  
ATOM   2668  CD  LYS B 107      16.258  65.249  31.889  1.00 49.30           C  
ATOM   2669  CE  LYS B 107      15.021  66.096  32.194  1.00 56.00           C  
ATOM   2670  NZ  LYS B 107      13.885  65.817  31.239  1.00 47.60           N  
ATOM   2671  N   THR B 108      16.942  61.532  27.180  1.00 15.40           N  
ATOM   2672  CA  THR B 108      16.727  60.193  26.653  1.00 15.70           C  
ATOM   2673  C   THR B 108      17.932  59.344  26.913  1.00 15.70           C  
ATOM   2674  O   THR B 108      19.045  59.842  26.990  1.00 16.00           O  
ATOM   2675  CB  THR B 108      16.458  60.196  25.105  1.00 14.80           C  
ATOM   2676  OG1 THR B 108      17.635  60.640  24.431  1.00 14.00           O  
ATOM   2677  CG2 THR B 108      15.316  61.136  24.755  1.00 14.40           C  
ATOM   2678  N   ARG B 109      17.693  58.055  27.083  1.00 16.80           N  
ATOM   2679  CA  ARG B 109      18.761  57.097  27.169  1.00 18.00           C  
ATOM   2680  C   ARG B 109      18.312  55.913  26.343  1.00 17.40           C  
ATOM   2681  O   ARG B 109      17.189  55.442  26.481  1.00 16.10           O  
ATOM   2682  CB  ARG B 109      18.991  56.652  28.623  1.00 20.50           C  
ATOM   2683  CG  ARG B 109      20.365  56.055  28.807  1.00 24.90           C  
ATOM   2684  CD  ARG B 109      20.521  55.296  30.096  1.00 28.50           C  
ATOM   2685  NE  ARG B 109      20.104  53.928  29.888  1.00 31.60           N  
ATOM   2686  CZ  ARG B 109      20.921  52.883  29.838  1.00 32.70           C  
ATOM   2687  NH1 ARG B 109      22.198  52.996  30.181  1.00 33.50           N  
ATOM   2688  NH2 ARG B 109      20.417  51.688  29.545  1.00 34.80           N  
ATOM   2689  N   ALA B 110      19.200  55.442  25.477  1.00 18.10           N  
ATOM   2690  CA  ALA B 110      18.866  54.362  24.558  1.00 17.90           C  
ATOM   2691  C   ALA B 110      20.015  53.383  24.497  1.00 17.50           C  
ATOM   2692  O   ALA B 110      21.195  53.755  24.641  1.00 16.90           O  
ATOM   2693  CB  ALA B 110      18.583  54.934  23.158  1.00 18.00           C  
ATOM   2694  N   GLU B 111      19.671  52.125  24.269  1.00 17.80           N  
ATOM   2695  CA  GLU B 111      20.681  51.139  23.903  1.00 19.10           C  
ATOM   2696  C   GLU B 111      20.450  50.713  22.451  1.00 19.00           C  
ATOM   2697  O   GLU B 111      19.330  50.319  22.096  1.00 19.90           O  
ATOM   2698  CB  GLU B 111      20.584  49.906  24.806  1.00 19.30           C  
ATOM   2699  CG  GLU B 111      21.392  50.017  26.083  1.00 33.90           C  
ATOM   2700  CD  GLU B 111      21.025  48.940  27.077  1.00 43.70           C  
ATOM   2701  OE1 GLU B 111      20.655  47.825  26.640  1.00 45.90           O  
ATOM   2702  OE2 GLU B 111      21.086  49.221  28.298  1.00 55.70           O  
ATOM   2703  N   VAL B 112      21.532  50.672  21.677  1.00 18.80           N  
ATOM   2704  CA  VAL B 112      21.491  50.292  20.265  1.00 18.70           C  
ATOM   2705  C   VAL B 112      22.449  49.115  20.096  1.00 18.60           C  
ATOM   2706  O   VAL B 112      23.653  49.239  20.311  1.00 18.70           O  
ATOM   2707  CB  VAL B 112      21.969  51.463  19.372  1.00 18.40           C  
ATOM   2708  CG1 VAL B 112      21.964  51.032  17.892  1.00 18.60           C  
ATOM   2709  CG2 VAL B 112      21.097  52.720  19.620  1.00 17.60           C  
ATOM   2710  N   LYS B 113      21.912  47.943  19.820  1.00 19.20           N  
ATOM   2711  CA  LYS B 113      22.755  46.755  19.770  1.00 20.50           C  
ATOM   2712  C   LYS B 113      22.080  45.688  18.898  1.00 21.10           C  
ATOM   2713  O   LYS B 113      20.869  45.789  18.622  1.00 21.20           O  
ATOM   2714  CB  LYS B 113      22.970  46.219  21.199  1.00 21.00           C  
ATOM   2715  CG  LYS B 113      21.702  45.615  21.820  1.00 25.50           C  
ATOM   2716  CD  LYS B 113      22.008  44.885  23.119  1.00 41.60           C  
ATOM   2717  CE  LYS B 113      21.366  45.572  24.317  1.00 43.00           C  
ATOM   2718  NZ  LYS B 113      19.871  45.555  24.281  1.00 49.50           N  
ATOM   2719  N   PHE B 114      22.828  44.655  18.503  1.00 21.40           N  
ATOM   2720  CA  PHE B 114      22.212  43.527  17.817  1.00 24.00           C  
ATOM   2721  C   PHE B 114      21.603  42.556  18.794  1.00 25.70           C  
ATOM   2722  O   PHE B 114      22.192  42.266  19.831  1.00 27.30           O  
ATOM   2723  CB  PHE B 114      23.221  42.785  16.941  1.00 23.60           C  
ATOM   2724  CG  PHE B 114      23.391  43.383  15.591  1.00 23.00           C  
ATOM   2725  CD1 PHE B 114      24.304  44.379  15.384  1.00 23.00           C  
ATOM   2726  CD2 PHE B 114      22.599  42.976  14.540  1.00 23.30           C  
ATOM   2727  CE1 PHE B 114      24.431  44.971  14.133  1.00 23.90           C  
ATOM   2728  CE2 PHE B 114      22.720  43.556  13.293  1.00 23.30           C  
ATOM   2729  CZ  PHE B 114      23.631  44.552  13.090  1.00 23.40           C  
ATOM   2730  N   GLU B 115      20.367  42.169  18.529  1.00 26.80           N  
ATOM   2731  CA  GLU B 115      19.707  41.110  19.264  1.00 28.70           C  
ATOM   2732  C   GLU B 115      19.335  40.060  18.221  1.00 30.30           C  
ATOM   2733  O   GLU B 115      18.361  40.237  17.482  1.00 31.10           O  
ATOM   2734  CB  GLU B 115      18.435  41.642  19.935  1.00 28.90           C  
ATOM   2735  CG  GLU B 115      18.715  42.573  21.115  1.00 36.40           C  
ATOM   2736  CD  GLU B 115      17.456  43.069  21.808  1.00 45.00           C  
ATOM   2737  OE1 GLU B 115      16.355  42.570  21.475  1.00 46.50           O  
ATOM   2738  OE2 GLU B 115      17.566  43.993  22.655  1.00 45.60           O  
ATOM   2739  N   GLY B 116      20.192  39.055  18.047  1.00 31.10           N  
ATOM   2740  CA  GLY B 116      19.978  38.101  16.972  1.00 31.70           C  
ATOM   2741  C   GLY B 116      20.402  38.713  15.647  1.00 32.00           C  
ATOM   2742  O   GLY B 116      21.503  39.271  15.533  1.00 33.00           O  
ATOM   2743  N   ASP B 117      19.502  38.721  14.674  1.00 31.90           N  
ATOM   2744  CA  ASP B 117      19.843  39.327  13.394  1.00 31.80           C  
ATOM   2745  C   ASP B 117      19.338  40.762  13.195  1.00 31.00           C  
ATOM   2746  O   ASP B 117      19.653  41.391  12.185  1.00 31.50           O  
ATOM   2747  CB  ASP B 117      19.412  38.409  12.217  1.00 33.40           C  
ATOM   2748  CG  ASP B 117      17.993  37.847  12.372  1.00 56.80           C  
ATOM   2749  OD1 ASP B 117      17.001  38.611  12.215  1.00 57.00           O  
ATOM   2750  OD2 ASP B 117      17.875  36.610  12.564  1.00 76.60           O  
ATOM   2751  N   THR B 118      18.629  41.316  14.178  1.00 29.40           N  
ATOM   2752  CA  THR B 118      18.087  42.657  14.019  1.00 27.20           C  
ATOM   2753  C   THR B 118      18.727  43.675  14.951  1.00 25.50           C  
ATOM   2754  O   THR B 118      19.340  43.309  15.960  1.00 24.40           O  
ATOM   2755  CB  THR B 118      16.512  42.673  14.076  1.00 28.10           C  
ATOM   2756  OG1 THR B 118      16.021  43.523  15.122  1.00 28.50           O  
ATOM   2757  CG2 THR B 118      15.964  41.284  14.212  1.00 27.70           C  
ATOM   2758  N   LEU B 119      18.902  44.868  14.406  1.00 23.40           N  
ATOM   2759  CA  LEU B 119      19.418  45.965  15.166  1.00 22.40           C  
ATOM   2760  C   LEU B 119      18.262  46.621  15.889  1.00 21.90           C  
ATOM   2761  O   LEU B 119      17.237  46.947  15.283  1.00 21.20           O  
ATOM   2762  CB  LEU B 119      20.119  46.971  14.261  1.00 22.70           C  
ATOM   2763  CG  LEU B 119      20.732  48.036  15.159  1.00 23.20           C  
ATOM   2764  CD1 LEU B 119      22.202  48.101  14.904  1.00 23.70           C  
ATOM   2765  CD2 LEU B 119      20.020  49.367  15.000  1.00 22.10           C  
ATOM   2766  N   VAL B 120      18.358  46.666  17.219  1.00 21.40           N  
ATOM   2767  CA  VAL B 120      17.266  47.214  18.002  1.00 20.50           C  
ATOM   2768  C   VAL B 120      17.688  48.438  18.806  1.00 19.90           C  
ATOM   2769  O   VAL B 120      18.837  48.573  19.224  1.00 20.00           O  
ATOM   2770  CB  VAL B 120      16.609  46.171  18.930  1.00 21.20           C  
ATOM   2771  CG1 VAL B 120      16.740  44.766  18.374  1.00 20.10           C  
ATOM   2772  CG2 VAL B 120      17.205  46.261  20.289  1.00 23.10           C  
ATOM   2773  N   ASN B 121      16.775  49.392  18.871  1.00 19.40           N  
ATOM   2774  CA  ASN B 121      16.992  50.674  19.524  1.00 19.70           C  
ATOM   2775  C   ASN B 121      15.899  50.779  20.609  1.00 19.30           C  
ATOM   2776  O   ASN B 121      14.706  50.877  20.301  1.00 18.60           O  
ATOM   2777  CB  ASN B 121      16.869  51.808  18.474  1.00 19.60           C  
ATOM   2778  CG  ASN B 121      17.056  53.230  19.047  1.00 16.70           C  
ATOM   2779  OD1 ASN B 121      16.630  54.217  18.413  1.00 18.70           O  
ATOM   2780  ND2 ASN B 121      17.754  53.353  20.172  1.00 12.70           N  
ATOM   2781  N   ARG B 122      16.305  50.575  21.864  1.00 18.90           N  
ATOM   2782  CA  ARG B 122      15.374  50.648  22.987  1.00 19.30           C  
ATOM   2783  C   ARG B 122      15.644  51.914  23.771  1.00 17.80           C  
ATOM   2784  O   ARG B 122      16.793  52.156  24.160  1.00 17.60           O  
ATOM   2785  CB  ARG B 122      15.552  49.451  23.903  1.00 20.80           C  
ATOM   2786  CG  ARG B 122      15.096  48.157  23.341  1.00 23.30           C  
ATOM   2787  CD  ARG B 122      15.619  47.065  24.266  1.00 26.60           C  
ATOM   2788  NE  ARG B 122      15.519  45.726  23.706  1.00 27.60           N  
ATOM   2789  CZ  ARG B 122      14.380  45.187  23.307  1.00 28.20           C  
ATOM   2790  NH1 ARG B 122      13.233  45.841  23.464  1.00 29.20           N  
ATOM   2791  NH2 ARG B 122      14.383  43.968  22.802  1.00 30.50           N  
ATOM   2792  N   ILE B 123      14.598  52.730  23.948  1.00 17.50           N  
ATOM   2793  CA  ILE B 123      14.730  54.128  24.385  1.00 17.50           C  
ATOM   2794  C   ILE B 123      13.835  54.364  25.609  1.00 17.20           C  
ATOM   2795  O   ILE B 123      12.665  53.941  25.647  1.00 16.80           O  
ATOM   2796  CB  ILE B 123      14.304  55.182  23.267  1.00 17.30           C  
ATOM   2797  CG1 ILE B 123      15.053  54.953  21.952  1.00 16.80           C  
ATOM   2798  CG2 ILE B 123      14.619  56.598  23.702  1.00 16.60           C  
ATOM   2799  CD1 ILE B 123      14.149  55.018  20.783  1.00 17.70           C  
ATOM   2800  N   GLU B 124      14.374  55.133  26.551  1.00 17.20           N  
ATOM   2801  CA  GLU B 124      13.562  55.801  27.567  1.00 17.80           C  
ATOM   2802  C   GLU B 124      13.627  57.285  27.402  1.00 16.50           C  
ATOM   2803  O   GLU B 124      14.702  57.829  27.272  1.00 17.70           O  
ATOM   2804  CB  GLU B 124      14.047  55.439  28.977  1.00 19.30           C  
ATOM   2805  CG  GLU B 124      13.711  54.008  29.371  1.00 46.30           C  
ATOM   2806  CD  GLU B 124      14.145  53.684  30.773  1.00 67.80           C  
ATOM   2807  OE1 GLU B 124      14.299  54.626  31.586  1.00 93.60           O  
ATOM   2808  OE2 GLU B 124      14.328  52.484  31.061  1.00 93.80           O  
ATOM   2809  N   LEU B 125      12.475  57.933  27.467  1.00 16.70           N  
ATOM   2810  CA  LEU B 125      12.392  59.377  27.331  1.00 17.10           C  
ATOM   2811  C   LEU B 125      11.612  60.022  28.487  1.00 16.90           C  
ATOM   2812  O   LEU B 125      10.467  59.634  28.795  1.00 17.00           O  
ATOM   2813  CB  LEU B 125      11.720  59.726  25.987  1.00 17.20           C  
ATOM   2814  CG  LEU B 125      11.498  61.198  25.631  1.00 17.40           C  
ATOM   2815  CD1 LEU B 125      11.578  61.320  24.118  1.00 19.00           C  
ATOM   2816  CD2 LEU B 125      10.158  61.684  26.103  1.00 16.80           C  
ATOM   2817  N   LYS B 126      12.140  61.134  28.973  1.00 17.50           N  
ATOM   2818  CA  LYS B 126      11.397  61.946  29.924  1.00 18.50           C  
ATOM   2819  C   LYS B 126      11.447  63.366  29.442  1.00 18.60           C  
ATOM   2820  O   LYS B 126      12.500  63.880  29.105  1.00 19.20           O  
ATOM   2821  CB  LYS B 126      12.030  61.849  31.334  1.00 20.30           C  
ATOM   2822  CG  LYS B 126      11.041  61.804  32.514  1.00 41.60           C  
ATOM   2823  CD  LYS B 126      10.529  63.186  32.925  1.00 44.70           C  
ATOM   2824  CE  LYS B 126       9.120  63.085  33.550  1.00 69.50           C  
ATOM   2825  NZ  LYS B 126       8.130  64.163  33.162  1.00 42.50           N  
ATOM   2826  N   GLY B 127      10.309  64.024  29.473  1.00 19.00           N  
ATOM   2827  CA  GLY B 127      10.280  65.402  29.074  1.00 20.40           C  
ATOM   2828  C   GLY B 127       9.526  66.201  30.084  1.00 21.60           C  
ATOM   2829  O   GLY B 127       8.536  65.743  30.626  1.00 21.70           O  
ATOM   2830  N   ILE B 128       9.990  67.403  30.333  1.00 23.10           N  
ATOM   2831  CA  ILE B 128       9.389  68.251  31.346  1.00 25.80           C  
ATOM   2832  C   ILE B 128       9.522  69.694  30.948  1.00 25.50           C  
ATOM   2833  O   ILE B 128      10.447  70.063  30.232  1.00 24.30           O  
ATOM   2834  CB  ILE B 128      10.093  68.094  32.740  1.00 28.00           C  
ATOM   2835  CG1 ILE B 128      11.600  67.838  32.580  1.00 29.40           C  
ATOM   2836  CG2 ILE B 128       9.440  66.984  33.534  1.00 29.70           C  
ATOM   2837  CD1 ILE B 128      12.470  69.075  32.873  1.00 30.80           C  
ATOM   2838  N   ASP B 129       8.787  70.522  31.670  1.00 26.40           N  
ATOM   2839  CA  ASP B 129       8.904  71.982  31.566  1.00 27.70           C  
ATOM   2840  C   ASP B 129       8.269  72.439  30.266  1.00 27.60           C  
ATOM   2841  O   ASP B 129       8.534  73.537  29.788  1.00 27.50           O  
ATOM   2842  CB  ASP B 129      10.380  72.476  31.621  1.00 29.10           C  
ATOM   2843  CG  ASP B 129      10.887  72.745  33.049  1.00 39.90           C  
ATOM   2844  OD1 ASP B 129      10.046  73.044  33.914  1.00 46.40           O  
ATOM   2845  OD2 ASP B 129      12.132  72.654  33.288  1.00 50.10           O  
ATOM   2846  N   PHE B 130       7.371  71.632  29.731  1.00 27.30           N  
ATOM   2847  CA  PHE B 130       6.736  72.020  28.497  1.00 27.90           C  
ATOM   2848  C   PHE B 130       5.528  72.888  28.798  1.00 29.10           C  
ATOM   2849  O   PHE B 130       4.841  72.671  29.795  1.00 29.40           O  
ATOM   2850  CB  PHE B 130       6.339  70.781  27.679  1.00 26.70           C  
ATOM   2851  CG  PHE B 130       7.493  70.131  26.935  1.00 25.50           C  
ATOM   2852  CD1 PHE B 130       7.995  70.696  25.757  1.00 24.60           C  
ATOM   2853  CD2 PHE B 130       8.154  69.024  27.469  1.00 23.80           C  
ATOM   2854  CE1 PHE B 130       9.153  70.173  25.156  1.00 23.20           C  
ATOM   2855  CE2 PHE B 130       9.306  68.505  26.866  1.00 23.10           C  
ATOM   2856  CZ  PHE B 130       9.807  69.083  25.717  1.00 22.20           C  
ATOM   2857  N   LYS B 131       5.401  73.978  28.047  1.00 30.90           N  
ATOM   2858  CA  LYS B 131       4.222  74.842  28.096  1.00 32.70           C  
ATOM   2859  C   LYS B 131       3.028  74.207  27.392  1.00 34.50           C  
ATOM   2860  O   LYS B 131       3.134  73.771  26.246  1.00 34.80           O  
ATOM   2861  CB  LYS B 131       4.510  76.169  27.426  1.00 32.50           C  
ATOM   2862  CG  LYS B 131       5.678  76.960  27.976  1.00 37.70           C  
ATOM   2863  CD  LYS B 131       5.718  78.285  27.222  1.00 54.30           C  
ATOM   2864  CE  LYS B 131       7.110  78.901  27.147  1.00 68.70           C  
ATOM   2865  NZ  LYS B 131       7.281  79.735  25.904  1.00 66.30           N  
ATOM   2866  N   GLU B 132       1.858  74.312  28.008  1.00 36.10           N  
ATOM   2867  CA  GLU B 132       0.663  73.658  27.476  1.00 38.30           C  
ATOM   2868  C   GLU B 132       0.149  74.483  26.282  1.00 39.00           C  
ATOM   2869  O   GLU B 132      -0.660  74.004  25.464  1.00 39.80           O  
ATOM   2870  CB  GLU B 132      -0.431  73.510  28.580  1.00 39.90           C  
ATOM   2871  CG  GLU B 132      -0.086  72.522  29.778  1.00 65.80           C  
ATOM   2872  CD  GLU B 132      -0.215  70.983  29.460  1.00 78.10           C  
ATOM   2873  OE1 GLU B 132      -1.240  70.563  28.866  1.00 96.40           O  
ATOM   2874  OE2 GLU B 132       0.662  70.180  29.896  1.00 66.90           O  
ATOM   2875  N   ASP B 133       0.763  75.652  26.115  1.00 38.60           N  
ATOM   2876  CA  ASP B 133       0.434  76.578  25.050  1.00 38.50           C  
ATOM   2877  C   ASP B 133       1.607  76.683  24.064  1.00 37.60           C  
ATOM   2878  O   ASP B 133       1.595  77.514  23.144  1.00 38.40           O  
ATOM   2879  CB  ASP B 133       0.129  77.965  25.651  1.00 40.30           C  
ATOM   2880  CG  ASP B 133       1.293  78.527  26.525  1.00 66.00           C  
ATOM   2881  OD1 ASP B 133       1.610  77.915  27.571  1.00 79.40           O  
ATOM   2882  OD2 ASP B 133       1.823  79.631  26.220  1.00 69.90           O  
ATOM   2883  N   GLY B 134       2.667  75.932  24.340  1.00 35.50           N  
ATOM   2884  CA  GLY B 134       3.882  76.094  23.573  1.00 32.90           C  
ATOM   2885  C   GLY B 134       3.703  75.508  22.194  1.00 31.10           C  
ATOM   2886  O   GLY B 134       2.671  74.920  21.890  1.00 30.10           O  
ATOM   2887  N   ASN B 135       4.769  75.547  21.416  1.00 29.80           N  
ATOM   2888  CA  ASN B 135       4.757  74.949  20.093  1.00 28.80           C  
ATOM   2889  C   ASN B 135       4.605  73.441  20.152  1.00 28.30           C  
ATOM   2890  O   ASN B 135       3.953  72.858  19.281  1.00 28.30           O  
ATOM   2891  CB  ASN B 135       6.039  75.293  19.349  1.00 29.10           C  
ATOM   2892  CG  ASN B 135       6.069  76.727  18.858  1.00 25.90           C  
ATOM   2893  OD1 ASN B 135       7.125  77.381  18.876  1.00 31.20           O  
ATOM   2894  ND2 ASN B 135       4.916  77.236  18.429  1.00 32.70           N  
ATOM   2895  N   ILE B 136       5.165  72.811  21.190  1.00 27.20           N  
ATOM   2896  CA  ILE B 136       5.164  71.351  21.284  1.00 26.30           C  
ATOM   2897  C   ILE B 136       3.837  70.805  21.809  1.00 26.40           C  
ATOM   2898  O   ILE B 136       3.153  70.058  21.092  1.00 25.20           O  
ATOM   2899  CB  ILE B 136       6.357  70.828  22.139  1.00 26.00           C  
ATOM   2900  CG1 ILE B 136       7.661  70.962  21.361  1.00 26.50           C  
ATOM   2901  CG2 ILE B 136       6.184  69.358  22.469  1.00 25.30           C  
ATOM   2902  CD1 ILE B 136       8.250  72.331  21.351  1.00 26.90           C  
ATOM   2903  N   LEU B 137       3.429  71.236  23.010  1.00 26.40           N  
ATOM   2904  CA  LEU B 137       2.221  70.695  23.625  1.00 26.40           C  
ATOM   2905  C   LEU B 137       1.027  71.291  22.927  1.00 26.40           C  
ATOM   2906  O   LEU B 137      -0.037  70.669  22.852  1.00 26.60           O  
ATOM   2907  CB  LEU B 137       2.170  71.001  25.123  1.00 27.90           C  
ATOM   2908  CG  LEU B 137       2.767  69.927  26.032  1.00 28.80           C  
ATOM   2909  CD1 LEU B 137       2.065  68.612  25.876  1.00 30.20           C  
ATOM   2910  CD2 LEU B 137       4.184  69.715  25.622  1.00 30.90           C  
ATOM   2911  N   GLY B 138       1.249  72.437  22.294  1.00 26.10           N  
ATOM   2912  CA  GLY B 138       0.240  73.009  21.409  1.00 26.60           C  
ATOM   2913  C   GLY B 138       0.091  72.380  20.009  1.00 26.80           C  
ATOM   2914  O   GLY B 138      -0.878  72.686  19.292  1.00 26.70           O  
ATOM   2915  N   HIS B 139       1.047  71.539  19.595  1.00 26.30           N  
ATOM   2916  CA  HIS B 139       0.996  70.879  18.287  1.00 25.40           C  
ATOM   2917  C   HIS B 139       0.945  71.900  17.128  1.00 24.00           C  
ATOM   2918  O   HIS B 139       0.026  71.903  16.318  1.00 23.80           O  
ATOM   2919  CB  HIS B 139      -0.213  69.932  18.220  1.00 26.70           C  
ATOM   2920  CG  HIS B 139      -0.165  68.647  18.980  1.00 28.00           C  
ATOM   2921  ND1 HIS B 139      -0.289  68.474  20.379  1.00 29.90           N  
ATOM   2922  CD2 HIS B 139       0.168  67.474  18.379  1.00 28.20           C  
ATOM   2923  CE1 HIS B 139       0.001  67.178  20.636  1.00 29.50           C  
ATOM   2924  NE2 HIS B 139       0.284  66.617  19.482  1.00 30.10           N  
ATOM   2925  N   LYS B 140       1.913  72.801  17.101  1.00 22.90           N  
ATOM   2926  CA  LYS B 140       1.967  73.838  16.079  1.00 22.60           C  
ATOM   2927  C   LYS B 140       3.069  73.567  15.048  1.00 21.50           C  
ATOM   2928  O   LYS B 140       3.406  74.462  14.281  1.00 21.70           O  
ATOM   2929  CB  LYS B 140       2.236  75.204  16.719  1.00 23.30           C  
ATOM   2930  CG  LYS B 140       1.069  75.815  17.503  1.00 28.80           C  
ATOM   2931  CD  LYS B 140       1.587  76.870  18.459  1.00 31.10           C  
ATOM   2932  CE  LYS B 140       0.457  77.554  19.188  1.00 53.00           C  
ATOM   2933  NZ  LYS B 140      -0.447  76.587  19.858  1.00 61.20           N  
ATOM   2934  N   LEU B 141       3.755  72.434  15.184  1.00 20.30           N  
ATOM   2935  CA  LEU B 141       4.812  72.045  14.258  1.00 19.40           C  
ATOM   2936  C   LEU B 141       4.202  71.538  12.921  1.00 19.10           C  
ATOM   2937  O   LEU B 141       3.255  70.735  12.902  1.00 18.60           O  
ATOM   2938  CB  LEU B 141       5.662  70.911  14.824  1.00 19.10           C  
ATOM   2939  CG  LEU B 141       6.510  71.066  16.052  1.00 20.40           C  
ATOM   2940  CD1 LEU B 141       7.654  70.059  15.983  1.00 19.90           C  
ATOM   2941  CD2 LEU B 141       7.004  72.451  16.152  1.00 21.20           C  
ATOM   2942  N   GLU B 142       4.857  71.862  11.817  1.00 17.90           N  
ATOM   2943  CA  GLU B 142       4.472  71.240  10.553  1.00 17.50           C  
ATOM   2944  C   GLU B 142       4.968  69.813  10.479  1.00 16.40           C  
ATOM   2945  O   GLU B 142       5.998  69.469  11.065  1.00 15.90           O  
ATOM   2946  CB  GLU B 142       5.031  72.032   9.383  1.00 17.60           C  
ATOM   2947  CG  GLU B 142       4.507  73.436   9.313  1.00 21.20           C  
ATOM   2948  CD  GLU B 142       5.010  74.172   8.089  1.00 26.80           C  
ATOM   2949  OE1 GLU B 142       6.191  74.010   7.707  1.00 24.50           O  
ATOM   2950  OE2 GLU B 142       4.234  74.957   7.525  1.00 32.50           O  
ATOM   2951  N   TYR B 143       4.208  68.965   9.795  1.00 16.50           N  
ATOM   2952  CA  TYR B 143       4.595  67.571   9.660  1.00 16.20           C  
ATOM   2953  C   TYR B 143       5.588  67.424   8.504  1.00 17.20           C  
ATOM   2954  O   TYR B 143       5.231  66.967   7.413  1.00 17.00           O  
ATOM   2955  CB  TYR B 143       3.373  66.698   9.441  1.00 14.50           C  
ATOM   2956  CG  TYR B 143       3.647  65.218   9.490  1.00 14.50           C  
ATOM   2957  CD1 TYR B 143       3.937  64.575  10.698  1.00 13.30           C  
ATOM   2958  CD2 TYR B 143       3.573  64.442   8.321  1.00 14.00           C  
ATOM   2959  CE1 TYR B 143       4.152  63.210  10.755  1.00 14.30           C  
ATOM   2960  CE2 TYR B 143       3.766  63.074   8.372  1.00 13.60           C  
ATOM   2961  CZ  TYR B 143       4.062  62.455   9.596  1.00 14.80           C  
ATOM   2962  OH  TYR B 143       4.271  61.087   9.673  1.00 15.10           O  
ATOM   2963  N   ASN B 144       6.842  67.768   8.775  1.00 17.00           N  
ATOM   2964  CA  ASN B 144       7.907  67.722   7.780  1.00 16.90           C  
ATOM   2965  C   ASN B 144       9.261  67.831   8.496  1.00 17.20           C  
ATOM   2966  O   ASN B 144       9.317  67.787   9.730  1.00 17.40           O  
ATOM   2967  CB  ASN B 144       7.717  68.848   6.728  1.00 16.30           C  
ATOM   2968  CG  ASN B 144       7.611  70.249   7.335  1.00 18.30           C  
ATOM   2969  OD1 ASN B 144       8.242  70.556   8.341  1.00 20.10           O  
ATOM   2970  ND2 ASN B 144       6.921  71.139   6.641  1.00 17.80           N  
ATOM   2971  N   TYR B 145      10.351  67.807   7.741  1.00 16.40           N  
ATOM   2972  CA  TYR B 145      11.692  67.815   8.315  1.00 16.20           C  
ATOM   2973  C   TYR B 145      12.722  68.436   7.368  1.00 16.50           C  
ATOM   2974  O   TYR B 145      12.518  68.436   6.147  1.00 17.20           O  
ATOM   2975  CB  TYR B 145      12.104  66.391   8.680  1.00 16.40           C  
ATOM   2976  CG  TYR B 145      12.807  66.298  10.018  1.00 16.20           C  
ATOM   2977  CD1 TYR B 145      12.083  66.175  11.204  1.00 16.20           C  
ATOM   2978  CD2 TYR B 145      14.193  66.371  10.099  1.00 16.20           C  
ATOM   2979  CE1 TYR B 145      12.729  66.132  12.454  1.00 16.10           C  
ATOM   2980  CE2 TYR B 145      14.849  66.314  11.327  1.00 17.60           C  
ATOM   2981  CZ  TYR B 145      14.110  66.197  12.510  1.00 17.10           C  
ATOM   2982  OH  TYR B 145      14.786  66.130  13.709  1.00 17.00           O  
ATOM   2983  N   ASN B 146      13.763  69.044   7.930  1.00 15.20           N  
ATOM   2984  CA  ASN B 146      14.806  69.706   7.176  1.00 15.10           C  
ATOM   2985  C   ASN B 146      16.103  68.944   7.244  1.00 14.90           C  
ATOM   2986  O   ASN B 146      16.190  67.935   7.909  1.00 16.60           O  
ATOM   2987  CB  ASN B 146      15.025  71.091   7.715  1.00 13.00           C  
ATOM   2988  CG  ASN B 146      13.808  71.925   7.615  1.00 20.50           C  
ATOM   2989  OD1 ASN B 146      13.148  71.947   6.575  1.00 20.40           O  
ATOM   2990  ND2 ASN B 146      13.468  72.603   8.685  1.00 20.10           N  
ATOM   2991  N   SER B 147      17.076  69.372   6.455  1.00 15.30           N  
ATOM   2992  CA  SER B 147      18.413  68.776   6.446  1.00 15.60           C  
ATOM   2993  C   SER B 147      19.250  69.348   7.567  1.00 14.30           C  
ATOM   2994  O   SER B 147      19.267  70.561   7.760  1.00 14.20           O  
ATOM   2995  CB  SER B 147      19.150  69.062   5.123  1.00 16.00           C  
ATOM   2996  OG  SER B 147      18.622  68.248   4.086  1.00 20.10           O  
ATOM   2997  N   HIS B 148      20.090  68.508   8.155  1.00 13.40           N  
ATOM   2998  CA  HIS B 148      20.957  68.971   9.223  1.00 13.70           C  
ATOM   2999  C   HIS B 148      22.297  68.294   9.154  1.00 14.20           C  
ATOM   3000  O   HIS B 148      22.410  67.189   8.617  1.00 13.60           O  
ATOM   3001  CB  HIS B 148      20.313  68.717  10.614  1.00 12.20           C  
ATOM   3002  CG  HIS B 148      18.963  69.393  10.818  1.00 10.30           C  
ATOM   3003  ND1 HIS B 148      17.780  68.740  10.631  1.00 10.20           N  
ATOM   3004  CD2 HIS B 148      18.725  70.703  11.162  1.00 10.90           C  
ATOM   3005  CE1 HIS B 148      16.810  69.650  10.786  1.00 10.60           C  
ATOM   3006  NE2 HIS B 148      17.332  70.798  11.100  1.00 11.20           N  
ATOM   3007  N   ASN B 149      23.327  69.010   9.610  1.00 15.00           N  
ATOM   3008  CA  ASN B 149      24.626  68.411   9.876  1.00 15.70           C  
ATOM   3009  C   ASN B 149      24.753  68.090  11.352  1.00 16.30           C  
ATOM   3010  O   ASN B 149      24.412  68.896  12.201  1.00 17.20           O  
ATOM   3011  CB  ASN B 149      25.738  69.361   9.483  1.00 15.80           C  
ATOM   3012  CG  ASN B 149      25.761  69.652   7.996  1.00 20.40           C  
ATOM   3013  OD1 ASN B 149      26.055  70.776   7.592  1.00 28.00           O  
ATOM   3014  ND2 ASN B 149      25.489  68.642   7.180  1.00 12.70           N  
ATOM   3015  N   VAL B 150      25.348  66.946  11.635  1.00 17.40           N  
ATOM   3016  CA  VAL B 150      25.485  66.407  12.987  1.00 17.30           C  
ATOM   3017  C   VAL B 150      26.983  66.307  13.256  1.00 18.50           C  
ATOM   3018  O   VAL B 150      27.692  65.623  12.519  1.00 19.30           O  
ATOM   3019  CB  VAL B 150      24.840  65.009  13.040  1.00 16.70           C  
ATOM   3020  CG1 VAL B 150      25.263  64.232  14.279  1.00 16.20           C  
ATOM   3021  CG2 VAL B 150      23.346  65.155  12.956  1.00 15.80           C  
ATOM   3022  N   TYR B 151      27.490  67.066  14.229  1.00 19.50           N  
ATOM   3023  CA  TYR B 151      28.935  67.181  14.427  1.00 20.90           C  
ATOM   3024  C   TYR B 151      29.451  66.209  15.453  1.00 21.60           C  
ATOM   3025  O   TYR B 151      28.897  66.100  16.558  1.00 22.00           O  
ATOM   3026  CB  TYR B 151      29.293  68.597  14.831  1.00 22.50           C  
ATOM   3027  CG  TYR B 151      28.984  69.559  13.739  1.00 25.40           C  
ATOM   3028  CD1 TYR B 151      29.775  69.600  12.595  1.00 27.40           C  
ATOM   3029  CD2 TYR B 151      27.824  70.328  13.763  1.00 26.60           C  
ATOM   3030  CE1 TYR B 151      29.424  70.383  11.494  1.00 28.20           C  
ATOM   3031  CE2 TYR B 151      27.451  71.110  12.652  1.00 28.10           C  
ATOM   3032  CZ  TYR B 151      28.266  71.130  11.527  1.00 28.60           C  
ATOM   3033  OH  TYR B 151      27.932  71.911  10.442  1.00 30.80           O  
ATOM   3034  N   ILE B 152      30.512  65.508  15.094  1.00 21.40           N  
ATOM   3035  CA  ILE B 152      31.038  64.430  15.908  1.00 22.80           C  
ATOM   3036  C   ILE B 152      32.486  64.679  16.320  1.00 24.50           C  
ATOM   3037  O   ILE B 152      33.318  65.040  15.485  1.00 24.50           O  
ATOM   3038  CB  ILE B 152      30.971  63.104  15.159  1.00 21.90           C  
ATOM   3039  CG1 ILE B 152      29.508  62.798  14.814  1.00 21.00           C  
ATOM   3040  CG2 ILE B 152      31.628  61.992  15.968  1.00 20.80           C  
ATOM   3041  CD1 ILE B 152      29.340  61.640  13.923  1.00 21.00           C  
ATOM   3042  N   MET B 153      32.765  64.506  17.617  1.00 26.00           N  
ATOM   3043  CA  MET B 153      34.137  64.476  18.124  1.00 26.60           C  
ATOM   3044  C   MET B 153      34.338  63.349  19.109  1.00 27.10           C  
ATOM   3045  O   MET B 153      33.391  62.874  19.731  1.00 26.20           O  
ATOM   3046  CB  MET B 153      34.488  65.789  18.795  1.00 27.10           C  
ATOM   3047  CG  MET B 153      33.372  66.375  19.618  1.00 48.30           C  
ATOM   3048  SD  MET B 153      33.382  68.156  19.451  1.00 68.60           S  
ATOM   3049  CE  MET B 153      35.035  68.492  20.166  1.00 59.60           C  
ATOM   3050  N   ALA B 154      35.552  62.829  19.139  1.00 28.00           N  
ATOM   3051  CA  ALA B 154      35.881  61.762  20.065  1.00 29.90           C  
ATOM   3052  C   ALA B 154      35.881  62.313  21.488  1.00 30.70           C  
ATOM   3053  O   ALA B 154      36.102  63.505  21.712  1.00 30.30           O  
ATOM   3054  CB  ALA B 154      37.245  61.184  19.739  1.00 29.60           C  
ATOM   3055  N   ASP B 155      35.503  61.460  22.426  1.00 32.40           N  
ATOM   3056  CA  ASP B 155      35.682  61.750  23.846  1.00 34.30           C  
ATOM   3057  C   ASP B 155      36.601  60.644  24.389  1.00 35.10           C  
ATOM   3058  O   ASP B 155      36.126  59.558  24.759  1.00 35.50           O  
ATOM   3059  CB  ASP B 155      34.318  61.725  24.558  1.00 35.30           C  
ATOM   3060  CG  ASP B 155      34.385  62.192  26.021  1.00 43.30           C  
ATOM   3061  OD1 ASP B 155      35.413  62.775  26.456  1.00 36.60           O  
ATOM   3062  OD2 ASP B 155      33.344  62.041  26.696  1.00 41.60           O  
ATOM   3063  N   LYS B 156      37.915  60.878  24.273  1.00 36.30           N  
ATOM   3064  CA  LYS B 156      38.979  59.919  24.642  1.00 36.80           C  
ATOM   3065  C   LYS B 156      38.798  59.383  26.067  1.00 36.70           C  
ATOM   3066  O   LYS B 156      38.775  58.157  26.290  1.00 36.10           O  
ATOM   3067  CB  LYS B 156      40.339  60.616  24.545  1.00 38.10           C  
ATOM   3068  CG  LYS B 156      41.440  59.800  23.883  1.00 66.50           C  
ATOM   3069  CD  LYS B 156      41.381  59.916  22.348  1.00 83.20           C  
ATOM   3070  CE  LYS B 156      41.692  61.337  21.841  1.00 89.00           C  
ATOM   3071  NZ  LYS B 156      41.972  61.375  20.359  1.00 93.50           N  
ATOM   3072  N   GLN B 157      38.481  60.317  26.972  1.00 36.30           N  
ATOM   3073  CA  GLN B 157      38.245  60.018  28.380  1.00 36.20           C  
ATOM   3074  C   GLN B 157      37.112  59.035  28.681  1.00 35.90           C  
ATOM   3075  O   GLN B 157      37.154  58.366  29.713  1.00 36.50           O  
ATOM   3076  CB  GLN B 157      38.003  61.307  29.151  1.00 36.80           C  
ATOM   3077  CG  GLN B 157      39.230  62.112  29.440  1.00 53.40           C  
ATOM   3078  CD  GLN B 157      38.913  63.477  30.021  1.00 72.20           C  
ATOM   3079  OE1 GLN B 157      39.777  64.116  30.633  1.00 83.60           O  
ATOM   3080  NE2 GLN B 157      37.691  63.952  29.805  1.00 74.10           N  
ATOM   3081  N   LYS B 158      36.078  58.990  27.838  1.00 34.80           N  
ATOM   3082  CA  LYS B 158      34.964  58.076  28.065  1.00 33.40           C  
ATOM   3083  C   LYS B 158      35.018  56.910  27.106  1.00 33.00           C  
ATOM   3084  O   LYS B 158      34.100  56.092  27.095  1.00 33.40           O  
ATOM   3085  CB  LYS B 158      33.634  58.798  27.878  1.00 34.50           C  
ATOM   3086  CG  LYS B 158      33.007  59.333  29.138  1.00 40.20           C  
ATOM   3087  CD  LYS B 158      33.600  60.641  29.527  1.00 43.50           C  
ATOM   3088  CE  LYS B 158      32.548  61.718  29.628  1.00 32.70           C  
ATOM   3089  NZ  LYS B 158      33.197  63.051  29.481  1.00 50.00           N  
ATOM   3090  N   ASN B 159      36.053  56.868  26.261  1.00 32.30           N  
ATOM   3091  CA  ASN B 159      36.140  55.908  25.137  1.00 32.00           C  
ATOM   3092  C   ASN B 159      34.917  55.970  24.186  1.00 30.60           C  
ATOM   3093  O   ASN B 159      34.365  54.937  23.787  1.00 30.70           O  
ATOM   3094  CB  ASN B 159      36.320  54.470  25.683  1.00 33.00           C  
ATOM   3095  CG  ASN B 159      36.752  53.458  24.599  1.00 46.10           C  
ATOM   3096  OD1 ASN B 159      37.787  53.631  23.947  1.00 47.50           O  
ATOM   3097  ND2 ASN B 159      36.010  52.355  24.484  1.00 53.10           N  
ATOM   3098  N   GLY B 160      34.489  57.180  23.845  1.00 29.10           N  
ATOM   3099  CA  GLY B 160      33.222  57.336  23.153  1.00 27.00           C  
ATOM   3100  C   GLY B 160      33.294  58.556  22.271  1.00 25.20           C  
ATOM   3101  O   GLY B 160      34.385  59.002  21.899  1.00 24.10           O  
ATOM   3102  N   ILE B 161      32.132  59.145  21.999  1.00 23.70           N  
ATOM   3103  CA  ILE B 161      32.055  60.352  21.182  1.00 22.20           C  
ATOM   3104  C   ILE B 161      31.049  61.298  21.797  1.00 21.60           C  
ATOM   3105  O   ILE B 161      30.170  60.873  22.537  1.00 22.00           O  
ATOM   3106  CB  ILE B 161      31.610  60.035  19.724  1.00 21.50           C  
ATOM   3107  CG1 ILE B 161      30.240  59.341  19.738  1.00 21.10           C  
ATOM   3108  CG2 ILE B 161      32.649  59.172  19.044  1.00 21.30           C  
ATOM   3109  CD1 ILE B 161      29.650  59.069  18.388  1.00 21.50           C  
ATOM   3110  N   LYS B 162      31.210  62.580  21.512  1.00 21.70           N  
ATOM   3111  CA  LYS B 162      30.235  63.611  21.845  1.00 22.20           C  
ATOM   3112  C   LYS B 162      29.741  64.156  20.543  1.00 21.30           C  
ATOM   3113  O   LYS B 162      30.556  64.487  19.686  1.00 22.30           O  
ATOM   3114  CB  LYS B 162      30.880  64.771  22.610  1.00 22.90           C  
ATOM   3115  CG  LYS B 162      31.021  64.516  24.082  1.00 34.60           C  
ATOM   3116  CD  LYS B 162      32.363  64.998  24.577  1.00 70.10           C  
ATOM   3117  CE  LYS B 162      32.661  66.419  24.097  1.00 91.20           C  
ATOM   3118  NZ  LYS B 162      31.711  67.426  24.668  1.00113.20           N  
ATOM   3119  N   VAL B 163      28.445  64.452  20.501  1.00 21.10           N  
ATOM   3120  CA  VAL B 163      27.739  64.852  19.274  1.00 20.70           C  
ATOM   3121  C   VAL B 163      26.866  66.072  19.594  1.00 21.00           C  
ATOM   3122  O   VAL B 163      26.238  66.114  20.659  1.00 21.40           O  
ATOM   3123  CB  VAL B 163      26.852  63.671  18.745  1.00 19.90           C  
ATOM   3124  CG1 VAL B 163      26.142  64.061  17.505  1.00 19.00           C  
ATOM   3125  CG2 VAL B 163      27.696  62.430  18.501  1.00 19.40           C  
ATOM   3126  N   ASN B 164      26.969  67.127  18.785  1.00 20.40           N  
ATOM   3127  CA  ASN B 164      26.103  68.300  18.940  1.00 20.10           C  
ATOM   3128  C   ASN B 164      25.462  68.691  17.617  1.00 19.40           C  
ATOM   3129  O   ASN B 164      26.081  68.576  16.572  1.00 20.20           O  
ATOM   3130  CB  ASN B 164      26.867  69.521  19.478  1.00 20.90           C  
ATOM   3131  CG  ASN B 164      28.070  69.143  20.312  1.00 46.50           C  
ATOM   3132  OD1 ASN B 164      27.945  68.568  21.392  1.00 58.40           O  
ATOM   3133  ND2 ASN B 164      29.252  69.433  19.795  1.00 69.10           N  
ATOM   3134  N   PHE B 165      24.258  69.238  17.679  1.00 18.50           N  
ATOM   3135  CA  PHE B 165      23.597  69.778  16.503  1.00 18.20           C  
ATOM   3136  C   PHE B 165      22.285  70.430  16.933  1.00 18.80           C  
ATOM   3137  O   PHE B 165      21.816  70.164  18.038  1.00 19.30           O  
ATOM   3138  CB  PHE B 165      23.340  68.672  15.466  1.00 16.70           C  
ATOM   3139  CG  PHE B 165      22.547  67.501  15.986  1.00 15.70           C  
ATOM   3140  CD1 PHE B 165      23.187  66.453  16.650  1.00 15.20           C  
ATOM   3141  CD2 PHE B 165      21.184  67.403  15.727  1.00 15.50           C  
ATOM   3142  CE1 PHE B 165      22.491  65.312  17.039  1.00 15.00           C  
ATOM   3143  CE2 PHE B 165      20.469  66.274  16.111  1.00 15.10           C  
ATOM   3144  CZ  PHE B 165      21.136  65.217  16.769  1.00 15.10           C  
ATOM   3145  N   LYS B 166      21.774  71.366  16.131  1.00 18.30           N  
ATOM   3146  CA  LYS B 166      20.510  72.033  16.391  1.00 19.20           C  
ATOM   3147  C   LYS B 166      19.541  71.693  15.280  1.00 19.60           C  
ATOM   3148  O   LYS B 166      19.878  71.858  14.108  1.00 20.70           O  
ATOM   3149  CB  LYS B 166      20.694  73.545  16.413  1.00 20.20           C  
ATOM   3150  CG  LYS B 166      21.822  74.027  17.297  1.00 37.40           C  
ATOM   3151  CD  LYS B 166      21.792  75.537  17.462  1.00 43.00           C  
ATOM   3152  CE  LYS B 166      20.677  75.947  18.402  1.00 67.60           C  
ATOM   3153  NZ  LYS B 166      20.946  77.279  19.027  1.00 74.30           N  
ATOM   3154  N   ILE B 167      18.370  71.179  15.643  1.00 18.80           N  
ATOM   3155  CA  ILE B 167      17.314  70.859  14.691  1.00 17.90           C  
ATOM   3156  C   ILE B 167      16.443  72.107  14.516  1.00 19.90           C  
ATOM   3157  O   ILE B 167      16.147  72.803  15.498  1.00 19.40           O  
ATOM   3158  CB  ILE B 167      16.434  69.665  15.193  1.00 16.00           C  
ATOM   3159  CG1 ILE B 167      17.244  68.364  15.206  1.00 14.40           C  
ATOM   3160  CG2 ILE B 167      15.171  69.533  14.371  1.00 14.40           C  
ATOM   3161  CD1 ILE B 167      17.844  67.952  13.852  1.00 14.10           C  
ATOM   3162  N   ARG B 168      16.092  72.431  13.265  1.00 20.60           N  
ATOM   3163  CA  ARG B 168      15.145  73.508  12.984  1.00 21.50           C  
ATOM   3164  C   ARG B 168      13.830  72.882  12.572  1.00 20.70           C  
ATOM   3165  O   ARG B 168      13.786  72.163  11.563  1.00 19.60           O  
ATOM   3166  CB  ARG B 168      15.654  74.394  11.848  1.00 24.40           C  
ATOM   3167  CG  ARG B 168      17.037  74.957  12.084  1.00 28.50           C  
ATOM   3168  CD  ARG B 168      17.604  75.642  10.831  1.00 33.40           C  
ATOM   3169  NE  ARG B 168      17.287  74.932   9.573  1.00 38.00           N  
ATOM   3170  CZ  ARG B 168      18.106  74.081   8.929  1.00 39.60           C  
ATOM   3171  NH1 ARG B 168      19.374  73.922   9.329  1.00 40.90           N  
ATOM   3172  NH2 ARG B 168      17.673  73.413   7.848  1.00 39.90           N  
ATOM   3173  N   HIS B 169      12.813  73.051  13.430  1.00 19.10           N  
ATOM   3174  CA  HIS B 169      11.459  72.547  13.196  1.00 19.30           C  
ATOM   3175  C   HIS B 169      10.551  73.659  12.707  1.00 20.10           C  
ATOM   3176  O   HIS B 169      10.434  74.704  13.361  1.00 19.60           O  
ATOM   3177  CB  HIS B 169      10.867  71.955  14.488  1.00 19.00           C  
ATOM   3178  CG  HIS B 169      11.447  70.688  15.124  1.00 17.80           C  
ATOM   3179  ND1 HIS B 169      12.128  70.649  16.341  1.00 17.40           N  
ATOM   3180  CD2 HIS B 169      11.326  69.412  14.646  1.00 17.10           C  
ATOM   3181  CE1 HIS B 169      12.419  69.365  16.586  1.00 16.70           C  
ATOM   3182  NE2 HIS B 169      11.974  68.638  15.639  1.00 18.80           N  
ATOM   3183  N   ASN B 170       9.922  73.463  11.551  1.00 19.70           N  
ATOM   3184  CA  ASN B 170       9.110  74.526  10.983  1.00 20.50           C  
ATOM   3185  C   ASN B 170       7.826  74.588  11.761  1.00 21.00           C  
ATOM   3186  O   ASN B 170       7.171  73.568  11.960  1.00 20.70           O  
ATOM   3187  CB  ASN B 170       8.787  74.259   9.504  1.00 20.30           C  
ATOM   3188  CG  ASN B 170      10.009  74.337   8.596  1.00 20.50           C  
ATOM   3189  OD1 ASN B 170      11.033  74.940   8.921  1.00 21.40           O  
ATOM   3190  ND2 ASN B 170       9.902  73.706   7.454  1.00 27.60           N  
ATOM   3191  N   ILE B 171       7.418  75.798  12.124  1.00 23.00           N  
ATOM   3192  CA  ILE B 171       6.131  76.001  12.803  1.00 24.90           C  
ATOM   3193  C   ILE B 171       5.115  76.437  11.777  1.00 26.10           C  
ATOM   3194  O   ILE B 171       5.441  77.148  10.838  1.00 26.00           O  
ATOM   3195  CB  ILE B 171       6.191  77.091  13.899  1.00 25.00           C  
ATOM   3196  CG1 ILE B 171       7.379  76.831  14.801  1.00 25.00           C  
ATOM   3197  CG2 ILE B 171       4.914  77.067  14.725  1.00 24.20           C  
ATOM   3198  CD1 ILE B 171       7.360  75.443  15.344  1.00 26.40           C  
ATOM   3199  N   GLU B 172       3.866  76.095  12.022  1.00 28.00           N  
ATOM   3200  CA  GLU B 172       2.847  76.253  11.024  1.00 30.40           C  
ATOM   3201  C   GLU B 172       2.536  77.724  10.744  1.00 32.70           C  
ATOM   3202  O   GLU B 172       1.929  78.045   9.727  1.00 34.50           O  
ATOM   3203  CB  GLU B 172       1.615  75.477  11.456  1.00 30.60           C  
ATOM   3204  CG  GLU B 172       0.393  76.306  11.687  1.00 51.10           C  
ATOM   3205  CD  GLU B 172      -0.238  76.039  13.038  1.00 61.50           C  
ATOM   3206  OE1 GLU B 172       0.172  76.709  14.012  1.00 85.80           O  
ATOM   3207  OE2 GLU B 172      -1.159  75.188  13.127  1.00 56.80           O  
ATOM   3208  N   ASP B 173       3.127  78.637  11.505  1.00 33.60           N  
ATOM   3209  CA  ASP B 173       2.929  80.055  11.230  1.00 33.90           C  
ATOM   3210  C   ASP B 173       4.093  80.699  10.471  1.00 34.10           C  
ATOM   3211  O   ASP B 173       4.112  81.914  10.286  1.00 35.10           O  
ATOM   3212  CB  ASP B 173       2.644  80.827  12.534  1.00 34.30           C  
ATOM   3213  CG  ASP B 173       3.875  80.984  13.431  1.00 40.30           C  
ATOM   3214  OD1 ASP B 173       4.936  80.394  13.142  1.00 47.80           O  
ATOM   3215  OD2 ASP B 173       3.780  81.700  14.447  1.00 49.80           O  
ATOM   3216  N   GLY B 174       5.116  79.913  10.146  1.00 33.90           N  
ATOM   3217  CA  GLY B 174       6.222  80.434   9.355  1.00 32.70           C  
ATOM   3218  C   GLY B 174       7.522  80.594  10.116  1.00 32.50           C  
ATOM   3219  O   GLY B 174       8.584  80.752   9.508  1.00 32.50           O  
ATOM   3220  N   SER B 175       7.454  80.546  11.446  1.00 32.30           N  
ATOM   3221  CA  SER B 175       8.663  80.614  12.273  1.00 31.30           C  
ATOM   3222  C   SER B 175       9.343  79.248  12.376  1.00 30.40           C  
ATOM   3223  O   SER B 175       8.893  78.262  11.783  1.00 30.10           O  
ATOM   3224  CB  SER B 175       8.322  81.148  13.676  1.00 31.40           C  
ATOM   3225  OG  SER B 175       7.245  80.420  14.257  1.00 32.50           O  
ATOM   3226  N   VAL B 176      10.433  79.204  13.131  1.00 29.70           N  
ATOM   3227  CA  VAL B 176      11.145  77.965  13.409  1.00 29.20           C  
ATOM   3228  C   VAL B 176      11.318  77.749  14.936  1.00 28.00           C  
ATOM   3229  O   VAL B 176      11.505  78.713  15.680  1.00 28.10           O  
ATOM   3230  CB  VAL B 176      12.507  77.985  12.695  1.00 29.40           C  
ATOM   3231  CG1 VAL B 176      13.408  76.878  13.209  1.00 30.40           C  
ATOM   3232  CG2 VAL B 176      12.284  77.781  11.221  1.00 30.10           C  
ATOM   3233  N   GLN B 177      11.097  76.520  15.403  1.00 26.30           N  
ATOM   3234  CA  GLN B 177      11.386  76.122  16.790  1.00 24.60           C  
ATOM   3235  C   GLN B 177      12.731  75.395  16.830  1.00 23.70           C  
ATOM   3236  O   GLN B 177      12.922  74.420  16.121  1.00 23.60           O  
ATOM   3237  CB  GLN B 177      10.271  75.205  17.318  1.00 24.50           C  
ATOM   3238  CG  GLN B 177      10.610  74.396  18.593  1.00 29.70           C  
ATOM   3239  CD  GLN B 177      10.567  75.232  19.853  1.00 21.00           C  
ATOM   3240  OE1 GLN B 177      11.578  75.406  20.527  1.00 26.00           O  
ATOM   3241  NE2 GLN B 177       9.424  75.812  20.129  1.00 19.10           N  
ATOM   3242  N   LEU B 178      13.694  75.945  17.554  1.00 22.30           N  
ATOM   3243  CA  LEU B 178      14.993  75.331  17.652  1.00 22.40           C  
ATOM   3244  C   LEU B 178      15.001  74.209  18.674  1.00 21.90           C  
ATOM   3245  O   LEU B 178      14.400  74.329  19.743  1.00 22.40           O  
ATOM   3246  CB  LEU B 178      16.006  76.374  18.056  1.00 24.10           C  
ATOM   3247  CG  LEU B 178      17.047  76.723  17.011  1.00 27.00           C  
ATOM   3248  CD1 LEU B 178      16.427  76.772  15.574  1.00 26.10           C  
ATOM   3249  CD2 LEU B 178      17.653  78.074  17.452  1.00 27.40           C  
ATOM   3250  N   ALA B 179      15.703  73.130  18.367  1.00 20.50           N  
ATOM   3251  CA  ALA B 179      15.893  72.044  19.313  1.00 19.30           C  
ATOM   3252  C   ALA B 179      17.364  71.698  19.367  1.00 18.90           C  
ATOM   3253  O   ALA B 179      17.933  71.102  18.445  1.00 18.40           O  
ATOM   3254  CB  ALA B 179      15.066  70.828  18.925  1.00 18.70           C  
ATOM   3255  N   ASP B 180      17.996  72.140  20.448  1.00 18.60           N  
ATOM   3256  CA  ASP B 180      19.450  72.019  20.648  1.00 17.50           C  
ATOM   3257  C   ASP B 180      19.773  70.630  21.247  1.00 16.60           C  
ATOM   3258  O   ASP B 180      19.298  70.286  22.348  1.00 16.50           O  
ATOM   3259  CB  ASP B 180      19.868  73.161  21.598  1.00 19.60           C  
ATOM   3260  CG  ASP B 180      21.336  73.541  21.507  1.00 37.10           C  
ATOM   3261  OD1 ASP B 180      22.130  72.854  20.831  1.00 34.50           O  
ATOM   3262  OD2 ASP B 180      21.703  74.513  22.206  1.00 45.30           O  
ATOM   3263  N   HIS B 181      20.580  69.845  20.539  1.00 15.00           N  
ATOM   3264  CA  HIS B 181      20.827  68.474  20.932  1.00 15.50           C  
ATOM   3265  C   HIS B 181      22.222  68.301  21.481  1.00 16.30           C  
ATOM   3266  O   HIS B 181      23.204  68.700  20.861  1.00 17.20           O  
ATOM   3267  CB  HIS B 181      20.619  67.505  19.754  1.00 13.80           C  
ATOM   3268  CG  HIS B 181      19.226  67.244  19.219  1.00 10.70           C  
ATOM   3269  ND1 HIS B 181      18.333  68.210  18.840  1.00 11.70           N  
ATOM   3270  CD2 HIS B 181      18.657  65.983  19.147  1.00 10.30           C  
ATOM   3271  CE1 HIS B 181      17.169  67.551  18.613  1.00 10.50           C  
ATOM   3272  NE2 HIS B 181      17.356  66.287  18.755  1.00 10.40           N  
ATOM   3273  N   TYR B 182      22.312  67.678  22.646  1.00 16.80           N  
ATOM   3274  CA  TYR B 182      23.600  67.315  23.201  1.00 17.10           C  
ATOM   3275  C   TYR B 182      23.603  65.841  23.421  1.00 16.40           C  
ATOM   3276  O   TYR B 182      22.642  65.285  23.964  1.00 17.50           O  
ATOM   3277  CB  TYR B 182      23.824  68.034  24.524  1.00 18.50           C  
ATOM   3278  CG  TYR B 182      23.923  69.510  24.379  1.00 19.00           C  
ATOM   3279  CD1 TYR B 182      22.789  70.284  24.340  1.00 20.50           C  
ATOM   3280  CD2 TYR B 182      25.153  70.123  24.223  1.00 20.20           C  
ATOM   3281  CE1 TYR B 182      22.863  71.630  24.139  1.00 22.10           C  
ATOM   3282  CE2 TYR B 182      25.247  71.473  24.020  1.00 21.50           C  
ATOM   3283  CZ  TYR B 182      24.096  72.218  23.973  1.00 22.50           C  
ATOM   3284  OH  TYR B 182      24.154  73.566  23.729  1.00 25.10           O  
ATOM   3285  N   GLN B 183      24.646  65.186  22.958  1.00 15.20           N  
ATOM   3286  CA  GLN B 183      24.580  63.759  22.828  1.00 15.30           C  
ATOM   3287  C   GLN B 183      25.895  63.127  23.219  1.00 15.10           C  
ATOM   3288  O   GLN B 183      26.964  63.710  23.019  1.00 15.20           O  
ATOM   3289  CB  GLN B 183      24.188  63.387  21.382  1.00 15.80           C  
ATOM   3290  CG  GLN B 183      24.409  61.924  20.980  1.00 13.60           C  
ATOM   3291  CD  GLN B 183      24.027  61.643  19.511  1.00 18.30           C  
ATOM   3292  OE1 GLN B 183      23.337  62.434  18.859  1.00 18.80           O  
ATOM   3293  NE2 GLN B 183      24.442  60.491  19.013  1.00 15.90           N  
ATOM   3294  N   GLN B 184      25.794  61.892  23.692  1.00 15.80           N  
ATOM   3295  CA  GLN B 184      26.917  61.137  24.197  1.00 16.70           C  
ATOM   3296  C   GLN B 184      26.680  59.677  23.848  1.00 16.60           C  
ATOM   3297  O   GLN B 184      25.589  59.146  24.056  1.00 17.00           O  
ATOM   3298  CB  GLN B 184      26.957  61.327  25.736  1.00 19.50           C  
ATOM   3299  CG  GLN B 184      28.196  60.927  26.483  1.00 37.00           C  
ATOM   3300  CD  GLN B 184      28.124  61.378  27.931  1.00 40.90           C  
ATOM   3301  OE1 GLN B 184      27.200  60.999  28.667  1.00 45.30           O  
ATOM   3302  NE2 GLN B 184      29.027  62.259  28.313  1.00 44.00           N  
ATOM   3303  N   ASN B 185      27.730  59.012  23.374  1.00 17.10           N  
ATOM   3304  CA  ASN B 185      27.674  57.629  22.953  1.00 17.40           C  
ATOM   3305  C   ASN B 185      28.874  56.951  23.590  1.00 17.30           C  
ATOM   3306  O   ASN B 185      30.006  57.410  23.448  1.00 18.20           O  
ATOM   3307  CB  ASN B 185      27.792  57.529  21.396  1.00 17.00           C  
ATOM   3308  CG  ASN B 185      26.536  57.970  20.678  1.00  8.40           C  
ATOM   3309  OD1 ASN B 185      25.708  57.150  20.290  1.00 17.90           O  
ATOM   3310  ND2 ASN B 185      26.357  59.257  20.566  1.00 14.30           N  
ATOM   3311  N   THR B 186      28.639  55.807  24.197  1.00 18.50           N  
ATOM   3312  CA  THR B 186      29.698  55.037  24.836  1.00 20.50           C  
ATOM   3313  C   THR B 186      29.426  53.541  24.620  1.00 21.10           C  
ATOM   3314  O   THR B 186      28.265  53.129  24.531  1.00 20.60           O  
ATOM   3315  CB  THR B 186      29.809  55.364  26.400  1.00 19.20           C  
ATOM   3316  OG1 THR B 186      28.534  55.207  27.004  1.00 21.20           O  
ATOM   3317  CG2 THR B 186      30.279  56.801  26.642  1.00 19.10           C  
ATOM   3318  N   PRO B 187      30.498  52.730  24.390  1.00 22.80           N  
ATOM   3319  CA  PRO B 187      30.265  51.326  24.011  1.00 24.50           C  
ATOM   3320  C   PRO B 187      29.714  50.505  25.164  1.00 26.20           C  
ATOM   3321  O   PRO B 187      29.975  50.821  26.336  1.00 26.60           O  
ATOM   3322  CB  PRO B 187      31.642  50.825  23.557  1.00 24.60           C  
ATOM   3323  CG  PRO B 187      32.633  51.762  24.140  1.00 23.30           C  
ATOM   3324  CD  PRO B 187      31.933  53.072  24.426  1.00 23.00           C  
ATOM   3325  N   ILE B 188      28.833  49.568  24.838  1.00 27.60           N  
ATOM   3326  CA  ILE B 188      28.319  48.633  25.815  1.00 29.80           C  
ATOM   3327  C   ILE B 188      29.347  47.534  26.029  1.00 32.70           C  
ATOM   3328  O   ILE B 188      29.519  47.045  27.150  1.00 34.00           O  
ATOM   3329  CB  ILE B 188      27.002  48.012  25.352  1.00 28.60           C  
ATOM   3330  CG1 ILE B 188      25.894  49.067  25.386  1.00 27.90           C  
ATOM   3331  CG2 ILE B 188      26.618  46.850  26.251  1.00 28.30           C  
ATOM   3332  CD1 ILE B 188      24.549  48.599  24.841  1.00 27.80           C  
ATOM   3333  N   GLY B 189      30.128  47.240  24.995  1.00 34.70           N  
ATOM   3334  CA  GLY B 189      31.096  46.163  25.079  1.00 36.50           C  
ATOM   3335  C   GLY B 189      32.487  46.505  25.600  1.00 38.10           C  
ATOM   3336  O   GLY B 189      32.860  47.675  25.766  1.00 38.20           O  
ATOM   3337  N   ASP B 190      33.321  45.466  25.585  1.00 39.80           N  
ATOM   3338  CA  ASP B 190      34.614  45.417  26.288  1.00 41.10           C  
ATOM   3339  C   ASP B 190      35.704  45.786  25.292  1.00 41.40           C  
ATOM   3340  O   ASP B 190      36.749  46.342  25.664  1.00 42.00           O  
ATOM   3341  CB  ASP B 190      34.890  43.985  26.825  1.00 42.30           C  
ATOM   3342  CG  ASP B 190      33.599  43.114  26.953  1.00 76.50           C  
ATOM   3343  OD1 ASP B 190      32.903  42.860  25.924  1.00 73.40           O  
ATOM   3344  OD2 ASP B 190      33.303  42.647  28.086  1.00 87.60           O  
ATOM   3345  N   GLY B 191      35.437  45.458  24.023  1.00 41.30           N  
ATOM   3346  CA  GLY B 191      36.434  45.558  22.964  1.00 39.90           C  
ATOM   3347  C   GLY B 191      36.823  46.976  22.597  1.00 39.00           C  
ATOM   3348  O   GLY B 191      36.205  47.934  23.065  1.00 39.40           O  
ATOM   3349  N   PRO B 192      37.865  47.146  21.774  1.00 38.20           N  
ATOM   3350  CA  PRO B 192      38.252  48.476  21.293  1.00 37.20           C  
ATOM   3351  C   PRO B 192      37.216  49.002  20.294  1.00 35.90           C  
ATOM   3352  O   PRO B 192      36.409  48.239  19.760  1.00 35.80           O  
ATOM   3353  CB  PRO B 192      39.594  48.227  20.630  1.00 37.50           C  
ATOM   3354  CG  PRO B 192      39.378  46.874  20.002  1.00 38.60           C  
ATOM   3355  CD  PRO B 192      38.528  46.085  21.001  1.00 38.40           C  
ATOM   3356  N   VAL B 193      37.094  50.321  20.260  1.00 34.50           N  
ATOM   3357  CA  VAL B 193      36.185  51.002  19.372  1.00 32.70           C  
ATOM   3358  C   VAL B 193      37.036  52.045  18.652  1.00 32.50           C  
ATOM   3359  O   VAL B 193      38.208  52.283  19.005  1.00 32.30           O  
ATOM   3360  CB  VAL B 193      34.988  51.697  20.141  1.00 32.40           C  
ATOM   3361  CG1 VAL B 193      34.030  50.642  20.707  1.00 31.50           C  
ATOM   3362  CG2 VAL B 193      35.509  52.608  21.240  1.00 32.00           C  
ATOM   3363  N   LEU B 194      36.516  52.524  17.531  1.00 31.40           N  
ATOM   3364  CA  LEU B 194      37.213  53.523  16.754  1.00 30.30           C  
ATOM   3365  C   LEU B 194      36.813  54.867  17.311  1.00 29.50           C  
ATOM   3366  O   LEU B 194      35.633  55.204  17.327  1.00 29.80           O  
ATOM   3367  CB  LEU B 194      36.817  53.427  15.263  1.00 30.10           C  
ATOM   3368  CG  LEU B 194      37.175  52.142  14.504  1.00 30.10           C  
ATOM   3369  CD1 LEU B 194      36.597  52.199  13.068  1.00 29.20           C  
ATOM   3370  CD2 LEU B 194      38.703  51.964  14.496  1.00 29.70           C  
ATOM   3371  N   LEU B 195      37.797  55.659  17.703  1.00 28.90           N  
ATOM   3372  CA  LEU B 195      37.531  56.990  18.197  1.00 28.80           C  
ATOM   3373  C   LEU B 195      37.908  57.947  17.085  1.00 28.30           C  
ATOM   3374  O   LEU B 195      39.023  57.897  16.587  1.00 29.10           O  
ATOM   3375  CB  LEU B 195      38.364  57.257  19.459  1.00 29.30           C  
ATOM   3376  CG  LEU B 195      37.902  56.680  20.802  1.00 30.30           C  
ATOM   3377  CD1 LEU B 195      37.769  57.841  21.776  1.00 31.70           C  
ATOM   3378  CD2 LEU B 195      36.564  55.991  20.706  1.00 30.30           C  
ATOM   3379  N   PRO B 196      36.953  58.752  16.591  1.00 28.00           N  
ATOM   3380  CA  PRO B 196      37.144  59.470  15.324  1.00 27.40           C  
ATOM   3381  C   PRO B 196      37.887  60.804  15.465  1.00 27.50           C  
ATOM   3382  O   PRO B 196      37.761  61.497  16.480  1.00 27.90           O  
ATOM   3383  CB  PRO B 196      35.710  59.720  14.864  1.00 27.30           C  
ATOM   3384  CG  PRO B 196      34.996  59.984  16.164  1.00 27.40           C  
ATOM   3385  CD  PRO B 196      35.569  58.907  17.083  1.00 27.70           C  
ATOM   3386  N   ASP B 197      38.448  61.278  14.359  1.00 27.10           N  
ATOM   3387  CA  ASP B 197      38.693  62.709  14.207  1.00 26.70           C  
ATOM   3388  C   ASP B 197      37.368  63.426  13.992  1.00 26.00           C  
ATOM   3389  O   ASP B 197      36.351  62.799  13.689  1.00 25.80           O  
ATOM   3390  CB  ASP B 197      39.641  62.987  13.026  1.00 27.70           C  
ATOM   3391  CG  ASP B 197      41.049  62.464  13.267  1.00 36.20           C  
ATOM   3392  OD1 ASP B 197      41.548  62.610  14.411  1.00 47.10           O  
ATOM   3393  OD2 ASP B 197      41.657  61.906  12.318  1.00 38.60           O  
ATOM   3394  N   ASN B 198      37.382  64.736  14.166  1.00 25.20           N  
ATOM   3395  CA  ASN B 198      36.174  65.519  14.012  1.00 25.80           C  
ATOM   3396  C   ASN B 198      35.669  65.401  12.578  1.00 25.90           C  
ATOM   3397  O   ASN B 198      36.425  65.618  11.616  1.00 26.00           O  
ATOM   3398  CB  ASN B 198      36.405  67.007  14.326  1.00 26.10           C  
ATOM   3399  CG  ASN B 198      37.002  67.247  15.719  1.00 39.10           C  
ATOM   3400  OD1 ASN B 198      36.970  66.380  16.609  1.00 33.90           O  
ATOM   3401  ND2 ASN B 198      37.572  68.431  15.899  1.00 48.70           N  
ATOM   3402  N   HIS B 199      34.367  65.141  12.451  1.00 25.50           N  
ATOM   3403  CA  HIS B 199      33.692  65.084  11.153  1.00 23.30           C  
ATOM   3404  C   HIS B 199      32.215  65.384  11.389  1.00 23.00           C  
ATOM   3405  O   HIS B 199      31.851  65.879  12.461  1.00 22.80           O  
ATOM   3406  CB  HIS B 199      33.887  63.706  10.538  1.00 21.20           C  
ATOM   3407  CG  HIS B 199      33.274  62.543  11.197  1.00 20.10           C  
ATOM   3408  ND1 HIS B 199      33.942  61.966  12.209  1.00 20.20           N  
ATOM   3409  CD2 HIS B 199      32.150  61.870  10.787  1.00 18.90           C  
ATOM   3410  CE1 HIS B 199      33.249  60.840  12.338  1.00 19.50           C  
ATOM   3411  NE2 HIS B 199      32.230  60.766  11.558  1.00 19.70           N  
ATOM   3412  N   TYR B 200      31.373  65.153  10.392  1.00 22.10           N  
ATOM   3413  CA  TYR B 200      29.957  65.340  10.588  1.00 22.00           C  
ATOM   3414  C   TYR B 200      29.158  64.459   9.660  1.00 21.40           C  
ATOM   3415  O   TYR B 200      29.705  63.868   8.722  1.00 21.80           O  
ATOM   3416  CB  TYR B 200      29.576  66.801  10.396  1.00 23.90           C  
ATOM   3417  CG  TYR B 200      29.707  67.277   8.977  1.00 26.80           C  
ATOM   3418  CD1 TYR B 200      28.660  67.122   8.079  1.00 27.50           C  
ATOM   3419  CD2 TYR B 200      30.883  67.876   8.532  1.00 28.00           C  
ATOM   3420  CE1 TYR B 200      28.769  67.546   6.777  1.00 29.60           C  
ATOM   3421  CE2 TYR B 200      31.008  68.310   7.230  1.00 29.40           C  
ATOM   3422  CZ  TYR B 200      29.944  68.138   6.347  1.00 30.00           C  
ATOM   3423  OH  TYR B 200      30.068  68.508   5.019  1.00 31.50           O  
ATOM   3424  N   LEU B 201      27.911  64.221  10.033  1.00 19.50           N  
ATOM   3425  CA  LEU B 201      27.017  63.393   9.240  1.00 18.20           C  
ATOM   3426  C   LEU B 201      26.021  64.317   8.586  1.00 17.90           C  
ATOM   3427  O   LEU B 201      25.409  65.165   9.248  1.00 17.70           O  
ATOM   3428  CB  LEU B 201      26.264  62.380  10.116  1.00 17.00           C  
ATOM   3429  CG  LEU B 201      27.077  61.427  10.979  1.00 16.20           C  
ATOM   3430  CD1 LEU B 201      26.130  60.533  11.754  1.00 17.00           C  
ATOM   3431  CD2 LEU B 201      27.983  60.597  10.140  1.00 16.20           C  
ATOM   3432  N   SER B 202      25.968  64.270   7.264  1.00 16.90           N  
ATOM   3433  CA  SER B 202      24.967  65.034   6.547  1.00 16.00           C  
ATOM   3434  C   SER B 202      23.669  64.221   6.574  1.00 15.30           C  
ATOM   3435  O   SER B 202      23.630  63.117   6.049  1.00 15.20           O  
ATOM   3436  CB  SER B 202      25.450  65.245   5.106  1.00 16.90           C  
ATOM   3437  OG  SER B 202      24.436  65.836   4.336  1.00 18.50           O  
ATOM   3438  N   THR B 203      22.598  64.762   7.146  1.00 14.20           N  
ATOM   3439  CA  THR B 203      21.376  63.982   7.307  1.00 14.00           C  
ATOM   3440  C   THR B 203      20.159  64.644   6.655  1.00 13.30           C  
ATOM   3441  O   THR B 203      19.982  65.866   6.734  1.00 14.60           O  
ATOM   3442  CB  THR B 203      21.094  63.744   8.831  1.00 13.80           C  
ATOM   3443  OG1 THR B 203      22.283  63.243   9.458  1.00 14.80           O  
ATOM   3444  CG2 THR B 203      19.980  62.756   9.028  1.00 13.90           C  
ATOM   3445  N   GLN B 204      19.340  63.845   5.982  1.00 12.90           N  
ATOM   3446  CA  GLN B 204      18.124  64.368   5.389  1.00 12.80           C  
ATOM   3447  C   GLN B 204      17.041  63.409   5.729  1.00 12.20           C  
ATOM   3448  O   GLN B 204      17.230  62.206   5.584  1.00 13.30           O  
ATOM   3449  CB  GLN B 204      18.236  64.435   3.850  1.00 13.10           C  
ATOM   3450  CG  GLN B 204      19.397  65.257   3.371  1.00 15.80           C  
ATOM   3451  CD  GLN B 204      19.627  65.092   1.886  1.00 18.10           C  
ATOM   3452  OE1 GLN B 204      20.618  64.483   1.466  1.00 18.20           O  
ATOM   3453  NE2 GLN B 204      18.748  65.656   1.098  1.00  7.60           N  
ATOM   3454  N   SER B 205      15.852  63.943   5.972  1.00 12.60           N  
ATOM   3455  CA  SER B 205      14.709  63.134   6.375  1.00 14.00           C  
ATOM   3456  C   SER B 205      13.451  63.611   5.696  1.00 13.70           C  
ATOM   3457  O   SER B 205      13.267  64.801   5.522  1.00 15.30           O  
ATOM   3458  CB  SER B 205      14.483  63.195   7.899  1.00 13.60           C  
ATOM   3459  OG  SER B 205      15.720  63.224   8.592  1.00 16.10           O  
ATOM   3460  N   ALA B 206      12.541  62.676   5.432  1.00 14.10           N  
ATOM   3461  CA  ALA B 206      11.218  62.986   4.917  1.00 14.60           C  
ATOM   3462  C   ALA B 206      10.183  62.183   5.690  1.00 13.60           C  
ATOM   3463  O   ALA B 206      10.338  60.973   5.843  1.00 14.10           O  
ATOM   3464  CB  ALA B 206      11.143  62.615   3.435  1.00 14.50           C  
ATOM   3465  N   LEU B 207       9.089  62.837   6.080  1.00 14.10           N  
ATOM   3466  CA  LEU B 207       8.015  62.200   6.856  1.00 15.10           C  
ATOM   3467  C   LEU B 207       6.771  62.061   6.005  1.00 15.40           C  
ATOM   3468  O   LEU B 207       6.344  63.025   5.379  1.00 16.40           O  
ATOM   3469  CB  LEU B 207       7.653  63.031   8.111  1.00 14.50           C  
ATOM   3470  CG  LEU B 207       8.733  63.681   8.968  1.00 14.50           C  
ATOM   3471  CD1 LEU B 207       8.116  64.257  10.240  1.00 14.80           C  
ATOM   3472  CD2 LEU B 207       9.780  62.641   9.302  1.00 14.50           C  
ATOM   3473  N   SER B 208       6.106  60.918   6.092  1.00 16.40           N  
ATOM   3474  CA  SER B 208       4.824  60.747   5.418  1.00 18.00           C  
ATOM   3475  C   SER B 208       3.870  59.868   6.227  1.00 18.60           C  
ATOM   3476  O   SER B 208       4.185  59.446   7.345  1.00 18.70           O  
ATOM   3477  CB  SER B 208       5.042  60.122   4.036  1.00 17.90           C  
ATOM   3478  OG  SER B 208       5.734  58.901   4.189  1.00 19.70           O  
ATOM   3479  N   LYS B 209       2.672  59.680   5.692  1.00 20.10           N  
ATOM   3480  CA  LYS B 209       1.672  58.801   6.274  1.00 22.30           C  
ATOM   3481  C   LYS B 209       1.372  57.692   5.291  1.00 24.60           C  
ATOM   3482  O   LYS B 209       1.431  57.892   4.075  1.00 24.80           O  
ATOM   3483  CB  LYS B 209       0.376  59.559   6.547  1.00 21.10           C  
ATOM   3484  CG  LYS B 209       0.513  60.672   7.533  1.00 21.60           C  
ATOM   3485  CD  LYS B 209       0.786  60.195   8.971  1.00 25.10           C  
ATOM   3486  CE  LYS B 209       0.661  61.389   9.946  1.00 19.90           C  
ATOM   3487  NZ  LYS B 209       1.013  61.061  11.340  1.00 21.30           N  
ATOM   3488  N   ASP B 210       1.151  56.502   5.839  1.00 26.80           N  
ATOM   3489  CA  ASP B 210       0.642  55.352   5.127  1.00 28.90           C  
ATOM   3490  C   ASP B 210      -0.872  55.547   5.067  1.00 31.20           C  
ATOM   3491  O   ASP B 210      -1.536  55.607   6.112  1.00 31.70           O  
ATOM   3492  CB  ASP B 210       1.016  54.094   5.920  1.00 29.00           C  
ATOM   3493  CG  ASP B 210       0.429  52.817   5.352  1.00 38.00           C  
ATOM   3494  OD1 ASP B 210      -0.508  52.879   4.535  1.00 32.60           O  
ATOM   3495  OD2 ASP B 210       0.900  51.733   5.768  1.00 37.10           O  
ATOM   3496  N   PRO B 211      -1.435  55.720   3.853  1.00 32.70           N  
ATOM   3497  CA  PRO B 211      -2.837  56.149   3.851  1.00 33.90           C  
ATOM   3498  C   PRO B 211      -3.824  54.985   4.084  1.00 35.00           C  
ATOM   3499  O   PRO B 211      -5.028  55.216   4.229  1.00 35.70           O  
ATOM   3500  CB  PRO B 211      -3.005  56.842   2.496  1.00 34.00           C  
ATOM   3501  CG  PRO B 211      -1.891  56.321   1.650  1.00 33.90           C  
ATOM   3502  CD  PRO B 211      -0.760  56.005   2.573  1.00 33.20           C  
ATOM   3503  N   ASN B 212      -3.278  53.774   4.271  1.00 35.80           N  
ATOM   3504  CA  ASN B 212      -4.034  52.587   4.749  1.00 36.40           C  
ATOM   3505  C   ASN B 212      -4.077  52.401   6.290  1.00 36.30           C  
ATOM   3506  O   ASN B 212      -4.658  51.420   6.793  1.00 36.10           O  
ATOM   3507  CB  ASN B 212      -3.435  51.288   4.166  1.00 38.00           C  
ATOM   3508  CG  ASN B 212      -3.673  51.132   2.664  1.00 58.30           C  
ATOM   3509  OD1 ASN B 212      -2.816  50.603   1.944  1.00 63.80           O  
ATOM   3510  ND2 ASN B 212      -4.844  51.567   2.191  1.00 59.30           N  
ATOM   3511  N   GLU B 213      -3.318  53.227   7.014  1.00 35.20           N  
ATOM   3512  CA  GLU B 213      -3.044  53.000   8.432  1.00 34.10           C  
ATOM   3513  C   GLU B 213      -3.948  53.865   9.311  1.00 33.60           C  
ATOM   3514  O   GLU B 213      -3.958  55.096   9.197  1.00 33.60           O  
ATOM   3515  CB  GLU B 213      -1.569  53.302   8.727  1.00 34.30           C  
ATOM   3516  CG  GLU B 213      -1.166  53.168  10.169  1.00 24.50           C  
ATOM   3517  CD  GLU B 213      -1.372  51.790  10.678  1.00 31.00           C  
ATOM   3518  OE1 GLU B 213      -0.540  50.927  10.384  1.00 35.40           O  
ATOM   3519  OE2 GLU B 213      -2.407  51.549  11.317  1.00 39.60           O  
ATOM   3520  N   LYS B 214      -4.717  53.216  10.182  1.00 32.90           N  
ATOM   3521  CA  LYS B 214      -5.680  53.929  11.013  1.00 31.90           C  
ATOM   3522  C   LYS B 214      -5.099  54.328  12.363  1.00 31.00           C  
ATOM   3523  O   LYS B 214      -5.577  55.270  12.964  1.00 30.60           O  
ATOM   3524  CB  LYS B 214      -6.937  53.088  11.217  1.00 32.60           C  
ATOM   3525  CG  LYS B 214      -8.049  53.468  10.290  1.00 43.30           C  
ATOM   3526  CD  LYS B 214      -8.570  52.263   9.541  1.00 61.70           C  
ATOM   3527  CE  LYS B 214      -7.577  51.715   8.508  1.00 83.00           C  
ATOM   3528  NZ  LYS B 214      -8.252  50.901   7.445  1.00 82.80           N  
ATOM   3529  N   ARG B 215      -4.049  53.641  12.808  1.00 30.00           N  
ATOM   3530  CA  ARG B 215      -3.318  54.037  14.015  1.00 29.50           C  
ATOM   3531  C   ARG B 215      -2.568  55.343  13.824  1.00 28.00           C  
ATOM   3532  O   ARG B 215      -2.174  55.696  12.710  1.00 27.80           O  
ATOM   3533  CB  ARG B 215      -2.296  52.978  14.413  1.00 31.90           C  
ATOM   3534  CG  ARG B 215      -2.881  51.728  15.032  1.00 35.50           C  
ATOM   3535  CD  ARG B 215      -2.729  50.550  14.097  1.00 38.90           C  
ATOM   3536  NE  ARG B 215      -1.874  49.493  14.629  1.00 41.90           N  
ATOM   3537  CZ  ARG B 215      -0.973  48.817  13.913  1.00 43.10           C  
ATOM   3538  NH1 ARG B 215      -0.634  49.212  12.680  1.00 43.40           N  
ATOM   3539  NH2 ARG B 215      -0.340  47.791  14.470  1.00 44.30           N  
ATOM   3540  N   ASP B 216      -2.293  56.021  14.927  1.00 25.60           N  
ATOM   3541  CA  ASP B 216      -1.411  57.171  14.890  1.00 23.40           C  
ATOM   3542  C   ASP B 216       0.009  56.670  14.609  1.00 22.00           C  
ATOM   3543  O   ASP B 216       0.494  55.722  15.244  1.00 21.80           O  
ATOM   3544  CB  ASP B 216      -1.477  57.916  16.218  1.00 23.90           C  
ATOM   3545  CG  ASP B 216      -1.004  59.353  16.112  1.00 23.30           C  
ATOM   3546  OD1 ASP B 216      -0.817  59.879  14.994  1.00 23.10           O  
ATOM   3547  OD2 ASP B 216      -0.855  59.990  17.170  1.00 27.90           O  
ATOM   3548  N   HIS B 217       0.626  57.217  13.568  1.00 20.50           N  
ATOM   3549  CA  HIS B 217       1.861  56.648  13.039  1.00 19.10           C  
ATOM   3550  C   HIS B 217       2.662  57.679  12.257  1.00 17.30           C  
ATOM   3551  O   HIS B 217       2.154  58.754  11.901  1.00 16.90           O  
ATOM   3552  CB  HIS B 217       1.550  55.433  12.140  1.00 19.90           C  
ATOM   3553  CG  HIS B 217       0.973  55.799  10.764  1.00 20.50           C  
ATOM   3554  ND1 HIS B 217      -0.274  56.363  10.523  1.00 21.60           N  
ATOM   3555  CD2 HIS B 217       1.671  55.781   9.537  1.00 20.50           C  
ATOM   3556  CE1 HIS B 217      -0.358  56.713   9.187  1.00 20.70           C  
ATOM   3557  NE2 HIS B 217       0.767  56.433   8.690  1.00 20.20           N  
ATOM   3558  N   MET B 218       3.928  57.341  12.034  1.00 16.20           N  
ATOM   3559  CA  MET B 218       4.824  58.133  11.218  1.00 15.20           C  
ATOM   3560  C   MET B 218       5.676  57.189  10.387  1.00 14.70           C  
ATOM   3561  O   MET B 218       6.080  56.127  10.864  1.00 14.40           O  
ATOM   3562  CB  MET B 218       5.720  59.001  12.082  1.00 15.00           C  
ATOM   3563  CG  MET B 218       6.820  59.666  11.328  1.00 15.60           C  
ATOM   3564  SD  MET B 218       7.823  60.806  12.274  1.00 17.80           S  
ATOM   3565  CE  MET B 218       8.766  59.714  13.179  1.00 15.10           C  
ATOM   3566  N   VAL B 219       5.794  57.500   9.095  1.00 14.20           N  
ATOM   3567  CA  VAL B 219       6.749  56.819   8.216  1.00 13.30           C  
ATOM   3568  C   VAL B 219       7.928  57.770   8.008  1.00 12.40           C  
ATOM   3569  O   VAL B 219       7.739  58.966   7.770  1.00 12.00           O  
ATOM   3570  CB  VAL B 219       6.084  56.435   6.823  1.00 13.50           C  
ATOM   3571  CG1 VAL B 219       7.133  55.750   5.881  1.00 12.90           C  
ATOM   3572  CG2 VAL B 219       4.879  55.517   7.057  1.00 13.00           C  
ATOM   3573  N   LEU B 220       9.133  57.263   8.224  1.00 12.90           N  
ATOM   3574  CA  LEU B 220      10.328  58.091   8.150  1.00 14.00           C  
ATOM   3575  C   LEU B 220      11.256  57.486   7.112  1.00 15.30           C  
ATOM   3576  O   LEU B 220      11.540  56.275   7.173  1.00 16.40           O  
ATOM   3577  CB  LEU B 220      11.037  58.113   9.514  1.00 14.00           C  
ATOM   3578  CG  LEU B 220      12.428  58.730   9.639  1.00 14.80           C  
ATOM   3579  CD1 LEU B 220      12.395  60.219   9.363  1.00 14.10           C  
ATOM   3580  CD2 LEU B 220      12.953  58.453  11.047  1.00 14.60           C  
ATOM   3581  N   LEU B 221      11.685  58.310   6.149  1.00 16.00           N  
ATOM   3582  CA  LEU B 221      12.758  57.961   5.206  1.00 16.40           C  
ATOM   3583  C   LEU B 221      13.921  58.886   5.500  1.00 14.90           C  
ATOM   3584  O   LEU B 221      13.726  60.087   5.578  1.00 14.80           O  
ATOM   3585  CB  LEU B 221      12.317  58.225   3.758  1.00 19.00           C  
ATOM   3586  CG  LEU B 221      12.043  57.046   2.844  1.00 21.20           C  
ATOM   3587  CD1 LEU B 221      13.310  56.164   2.691  1.00 23.20           C  
ATOM   3588  CD2 LEU B 221      10.850  56.295   3.417  1.00 22.90           C  
ATOM   3589  N   GLU B 222      15.133  58.357   5.542  1.00 13.90           N  
ATOM   3590  CA  GLU B 222      16.264  59.172   5.939  1.00 15.10           C  
ATOM   3591  C   GLU B 222      17.525  58.736   5.194  1.00 14.70           C  
ATOM   3592  O   GLU B 222      17.721  57.539   4.957  1.00 15.70           O  
ATOM   3593  CB  GLU B 222      16.447  59.032   7.477  1.00 15.90           C  
ATOM   3594  CG  GLU B 222      17.164  60.165   8.137  1.00 19.30           C  
ATOM   3595  CD  GLU B 222      16.977  60.157   9.665  1.00 16.40           C  
ATOM   3596  OE1 GLU B 222      17.663  59.374  10.330  1.00 18.20           O  
ATOM   3597  OE2 GLU B 222      16.165  60.954  10.180  1.00 16.50           O  
ATOM   3598  N   PHE B 223      18.349  59.700   4.798  1.00 14.70           N  
ATOM   3599  CA  PHE B 223      19.595  59.417   4.101  1.00 15.40           C  
ATOM   3600  C   PHE B 223      20.681  60.062   4.920  1.00 14.80           C  
ATOM   3601  O   PHE B 223      20.542  61.217   5.314  1.00 15.80           O  
ATOM   3602  CB  PHE B 223      19.597  60.049   2.707  1.00 16.90           C  
ATOM   3603  CG  PHE B 223      18.592  59.441   1.763  1.00 18.10           C  
ATOM   3604  CD1 PHE B 223      18.920  58.316   1.014  1.00 18.40           C  
ATOM   3605  CD2 PHE B 223      17.284  59.905   1.729  1.00 18.80           C  
ATOM   3606  CE1 PHE B 223      17.972  57.665   0.262  1.00 18.10           C  
ATOM   3607  CE2 PHE B 223      16.324  59.243   0.961  1.00 19.50           C  
ATOM   3608  CZ  PHE B 223      16.680  58.123   0.233  1.00 18.70           C  
ATOM   3609  N   VAL B 224      21.737  59.322   5.205  1.00 14.40           N  
ATOM   3610  CA  VAL B 224      22.801  59.848   6.035  1.00 15.20           C  
ATOM   3611  C   VAL B 224      24.144  59.468   5.432  1.00 15.30           C  
ATOM   3612  O   VAL B 224      24.444  58.267   5.292  1.00 14.50           O  
ATOM   3613  CB  VAL B 224      22.778  59.276   7.539  1.00 14.60           C  
ATOM   3614  CG1 VAL B 224      23.620  60.172   8.421  1.00 14.40           C  
ATOM   3615  CG2 VAL B 224      21.399  59.133   8.044  1.00 13.70           C  
ATOM   3616  N   THR B 225      25.003  60.478   5.300  1.00 16.20           N  
ATOM   3617  CA  THR B 225      26.356  60.310   4.796  1.00 19.10           C  
ATOM   3618  C   THR B 225      27.373  61.094   5.605  1.00 19.10           C  
ATOM   3619  O   THR B 225      27.182  62.275   5.868  1.00 19.60           O  
ATOM   3620  CB  THR B 225      26.463  60.794   3.335  1.00 19.30           C  
ATOM   3621  OG1 THR B 225      25.508  60.096   2.543  1.00 22.30           O  
ATOM   3622  CG2 THR B 225      27.812  60.468   2.780  1.00 20.50           C  
ATOM   3623  N   ALA B 226      28.501  60.461   5.881  1.00 20.70           N  
ATOM   3624  CA  ALA B 226      29.571  61.100   6.632  1.00 23.30           C  
ATOM   3625  C   ALA B 226      30.411  62.003   5.729  1.00 24.50           C  
ATOM   3626  O   ALA B 226      30.670  61.674   4.581  1.00 25.50           O  
ATOM   3627  CB  ALA B 226      30.454  60.024   7.316  1.00 22.20           C  
ATOM   3628  N   ALA B 227      30.859  63.126   6.267  1.00 26.70           N  
ATOM   3629  CA  ALA B 227      31.689  64.068   5.537  1.00 28.40           C  
ATOM   3630  C   ALA B 227      32.623  64.802   6.514  1.00 31.00           C  
ATOM   3631  O   ALA B 227      32.613  64.537   7.732  1.00 29.60           O  
ATOM   3632  CB  ALA B 227      30.804  65.049   4.830  1.00 27.20           C  
ATOM   3633  N   GLY B 228      33.472  65.668   5.950  1.00 34.10           N  
ATOM   3634  CA  GLY B 228      34.232  66.628   6.743  1.00 37.50           C  
ATOM   3635  C   GLY B 228      35.723  66.352   6.917  1.00 40.00           C  
ATOM   3636  O   GLY B 228      36.434  67.177   7.513  1.00 41.20           O  
ATOM   3637  N   ILE B 229      36.167  65.162   6.509  1.00 41.80           N  
ATOM   3638  CA  ILE B 229      37.574  64.763   6.580  1.00 44.20           C  
ATOM   3639  C   ILE B 229      38.105  64.606   5.137  1.00 46.50           C  
ATOM   3640  O   ILE B 229      37.375  64.120   4.259  1.00 47.30           O  
ATOM   3641  CB  ILE B 229      37.727  63.406   7.338  1.00 43.40           C  
ATOM   3642  CG1 ILE B 229      37.484  63.603   8.838  1.00 42.80           C  
ATOM   3643  CG2 ILE B 229      39.117  62.821   7.117  1.00 43.50           C  
ATOM   3644  CD1 ILE B 229      37.302  62.306   9.573  1.00 42.30           C  
ATOM   3645  N   THR B 230      39.340  65.048   4.879  1.00 48.70           N  
ATOM   3646  CA  THR B 230      39.969  64.839   3.566  1.00 50.40           C  
ATOM   3647  C   THR B 230      41.207  63.924   3.637  1.00 51.30           C  
ATOM   3648  O   THR B 230      41.290  63.017   2.763  1.00 52.60           O  
ATOM   3649  CB  THR B 230      40.340  66.202   2.873  1.00 50.80           C  
ATOM   3650  OG1 THR B 230      40.899  67.112   3.841  1.00 52.20           O  
ATOM   3651  CG2 THR B 230      39.087  66.844   2.233  1.00 50.40           C  
CONECT  480  495                                                                
CONECT  495  480                                                                
CONECT 2306 2321                                                                
CONECT 2321 2306                                                                
MASTER      397    0    0   12   24    0    0    9 3950    2    4   38          
END                                                                             
`;
