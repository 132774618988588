import { Button } from "antd";
import React from "react";
import { AnalysisRequestObject } from "../../Types/AnalysisRequestObject";
import { AdvancedParametersSegment } from "./Components/AdvancedParameters";
import { ChooseNameSegment } from "./Components/ChooseNameSegment";
import { ProteinSegment } from "./Components/ProteinSegment";
import { FluorescenceSegment } from "./Components/FluorophoreSegment";
import { PendingRequest } from "../../Types/PendingRequest";

export enum StructureDb {
  PDB = "PDB",
  AlphaFoldDB = "AlphaFoldDB",
}

export interface LabelizerLayoutDataProps {
  data: AnalysisRequestObject;
  changeData: (newData: AnalysisRequestObject) => void;
}

export interface LabelizerLayoutProps {
  loadProteinDB: (
    proteinDbId: string,
    fieldIndex: number,
    db: StructureDb
  ) => void;
  executeAnalysis: () => void;
  pendingRequests: PendingRequest[];
  postProteinDB: (
    payload: { pdbID: string; chains: string; payload: any },
    fieldIndex: number
  ) => void;
}

type Props = { fluorohpores: string[] } & LabelizerLayoutDataProps &
  LabelizerLayoutProps;
const LabelizerLayout: React.FC<Props> = (props) => {
  const {
    data,
    changeData,
    loadProteinDB,
    executeAnalysis,
    postProteinDB,
    pendingRequests,
    fluorohpores,
  } = props;

  return (
    <div className="worksurface-body">
      <Section border={true}>
        <ChooseNameSegment data={data} changeData={changeData} />
      </Section>
      <Section border={true}>
        <ProteinSegment
          postProteinDB={postProteinDB}
          pendingRequests={pendingRequests}
          data={data}
          changeData={changeData}
          loadProteinDB={loadProteinDB}
          executeAnalysis={executeAnalysis}
        />
      </Section>
      <Section>
        <FluorescenceSegment
          fluorohpores={fluorohpores}
          data={data}
          changeData={changeData}
        />
      </Section>
      <div className="start-section">
        <div className="start-frame">
          <Button onClick={() => executeAnalysis()} className="start-button">
            Start Analysis
          </Button>
        </div>
      </div>
      <Section>
        <AdvancedParametersSegment data={data} changeData={changeData} />
      </Section>
    </div>
  );
};

interface SectionProps {
  children: React.ReactNode;
  border?: boolean;
}
const Section: React.FC<SectionProps> = (props) => (
  <div className="section-frame-padding">
    <div className="section-body">{props.children}</div>
    {props.border ? <div className="border" /> : null}
  </div>
);

export default LabelizerLayout;
