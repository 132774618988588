import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";

export interface SmartsliderProps {
  value: any;
  options: any[];
  changeData: (value: string) => void;
}

export const SmartSlider: React.FC<SmartsliderProps> = (props) => {
  const { value, options, changeData } = props;

  const localValue = options.includes(value)
    ? options.findIndex((val: any) => val === value)
    : 0;
  let marks: { [key: number]: any } = {};
  options.forEach((mark: string, idx: number) => (marks[idx] = mark));

  return (
    <div>
      <Slider
        min={0}
        max={options.length - 1}
        marks={marks}
        value={localValue}
        onChange={(keyIndex: number) => changeData(options[keyIndex])}
      />
    </div>
  );
};
