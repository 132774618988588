export enum ParameterName {
  ConservationScore = "Conservation score",
  SolventExposure = "Solvent exposure",
  CysteineResemblance = "Cysteine resemblance",
  SecondaryStructure = "Secondary structure",
  AAExclusion = "Amino acid exclusion",
  TryptophaneProximity = "Tryptophan proximity",
  ChargeEnvironment = "Charge environment",
}

export function toFileTag(parameterName: ParameterName): string {
  switch (parameterName) {
    case ParameterName.ConservationScore:
      return "cs";
    case ParameterName.SolventExposure:
      return "se";
    case ParameterName.CysteineResemblance:
      return "cr";
    case ParameterName.SecondaryStructure:
      return "ss";
    case ParameterName.AAExclusion:
      // Originally Methionine Exclusion
      return "me";
    case ParameterName.TryptophaneProximity:
      return "tp";
    case ParameterName.ChargeEnvironment:
      return "ce";
  }
}

export const Parameters: Array<ParameterName> = [
  ParameterName.ConservationScore,
  ParameterName.SolventExposure,
  ParameterName.CysteineResemblance,
  ParameterName.SecondaryStructure,
  ParameterName.AAExclusion,
  // never happened
  //ParameterName.TryptophaneProximity,
  //ParameterName.ChargeEnvironment,
];
