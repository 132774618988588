import * as _ from "lodash";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { getRequest, postRequest } from "../../services/apicalls";
import { addressBuilder } from "../../utils/addressbuilder";
import LabelizerLayout, { StructureDb } from "./LabelizerLayout";
import { AnalysisRequestObject } from "../../Types/AnalysisRequestObject";
import { PendingRequest } from "../../Types/PendingRequest";
import { convertChainStringToChainOptions } from "../../Types/Protein";
import { notification } from "antd";
import { Headline } from "../../GenericComponents/Headline";
import { ParameterName, toFileTag } from "../../Constants/Parameters";

type Stages = "START";

export interface LabelizerPageProps {
  history: any;
}

export interface LabelizerPageState {
  fluorohpores: string[];
  stage: Stages;
  data: AnalysisRequestObject;
  pendingRequests: PendingRequest[];
}

class LabelizerContainer extends React.Component<
  LabelizerPageProps,
  LabelizerPageState
> {
  constructor(props: LabelizerPageProps) {
    super(props);
    const advancedParameters: Array<ParameterName> = [
      ParameterName.ConservationScore,
      ParameterName.SolventExposure,
      ParameterName.CysteineResemblance,
      ParameterName.SecondaryStructure,
    ];
    this.state = {
      fluorohpores: [],
      stage: "START",
      data: {
        uuid: uuidv4(),
        analysisName: "",
        analysisMode: "LABELLING_ONE_FLUOROPHORE_ONE_STRUCTURE",
        proteinId1: undefined,
        protein1: "",
        pdbId1: undefined,
        dbName1: undefined,
        selectableChainString1: "",
        selectedChain1: "",
        file1: undefined,
        proteinId2: undefined,
        protein2: "",
        pdbId2: undefined,
        dbName2: undefined,
        selectableChainString2: "",
        selectedChain2: "",
        file2: undefined,
        fluorophore1: "",
        fluorophore2: "",
        foersterRadius: undefined,
        advancedParameters: Object.fromEntries(
          advancedParameters.map((name) => [
            name,
            {
              name: name,
              file_tag: toFileTag(name),
              sensitivity: "medium",
              weight: "normal",
              used: true,
            },
          ])
        ),
      },
      pendingRequests: [],
    };
  }

  componentDidMount() {
    this.getFluorophores();
  }

  //
  componentDidUpdate(
    prevProps: LabelizerPageProps,
    prevState: LabelizerPageState
  ) {
    const { fluorophore1, fluorophore2 } = this.state.data;
    if (fluorophore1 !== "" && fluorophore2 !== "") {
      if (
        prevState.data.fluorophore1 !== fluorophore1 ||
        prevState.data.fluorophore2 !== fluorophore2
      ) {
        this.getFoersterRadius();
      }
    }
  }

  getFoersterRadius = () => {
    const pendingRequest: PendingRequest = {
      id: "foersterRadius",
    };
    const processFoersterradius = (resopnse: any) => {
      this.setState({
        pendingRequests: this.state.pendingRequests.filter(
          (x: PendingRequest) => x.id !== pendingRequest.id
        ),
        data: {
          ...this.state.data,
          foersterRadius: resopnse.data.foersterRadius,
        },
      });
    };

    const handleError = (_response: any) => {
      this.setState({
        pendingRequests: this.state.pendingRequests.filter(
          (x: PendingRequest) => x.id !== pendingRequest.id
        ),
        data: { ...this.state.data, foersterRadius: undefined },
      });
    };

    this.setState({
      pendingRequests: this.state.pendingRequests.concat(pendingRequest),
      data: { ...this.state.data, foersterRadius: -1 },
    });

    postRequest(
      addressBuilder(`foersterRadius`),
      {
        fluorohpore1: this.state.data.fluorophore1,
        fluorohpore2: this.state.data.fluorophore2,
      },
      processFoersterradius,
      handleError
    );
  };

  processProteinData = (
    response: any,
    fieldIndex: number,
    data: AnalysisRequestObject,
    pendingRequest: PendingRequest,
    db: string
  ) => {
    if (fieldIndex === 1) {
      data[`proteinId1`] = response.data.id;
      data[`protein1`] = response.data.title;
      data[`pdbId1`] = response.data.pdb_id;
      data[`dbName1`] = db;
      data[`selectableChainString1`] = response.data.chains;
      const chains = convertChainStringToChainOptions(
        `${response.data.chains}`
      );
      if (chains.length > 0) {
        data["selectedChain1"] = chains[0].label;
      }
    }
    if (fieldIndex === 2) {
      data[`proteinId2`] = response.data.id;
      data[`protein2`] = response.data.title;
      data[`pdbId2`] = response.data.pdb_id;
      data[`dbName2`] = db;
      data[`selectableChainString2`] = response.data.chains;
      const chains = convertChainStringToChainOptions(
        `${response.data.chains}`
      );
      if (chains.length > 0) {
        data["selectedChain2"] = chains[0].label;
      }
    }
    this.setState({
      data: _.cloneDeep(data),
      pendingRequests: this.state.pendingRequests.filter(
        (x: PendingRequest) => x.id !== pendingRequest.id
      ),
    });
  };

  loadProteinDB = (
    proteinDbId: string,
    fieldIndex: number,
    db: StructureDb
  ) => {
    let { data } = this.state;
    const pendingRequest: PendingRequest = {
      id: fieldIndex.toString(),
    };

    this.setState({
      pendingRequests: this.state.pendingRequests.concat(pendingRequest),
    });

    const handleError = (_response: any) => {
      this.setState({
        pendingRequests: this.state.pendingRequests.filter(
          (x: PendingRequest) => x.id !== pendingRequest.id
        ),
      });
    };

    getRequest(
      addressBuilder(`load_pdb/${proteinDbId}?db=${db}`),
      (response: any) =>
        this.processProteinData(response, fieldIndex, data, pendingRequest, db),
      handleError
    );
  };

  postProteinDB = (
    payload: { pdbID: string; chains: string; payload: any },
    fieldIndex: number
  ): void => {
    let { data } = this.state;
    const pendingRequest: PendingRequest = {
      id: fieldIndex.toString(),
    };
    this.setState({
      pendingRequests: this.state.pendingRequests.concat(pendingRequest),
    });

    const handleError = (_response: any) => {
      this.setState({
        pendingRequests: this.state.pendingRequests.filter(
          (x: PendingRequest) => x.id !== pendingRequest.id
        ),
      });
    };

    postRequest(
      addressBuilder(`load_pdb`),
      payload,
      (response: any) =>
        this.processProteinData(
          response,
          fieldIndex,
          data,
          pendingRequest,
          "CUSTOM"
        ),
      handleError
    );
  };

  getFluorophores = () => {
    const pendingRequest: PendingRequest = {
      id: "fluorohpores",
    };
    const processFluorohpores = (resopnse: any) => {
      this.setState({
        pendingRequests: this.state.pendingRequests.filter(
          (x: PendingRequest) => x.id !== pendingRequest.id
        ),
        fluorohpores: resopnse.data.fluorophores,
      });
    };

    const handleError = (_response: any) => {
      this.setState({
        pendingRequests: this.state.pendingRequests.filter(
          (x: PendingRequest) => x.id !== pendingRequest.id
        ),
      });
    };

    this.setState({
      pendingRequests: this.state.pendingRequests.concat(pendingRequest),
    });

    getRequest(
      addressBuilder(`fluorophores`),
      processFluorohpores,
      handleError
    );
  };

  executeAnalysis = () => {
    const { data } = this.state;
    const { history } = this.props;
    const processPostResponse = (response: any) => {
      const { jobId } = response.data;
      history.push(`/results/${jobId}`);
    };

    if (
      data.proteinId1 === undefined ||
      (data.analysisMode === "FRET_TWO_STRUCTURES_APO_HOLO" &&
        data.proteinId2 === undefined)
    ) {
      notification.warn({
        message: "Can not start analysis",
        description:
          "Please select in single mode at least one Protein and in FRET mode exactly tw o proteins",
        duration: 10,
      });
    } else if (data.proteinId1 !== undefined && data.selectedChain1 === "") {
      notification.warn({
        message: "Can not start analysis",
        description: "Please at least one chain for protein 1",
        duration: 10,
      });
    } else if (data.proteinId2 !== undefined && data.selectedChain2 === "") {
      notification.warn({
        message: "Can not start analysis",
        description: "Please at least one chain for protein 2",
        duration: 10,
      });
    } else {
      postRequest(
        addressBuilder(`analysis`),
        data,
        processPostResponse,
        (_response: any) => {}
      );
    }
  };

  render() {
    const { data, fluorohpores } = this.state;
    return (
      <div className="labelizer-page">
        <Headline history={this.props.history} />
        <LabelizerLayout
          fluorohpores={fluorohpores}
          postProteinDB={this.postProteinDB}
          pendingRequests={this.state.pendingRequests}
          executeAnalysis={this.executeAnalysis}
          loadProteinDB={this.loadProteinDB}
          data={data}
          changeData={(newData: any) => this.setState({ data: newData })}
        />
      </div>
    );
  }
}

export default LabelizerContainer;
