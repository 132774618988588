import React from "react";
import { Headline } from "../GenericComponents/Headline";
//import { Input } from 'antd';
// const { TextArea } = Input;

export interface FeedbackPageContainerProps {
  history: any;
}

export interface FeedbackPageContainerState {}

class FeedbackContainer extends React.Component<
  FeedbackPageContainerProps,
  FeedbackPageContainerState
> {
  componentDidMount() {}

  render() {
    const red = () => {
      return "#c0392b";
    };
    const darkGray = () => {
      return "#979a9a";
    };

    return (
      <div className="informational-page">
        <Headline history={this.props.history} />
        <div className="informational-content">
          <h1>Feedback</h1>
          We encourage all researchers using the labelizer script or webserver to provide feedback 
          for improvement of the algorithm, the webserver and with that to contribute to a larger 
          database and better prediction. 
          <h2>Content of Feedback</h2>
          If you used the labelizer and experimentally tested the predictions made, we would be 
          grateful if you could send us the following information 
          (to <a href="mailto: labelizer-feedback@bio.lmu.de">labelizer-feedback@bio.lmu.de</a>): 
          <ul>
            <li>
              Analysis identifier (UUID) from the URL of the labelizer analysis in this format:{" "}
              <span style={{ color: darkGray() }}>
                http://labelizer.net/results/
              </span>
              <span style={{ color: red() }}>
                cf7ef919-a191-3279-b7dd-f6921379e4bb
              </span>
            </li>
            <li>
              4-letter PDB identifier (e.g. 1OIB) and short summary of your protein (name, function, relevant papers) 
            </li>
            <li>
              Selected residues (chain and residue number); optionally you can provide reasoning why 
              you selected them based on your previous knowledge and our analysis 
            </li>
            <li>
              Tested fluorophores with a statement whether labeling was successful and what the degree of labelling was
            </li>
            <li>
              If you conducted any further characterization we would be happy to know how the labelled 
              and unlabelled protein behaves (affinity measurements, biochemical activity etc.)
            </li>
            <li>
              If the prediction did not give you suitable residues, do you have any explanation for this? 
            </li>
          </ul>
          <h2>Problems, bug fixing and other requests</h2>
          <p>
            If you encounter problems or if you have suggestions for improvements 
            of our webserver, we are open for feedback – just send an mail to {" "}
            <a href="mailto: labelizer-feedback@bio.lmu.de">Thorben Cordes</a>. 
            In urgent cases, such as bugs or other problems occuring, feel free to 
            directly contact <a href="mailto: robin.schenk@tum.de">Robin Schenk</a>. 
          </p>
        </div>
      </div>
    );
  }
}

//          <div>
//            Tell us your feedback about this website in general and this service. Are there features you would like to see? Is there something we could do more intuitive?
//            <TextArea maxLength={2000} />
//          </div>
//          <h2>Result Feedback</h2>
//          <div>
//            Did our results help you conduct your experiment? Was it successful? Was it unseucccesfull? Tell us so we can improve this service's quality!
//            <TextArea maxLength={2000} />
//          </div>

export default FeedbackContainer;
