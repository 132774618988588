import React from "react";
import {
  LabelizerLayoutDataProps,
  LabelizerLayoutProps,
} from "../LabelizerLayout";
import ProteinUploadSegment from "./ProteinUploadSegment";
import * as _ from "lodash";
import { Helpertexts } from "../../../Constants/HelperTexts";
import { Radio, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export type ProteinSegmentProps = LabelizerLayoutProps &
  LabelizerLayoutDataProps & {
    postProteinDB: (
      payload: { pdbID: string; chains: string; payload: any },
      fieldIndex: number
    ) => void;
  };
export const ProteinSegment = (props: ProteinSegmentProps) => {
  const { analysisMode } = props.data;

  return (
    <div className="choose-protein">
      <h2>2. Protein Data</h2>
      <ChooseModeComponent {...props} />
      <ProteinUploadSegment
        {...props}
        changeProteinName={(val: any) =>
          props.changeData({
            ...props.data,
            protein1: val,
          })
        }
        index={1}
        clearPdb={() =>
          props.changeData({
            ...props.data,
            protein1: "",
            pdbId1: undefined,
          })
        }
        changeSelectedChain={(chain: string) =>
          props.changeData({ ...props.data, selectedChain1: chain })
        }
      />

      {analysisMode === "FRET_TWO_STRUCTURES_APO_HOLO" ? (
        <ProteinUploadSegment
          {...props}
          changeProteinName={(val: any) =>
            props.changeData({
              ...props.data,
              protein2: val,
            })
          }
          index={2}
          clearPdb={() =>
            props.changeData({
              ...props.data,
              protein2: "",
              pdbId2: undefined,
            })
          }
          changeSelectedChain={(chain: string) =>
            props.changeData({ ...props.data, selectedChain2: chain })
          }
        />
      ) : null}
    </div>
  );
};

const ChooseModeComponent: React.FC<LabelizerLayoutDataProps> = (props) => {
  const { data, changeData } = props;
  const { analysisMode } = props.data;

  const onClickSwitchHandler = (event: any) => {
    const { value } = event.target;
    let newData = _.cloneDeep(data);
    newData.analysisMode = value;
    newData.fluorophore1 = "";
    newData.fluorophore2 = "";
    changeData(newData);
  };
  return (
    <div className="choose-mode-content">
      <div className="headline">Analysis Mode</div>
      <div className="choose-mode-input-frame">
        <div className="toggle-frame">
          <Radio.Group onChange={onClickSwitchHandler} value={analysisMode}>
            <Radio
              style={{ display: "block", lineHeight: "24pt" }}
              value={"LABELLING_ONE_FLUOROPHORE_ONE_STRUCTURE"}
            >
              <Tooltip
                placement="rightBottom"
                title={
                  Helpertexts["analysisMode"][
                    "LABELLING_ONE_FLUOROPHORE_ONE_STRUCTURE"
                  ]
                }
              >
                <span>
                  Single Label <InfoCircleOutlined className="icon" />
                </span>
              </Tooltip>
            </Radio>
            <Radio
              style={{ display: "block", lineHeight: "24pt" }}
              value={"FRET_ONE_STRUCTURE"}
            >
              <Tooltip
                placement="rightBottom"
                title={Helpertexts["analysisMode"]["FRET_ONE_STRUCTURE"]}
              >
                <span>
                  Single FRET <InfoCircleOutlined className="icon" />
                </span>
              </Tooltip>
            </Radio>
            <Radio
              style={{ display: "block", lineHeight: "24pt" }}
              value={"FRET_TWO_STRUCTURES_APO_HOLO"}
            >
              <Tooltip
                placement="rightBottom"
                title={
                  Helpertexts["analysisMode"]["FRET_TWO_STRUCTURES_APO_HOLO"]
                }
              >
                <span>
                  FRET <InfoCircleOutlined className="icon" />
                </span>
              </Tooltip>
            </Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};
