import React from "react";
import { Button, Input } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import Info from "../../../GenericComponents/info";
import { SelectChainRadio } from "./SelectChainRadio";
import PdbViewer from "./PdbViewer";

type DisplaySelectedProteinProps = {
  chainOptions: any[];
  changeSelectedChain: (chain: string) => void;
  selectedChain: string | undefined;
  protein: string | undefined;
  pdbId: string | undefined;
  dbName: string | undefined;
  id: string | undefined;
  clearPdb: () => void;
  index: number;
  changeProteinName: (name: string) => void;
};
export const DisplaySelectedProtein: React.FC<DisplaySelectedProteinProps> = (
  props
) => {
  const {
    chainOptions,
    changeSelectedChain,
    selectedChain,
    protein,
    pdbId,
    dbName,
    id,
    clearPdb,
    changeProteinName,
    index,
  } = props;

  return (
    <div className="display-grid">
      <div className="display-pdbid">
        <div className="remove-selection-frame">
          <div className="headline">{dbName + " ID"}</div>
          <Button className="button" onClick={() => clearPdb()}>
            <DeleteFilled className="trashcan" />
            {pdbId}
          </Button>
        </div>
        <SelectChainRadio
          chainOptions={chainOptions}
          changeSelectedChain={changeSelectedChain}
          selectedChain={selectedChain}
        />
      </div>

      <div className="pdb-viewer-frame">
        <PdbViewer id={id} pdbId={pdbId} index={index} />
      </div>

      <div className="choose-proteinname-input">
        <div className="headline">
          Protein Name
          <Info infotext="You can give this Protein a name. This name does not affect the analysis in any way. It is just for later referencing" />{" "}
        </div>
        <Input
          value={protein}
          onChange={(val: any) => changeProteinName(val.target.value)}
          className="text"
          placeholder="(optional)"
        />
      </div>
    </div>
  );
};
