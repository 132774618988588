import * as config from "../config.json";

let backendConfiguration = {
  host: "",
  protocol: "",
  port: -1,
};
if (process.env.NODE_ENV === "development") {
  backendConfiguration = { ...backendConfiguration, ...config.dev };
} else {
  backendConfiguration = { ...backendConfiguration, ...config.prod };
}

export function addressBuilder(
  path: string,
  parameters?: { key: string; val: string }[]
): URL {
  const baseURL: string = `${backendConfiguration.protocol}://${backendConfiguration.host}/backend/`;
  return new URL(`${baseURL}${path}`);
}
