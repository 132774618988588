import { default as axios, AxiosError } from "axios";
import { notification } from "antd";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export function getRequest(
  url: URL,
  successCallback: (response: any) => void,
  errorCallback: (response: any) => void
) {
  return axios
    .get(url.href)
    .then((resSuccess: any) => {
      if (resSuccess.status === 200) {
        successCallback(resSuccess);
      } else {
        errorCallback(resSuccess);
      }
    })
    .catch((resError: any) => {
      processError(resError);
      errorCallback(resError);
    });
}

export function getZip(url: URL, successCallback: (response: any) => void) {
  axios
    .get(url.href, { responseType: "arraybuffer" })
    .then((response: any) => {
      if (response.status === 200 || response.status === 0) {
        return new Blob([response.data], { type: "application/zip" });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    })
    .then(JSZip.loadAsync)
    .then((zip: JSZip) => {
      zip.generateAsync({ type: "blob" }).then((blob: any) => {
        saveAs(blob, "result.zip");
      });
    });
}

export function getTextFileRequest(
  url: URL,
  successCallback: (response: any) => void
) {
  return axios
    .get(url.href, { headers: { "Content-Type": "text/plain" } })
    .then((resSuccess: any) => {
      if (resSuccess.status === 200) {
        successCallback(resSuccess);
      } else {
        console.log(resSuccess);
      }
    })
    .catch((resError: any) => console.log(resError));
}

export function postRequest(
  url: URL,
  payload: any,
  successCallback: (response: any) => void,
  errorCallback: (response: any) => void
) {
  return axios
    .post(url.href, payload)
    .then((resSuccess: any) => {
      if (resSuccess.status === 200) {
        successCallback(resSuccess);
      } else {
        console.log(resSuccess);
      }
    })
    .catch((resError: any) => {
      processError(resError);
      errorCallback(resError);
    });
}

function processError(resError: AxiosError) {
  if (resError.message === "Network Error") {
    notification.error({
      message: "Network Error",
      description:
        "We are sorry, but server is currently not available. Please try again later.",
      duration: 5,
    });
  } else if (resError.message === "Request failed with status code 500") {
    notification.error({
      message: "Server Error",
      description:
        "There was a problem when processing your request. If you want to help us improve the service, please tell us how this happened.",
      duration: 10,
    });
  } else {
    notification.error({
      message: "Unknown error sending request. This is likely a bug",
      description: resError.message,
      duration: 10,
    });
    //debugIfDev();
  }
}
