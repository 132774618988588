import React from "react";
import { LabelizerLayoutDataProps } from "../LabelizerLayout";
import {
  MetExclusionParameterConfig,
  ParameterConfig,
} from "../../../Types/ParameterConfig";
import * as _ from "lodash";
import { Checkbox, InputNumber, Select, Space } from "antd";
import { SmartSlider } from "../../../GenericComponents/SmartSlider";
import { weights } from "../../../Constants/Weights";
import { sensitivity } from "../../../Constants/Sensitivity";
import Info from "../../../GenericComponents/info";
import { Helpertexts } from "../../../Constants/HelperTexts";
import { ParameterName, toFileTag } from "../../../Constants/Parameters";

type Props = LabelizerLayoutDataProps & {
  parameterName: ParameterName;
};
export const ParameterSliders = (props: Props) => {
  const { data, changeData, parameterName } = props;

  const defaultParameterObject: ParameterConfig = {
    name: parameterName,
    file_tag: toFileTag(parameterName),
    sensitivity: sensitivity[1],
    weight: weights[1],
    used: false,
  };
  const defaultMetExclusion: MetExclusionParameterConfig = {
    ...defaultParameterObject,
    distance: 3,
    solventExposure: 3,
    aminoAcid: "MET",
  };

  const currentParameter: ParameterConfig =
    data.advancedParameters[parameterName] || defaultParameterObject;

  const onClickCheckbox = () => {
    if (currentParameter.used) {
      let newDataObject = _.cloneDeep(data);
      newDataObject.advancedParameters[parameterName] = undefined;
      changeData(newDataObject);
    } else {
      let newDataObject = _.cloneDeep(data);
      if (parameterName === ParameterName.AAExclusion) {
        newDataObject.advancedParameters[parameterName] = {
          ...defaultMetExclusion,
          used: true,
        };
      } else {
        newDataObject.advancedParameters[parameterName] = {
          ...defaultParameterObject,
          used: true,
        };
      }
      changeData(newDataObject);
    }
  };

  const aaExclusion = (
    <Space className="aa-exclusion-parameter">
      <label>
        <span>
          Distance{" "}
          <Info infotext="A Methionine with sufficient solvent exposure must be more than this many Ångström away" />
        </span>
        <InputNumber
          defaultValue={7}
          name="methioninExclusionRadius"
          // The typing is definitely too broad (the antd docs also say so) but it silences pycharm
          onChange={(value: string | number | undefined) => {
            let newDataObject = _.cloneDeep(data);
            (
              newDataObject.advancedParameters[
                parameterName
              ] as MetExclusionParameterConfig
            ).distance = value as number;
            changeData(newDataObject);
          }}
        />
      </label>
      <label>
        <span>
          Solvent exposure{" "}
          <Info
            infotext="A close Methionine will be ignored if it's buried, that is the
        solvent exposure is lower than this threshold (given as 1 divided by residue depth)"
          />
        </span>
        <InputNumber
          min={0}
          max={1}
          defaultValue={0.35}
          name="methioninExclusionSolventExposure"
          onChange={(value: string | number | undefined) => {
            let newDataObject = _.cloneDeep(data);
            (
              newDataObject.advancedParameters[
                parameterName
              ] as MetExclusionParameterConfig
            ).solventExposure = value as number;
            changeData(newDataObject);
          }}
        />
      </label>
      <label>
        <span>
          Amino Acid{" "}
          <Info infotext="Ban a different amino acid instead of Methionine" />
        </span>
        <Select
          defaultValue="Met"
          onChange={(value: string) => {
            let newDataObject = _.cloneDeep(data);
            (
              newDataObject.advancedParameters[
                parameterName
              ] as MetExclusionParameterConfig
            ).aminoAcid = value;
            changeData(newDataObject);
          }}
          options={[
            { value: "ALA", label: "Alanine" },
            { value: "ARG", label: "Arginine" },
            { value: "ASN", label: "Asparagine" },
            { value: "ASP", label: "Aspartic acid" },
            { value: "CYS", label: "Cysteine" },
            { value: "GLU", label: "Glutamic acid" },
            { value: "GLN", label: "Glutamine" },
            { value: "GLY", label: "Glycine" },
            { value: "HIS", label: "Histidine" },
            { value: "ILE", label: "Isoleucine" },
            { value: "LEU", label: "Leucine" },
            { value: "LYS", label: "Lysine" },
            { value: "MET", label: "Methionine" },
            { value: "PHE", label: "Phenylalanine" },
            { value: "PRO", label: "Proline" },
            { value: "SER", label: "Serine" },
            { value: "THR", label: "Threonine" },
            { value: "TRP", label: "Tryptophan" },
            { value: "TYR", label: "Tyrosine" },
            { value: "VAL", label: "Valine" },
          ]}
        />
      </label>
    </Space>
  );

  const usedContent = (
    <div className="parameter">
      <div className="weight">
        Weight
        <SmartSlider
          changeData={(value: string) => {
            let newDataObject = _.cloneDeep(data);
            let newConfig: ParameterConfig | undefined = _.cloneDeep(
              newDataObject.advancedParameters[parameterName]
            );
            if (newConfig !== undefined) {
              newConfig.weight = value;
              changeData(newDataObject);
            }
            newDataObject.advancedParameters[parameterName] = newConfig;
            changeData(newDataObject);
          }}
          options={weights}
          value={currentParameter.weight}
        />
      </div>
      <div className="sensitivity">
        sensitivity
        <SmartSlider
          changeData={(value: string) => {
            let newDataObject = _.cloneDeep(data);
            let newConfig: ParameterConfig | undefined = _.cloneDeep(
              newDataObject.advancedParameters[parameterName]
            );
            if (newConfig !== undefined) {
              newConfig.sensitivity = value;
            }
            newDataObject.advancedParameters[parameterName] = newConfig;
            changeData(newDataObject);
          }}
          options={sensitivity}
          value={currentParameter.sensitivity}
        />
      </div>
    </div>
  );

  const texts: Record<ParameterName, string> =
    Helpertexts["advancedParameters"];
  const infoText = texts[currentParameter.name];

  let futureFeature: boolean =
    currentParameter.name === ParameterName.TryptophaneProximity ||
    currentParameter.name === ParameterName.ChargeEnvironment;

  return (
    <>
      <div className="row">
        <div className="parameter">
          <Checkbox
            disabled={futureFeature}
            onClick={onClickCheckbox}
            checked={currentParameter.used}
            className="checkbox"
          />
          <div className="title">
            {currentParameter.name}
            {futureFeature ? (
              <span className="coming-soon"> - Coming soon</span>
            ) : (
              <Info infotext={infoText} />
            )}
          </div>
        </div>
        {currentParameter.used ? usedContent : null}
      </div>
      {currentParameter.used && parameterName === ParameterName.AAExclusion
        ? aaExclusion
        : null}
      <div className="border" />
    </>
  );
};
