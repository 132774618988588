import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Headline } from "../GenericComponents/Headline";

export interface WelcomePageProps {
  history: any;
}

const WelcomePage: React.SFC<WelcomePageProps> = (props) => {
  return (
    <div className="welcome-page">
      <Headline history={props.history} />
      <div className="welcome-content">
        <div className="goto-analysis-frame">
          <Button onClick={() => props.history.push("/analysis")}>
            Go to Analysis <ArrowRightOutlined />{" "}
          </Button>
        </div>
        <h1>Welcome, to the “Labelizer” webserver!</h1>
        <p>
          Are you regularly labelling proteins with dyes or other functional
          probes and would you like to systematically select residues as anchor
          groups (e.g., for cysteines or unnatural amino acids) with optimal
          chances for labelling success and for your biophysical assay (e.g.,
          FRET as a distance ruler) to work?
        </p>
        <h2>How can we help you:</h2>
        <p>
          Our free online service uses available structural information, i.e., a
          pdb deposited structure or a model that you made, and will suggest
          residues in the protein of interest for labelling. For this, we
          established a bioinformatics procedure using information from hundreds
          of successful labelling experiments on proteins that allows
          predictions for unknown samples to optimize the chance for a
          successful assay. Using parameters such as conservation score, solvent
          accessibility, secondary structure and others our server provides a
          list of residues in your protein that, according to our criteria,
          allow exchange of amino-acids to cysteines (or unnatural amino acids)
          and that should also label well. Furthermore, the algorithm predicts
          optimal residue pairs in a structure if you are planning a FRET
          experiment, for which we consider dye properties (linker length etc.)
          and their sensitive range (given by the Förster radius). Finally, if
          you trying to probe conformational changes and you have distinct
          protein structures available, the server identifies optimal candidate
          residue pairs that will show the largest changes of FRET efficiency in
          your experiment.
        </p>
        <h2>How you can help us:</h2>
        <p>
          If you like the service and we could support your research, we would
          be grateful if you send us feedback on successful and non-succesful
          labeling attempts (including information on the protein structure) as
          described in the <a href="/feedback">feedback</a> section, so we can
          improve our database and services.
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
