import { notification } from "antd";
import React, { ReactNode } from "react";
import { Headline } from "../../GenericComponents/Headline";
import { getRequest, getZip, postRequest } from "../../services/apicalls";
import { addressBuilder } from "../../utils/addressbuilder";
import ResultPendingPage from "./Components/ResultPendindLayout";
import ResultEvaluationPage, {
  AtomColorProperty,
  ResultFileType,
} from "./Components/ResultEvaluationPage";
import { LoadingBubbles } from "../../GenericComponents/LoadingBubbles";
import FileSaver from "file-saver";
import * as _ from "lodash";
import { RowNode } from "ag-grid-community";
import JSZip from "jszip";
import Papa from "papaparse";
import DistanceHeatmapContainer from "../../GenericComponents/DistanceHeatmapContainer";

type AnalysisResult = {
  filenames: string[];
  selectedFilename: string | undefined;
  fileCache: Record<string, any>;
};

export interface ResultContainerrops {
  history: any;
  match: any;
}

export interface ResultContainerState {
  mailAddress: string | undefined;
  results: AnalysisResult | undefined;
  requestPending: boolean;
  selectedRows: RowNode[];
  protein1: any;
  protein2: any;
}

class ResultContainer extends React.Component<
  ResultContainerrops,
  ResultContainerState
> {
  resultCheckingInterval: any;
  constructor(props: ResultContainerrops) {
    super(props);
    this.state = {
      mailAddress: undefined,
      results: undefined,
      requestPending: true,
      selectedRows: [],
      protein1: undefined,
      protein2: undefined,
    };
  }

  componentDidMount() {
    this.getResults();
  }

  subscribeAnalysisToMail = (mailAddress: string, jobId: string) => {
    const processProteinData = (response: any) => {
      this.setState({ mailAddress: undefined });
      notification.success({
        message: "Subscribtion successfull",
        duration: 3,
      });
    };

    postRequest(
      addressBuilder(`subscribe_to_analysis`),
      { mailAddress: mailAddress, jobId: jobId },
      processProteinData,
      (response: any) => {}
    );
  };

  onclickSubscribeHandler = () => {
    const { jobId } = this.props.match.params;
    if (this.state.mailAddress !== undefined) {
      this.subscribeAnalysisToMail(this.state.mailAddress, jobId);
    }
  };

  getResults = () => {
    const { jobId } = this.props.match.params;

    const processResults = (response: any) => {
      if (response.data.jobState === "TERMINATED") {
        const files: string[] = response.data.files;
        this.setState({
          results: {
            filenames: files,
            fileCache: {},
            selectedFilename: undefined,
          },
          requestPending: false,
          protein1: response.data.protein1,
          protein2: response.data.protein2,
        });
        const fretFile: string | undefined = files.find(
          (filename: string) =>
            filename.includes("FRET") && !filename.includes("LONG")
        );
        if (fretFile !== undefined) {
          this.onChangeSelectedFile(fretFile);
          return;
        }
        const labelscoreFile: string | undefined = files.find(
          (filename: string) =>
            filename.includes("LS") && !filename.includes("LONG")
        );
        if (labelscoreFile !== undefined) {
          this.onChangeSelectedFile(labelscoreFile);
          return;
        }
      } else {
        this.setState({ requestPending: false });
        this.resultCheckingInterval = setTimeout(this.getResults, 20000);
      }
    };

    const errorCallback = () => {
      this.setState({ requestPending: false });
    };

    getRequest(
      addressBuilder(`analysis/${jobId}`),
      processResults,
      errorCallback
    );
  };

  onChangeSelectedFile = (file: string) => {
    const { jobId } = this.props.match.params;

    this.setState({ selectedRows: [] });

    const processResults = (response: any) => {
      let result: undefined | any = undefined;
      const resultFileType: ResultFileType = this.extractFileType(file);
      if (resultFileType === undefined) {
        return;
      } else if (resultFileType === "LABELSCORE") {
        const rawData = response.data
          .replace("ID", "chain,residueIndex")
          .replace("Labeling Score", "score")
          .replace(/([a-zA-Z])(\d+)/g, "$1,$2");
        const results = Papa.parse(rawData, {
          header: true,
          dynamicTyping: true,
        });
        result = results.data;
      } else {
        const rawData = response.data
          .replace("ID", "chain1,residueIndex1,chain2,residueIndex2")
          .replace("FRET Score", "fretScore")
          .replace(/([a-zA-Z])(\d+)_([a-zA-Z])(\d+)/g, "$1,$2,$3,$4")
          .replace(/Labeling Score (\d) \(Res. (\d)\)/g, "LS$1R$2")
          .replace(/Labeling Score \(Res. (\d)\)/g, "LSR$1");
        const results = Papa.parse(rawData, {
          header: true,
          dynamicTyping: true,
        });
        result = results.data;
      }

      const roundtoNDigits = (number: number, n: number) => {
        return Math.floor(number * Math.pow(10, n)) / Math.pow(10, n);
      };

      result.forEach((resultEntry: any) => {
        Object.keys(resultEntry).forEach((key: string) => {
          if (typeof resultEntry[key] === "number") {
            resultEntry[key] = roundtoNDigits(resultEntry[key], 3);
          }
        });
      });

      result = result.filter((resultElement: any) => {
        const chainLetter: string = `${resultElement.chain1 || ""}${
          resultElement.chain || ""
        }`;

        return chainLetter.length === 1 && chainLetter.match(/[a-z]/i);
      });

      let newCache = _.cloneDeep(this.state.results?.fileCache) || {};

      newCache[file] = result;
      this.setState({
        requestPending: false,
        results: {
          filenames: this.state.results?.filenames || [],
          selectedFilename: file,
          fileCache: newCache,
        },
      });
    };

    const errorCallback = () => {
      this.setState({ requestPending: false });
    };

    getRequest(
      addressBuilder(`analysis/${jobId}/${file}`),
      processResults,
      errorCallback
    );
  };

  downloadFile = (filename: string) => {
    const { jobId } = this.props.match.params;
    const address: URL = addressBuilder(`analysis/${jobId}/${filename}`);

    const processResults = (response: any) => {
      let blob;
      if (filename.includes(".csv")) {
        blob = new Blob([response.data], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, filename);
      } else {
        var zip = new JSZip();
        zip.file("result.zip", response.data);
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "example.zip");
        });
      }

      this.setState({ requestPending: false });
    };

    const errorCallback = () => {
      this.setState({ requestPending: false });
    };

    if (filename.includes(".csv")) {
      getRequest(address, processResults, errorCallback);
    } else {
      getZip(address, () => {});
    }
  };

  extractFileType = (selectedFilename: string | undefined): ResultFileType => {
    if (typeof selectedFilename !== "string") {
      return undefined;
    }
    if (selectedFilename.includes("FRET")) {
      return "FRET";
    }
    return "LABELSCORE";
  };

  extractAtomColorProperties = (
    selectedResultFile: any,
    selectedResultFileType: ResultFileType,
    selectedRow?: any
  ): AtomColorProperty[] => {
    let coloredAtoms: AtomColorProperty[] = [];
    if (
      selectedResultFile !== undefined &&
      selectedResultFileType !== undefined
    ) {
      if (selectedResultFileType === "LABELSCORE") {
        coloredAtoms = selectedResultFile.map((result: any) => {
          const score: any =
            result.score ||
            result["Parameter Score (CS)"] ||
            result["Parameter Score (SE)"] ||
            result["Parameter Score (SS)"] ||
            result["Parameter Score (CR)"];
          return {
            residueIndex: result.residueIndex,
            score: score,
            proteinIndex: 1,
          };
        });
      } else if (
        selectedResultFileType === "FRET" &&
        this.state.selectedRows.length > 0
      ) {
        const data = this.state.selectedRows[0].data;
        coloredAtoms = [
          { residueIndex: data.residueIndex1, score: 2 },
          { residueIndex: data.residueIndex2, score: 2 },
        ];
      }
    }

    return coloredAtoms.concat([{ residueIndex: -1, score: 0 }]);
  };

  render() {
    const { jobId } = this.props.match.params;

    let content: ReactNode = null;
    const selectedResultFile: any | undefined =
      this.state.results?.fileCache[this.state.results.selectedFilename || ""];
    const selectedResultFileType: ResultFileType = this.extractFileType(
      this.state.results?.selectedFilename
    );
    const atomColorProperties: AtomColorProperty[] =
      this.extractAtomColorProperties(
        selectedResultFile,
        selectedResultFileType
      );

    const DistanceHeatmapContainerNode: React.ReactNode = (
      <>
        {" "}
        <DistanceHeatmapContainer jobId={jobId} />
      </>
    );

    if (this.state.requestPending) {
      content = <LoadingBubbles />;
    } else if (this.state.results !== undefined) {
      content = (
        <ResultEvaluationPage
          currentFileType={selectedResultFileType}
          atomColorProperties={atomColorProperties}
          selectedFile={this.state.results.selectedFilename}
          onChangeSelectedFile={this.onChangeSelectedFile}
          dataRows={selectedResultFile}
          filenames={this.state.results.filenames}
          downloadFile={this.downloadFile}
          selectRowHandler={(selectedRows: RowNode[]) =>
            this.setState({ selectedRows: selectedRows })
          }
          protein1={this.state.protein1}
          protein2={this.state.protein2}
          DistanceHeatmapContainerNode={DistanceHeatmapContainerNode}
        />
      );
    } else if (this.state.results === undefined) {
      content = (
        <ResultPendingPage
          onClickSubscribeHandler={this.onclickSubscribeHandler}
          jobId={jobId}
          onChangeMailAddressHandler={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => this.setState({ mailAddress: event.target.value })}
        />
      );
    }

    return (
      <div className="result-page">
        <Headline history={this.props.history} />
        {content}
      </div>
    );
  }
}

export default ResultContainer;
