import React from "react";
import PdbViewer from "../../LabelierPage/Components/PdbViewer";
import { Button, Input } from "antd";

export interface ResultPendingPageProps {
  jobId: string;
  onClickSubscribeHandler: () => void;
  onChangeMailAddressHandler: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const ResultPendingPage: React.FC<ResultPendingPageProps> = (props) => {
  const { jobId, onChangeMailAddressHandler, onClickSubscribeHandler } = props;
  return (
    <div>
      <div className="loading">
        <div>
          <PdbViewer screenSaverMode={true} pdbId={"1GFP"} index={-1} />
          <h2>Loading...</h2>
        </div>
      </div>
      <div className="result-text">
        <h2>Hello again,</h2>
        <p>
          our servers are processing your request and soon you will have your
          results. This process can take between seconds and minutes depending
          on the complexity of your request and the workload of our servers. We
          know that you might not want to keep this page open this long so here
          you have a unique link that will lead you back to this page. It will
          display your results as soon as the computaion is done:
        </p>
        <p>
          <a href={`${window.location.origin}/results/${jobId}`}>
            {window.location.origin}/results/{jobId}
          </a>
        </p>
        <p>
          Alternatively, you can enter your mail address here and we will send
          you a mail with the link as soon as the calculation is done:
        </p>
        <p>
          <div className="maillist-input-frame">
            <Input
              placeholder="t.aquaticus@example.com"
              onChange={onChangeMailAddressHandler}
              className="mail-input"
            />
            <Button onClick={onClickSubscribeHandler}>
              Subscribe to Result
            </Button>
          </div>
        </p>
        <p>
          This step is optional and we will delete your mail address immediately
          after the calculation if you choose to as we do not support user
          registration and do not store any userdata
        </p>
        <h2>One last thing:</h2>
        <p>
          We will keep developing this service and we want to keep improving it.
          But for this we need help. We would be very happy of you would revisit
          the result page after you have conducted your experiments to give us
          feedback whether it worked good or not. Again, this step is purely
          optional but you would help us improving which would benefit you and
          everyone who wants to use this service.
        </p>
      </div>
    </div>
  );
};

export default ResultPendingPage;
