import { ColDef, ColGroupDef, GridApi, RowNode } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Empty, Modal, Select } from "antd";
import React, { useState } from "react";
import PdbViewer from "../../LabelierPage/Components/PdbViewer";

export type ResultFileType = "FRET" | "LABELSCORE" | undefined;

export interface ResultEvaluationPageProps {
  filenames: string[];
  dataRows: any;
  atomColorProperties: AtomColorProperty[];
  onChangeSelectedFile: (file: string) => void;
  selectedFile: string | undefined;
  downloadFile: (filename: string) => void;
  currentFileType: ResultFileType;
  selectRowHandler: (selectedRows: RowNode[]) => void;
  protein1: any;
  protein2: any;
  DistanceHeatmapContainerNode: React.ReactNode;
}

export type AtomColorProperty = {
  residueIndex: number;
  score: number;
  proteinIndex?: number;
};

/*
const extractScoreNameFromFilename = (filename: string | undefined): string => {
  if (filename === undefined) {
    return 'Score';
  }
  const match: any = /_(.*).csv/g.exec(filename);

  let parameter: string | undefined;
  if (Array.isArray(match) && match.length > 1) {
    parameter = match[1];
  }

  if (parameter === 'LS' || parameter === 'LSlong') {
    return 'Labeling Score';
  } else if (parameter !== undefined) {
    return `Parameter Score ${parameter}`;
  }

  return 'Score';
};

const getColumnDef = (filename: string | undefined): (ColDef | ColGroupDef)[] => {
  return [
    { headerName: 'Chain', field: 'chain', filter: true, width: 100 },
    { headerName: 'Residue', field: 'residueIndex', sortable: true, filter: 'agNumberColumnFilter', width: 120 },
    { headerName: extractScoreNameFromFilename(filename), field: 'score', sortable: true, filter: 'agNumberColumnFilter', width: 200 }
  ];
};

const FRET_COLUMNDEF: (ColDef | ColGroupDef)[] = [
  { headerName: '', field: '', width: 50, checkboxSelection: true },
  { headerName: 'Chain 1', field: 'chain1', filter: true, width: 100 },
  { headerName: 'Residue 1', field: 'residueIndex1', sortable: true, filter: 'agNumberColumnFilter', width: 120 },
  { headerName: 'Chain 2', field: 'chain2', filter: true, width: 100 },
  { headerName: 'Residue 2', field: 'residueIndex2', sortable: true, filter: 'agNumberColumnFilter', width: 120 },
  {
    headerName: 'Score',
    field: 'fretScore',
    sortable: true,
    filter: 'agNumberColumnFilter',
    width: 160
  },
  { headerName: 'Distance 1', field: 'distance1', sortable: true, filter: 'agNumberColumnFilter', width: 160 },
  { headerName: 'Distance 2', field: 'distance2', sortable: true, filter: 'agNumberColumnFilter', width: 160 },
  { headerName: 'LS Mean', field: 'labelscoreMean', sortable: true, filter: 'agNumberColumnFilter', width: 160 }
];
 */

const ColumnDefinitionsDictionary: Record<string, ColDef | ColGroupDef> = {
  chain: { headerName: "Chain", field: "chain", filter: true, width: 100 },
  residueIndex: {
    headerName: "Residue",
    field: "residueIndex",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 120,
  },
  chain1: { headerName: "Chain 1", field: "chain1", filter: true, width: 100 },
  residueIndex1: {
    headerName: "Residue 1",
    field: "residueIndex1",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 120,
  },
  chain2: { headerName: "Chain 2", field: "chain2", filter: true, width: 100 },
  residueIndex2: {
    headerName: "Residue 2",
    field: "residueIndex2",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 120,
  },
  fretScore: {
    headerName: "Score",
    field: "fretScore",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  score: {
    headerName: "Score",
    field: "score",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  CS: {
    headerName: "Conservation score",
    field: "CS",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 180,
  },
  SE: {
    headerName: "Solvent exposure",
    field: "SE",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  CR: {
    headerName: "Cysteine resemblance",
    field: "CR",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  SS: {
    headerName: "Secondary Structure",
    field: "SS",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  ME: {
    headerName: "Amino Acid exclusion",
    field: "ME",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  distance1: {
    headerName: "Distance 1",
    field: "distance1",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  distance2: {
    headerName: "Distance 2",
    field: "distance2",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  labelscoreMean: {
    headerName: "LS Mean",
    field: "labelscoreMean",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 160,
  },
  "Distance": {
    headerName: "Distance",
    field: "Distance",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 180,
  },
  "Distance 1": {
    headerName: "Distance 1",
    field: "Distance 1",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 180,
  },
  "Distance 2": {
    headerName: "Distance 2",
    field: "Distance 2",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 180,
  },
  "Labeling Score": {
    headerName: "Labeling Score",
    field: "Labeling Score",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 180,
  },
  "Mean Labeling Score": {
    headerName: "Mean Labeling Score",
    field: "Mean Labeling Score",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 180,
  },
  LSR1: {
    headerName: "Labeling Score (Res. 1)",
    field: "Labeling Score (Res. 1)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  LSR2: {
    headerName: "Labeling Score (Res. 2)",
    field: "Labeling Score (Res. 2)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  LS1R1: {
    headerName: "Labeling Score 1 (Res. 1)",
    field: "LS1R1",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  LS1R2: {
    headerName: "Labeling Score 1 (Res. 2)",
    field: "LS1R2",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  LS2R1: {
    headerName: "Labeling Score 2 (Res. 1)",
    field: "LS2R1",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  LS2R2: {
    headerName: "Labeling Score 2 (Res. 2)",
    field: "LS2R2",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  "Parameter Score (CS)": {
    headerName: "Parameter Score (CS)",
    field: "Parameter Score (CS)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  "Parameter Score (SE)": {
    headerName: "Parameter Score (SE)",
    field: "Parameter Score (SE)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  "Parameter Score (CR)": {
    headerName: "Parameter Score (CR)",
    field: "Parameter Score (CR)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  "Parameter Score (SS)": {
    headerName: "Parameter Score (SS)",
    field: "Parameter Score (SS)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
  "Parameter Score (ME)": {
    headerName: "Parameter Score (ME)",
    field: "Parameter Score (ME)",
    sortable: true,
    filter: "agNumberColumnFilter",
    width: 250,
  },
};

const ResultEvaluationPage: React.FC<ResultEvaluationPageProps> = (props) => {
  const {
    filenames,
    dataRows,
    onChangeSelectedFile,
    selectedFile,
    downloadFile,
    atomColorProperties,
    currentFileType,
    protein1,
    protein2,
  } = props;

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const SelectFileContent: React.ReactNode = (
    <Select
      onChange={onChangeSelectedFile}
      className="select-pdb"
      placeholder="Select a file to display"
    >
      {filenames
        .filter((filename: string) => !filename.includes(".zip"))
        .map((filename: string) => (
          <Select.Option value={filename}>{filename}</Select.Option>
        ))}
    </Select>
  );

  const pdbId: string | undefined = selectedFile
    ? selectedFile.split("_")[0]
    : undefined;
  const pdbId2: string | undefined =
    selectedFile && selectedFile.split("_").length > 2
      ? selectedFile.split("_")[1]
      : undefined;

  let pdbEntryId1 = undefined;
  if (pdbId !== undefined) {
    pdbEntryId1 = protein1.pdb_id === pdbId ? protein1.id : protein2.id;
  }

  let pdbEntryId2 = undefined;
  if (pdbId2 !== undefined) {
    pdbEntryId2 = protein1.pdb_id === pdbId2 ? protein1.id : protein2.id;
  }

  let gridOptions: any;
  let table: React.ReactNode = null;

  let columnDefs: (ColDef | ColGroupDef)[] = [];
  if (Array.isArray(dataRows) && dataRows.length > 0) {
    const firstElement = dataRows[0];
    const keys = Object.keys(firstElement);
    keys.forEach((key: string) => {
      columnDefs.push(ColumnDefinitionsDictionary[key]);
    });
  }

  // Generally, we found that Methionine has not been empirically excluded from
  // environment of the labeled resides, but we want to make it so that people
  // can design future experiments around it
  const highlightMethionine = (params: any) => {
    if (
      params.data.hasOwnProperty("ME") &&
      params.data.ME > 0 &&
      params.data.ME < 0.5
    ) {
      return { background: "salmon" };
    }
  };

  if (currentFileType === "LABELSCORE") {
    gridOptions = {
      columnDefs: columnDefs,
      rowData: dataRows || [],
    };
    table = (
      <div className="ag-theme-alpine" style={{ height: 500, width: 450 }}>
        <AgGridReact
          onGridReady={onGridReady}
          getRowStyle={highlightMethionine}
          {...gridOptions}
        ></AgGridReact>
      </div>
    );
  } else if (currentFileType === "FRET") {
    gridOptions = {
      columnDefs: [
        { headerName: "", field: "", width: 50, checkboxSelection: true },
        ...columnDefs,
      ],
      rowData: dataRows || [],
    };
    table = (
      <div className="ag-theme-alpine" style={{ height: 500, width: 1100 }}>
        <AgGridReact
          onRowSelected={(event: any) =>
            gridApi ? props.selectRowHandler(gridApi.getSelectedNodes()) : {}
          }
          onGridReady={onGridReady}
          getRowStyle={highlightMethionine}
          {...gridOptions}
        ></AgGridReact>
      </div>
    );
  }

  return (
    <div className="result-evaluation">
      <div className="evaluation-frame">
        <div className="pdb-viewer-frame">
          {selectedFile ? (
            <PdbViewer
              id={pdbEntryId1}
              pdbId={pdbId}
              index={`${selectedFile}-1`}
              currentFileType={currentFileType}
              userSelectedAtoms={atomColorProperties.filter(
                (entry: AtomColorProperty) =>
                  entry.score !== undefined &&
                  (entry.proteinIndex === 1 || entry.proteinIndex === undefined)
              )}
            />
          ) : (
            <Empty description="Please choose a result file" />
          )}
        </div>
        {pdbId2 ? (
          <div className="pdb-viewer-frame">
            {selectedFile ? (
              <PdbViewer
                id={pdbEntryId2}
                pdbId={pdbId2}
                index={`${selectedFile}-2`}
                currentFileType={currentFileType}
                userSelectedAtoms={atomColorProperties.filter(
                  (entry: AtomColorProperty) =>
                    entry.score !== undefined &&
                    (entry.proteinIndex === 2 ||
                      entry.proteinIndex === undefined)
                )}
              />
            ) : (
              <Empty description="Please choose a result file" />
            )}
          </div>
        ) : null}
        <div className="selection-frame">
          <div className="select-pdb-frame">
            {SelectFileContent}{" "}
            {currentFileType === "FRET" ? (
              <Button onClick={() => setModalOpen(true)}>
                Open Distance Heatmap
              </Button>
            ) : null}
          </div>
          <div className="pdb-table-frame">{table}</div>
        </div>
        <div className="file-download-frame">
          <table>
            <tr>
              <th>Click here to download a file</th>
            </tr>
            {filenames.map((filename: string) => (
              <tr>
                <span
                  onClick={() => downloadFile(filename)}
                  className="file-link"
                >
                  {filename}
                </span>
              </tr>
            ))}
          </table>
        </div>
      </div>
      <Modal
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {props.DistanceHeatmapContainerNode}
      </Modal>
    </div>
  );
};

export default ResultEvaluationPage;
