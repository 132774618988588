import React from "react";
import { Headline } from "../GenericComponents/Headline";

import PSBlogo from "../PSB_image.png";

export interface WorkingGroupPageContainerProps {
  history: any;
}

export interface WorkingGroupPageContainerState {}

class WorkingGroupContainer extends React.Component<
  WorkingGroupPageContainerProps,
  WorkingGroupPageContainerState
> {
  componentDidMount() {}

  render() {
    return (
      <div className="informational-page">
        <Headline history={this.props.history} />
        <div className="informational-content">
          <h2>What articles should I cite when I use this webserver?</h2>

          <h3>
            <u>
              Main publication
            </u>
          </h3>
          <p>
            Gebhardt C., Bawidamann P., Schuetze K., Moya Munoz G. G., Spring A.-K., Griffith D., Lipfert L., and Cordes T. (2023)
            “Labelizer: systematic selection of protein residues for covalent fluorophore labeling.”{" "}
            <a href="https://www.biorxiv.org/content/10.1101/2023.06.12.544586v1.abstract"><i>bioRxiv</i>.</a>
          </p>
          <br />

          <h3>
            <u>
              When you use the standard settings of the labelizer, please cite the following papers for 
              calculation of the conservation score, solvent exposure parameter and accessible volume calculations: 
            </u>
          </h3>
          <p>
            Ashkenazy H., Abadi S., Martz E., Chay O., Mayrose I., Pupko T., and
            Ben-Tal N. (2016) “ConSurf 2016: an improved methodology to estimate
            and visualize evolutionary conservation in macromolecules”,{" "}
            <i>Nucl. Acids Res.</i>, 44(W1), W344-50.
          </p>
          <p>
            Sanner, M. F., Olson, A. J. & Spehner, J.-C. (1996) “Reduced
            surface: An efficient way to compute molecular surfaces”,{" "}
            <i>Biopolymers</i>, 38, 305–320.
          </p>
          <p>
            Kalinin, S., Peulen, T., Sindbert, S., Rothwell, P. J., Berger, S.,
            Restle, T., Goody, R. S., Gohlke, H., & Seidel, C. A. M. (2012). A
            toolkit and benchmark study for FRET-restrained high-precision
            structural modeling. <i>Nature Methods</i>, 9(12), 1218–1225.
          </p>

          <h2>Who we are?</h2>
          <p>
            This webserver was designed and set up by Christian Gebhardt and Pascal Bawidamann 
            and is currently maintained by Robin Schenk. Christian Gebhardt developed and programmed 
            the labelizer algorithms during his PhD project in biophysics at LMU Munich between 
            2017-2021. The project was in the Cordes lab, a biophysics research group at the Faculty 
            of Biology in the LMU Biocenter. The group specializes in the development and application 
            of new imaging and spectroscopic processes that map the structure and function of biological 
            and biochemical processes in space and time. The lab’s goal is to examine the behavior of 
            individual biomolecules to gain unique insights into the underlying molecular mechanisms.
          </p>
          <div className="logo">
            <a href="https://www.mikrobiologie.biologie.uni-muenchen.de/forschung/ag_cordes/index.html">
              <img src={PSBlogo} alt="" width="250" height="152" />
            </a>
          </div>
          <a href="https://www.mikrobiologie.biologie.uni-muenchen.de/forschung/ag_cordes/index.html">
            https://www.mikrobiologie.biologie.uni-muenchen.de/forschung/ag_cordes/index.html
          </a>
          <br />
        </div>
      </div>
    );
  }
}

export default WorkingGroupContainer;
