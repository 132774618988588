import { LoadingOutlined } from "@ant-design/icons";
import { InputNumber } from "antd";
import React from "react";
import { Helpertexts } from "../../../Constants/HelperTexts";
import CustomSelect from "../../../GenericComponents/CustomSelect";
import Info from "../../../GenericComponents/info";
import { LabelizerLayoutDataProps } from "../LabelizerLayout";

type FluorescenceSegmentProps = LabelizerLayoutDataProps & {
  fluorohpores: string[];
};

export const FluorescenceSegment = (props: FluorescenceSegmentProps) => (
  <div className="choose-fluorescence">
    <h2>3. Fluorophore Selection</h2>
    <div className="select-frame">
      {props.data.analysisMode === "FRET_TWO_STRUCTURES_APO_HOLO" ||
      props.data.analysisMode === "FRET_ONE_STRUCTURE" ? (
        <>
          <div className="donor">
            Donor Fluorophore{" "}
            <Info infotext={Helpertexts["FluorohporeDonor"]} />
            <CustomSelect
              options={props.fluorohpores}
              changeHandler={(val: any) =>
                props.changeData({ ...props.data, fluorophore1: val })
              }
            />
          </div>
          <div className="acceptor">
            Acceptor Fluorophore{" "}
            <Info infotext={Helpertexts["FluorohporeAcceptor"]} />
            <CustomSelect
              options={props.fluorohpores}
              changeHandler={(val: any) =>
                props.changeData({ ...props.data, fluorophore2: val })
              }
            />
          </div>
          <div className="foerster-radius">
            <div>
              <div>
                Förster Radius (Å)
                <Info infotext={Helpertexts["foersterRadius"]} />{" "}
              </div>
              <InputNumber
                min={0}
                value={props.data.foersterRadius}
                disabled={
                  props.data.fluorophore1 === "" ||
                  props.data.fluorophore2 === "" ||
                  props.data.foersterRadius === -1
                }
                onChange={(val: number | string | undefined) =>
                  props.changeData({
                    ...props.data,
                    foersterRadius:
                      typeof val !== "string" ? val : props.data.foersterRadius,
                  })
                }
              />
            </div>
            <div className="foerster-loading-spinner">
              {props.data.foersterRadius === -1 ? (
                <LoadingOutlined spin />
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <div className="singlelabel">
          Fluorophore
          <Info infotext={Helpertexts["fluorophore"]} />{" "}
          <CustomSelect
            options={props.fluorohpores}
            changeHandler={(val: any) =>
              props.changeData({ ...props.data, fluorophore1: val })
            }
          />
        </div>
      )}
    </div>
  </div>
);
