export class Protein {
  id: number;
  pdbId: string;
  chains: string;
  selectedChain: string | undefined;
  title: string;
  includes_conservation_scores: boolean;
  constructor(proteinResponse: any) {
    this.id = proteinResponse.id;
    this.pdbId = proteinResponse.pdbId;
    this.chains = proteinResponse.chains;
    const splittedChains: string[] = `${this.chains}`.split(",");
    if (splittedChains.length > 0 && splittedChains[0] !== "") {
      this.selectedChain = splittedChains[0];
    }
    this.title = proteinResponse.title;
    this.includes_conservation_scores =
      proteinResponse.includes_conservation_scores;
  }
}

export type chainOption = { label: string; value: string };
export function convertChainStringToChainOptions(
  chainstring: string
): chainOption[] {
  return chainstring
    .split(",")
    .filter((chain: string) => chain !== "")
    .sort()
    .map((chain: string) => {
      return { label: chain, value: chain };
    });
}
