import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export interface InfoProps {
  infotext: string;
}

const Info: React.FC<InfoProps> = (props) => {
  const { infotext } = props;

  return (
    <span className="info">
      <Tooltip placement="rightBottom" title={infotext}>
        <InfoCircleOutlined className="icon" />
      </Tooltip>
    </span>
  );
};

export default Info;
