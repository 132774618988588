import React, { useEffect } from "react";
//Helmet for Google-Meta-Data
import { Helmet } from "react-helmet";

import "antd/dist/antd.css";
import "./App.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import LabelizerContainer from "./Pages/LabelierPage/LabelizerContainer";
import WelcomePage from "./Pages/WelcomePage";
import ResultContainer from "./Pages/ResultPage/ResultContainer";
import ResultFormPage from "./Pages/ResultFormPage";
import WorkingGroupContainer from "./Pages/WorkingGroupPageContainer";
import FaqContainer from "./Pages/FaqPageContainer";
import FeedbackContainer from "./Pages/FeedbackPageContainer";

function App() {
  useEffect(() => {
    document.title = "Labelizer - A Protein Label Toolkit";
  }, []);
  return (
    <Router>
      <Helmet>
        <title>Labelizer - A Protein Label Toolkit</title>
      </Helmet>
      <div className="app-root">
        <Switch>
          <Route exact path="/results/:jobId" component={ResultContainer} />
          <Route exact path="/results" component={ResultFormPage} />
          <Route exact path="/welcome" component={WelcomePage} />
          <Route exact path="/analysis" component={LabelizerContainer} />
          <Route exact path="/workingGroup" component={WorkingGroupContainer} />
          <Route exact path="/faq" component={FaqContainer} />
          <Route exact path="/feedback" component={FeedbackContainer} />
          <Route path="/">
            <Redirect to="/welcome" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
