import React, { useState } from "react";
import { LoadingBubbles } from "../../../GenericComponents/LoadingBubbles";
import { PendingRequest } from "../../../Types/PendingRequest";
import { ChoosePdbidOrFile } from "./ChoosePdbidOrFile";
import { DisplaySelectedProtein } from "./DisplaySelectedProtein";
import { ProteinSegmentProps } from "./ProteinSegment";
import {
  chainOption,
  convertChainStringToChainOptions,
} from "../../../Types/Protein";

export type ProteinUploadSegmentProps = ProteinSegmentProps & {
  changeProteinName: (name: string) => void;
  index: number;
  clearPdb: () => void;
  changeSelectedChain: (chain: string) => void;
  postProteinDB: (
    payload: { pdbID: string; chains: string; payload: any },
    fieldIndex: number
  ) => void;
};

const ProteinUploadSegment: React.FC<ProteinUploadSegmentProps> = (
  props: ProteinUploadSegmentProps
) => {
  const {
    data,
    loadProteinDB,
    index,
    changeProteinName,
    changeSelectedChain,
    clearPdb,
    pendingRequests,
    postProteinDB,
  } = props;

  const protein: string | undefined =
    index === 1 ? data.protein1 : data.protein2;
  const pdbId: string | undefined = index === 1 ? data.pdbId1 : data.pdbId2;
  const dbName: string | undefined = index === 1 ? data.dbName1 : data.dbName1;
  const id: string | undefined =
    index === 1 ? data.proteinId1 : data.proteinId2;
  const chains: string | undefined =
    index === 1 ? data.selectableChainString1 : data.selectableChainString2;
  const selectedChain: string | undefined =
    index === 1 ? data.selectedChain1 : data.selectedChain2;

  const [selectValue, setSelectValue] = useState<undefined | string>(undefined);
  const [selectValueAfDb, setSelectValueAfDb] = useState<undefined | string>(
    undefined
  );

  if (
    pendingRequests.findIndex(
      (request: PendingRequest) => request.id === `${index}`
    ) > -1
  ) {
    return <LoadingBubbles />;
  }

  const chainOptions: chainOption[] = convertChainStringToChainOptions(
    `${chains}`
  );

  if (pdbId === undefined) {
    return (
      <ChoosePdbidOrFile
        postProteinDB={postProteinDB}
        selectValue={selectValue}
        selectValueAfDb={selectValueAfDb}
        loadProteinDB={loadProteinDB}
        index={index}
        setSelectValue={setSelectValue}
        setSelectValueAfDb={setSelectValueAfDb}
        protein={protein}
        changeProteinName={changeProteinName}
      />
    );
  } else {
    return (
      <DisplaySelectedProtein
        index={index}
        chainOptions={chainOptions}
        changeSelectedChain={changeSelectedChain}
        selectedChain={selectedChain}
        protein={protein}
        pdbId={pdbId}
        dbName={dbName}
        id={id}
        clearPdb={clearPdb}
        changeProteinName={changeProteinName}
      />
    );
  }
};

export default ProteinUploadSegment;
